/**
 * @class app.instafeed
 */

(function(app, $){
	
	//Documentation can be find here:
	//http://instafeedjs.com/
	
	var settings = {
		//The basic set of instafeed parameters
		//clientId 
		//{Required} Your API client id from Instagram
		clientId: null,
		
		//accessToken 
		//A valid oAuth token. Can be used in place of a client ID
		accessToken: null,
		
		//target
		//The ID of a DOM element you want to add the images to
		target: 'instafeed',
		
		//template 
		//Custom HTML template to use for images
		template: null,
		
		//get 
		//Customize what Instafeed fetches
		get: 'popular',
		
		//tagName 
		//Name of the tag to get. Use with get: 'tagged'.
		tagName: null,
		
		//locationId 
		//Unique id of a location to get. Use with get: 'location'
		locationId: null,
		
		//userId 
		//Unique id of a user to get. Use with get: 'user'
		userId: null,
		
		//sortBy
		//Sort the images in a set order
		sortBy: 'none',
		
		//links
		//Wrap the images with a link to the photo on Instagram
		links: true,
		
		//limit
		//Maximum number of Images to add
		limit: null,
		
		//resolution 
		//Size of the images to get
		resolution: 'thumbnail',
		
		//useHttp 
		//Use the standard http:// for images if set to true
		useHttp: false,
		
		//mock
		//Fetch data without inserting images into DOM if set to true
		//Use with success callback
		mock: false
	};
	
	/**
	 * Sets a content with data retrieved from instagram
	 * @input {Object} Settings for instafeed
	 * @return undefined
	 **/
	
	function initInstafeed(options){
		options = options || {};
		
		var params = $.extend({}, settings, options),
			userFeed;
		
		if($('#'+params.target).length){
			userFeed = new Instafeed(params);
			userFeed.run();
		}
	}
	
	app.instafeed = {
		init: function(options){
			initInstafeed(options);
		}
	};
	
}(window.app = window.app || {}, jQuery));
