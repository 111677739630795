$.widget("ui.thumbnailsSlider", {
	options: {
		itemCount : 3,
		itemsContainer : '.js-thumbnails',
		itemSelector : '.js-thumbnail',
		arrowUpClass : '',
		arrowDownClass : '',
		currentHeight : 0,
		currentWidth : 0,
		resizeTimer : null,
		loop : true,
		firstImageClass : 'js-first',
		lastImageClass : 'js-last',
		heightRecalculate : '',
		reverse : false
	},

	_create : function () {
		var self = this;
		if(this._isInDialog() && this._isViewPrtSmall()){
			self.changeHeight();
			self.initDialogResize();
		}
		if (self.getItemsCount() > self.options.itemCount) {
			self.buildArrow();
			if(!self.options.heightRecalculate){
				self.changeHeight();
			}
			self.initClickOnArrowUp();
			self.initClickOnArrowDown();
			//Some browser like FF fires `resize` continiously so we should declare timer and compare height and width
			$(window).on('resize', function() {
				var windowHeight = $(window).height(),
					windowWidth = $(window).width();
				if ( (windowHeight != self.options.currentHeight) || (windowWidth != self.options.currentWidth) ){
					if (self.options.resizeTimer) {
						clearTimeout(self.options.resizeTimer);
					}
					if(!self.options.heightRecalculate){
						self.options.resizeTimer = setTimeout(function () {
							self.changeHeight();
						}, 200);
					}
					self.options.currentHeight = windowHeight;
					self.options.currentWidth = windowWidth;
				}
			});
		}
		$(window.document).trigger('thumbnails.zoomed', {
			wrapper : self.element.parents('.fancybox-opened')
		});
	},
	
	changeHeight : function() {
		this.element.find(this.options.itemsContainer).height('');
		var self = this,
			oneItemHeight = self.getOneItemHeight(),
			newHeight = oneItemHeight * self.options.itemCount;

		if (this._isInDialog() && this._isViewPrtSmall()){
			var containerInnerHeight = window.innerHeight;
			var itemCount = self.element.find('li.js-thumbnail').length;
			if(itemCount > 0) {
				newHeight = itemCount * oneItemHeight;
			}
			if(containerInnerHeight < newHeight) {
				newHeight = containerInnerHeight;
			} 
			this.element.css({
				"bottom" : 0 
			});
		}
		self.element.find(self.options.itemsContainer).height(newHeight);
		self.element.find(self.options.itemsContainer).css('overflow', 'hidden');
		self.element.trigger('height_changed');
		
	},
	_isViewPrtSmall : function(){
		return (this.getOneItemHeight() * (+this.options.itemCount + 1)) > window.innerHeight; //counting with reserve of 1 item,so thumbnails wouldn't be back to back
	},
	_isInDialog : function(){
		return !!this.element.parents(".fancybox-opened").length;
	},
	getOneItemHeight : function() {
		var self = this;
		
		return self.element.find(self.options.itemSelector).outerHeight(true);
	},
	getOneItemWidth : function(){
		var self = this;
		
		return self.element.find(self.options.itemSelector).outerWidth(true);
	},
	initDialogResize : function() {
		var elemHeight = parseInt(window.innerHeight/(this.options.itemCount)),
			transfrmRatio = this.getOneItemHeight() / this.getOneItemWidth(),
			elemWidth = elemHeight / transfrmRatio;
			
		this.element.css({
			"max-width" : elemWidth 
		});
	},
	buildArrow : function() {
		 var self = this;
		 
		 //append to container top arrow
		 if (!$('.js-arrow_up', self.element).length) {
			 self.element.prepend("<div class='js-arrow_up " + self.options.arrowUpClass + "'></div>");
		 }
		 
		//append to container bottom arrow
		 if (!$('.js-arrow_down', self.element).length) {
			 self.element.append("<div class='js-arrow_down " + self.options.arrowDownClass + "'></div>");
		 }
		 
		 self.changeArrowsVisibility();
	},
	
	changeArrowsVisibility : function () {
		var self = this;
		var arrowUp = $('.js-arrow_up', self.element);
		var arrowDown = $('.js-arrow_down', self.element);
		if (self.options.loop) {
			// zoom carousel arrows  init
			if (arrowUp.css('display') === 'none') {
				arrowUp.css('display', 'block');
			}
			if (arrowDown.css('display') === 'none') {
				arrowDown.css('display', 'block');
			}			
			return;
		}
		if (!self.element.find('.' + self.options.firstImageClass)) {
			return;
		} 
		var firstVisible  = self.element.find(self.options.itemSelector)[0];
				
		if ($(firstVisible).hasClass(self.options.firstImageClass)) {
			arrowUp.css('display', 'none');
		} else {
			arrowUp.css('display', 'block');
		}
		
		if ($(firstVisible).hasClass(self.options.lastImageClass)) {
			arrowDown.css('display', 'none');
		} else {
			arrowDown.css('display', 'block');
		}
	},

	initClickOnArrowUp : function() {
		var self = this;
		
		var arrowUp = $('.js-arrow_up', self.element);
		
		self._on(arrowUp, {
			click: function (event) {
				event.stopPropagation();
				
				if (self.options.reverse) {
					self.moveLastItem();
				}
				else {
					self.moveFirstItem();
				}
				
				self.changeArrowsVisibility();
			} });
	},
	
	
	initClickOnArrowDown : function() {
		var self = this;
		
		var arrowDown = $('.js-arrow_down', self.element);
		
		self._on(arrowDown, {
			click: function (event) {
				event.stopPropagation();
				
				var scrollTop = $(window).scrollTop();
				
				if (self.options.reverse) {
					self.moveFirstItem();
				}
				else {
					self.moveLastItem();
				}
				
				$(window).scrollTop(scrollTop);
				self.changeArrowsVisibility();
			} });
	},

	getItemsCount : function() {
		 var self = this;
		 
		 var currentCount = self.element.find(self.options.itemSelector).length;
		 return currentCount;
	},
	
	moveLastItem : function() {
		var lastItem = this.element.find(this.options.itemSelector).last().clone(true);
		this.element.find(this.options.itemSelector).last().remove();
		this.element.find(this.options.itemsContainer).prepend(lastItem);
	},
	
	moveFirstItem : function() {
		var firstItem = this.element.find(this.options.itemSelector).first().clone(true);
		this.element.find(this.options.itemSelector).first().remove();
		this.element.find(this.options.itemsContainer).append(firstItem);
	}
});