/* global define*/
define('app.components.global.spinbar', function (require, exports) {
	/**
	 * @module global.spinbar
	 */
	var $ = require('$'),
		$document = require('$doc');
	
	function validateData( $this ) {
		var $closestForm = $this.closest('form');
		
		return !$closestForm.length || $closestForm.valid();
	}
	
	function initializeEvents() {
		$document.on('click', '.js-spinbar', function (event) {
			var $this = $(this);
			if( validateData( $this ) ) {
				if ($this.hasClass('js-input_spin_bar')){
					$this.parent().addClass('m-spin_bar'); 
				} else{
					$this.addClass('m-spin_bar');
				}
			}
		});
	}
	
	exports.init = function () {
		initializeEvents();
	};
});