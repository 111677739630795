/**
 * @class app.components.global.tooltips
 */
(function (app, $) {
	var $cache = {};
	
	/**
	 * @private
	 * @function
	 * @description Binds event to the place holder (.blur)
	 */
	function initializeEvents() {
		$cache.tooltipElements.tooltip({
			track: true,
			showURL: false,
		    bodyHandler: function() {
				// add a data attribute of data-layout="some-class" to your tooltip-content container if you want a custom class
				var tooltipClass = "";
				if( tooltipClass = $(this).find('.tooltip-content').data("layout") ) {
					tooltipClass = " class='" + tooltipClass + "' ";
				}
	        	return "<div " + tooltipClass + ">" + $(this).find('.tooltip-content').html() + "</div>";
			},
			showURL: false
		});
	}

	/*************** app.components.searchplaceholder public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.tooltips = {
	
		init : function () {
			$cache = {
				tooltipElements : $('.tooltip')
			};
			
			initializeEvents();
		}
			
			
	};
}(window.app = window.app || {}, jQuery));