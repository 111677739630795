/**
 *
 * Temporary overlapped global.scrol for waiting on merge in OneApp
 *
 * See {@link http://gromo.github.io/jquery.scrollbar/}
 * See {@link https://github.com/gromo/jquery.scrollbar/}
 *
 * @class app.components.global.scroll
 */
;(function (app, $) {
	'use strict';

	var $cache = {},
		resizeTimeout,
		snapScrollLocked = false,
		isMac = app.device.isMacOS(),
		isMobile = app.device.isMobileUserAgent();
	/**
	 * @private
	 * @function
	 * @description initialize class cache
	 */
	function initCache() {
		$cache = {
			scrollToElems: $('.js-scrollToElement'),
			elements : {
				document : $(window.document),
				body : $('html'),
				productTiles : $('html').find(".l-product_tiles"),
				scrollNavPoints : null,
				allHandledScrollElements : [],
				mainHeader: isMobile ? $('.js-header_wrapper') : $('.js-header_main')
			},
			preferences : {
				onResizeTimeout : 300,
				scrollElementsSelector : '.js-scroll',
				scrollToDefaultOptions : {
					dataAttr: 'scroll-to',
					duration: isMac ? 1100 : 500
				},
				scrollDefaultOptions : {
					markers : false, // show markers navigation
					
					markersTargetSelector : '.js-scroll-nav-point',
					markersMarkerCss : 'b-scroll-markers_item',
					markersMarkerCssActive : 'm-active',
					markersWrapperCss : 'b-scroll-markers',
					markersContainer : '.js-scroll-nav-point-navigation', // container to place markers. scrollArea - is a setting by default
					markersPosition: 'append', //(append, prepend, before, after) positioning

					scrollArea: null,
					disabled: false, //will be used default browser if it needed 
					disabledCssClass: 'm-scroll-disabled', //it will be added if disabled
					
					scrollToNextBtn : false, // show scroll-down button
					scrollToNextBtnContainer : '.js-scroll-to-next-point', // container to place scrollbar. scrollArea - is a setting by default
					scrollToNextBtnPosition : 'append', // (append, prepend, before, after) positioning

					external: false, //enable or disable external scrolling
					disableScrollBar: false,
					
					externalXAxisContainer : '.js-scroll-x-axis', // If container not found it will be added according externalXAxisContainerPosition
					externalXAxisContainerPosition : 'append', //(append, prepend, before, after) positioning
					externalYAxisContainer : '.js-scroll-y-axis', // If container not found it will be added according externalYAxisContainerPosition
					externalYAxisContainerPosition : 'append', //(append, prepend, before, after) positioning
					
					snappingEnabled : false,
					snappingMode: 'anchors', //(screen,anchors) scroll to anchors or by screens. Screen means scroll by $scrollArea.height()
					snappingContextSelector : '.js-scroll-snapping-context',
					snappingScrollDuration: 500,
					snappingScrollTimeout: 100,
					
					arrowKeysEnabled : false
					
				}
			}
		};
	}

	function updateEvents($wrapper) {
		initScrolls($wrapper);
		initScrollToLinks($wrapper);
		initScrollsFromPageContext($wrapper);
	}
	
	
	/**
	 * @private
	 * @function
	 * @description Gets configs from app.page configuration object and passes it to initScroll function
	 */
	function initScrollsFromPageContext($wrapper) {
		var scrollObjects = app.page.scroll;
		if(!scrollObjects) return;
		
		for(var i = 0; i < scrollObjects.length; i++) {
			var scrollObject = scrollObjects[i];
			if('scrollWrapper' in scrollObject && $(scrollObject['scrollWrapper']).length && !$(scrollObject['scrollWrapper']).data('scrollbar')) {
				var $scrollContainers = null;
				if($wrapper) {
					$scrollContainers = $wrapper.find(scrollObject['scrollWrapper']);
				} else {
					$scrollContainers = $(scrollObject['scrollWrapper']);
				}
				if($scrollContainers.length) {
					$scrollContainers.each(function( index ) {
						if($cache.elements.body.find("body").hasClass("s-search")){
							initScrollSimple($(this), scrollObject);
						}else {
							initScroll($(this), scrollObject);
						}
						
					});
				}
			}
		};
	}
	
	
	/**
	 * @private
	 * @function
	 * @description initialize class events
	 */
	function initEvents() {

		updateEvents($cache.elements.body);
		$cache.elements.document.on('flyout.reload', function( event, data ) {
			if (data && 'wrapper' in data) {
				updateEvents(data.wrapper);
			}
		});
		$(window).resize(function() {
			clearTimeout(resizeTimeout);
			resizeTimeout = setTimeout(onResizeEnd, $cache.preferences.onResizeTimeout);
		});
		$cache.scrollToElems.on('click', function() {
			var elem = $($(this).data('scroll-options'));
			var headerHeight = $cache.elements.mainHeader.height();
			
			if(!elem.length) {
				return;
			}
			
			$('html, body').animate({
				scrollTop: elem.position().top - headerHeight
			}, 200);
		});
	}

	function onResizeEnd() {
		for(var i=0; i < $cache.elements.allHandledScrollElements.length; i++) {
			var $element = $cache.elements.allHandledScrollElements[i],
				elementOptions = getScrollContextOptions($element);
			if (elementOptions.snappingEnabled) {
				fixSnappingAfterResizeEnd($element);
				var $nav = $element.data('scroll-nav-containers');
				if ($nav) {
					updateSrollNavPoints($element, $nav);
				}
			}
		}
	}
	function fixSnappingAfterResizeEnd($context) {
		var elementOptions = getScrollContextOptions($context);
		if(!snapScrollLocked) {
			if ('screen' === elementOptions.snappingMode) {
				var scrollAreaElementScrollHeight = $context.context.clientHeight,
					contextScrollHeight = $context.prop('scrollHeight'),
					scrollSteps = 0;

				if (scrollAreaElementScrollHeight) {
					scrollSteps = Math.round(contextScrollHeight / scrollAreaElementScrollHeight);
				}
				if (!scrollSteps) {
					return;
				}
				var scrollTop = $context.scrollTop();
				for (var i=0; i < scrollSteps; i++) {
					if(scrollAreaElementScrollHeight * i >= scrollTop) {
						snapScrollLocked = true;

						var prevScreen = scrollAreaElementScrollHeight * (i - 1),
							nextScreen = scrollAreaElementScrollHeight * i,
							topDelta = scrollTop - prevScreen,
							bottomDelta = nextScreen - scrollTop;
						vScrollToOffset(topDelta > bottomDelta ? nextScreen : prevScreen, $context, null, function() {
							snapScrollLocked = false;
							$cache.elements.document.trigger('scrolldown.finished', {
								index: scrollSteps[i]
							});
						});
						break;
					}
				}
			} else if ('anchors' === elementOptions.snappingMode) {
				var $context = $context || $cache.elements.body,
					$points = $points || $context.find(elementOptions.markersTargetSelector);
		
				if ($points.length === 0) {
					return;
				}
				
				var currentIndex  = $context.data('snapping-index');
				if (currentIndex === undefined) {
					var activePoint = scrollSnappingGetActivePoint($context, $points);
					currentIndex = activePoint ? activePoint.index : 0;
				}
				var $targretPoint = $points.eq(currentIndex);
				if($targretPoint.length) {
					snapScrollLocked = true;
					$context.data('snapping-index', currentIndex);
					scrollNavPointsSetActiveNavigationItem($context, currentIndex);
					vScrollToElement($targretPoint, $context, null, function() {
						snapScrollLocked = false;
						$cache.elements.document.trigger('scrolldown.finished', {
							index: currentIndex
						});
					});
				}
			}
		}
	}
	
	function getScrollContextOptions($element) {
		return $element.data('scroll-options-obj-cache') || $.extend({}, $cache.preferences.scrollDefaultOptions, $element.data('scroll-options') || {});
	}
	/**
	 * @public
	 * @function
	 * @description Init custom scroll for wrapper
	 *
	 * @param {Object} wrapper Context
	 */
	function initScrolls($wrapper) {
		var scrollContainers = $wrapper.find($cache.preferences.scrollElementsSelector);
		scrollContainers.each(function( index ) {
			if($cache.elements.body.find("body").hasClass("s-search")){
				initScrollSimple($(this));
			}else {
				initScroll($(this));
			}
			
		});
	}
	
	function initScrollSimple($element, options){
		
		if(!$element || !$element.is(':visible') || !$element.height() || !$element.width()) { 
			return;
		}
		
		$cache.elements.allHandledScrollElements.push($element);
		var elementOptions = $.extend({}, $cache.preferences.scrollDefaultOptions, $element.data('scroll-options') || {}, options || {});
		$element.data('scroll-options-obj-cache', elementOptions);

		if ('scrollbar' in $element) {

			if (elementOptions.disabled) {
				$element.addClass(elementOptions.disabledCssClass);
				return;
			}

			var pluginOptions = {
					disableScrollBar : elementOptions.disableScrollBar
			};
			if (elementOptions.scrollArea) {
				var scrollArea = $(elementOptions.scrollArea);
				if (scrollArea.length) {
					pluginOptions['scrollArea'] = scrollArea;
				}
			}
			
			if (elementOptions.external) {
				var $scrollYElement = createExternalScrollElement(),
					$scrollXElement = createExternalScrollElement(),
					placeScrollElement = function($element, elementOptions, optionName, axisElement) {
						var defPosition = elementOptions[optionName + 'Position'],
							$targetElement;
						if (optionName in elementOptions) {
							$targetElement = $(elementOptions[optionName]);
							if(!$targetElement.length) {
								$targetElement = $element;
							}
						} else {
							$targetElement = $element;
						}
						$targetElement[defPosition](axisElement);
					};
					
				placeScrollElement($element, elementOptions, 'externalYAxisContainer', $scrollYElement);
				placeScrollElement($element, elementOptions, 'externalXAxisContainer', $scrollXElement);

				$.extend(pluginOptions, {
					snappingEnabled : elementOptions.snappingEnabled,
					scrollx : $scrollXElement,
					scrolly : $scrollYElement
				});
			}
			$element.scrollbar(pluginOptions);
			$cache.elements.document.trigger('scroll.added', {
				container : $element
			});
			
			//must be after plugin
			var $scrollAreaElement;
			if('scrollArea' in pluginOptions) {
				$scrollAreaElement = pluginOptions.scrollArea;
			} else {
				$scrollAreaElement = $element.closest('.scroll-wrapper');
				if (!$scrollAreaElement.length) {
					$scrollAreaElement = $cache.elements.body;
				}
			}
			
			scrollSimpleSnapping($element, null, elementOptions);
			
			$cache.elements.body.bind( 'keydown', function ( e ) {
			    switch (e.keyCode) {
			        case 38:
			            $cache.elements.productTiles.get(0).scrollTop -= 100;
			            e.preventDefault();
			   			e.stopPropagation();
			            break;
			        case 40:
			        	e.preventDefault();
			    		e.stopPropagation();
			            $cache.elements.productTiles.get(0).scrollTop += 100;
			            $cache.elements.body.trigger("scroll"); // to make "infiniteScroll" know about product scrolling in LN-CC
			            break;
			    }
			});
		}
	}
	
	function scrollSimpleSnapping($context, points, options) {
		var $context = $context || $cache.elements.body,
			$contextScrollContent = $context.find('.b-scroll-content');

		//fix for context with custom scrolls
		if($contextScrollContent.length) {
			$context = $contextScrollContent;
		}
		
		//overridden of scroll zone by specific configuration parameter scrollZone that can be set in Configuration.ds
		var $scrollContext = (options && 'scrollZone' in options) ? $cache.elements.body.find(options.scrollZone) : $context;
		
		var enableScroll = true;
		
		if(!$scrollContext.length) {
		    $scrollContext = $context;
		}
		
		//now, only wheel, without scrollbar
		// do not use jquery ".scroll" event
		$scrollContext.on( 'mousewheel DOMMouseScroll', function ( e ) {
			var eDef = e.originalEvent,
				delta = eDef.wheelDelta || -eDef.detail,
				$currentContextElement = $context,
				timeOut,
				additionallyDelayTime = 0;
			
			if( isMac && 'additionallyDelayTime' in options ) {
				additionallyDelayTime = options.additionallyDelayTime > 0 ? options.additionallyDelayTime : additionallyDelayTime;
			} else {
				enableScroll = !snapScrollLocked;
			}

			if(enableScroll) {
				clearTimeout(timeOut);
				if(delta > 0) {
					//scroll up
					$cache.elements.productTiles.get(0).scrollTop -= 100;
				} else {
					//scroll down
					$cache.elements.productTiles.get(0).scrollTop += 100;
					$cache.elements.body.trigger("scroll"); // to make "infiniteScroll" know about product scrolling in LN-CC
				}
				enableScroll = false;
				timeOut = setTimeout( function() {
					enableScroll = true;
				}, 50);
			}
			e.preventDefault();
		});
		
		//context.scroll.totop trigger handler scrolls context to the top 
		$cache.elements.document.on('context.scroll.totop', function() {
			$context.animate({scrollTop: 0}, $cache.preferences.scrollToDefaultOptions.duration);
		});
	}
	
	
		
	function initScroll($element, options){
		
		if(!$element || !$element.is(':visible') || !$element.height() || !$element.width()) { 
			return;
		}
		
		$cache.elements.allHandledScrollElements.push($element);
		var elementOptions = $.extend({}, $cache.preferences.scrollDefaultOptions, $element.data('scroll-options') || {}, options || {});
		$element.data('scroll-options-obj-cache', elementOptions);

		if ('scrollbar' in $element) {

			if (elementOptions.disabled) {
				$element.addClass(elementOptions.disabledCssClass);
				return;
			}

			var pluginOptions = {
					disableScrollBar : elementOptions.disableScrollBar,
					ignoreMobile : elementOptions.ignoreMobile
			};
			if (elementOptions.scrollArea) {
				var scrollArea = $(elementOptions.scrollArea);
				if (scrollArea.length) {
					pluginOptions['scrollArea'] = scrollArea;
				}
			}
			
			if (elementOptions.external) {
				var $scrollYElement = createExternalScrollElement(),
					$scrollXElement = createExternalScrollElement(),
					placeScrollElement = function($element, elementOptions, optionName, axisElement) {
						var defPosition = elementOptions[optionName + 'Position'],
							$targetElement;
						if (optionName in elementOptions) {
							$targetElement = $(elementOptions[optionName]);
							if(!$targetElement.length) {
								$targetElement = $element;
							}
						} else {
							$targetElement = $element;
						}
						$targetElement[defPosition](axisElement);
					};
					
				placeScrollElement($element, elementOptions, 'externalYAxisContainer', $scrollYElement);
				placeScrollElement($element, elementOptions, 'externalXAxisContainer', $scrollXElement);

				$.extend(pluginOptions, {
					snappingEnabled : elementOptions.snappingEnabled,
					scrollx : $scrollXElement,
					scrolly : $scrollYElement
				});
			}
			$element.scrollbar(pluginOptions);
			$cache.elements.document.trigger('scroll.added', {
				container : $element
			});
			
			//must be after plugin
			var $scrollAreaElement;
			if('scrollArea' in pluginOptions) {
				$scrollAreaElement = pluginOptions.scrollArea;
			} else {
				$scrollAreaElement = $element.closest('.scroll-wrapper');
				if (!$scrollAreaElement.length) {
					$scrollAreaElement = $cache.elements.body;
				}
			}
			//cache area in scrolled element (can be dynamicly added by plugin)
			$element.data('scrollAreaElement', $scrollAreaElement);
			if (elementOptions.markers) {
				var $navTarget = $(elementOptions.markersContainer),
					$navElement = $('<div/>', {
						'class': elementOptions.markersWrapperCss
					});
				if (!$navTarget.length) {
					$navTarget = $element;
				}
				$navTarget[elementOptions.markersPosition]($navElement);
				updateSrollNavPoints($element, $navElement);
				$element.data('scroll-nav-containers', $navElement);
				$element.trigger('scroll.nav.added', {
					container : $element,
					nav : $navElement
				});
			}
			if (elementOptions.scrollToNextBtn) {
				var $scrollToNextBtnContainer = $(elementOptions.scrollToNextBtnContainer);
				if($scrollToNextBtnContainer.length) {
					var $scrollToNextBtnElement = $('<div/>', {
							'class' : 'b-scroll-scroll_down',
							text : app.resources.GLOBAL_SCROLL_SCROLLTONEXT,
							click : function() {
								var circular = $(this).data($cache.preferences.scrollToDefaultOptions.dataAttr + '-next-point-circular');
								scrollToNextPoint($element, null, circular);
							}
						});
					$scrollToNextBtnContainer[elementOptions.scrollToNextBtnPosition]($scrollToNextBtnElement);
					$element.data('scroll-nextbutton-element', $scrollToNextBtnElement);
					$element.trigger('scroll.nextbutton.added', {
						scrollContainer : $element,
						elementContainer: $scrollToNextBtnContainer,
						element : $scrollToNextBtnElement
					});
				}
			}
			
			if(!$element.data('scrollbar')) {
				$element.data("scrollbar", true);
			}
		}
		if (elementOptions.snappingEnabled) {
			scrollSnapping($element, null, elementOptions);

			if (elementOptions.arrowKeysEnabled) {
				$(document).keydown(function(e) {
					switch(e.which) {
						case 38: // up
							var circular = $(this).data($cache.preferences.scrollToDefaultOptions.dataAttr + '-next-point-circular');
							scrollToPrevPoint($element, null, circular);
						break;
						case 40: // down		
							var circular = $(this).data($cache.preferences.scrollToDefaultOptions.dataAttr + '-next-point-circular');
							scrollToNextPoint($element, null, circular);
						break;
				
						default: return; // exit this handler for other keys
					}
					e.preventDefault(); // prevent the default action (scroll / move caret)
				});
			}
		}
		
	}
	
	function createExternalScrollElement(wrapperCssClass) {
		return $(
			'<div class="b-scroll-bar">'
				+ '<div class="b-scroll-bar_outer">'
					+ '<div class="b-scroll-bar_size"></div>'
					+ '<div class="b-scroll-bar_track"></div>'
					+ '<div class="b-scroll-bar_control"></div>'
				+ '</div>'
			+ '</div>'
		);
	}
	
	function initScrollToLinks($wrapper) {
		$wrapper.on('click', '[data-' + $cache.preferences.scrollToDefaultOptions.dataAttr + ']', function(e) {
			e.preventDefault();
			var $this = $(this),
				$element = $($this.data($cache.preferences.scrollToDefaultOptions.dataAttr)),
				$context = $($this.data($cache.preferences.scrollToDefaultOptions.dataAttr + '-context'));
			if (!$context.length) {
				$context = $cache.elements.body;
			}
			vScrollToElement($element, $context);
		});
	}

	function scrollSnapping($context, points, options) {
		var $context = $context || $cache.elements.body,
			$contextScrollContent = $context.find('.b-scroll-content');

		//fix for context with custom scrolls
		if($contextScrollContent.length) {
			$context = $contextScrollContent;
		}
		
		//overridden of scroll zone by specific configuration parameter scrollZone that can be set in Configuration.ds
		var $scrollContext = (options && 'scrollZone' in options) ? $cache.elements.body.find(options.scrollZone) : $context;
		
		var enableScroll = true;
		
		if(!$scrollContext.length) {
		    $scrollContext = $context;
		}
		
		//now, only wheel, without scrollbar
		// do not use jquery ".scroll" event
		$scrollContext.on( 'mousewheel DOMMouseScroll', function ( e ) {
			var eDef = e.originalEvent,
				delta = eDef.wheelDelta || -eDef.detail,
				$currentContextElement = $context,
				timeOut,
				additionallyDelayTime = 0;
			
			if( isMac && 'additionallyDelayTime' in options ) {
				additionallyDelayTime = options.additionallyDelayTime > 0 ? options.additionallyDelayTime : additionallyDelayTime;
			} else {
				enableScroll = !snapScrollLocked;
			}

			if(enableScroll) {
				clearTimeout(timeOut);
				if(delta > 0) {
					//scroll up
					scrollToPrevPoint($currentContextElement);
				} else {
					//scroll down
					scrollToNextPoint($currentContextElement);
				}
				enableScroll = false;
				timeOut = setTimeout( function() {
					enableScroll = true;
				}, +additionallyDelayTime);
			}
			e.preventDefault();
		});
		
		//context.scroll.totop trigger handler scrolls context to the top 
		$cache.elements.document.on('context.scroll.totop', function() {
			$context.animate({scrollTop: 0}, $cache.preferences.scrollToDefaultOptions.duration);
		});
	}
	

	
	/**
	 * @private
	 * @function
	 * @description Update scroll Nav points with navigations
	 */
	
	function updateSrollNavPoints($context, $nav) {
		$nav.empty().data('scroll-context-element', $context);
		var elementOptions = getScrollContextOptions($context);
		if ('screen' === elementOptions.snappingMode) {
			var scrollAreaElementScrollHeight = $context.context.clientHeight,
				contextScrollHeight = $context.prop('scrollHeight'),
				scrollSteps = 0,
				currentIndexAlreadySetupped = false;
				
			if (scrollAreaElementScrollHeight) {
				scrollSteps = Math.round(contextScrollHeight / scrollAreaElementScrollHeight);
			}
			if (scrollSteps < 2) {
				return;
			}
			var scrollTop = $context.scrollTop();
			for(var i=0; i < scrollSteps; i++) {
				var $navItem = (function(offset, $context, index) {
					return createNavPointActionElement(function (e) {
						scrollNavPointsSetActiveNavigationItem($context, index);
						vScrollToOffset(offset, $context);
						$cache.elements.document.trigger('scroll.finished', {
							index : $(this).index()
						});
					}, elementOptions.markersMarkerCss);
				})(scrollAreaElementScrollHeight * i, $context, i);
				if(!currentIndexAlreadySetupped && scrollAreaElementScrollHeight * (i+1) > scrollTop) {
					currentIndexAlreadySetupped = true;
					$navItem.addClass(elementOptions.markersMarkerCssActive);
				}
				$nav.append($navItem);
			}
		} else if ('anchors' === elementOptions.snappingMode) {
			var $points = $context.find(elementOptions.markersTargetSelector);
			var currentIndex = $context.data('snapping-index') || 0;
			$points.each(function( index ) {
				var $onePoint = $(this);
				var $navItem = (function($point, $context, index) {
					return createNavPointActionElement(function (e) {
						$context.data('snapping-index', index);
						scrollNavPointsSetActiveNavigationItem($context, index);
						vScrollToElement($point, $context);
						$cache.elements.document.trigger('scroll.finished', {
							index : $(this).index()
						});
					}, elementOptions.markersMarkerCss);
				})($onePoint, $context, index);
				if(index == currentIndex) { 
					$navItem.addClass(elementOptions.markersMarkerCssActive);
				}
				$nav.append($navItem);
			});
		}
		return $nav;
	}

	function createNavPointActionElement(eventCallback, cssClass) {
		eventCallback = eventCallback || $.noop;
		return $('<a/>', {
			'class': cssClass,
			href: '#',
			click: eventCallback
		});
	}
	function scrollToPrevPoint($context, $points, circular) {
		var elementOptions = getScrollContextOptions($context);
		if(!snapScrollLocked) {
			if ('screen' === elementOptions.snappingMode) {
				var scrollAreaElementScrollHeight = $context.context.clientHeight,
					contextScrollHeight = $context.prop('scrollHeight'),
					scrollSteps = 0;
				
				if($points){
					scrollAreaElementScrollHeight = scrollAreaElementScrollHeight  / $points;
				}
				
				if (scrollAreaElementScrollHeight) {
					scrollSteps = Math.round(contextScrollHeight / scrollAreaElementScrollHeight);
				}
				
				if (!scrollSteps) {
					return;
				}
				var scrollTop = $context.scrollTop();
				for (var i=0; i < scrollSteps; i++) {
					if(scrollTop && scrollAreaElementScrollHeight*i >= scrollTop) {
						snapScrollLocked = true;
						scrollNavPointsSetActiveNavigationItem($context, i-1);
						vScrollToOffset(scrollAreaElementScrollHeight*(i-1), $context, null, function() {
							snapScrollLocked = false;
							$cache.elements.document.trigger('scrollup.finished', {
								index: scrollSteps[i]
							});
						});
						break;
					}
				}
			} else if ('anchors' === elementOptions.snappingMode) {
				var $context = $context || $cache.elements.body;
				
					$points = $points || $context.find(elementOptions.markersTargetSelector);
		
				if ($points.length === 0) {
					return;
				}
				
				var currentIndex  = $context.data('snapping-index');
				if (currentIndex === undefined) {
					var activePoint = scrollSnappingGetActivePoint($context, $points);
					currentIndex = activePoint ? activePoint.index : 0;
				}
				//scroll down
				if (!currentIndex && !circular) {
					return;
				}
				currentIndex--;
				var $targretPoint = $points.eq(currentIndex);
				if (!$targretPoint.length) {
					if(circular && $points.length) {
						currentIndex = $points.length - 1;
					} else {
						currentIndex = 0;
					}
				}
				if($targretPoint.length) {
					snapScrollLocked = true;
					$context.data('snapping-index', currentIndex);
					scrollNavPointsSetActiveNavigationItem($context, currentIndex);
					vScrollToElement($targretPoint, $context, null, function() {
						snapScrollLocked = false;
						$cache.elements.document.trigger('scrollup.finished', {
							index: currentIndex
						});
					});
				}
			}
		}
	}
	/**
	 * @private
	 * @function
	 * @description Scroll to next point in context
	 *
	 * @param {Object} context Context element
	 * @param {Object} points Points collection element
	 * @param {Boolean} circular Scroll from last to first 
	 */
	function scrollToNextPoint($context, $points, circular) {
		var elementOptions = getScrollContextOptions($context);
		if(!snapScrollLocked) {
			if ('screen' === elementOptions.snappingMode) {
				var scrollAreaElementScrollHeight = $context.context.clientHeight,
					contextScrollHeight = $context.prop('scrollHeight'),
					scrollSteps = 0;
				
				if($points){
					scrollAreaElementScrollHeight = scrollAreaElementScrollHeight  / $points;
				}
				if (scrollAreaElementScrollHeight) {
					scrollSteps = Math.round(contextScrollHeight / scrollAreaElementScrollHeight);
				}
				if (!scrollSteps) {
					return;
				}
				var scrollTop = $context.scrollTop();
				for (var i=0; i < scrollSteps; i++) {
					if(scrollAreaElementScrollHeight*i > scrollTop) {
						snapScrollLocked = true;
						scrollNavPointsSetActiveNavigationItem($context, i);
						vScrollToOffset(scrollAreaElementScrollHeight*i, $context, null, function() {
							snapScrollLocked = false;
							$cache.elements.document.trigger('scrolldown.finished', {
								index: scrollSteps[i]
							});
						});
						break;
					}
				}
			} else if ('anchors' === elementOptions.snappingMode) {
				var $context = $context || $cache.elements.body,
					$points = $points || $context.find(elementOptions.markersTargetSelector);
		
				if ($points.length === 0) {
					return;
				}
				
				var currentIndex  = $context.data('snapping-index');
				if (currentIndex === undefined) {
					var activePoint = scrollSnappingGetActivePoint($context, $points);
					currentIndex = activePoint ? activePoint.index : 0;
				}
				//scroll down
				currentIndex++;
				var $targretPoint = $points.eq(currentIndex);
				if (!$targretPoint.length) {
					if(circular) {
						currentIndex = 0;
					} else {
						currentIndex = ($points.length > 0) ? $points.length - 1 : 0;
					}
				}
				if($targretPoint.length) {
					snapScrollLocked = true;
					$context.data('snapping-index', currentIndex);
					scrollNavPointsSetActiveNavigationItem($context, currentIndex);
					vScrollToElement($targretPoint, $context, null, function() {
						snapScrollLocked = false;
						$cache.elements.document.trigger('scrolldown.finished', {
							index: currentIndex
						});
					});
				}
			}
		}
	}
	/**
	 * @private
	 * @function
	 * @description Set active class for navigation item by item index
	 *
	 * @param {Number} itemIndex index of specified item
	 */
	function scrollNavPointsSetActiveNavigationItem($context, itemIndex) {
		var $navs = $context.data('scroll-nav-containers');
		if ($navs && $navs.length) {
			var elementOptions = getScrollContextOptions($context);
			$navs.each(function( index ) {
			var $navCont = $(this),
				navPoints = $navCont.find('.' + elementOptions.markersMarkerCss);
				navPoints.removeClass(elementOptions.markersMarkerCssActive);
				navPoints.eq(itemIndex).addClass(elementOptions.markersMarkerCssActive);
			});
		}
	}
	
	function scrollSnappingGetActivePoint($context, $points) {
		var heightSummary = 0;
		for (var i = 0; i < $points.size(); i++) {
			var $currentPoint = $points.eq(i);
			heightSummary += $currentPoint.outerHeight();
			if (isOnScreen($context, $currentPoint) || (i == ($points.size()-1) && $context.scrollTop() >= heightSummary - $currentPoint.outerHeight())) {
				var $nextPoint = $points.eq(i+1);
				if($nextPoint.length && isOnScreen($context, $currentPoint)) {
					var scrollTop = $context.scrollTop();
					if(scrollTop > (heightSummary - $currentPoint.outerHeight() / 2)) {
						return {
							index :i+1,
							point: $nextPoint
						};
					} else {
						return {
							index :i,
							point: $currentPoint
						};
					}
				} else {
					return {
						index :i,
						point: $currentPoint
					};
				}
			}
		}
	}
	function isOnScreen($context, $element) {
		var viewport = {
			top : $context.scrollTop(),
			left : $context.scrollLeft()
		};
		viewport.right = viewport.left + $context.width();
		viewport.bottom = viewport.top + $context.height();
		
		var bounds = $element.offset();
		bounds.right = bounds.left + $element.outerWidth();
		bounds.bottom = bounds.top + $element.outerHeight();
		
		return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
	}
	/**
	 * @function
	 * @description Vertical scroll, for element to a given offset
	 *
	 * @param {Object} Target Element
	 * @param {Object} Element of context (optionally)
	 * @param {Number} duration Animation duration (optionally)
	 * @param {Function} callback Callback function
	 */
	function vScrollToElement( $element, $context, duration, callback ) {
		if ($element.length) {
			var $context = $context || $cache.elements.body,
				offset = $element[0].offsetTop; //offset().top will return value with fractions, and we will have 1px of clearance
			vScrollToOffset(offset, $context, duration, callback);
		}
	}
	/**
	 * @function
	 * @description Vertical scroll, for element to a given offset
	 *
	 * @param {String} offset The offset
	 * @param {Object} Element of context (optionally)
	 * @param {Number} duration Animation duration (optionally)
	 * @param {Function} callback Callback function
	 */
	function vScrollToOffset(offset, $context, duration, callback) {
		$context = $context || $cache.elements.body;
		callback = callback || $.noop;
		duration = duration || $cache.preferences.scrollToDefaultOptions.duration;
		$context.animate({ scrollTop: offset }, duration, callback);
		$cache.elements.document.trigger('search.anchorscroll', {
			context : '.js-product_tiles',
			scrollTop : offset
		});
	}
	/*************** app.components.global.scroll public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.scroll = {
		/**
		 * @public
		 * @function
		 * @description Initialize component
		 */
		init : function (preferences) {

			//$cache.preferences = $.extend($cache.preferences, preferences || {});
			initCache();
			initEvents();
		},

		/**
		 * @function
		 * @description Init custom scroll for wrapper
		 *
		 * @param {Object} wrapper Context
		 */
		initScrolls : initScrolls,
		
		/**
		 * @function
		 * @description Vertical scroll, for element to a given offset
		 *
		 * @param {Object} Target Element
		 * @param {Object} Element of context (optionally)
		 * @param {Number} duration Animation duration (optionally)
		 */
		vScrollToElement : vScrollToElement,

		/**
		 * @function
		 * @description Vertical scroll, for element to a given offset
		 *
		 * @param {String} offset The offset
		 * @param {Object} Element of context (optionally)
		 * @param {Number} duration Animation duration (optionally)
		 */
		vScrollToOffset : vScrollToOffset,
		
		/**
		 * @private
		 * @function
		 * @description Update scroll Nav points with navigations
		 * 
		 * @param {Object} Element of context (optionally)
		 * @param {Object} Element of navigation (optionally)
		 */
		updateSrollNavPoints : updateSrollNavPoints,
				/**
		 * @private
		 * @function
		 * @description initialize scroll snapping for context
		 *
		 * @param {Object} context Element of context (optionally)
		 * @param {Object} points Points elements collection. If null, will try to search via default points selector
		 */
		scrollSnapping : scrollSnapping,
		/**
		 * @private
		 * @function
		 * @description Scroll to next point in context
		 *
		 * @param {Object} context Context element
		 * @param {Object} points Points collection element
		 * @param {Boolean} circular Scroll from last to first 
		 */
		scrollToPrevPoint : scrollToPrevPoint,
		/**
		 * @private
		 * @function
		 * @description Scroll to next point in context
		 *
		 * @param {Object} context Context element
		 * @param {Object} points Points collection element
		 * @param {Boolean} circular Scroll from last to first 
		 */
		scrollToNextPoint : scrollToNextPoint
	};
}(window.app = window.app || {}, jQuery));