(function(window, document, $, undefined) {
	"use strict";
	
    var innerClass = "b-custom_placeholder",
    	outerClass = "b-custom_placeholder-wrapper",
    	showEvents = 'blur',
    	hideEvents = 'keyup keydown change';
    
    $.fn.PlaceholderShow = function() {
         return this.not('[type=submit]').each(function(){
             if($(this).nextAll("." + innerClass).length !== 0){
                 $(this).nextAll("." + innerClass).show();
             }
         });
    }; 
    
    $.fn.PlaceholderHide = function() {
         return this.not('[type=submit]').each(function(){
             if($(this).nextAll("." + innerClass).length !== 0){
                 $(this).nextAll("." + innerClass).hide();
             }
         });
    };
    
    $.fn.PlaceholderRemove = function() {
         return this.not('[type=submit]').each(function(){
             if($(this).nextAll("." + innerClass).length !== 0 && $(this).parent().hasClass(outerClass)){
                 $(this).attr("placeholder",  $(this).nextAll("." + innerClass).text());
                 $(this).nextAll("." + innerClass).remove();
                 $(this).parent().replaceWith($(this));
             }
         });
    };

    $.fn.PlaceholderFallback = function(options){
        return this.not('[type=submit]').each(function(){
            var text = $(this).attr('placeholder');
            if(text && text.length){
                var input = $(this).on(showEvents, function(event){
                        event.preventDefault();
                        !$(this).val() && innerDiv.show();
                    }).on(hideEvents, function(event){
                        if(!$(this).val()){
                            innerDiv.show();
                        }else{
                            innerDiv.hide();
                        }
                    });

                var innerDiv = $(document.createElement("div"))
                    .text( text )
                    .on('click', function(event){
                        input.focus();
                        event.preventDefault();
                    })
                    .addClass(innerClass);

                var outerDiv = $(document.createElement("div"))
                    .css('position', 'relative')
                    .insertAfter(input)
                    .append(input, innerDiv)
                    .addClass(outerClass);

                !input.val() && innerDiv.show();
                input.removeAttr('placeholder');
            }
        });
    };

})(window, document, jQuery);

