(function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			links: $('.js-splashpage-link')
		};
	}

	function initializeEvents() {
		$cache.links.on('click', function(e) {
			e.preventDefault();
			var countryCode = $(this).data('countrycode');
			var locale = $(this).data('locale');
			var defaultCountryURL = app.util.appendParamsToUrl(app.urls.changeCountry, {
				Country: countryCode,
				format: 'ajax',
				Language: locale,
				origin: 'Home-Show'
			});
			app.components.global.localeselector.changeCountry(defaultCountryURL);
		});
	}

	/**
	 * @namespace app.components.global public object
	 **/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.splashpage = {
		init: function() {
			initializeCache();
			initializeEvents();
		}
	};
}(window.app = window.app || {}, jQuery));
