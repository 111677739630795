(function (app, $) {
	var inited = false,
		$cache = {},
		$document = $(document);

	function highlightCardType(type) {
		$cache.cardTypes.addClass('off');
		if (type && $cache.cardTypeItems[type]) {
			$cache.cardTypeItems[type].removeClass('off');
		}
	}

	function validateCreditCardCallback(e) {
		highlightCardType('none');
        if (inited && e.card_type && $cache.cardTypeItems[e.card_type.name]) {
            highlightCardType(e.card_type.name);
        }
	}

	function initializeCache() {
		$cache.cardNumberAccount  = $('#dwfrm_paymentinstruments_creditcards_newcreditcard_number');
		$cache.cardNumber  = $('#dwfrm_billing_paymentMethods_creditCard_number');
		$cache.cardTypes   = $('.js-cardtype');
		$cache.cardTypeItems = {
			visa   						: $('.js-cardtype[data-cardtype="visa"]'),
			master 						: $('.js-cardtype[data-cardtype="master"]'),
			amex 						: $('.js-cardtype[data-cardtype="amex"]'),
			jcb   						: $('.js-cardtype[data-cardtype="jcb"]'),
			maestro 					: $('.js-cardtype[data-cardtype="maestro"]'),
			discover 					: $('.js-cardtype[data-cardtype="discover"]'),
			diners_club_carte_blanche: $('.js-cardtype[data-cardtype="diners"]'),
			diners_club_international: $('.js-cardtype[data-cardtype="diners"]')
		};
	}

	function initializeEvents() {
		var $cacheElement = null;

		if ($cache.cardNumber.length > 0) {
			$cacheElement = $cache.cardNumber;
		} else if ($cache.cardNumberAccount.length > 0) {
			$cacheElement = $cache.cardNumberAccount;
		}

		if ($cacheElement) {
			validateCreditCard($cacheElement);
			$document.on('creditcard.validate', function() {
				validateCreditCard($cacheElement);
			});
		}
		$document.on('creditcard.select', function(event, data) {
			highlightCardType((data.type + '').toLowerCase());
		});
	}

	function validateCreditCard($elem) {
		$elem.validateCreditCard(validateCreditCardCallback, {
			accept: app.util.getConfig('acceptedCreditCardTypes'),
			formatting: true
		});
	}

	function getCardType() {
		var cardType = null,
			cardNum = null;

		if ($cache.cardNumber.val().indexOf('*') !== -1) {
			cardNum = $cache.cardNumber.data('cardNum');
		}

		var $elem = cardNum ? $cache.cardNumber.clone().val(cardNum) : $cache.cardNumber;
		$elem.validateCreditCard(function(cardData) {
			if ("card_type" in cardData && cardData.card_type) {
				cardType = cardData.card_type.name;
			}
		}, {
			accept: app.util.getConfig('acceptedCreditCardTypes'),
			formatting : true
		});

		return cardType;
	}

  /**
   * @namespace app.components.global.creditcard public object
   **/
  app.components = app.components || {};
  app.components.global = app.components.global || {};
  app.components.global.creditcard = {
      init : function() {
          initializeCache();
          initializeEvents();
          inited = true;
      },
      getCardType : getCardType
  };

}(window.app = window.app || {}, jQuery));
