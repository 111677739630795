(function(app, $) {
	var widgetsConfigs = app.user.amazonWidgetConfigs || {},
		amazonConfigs = app.util.getConfig('amazon', {}),
		btnDefaultConfigs = amazonConfigs.buttonsConfigs || {},
		btnScope = amazonConfigs.scope,
		$cache = {};

	window.onAmazonLoginReady = function() {
		amazon.Login.setClientId(app.preferences.amazonClientId);
	};

	window.onAmazonPaymentsReady = function() {
		if(app.preferences.amazonEnabled) {
			app.components.global.amazonpayments.init();
		}
	};

	function onOrderReferenceCreate(orderReference) {
		$cache.inputOrderRefId.val(orderReference.getAmazonOrderReferenceId());
	}

	function initializeCache() {
		$cache = {
			document: $(document),
			window: $(window),
			logout: $('.js-logout_link'),
			placeOrderBtn: $('.js-checkout-submit-button'),
			amazonBtns: $('.js-amazon_button-cntr'),
			inputOrderRefId: $('[name$="_amazonpayments_orderReferenceID"]'),
			loginFlyout: $(amazonConfigs.loginFlyoutSel),
			loginToggler: $('.js-login_dropdown-title'),
			checkoutForm: $('.js-checkout_form'),
			logoutElem: $('[data-amazon-logout]'),
			minicartFlyoutSel : app.device.isMobileView() ? '.js-addtostatus' : '.js-mini_cart-flyout',
			amazonBtnsSel: '.js-amazon_button-cntr',
			amazonLoginPreTextSel: '.js-amazon_button-pre',
			amazonButtonErrorSel: '.js-amazon_button-error',
			amazonHiddenFormSel: '.js-amazon-checkout',
			loginFlyoutCntrSel: amazonConfigs.loginFlyoutCntrSel,
			checkoutLoginCntrSel: '.js-checkout_login_container',
			errorMessageSel: '.f-error_message',
			addressWidgetCntrId: 'amazon_widget-address',
			walletWidgetCntrId: 'amazon_widget-wallet',
			hiddenClass: 'h-hidden',
			showClass: 'h-show',
			owlCarouselSel: '.js-owl_carousel'
		};
		
		$cache.callbacks = {
			onOrderReferenceCreate: onOrderReferenceCreate
		};
		
		$cache.loginCntrs = {
			'loginpage': $('.js-account_login-form'),
			'logincheckout': app.device.isMobileView() ? $('.js-checkout_login-new') : $cache.loginFlyout,
			'default': $cache.loginFlyout
				
		};
		prepareConfigs();
	}

	function initializeDOM() {
		initializeAddressBookWidget();
		initializeWalletWidget();

		if($cache.logoutElem.length){
			logOut();
		};
		
		$cache.amazonBtns.each(function() {
			initializeAmazonButton($(this));
		});
	}

	function initializeEvents() {
		$cache.logout.on('click', function() {
			logOut();
		});

		$cache.document.on('minicart.afterload fancy.mobile.added', function() {
			initializeAmazonButton($($cache.minicartFlyoutSel).find($cache.amazonBtnsSel));
		});
	}

	/*
     * Extends default settings with component related settings taken in site preferences
     */
	function getButtonSettings(type, component) {
		var prefSettings = app.preferences.amazonButtonConfigs[type] || {},
			settings = prefSettings[component] || prefSettings.default || {};
		return $.extend({}, btnDefaultConfigs[type], settings);
	}

	/*
     * Iterates over widget configurations to replace placeholders with callbacks
     */
	function prepareConfigs() {
		for (var widget in widgetsConfigs) {
			for (var prop in widgetsConfigs[widget]) {
				if (prop in $cache.callbacks) {
					widgetsConfigs[widget][prop] = $cache.callbacks[prop];
				}
			}
		}
	}

	/*
	* Log out user from AmazonPay
	*/
	function logOut(){
		if (typeof amazon != 'undefined' && amazon.Login) {
			amazon.Login.logout();
		}		
	}
	
	/*
    * Address book widget for checkout page
    */
	function initializeAddressBookWidget() {
		if (!$('#' + $cache.addressWidgetCntrId).length) return;
		var refID = $cache.inputOrderRefId.val(),

			params = {
				sellerId: app.preferences.amazonMerchantId,
				design: {
					designMode: 'responsive'
				},
				onError: function(error) {
					console.log(error.getErrorMessage());
				},
				onAddressSelect: function(){}
			};
		if (refID) {
			params.amazonOrderReferenceId = refID;
		}
		if ($('#' + $cache.addressWidgetCntrId).data('isAmazonAbandoned')) {
			params.displayMode = 'Read';
		}
		// disable place order button
		$cache.placeOrderBtn.attr('disabled', 'disabled');
		$.extend(params, widgetsConfigs.address);
		new OffAmazonPayments.Widgets.AddressBook(params).bind($cache.addressWidgetCntrId);
	}

	/*
     * Wallet widget for checkout page.
     */
	function initializeWalletWidget() {
		if (!$('#' + $cache.walletWidgetCntrId).length) return;
		var refID = $cache.inputOrderRefId.val(),
			walletWidget;
		var params = {
			sellerId: app.preferences.amazonMerchantId,
			onPaymentSelect: function() {
				// enable continue button after payment selection
				$cache.placeOrderBtn.removeAttr('disabled');
			},
			design: {
				designMode: 'responsive'
			},
			onError: function(error) {
				console.log(error.getErrorMessage());
			}
		};

		if(!app.preferences.amazonAddressWidgetEnabled) {
			params.onOrderReferenceCreate = onOrderReferenceCreate;
		} else if(refID){
			params.amazonOrderReferenceId = refID;
		};

		$.extend(params, widgetsConfigs.wallet);
		walletWidget = new OffAmazonPayments.Widgets.Wallet(params).bind($cache.walletWidgetCntrId);
		walletWidget.setPresentmentCurrency(app.user.currencyCode);
	}

	/*
     * Amazon Pay/Login Button.
     */
	function initializeAmazonButton($btn) {
		var authRequest,
			params,
			id = $btn.attr('id'),
			btnData = $btn.data(),
			merchantId = app.preferences.amazonMerchantId,
			redirect = btnData && btnData.button === 'pay' ? app.urls.payButtonRedirect : app.urls.loginButtonRedirect;

		if (!merchantId || !$btn.length) return;

		// attach a random generated id
		if (!id) {
			id = getUniqueId();
			$btn.attr('id', id);
		}

		params = {
			language: btnData.locale,
			authorization: function() {
				var loginOptions = {
					scope: btnScope
				};

				authRequest = amazon.Login.authorize(loginOptions, function(){});
			},
			onError: function(error) {
				console.log(error.getErrorMessage());
			},
			onSignIn: function(orderReference) {
				authRequest.onComplete(function(params) {
					processLogin.call(
						$btn,
						redirect,
						btnData.component,
						params.access_token,
						btnData.button === 'pay' ? orderReference.getAmazonOrderReferenceId() : ''
					);
				});
			}
		};
		$.extend(params, getButtonSettings(btnData.button, btnData.component));
		OffAmazonPayments.Button(id, merchantId, params);
		$btn
			.parent()
			.find($cache.amazonLoginPreTextSel)
			.removeClass($cache.hiddenClass);
	}

	/*
     * Processes successful Amazon authorization
     */
	function processLogin(redirect, component, accessToken, orderReferenceId) {
		var url = app.util.appendParamsToUrl(redirect, {
				access_token: accessToken,
				source: component,
				orderReferenceID: orderReferenceId
			}),
			$this = $(this),
			$cntr, $error;

		$.ajax({
			url: url
		}).done(function(resp) {
			if (typeof resp === 'object') {
				if (resp && resp.success) {
					if (resp.checkout) {
						component === 'minicart' ? $($cache.amazonHiddenFormSel).submit() : $cache.checkoutForm.first().submit();
					} else {
						window.location.reload();
					}
				} else {
					amazon.Login.logout();
					resp.redirect && (window.location.href = resp.redirect);

					$error = $this.parent().next($($cache.amazonButtonErrorSel));
					if(resp.message) {
						$error.html(resp.message);
					}
					$error.removeClass($cache.hiddenClass);
				}
			} else {
				$cntr = $cache.loginCntrs[component] || $cache.loginCntrs['default'];

				$cntr.html(resp);
				initializeAmazonButton($cntr.find($cache.amazonBtnsSel));

				$cntr.find($cache.checkoutLoginCntrSel).removeClass($cache.hiddenClass);
				if (!$cntr.hasClass($cache.showClass)) {
					$cntr.closest($cache.loginFlyoutCntrSel).trigger('toggle');
				}
				if (component !== 'loginpage' && $cache.loginToggler.hasClass($cache.loginToggler.data('toggleElemClass'))) {
					$cache.loginToggler.trigger('toggle');
				}
				if ($cntr.find($cache.errorMessageSel).length) {
					amazon.Login.logout();
				}
				app.owlcarousel.initCarousel($cntr.find($cache.owlCarouselSel));
			}
		});
	}

	/**
	 * This function will generate a unique id
	 */
	function getUniqueId() {
		// Math.random should be unique because of its seeding algorithm.
		// Convert it to base 36 (numbers + letters), and grab the first 9 characters after the decimal.
		return (
			'random-' +
			Math.random()
				.toString(36)
				.substr(2, 9)
		);
	}

	function placeOrder (confirmationFlow) {
		var serializedData = $cache.checkoutForm.serialize();
		serializedData += '&isThreeDSRequestedAjax=true';
		$.ajax({
			url: app.urls.InitAmazonConfirmationFlow,
			type : 'POST',
			data : serializedData,
			success: function (data) {
				if (data.error) {
					confirmationFlow.error();
					window.location = app.urls.ThreeDSError;
				} else {
					confirmationFlow.success();
				}
			}
		});
	}

	/**
	* Initiates Amazon Pay checkout
	*/
	function initCheckout() {
		var countOfReinitCalls = 0;

		_initCheckout(app.amazon.pay);

		return {
			stopPropagation: true
		};

		function _initCheckout(config) {
			if (typeof amazon !== 'undefined') {
				amazon.Pay.initCheckout({
					merchantId: config.merchantId,
					ledgerCurrency: config.ledgerCurrency,
					sandbox: config.sandboxMode,
					checkoutLanguage: config.language,
					productType: config.productType,
					placement: 'Checkout',
					createCheckoutSessionConfig: {
						payloadJSON: config.payload,
						signature: config.signature,
						publicKeyId: config.publicKeyId,
						algorithm: 'AMZN-PAY-RSASSA-PSS-V2'
					}
				});
			} else if (countOfReinitCalls < 10) {
				setTimeout(function() {
					_initCheckout(config);
					countOfReinitCalls++;
				}, 500);
			}
		}
	}

	/**
	 * @namespace app.components.global.amazonpayments public object
	 **/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.amazonpayments = {
		init: function() {
			initializeCache();
			initializeDOM();
			initializeEvents();
		},
		placeOrder: placeOrder,
		initCheckout: initCheckout
	};
})((window.app = window.app || {}), jQuery);