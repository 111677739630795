(function (app, $) {

	var $cache = {},
		$window = $(window),
		isSlideDisabled;

	function initializeCache() {
		$cache.$footerContainer = $('.js-footer_container');
		isSlideDisabled = !!$cache.$footerContainer.data('slide-disabled');
	}

	function initializeEvents() {
		if (!isSlideDisabled) {
			$window.bind('scrollStart', function () {
				$cache.$footerContainer.slideToggle(300);
			});
			$window.bind('scrollEnd', function () {
				$cache.$footerContainer.slideToggle(300);
			});
		}
		if (app.device.currentDevice() === 'desktop') {
			$cache.$footerContainer.find('.js-hide_for_desktop').addClass('h-hidden');
		}
	}

	/*************** app.components.global.toggler public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.footer = {
		// initializations
		init : function () {
			initializeCache();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);
