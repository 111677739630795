//app.components.account.paymentinstruments
( function(app, $) {
		var $cache = {};

		function initializeEvents(params) {
			initPaymentListEvents(params);
			initPaymentDeleteEvents();
		}

		function initializeCreateEvents(params) {
			initPaymentCreateEvents();
		}

		function initializeCache() {
			$cache = {
					createPaymentInstrument : $('.js-create-button'),
					paymentList : $(".js-payment_list"),
					removePaymentInstrument : $('.js-delete-button')
			}
		}

		/**
		 * @private
		 * @function
		 * @description Binds the events of the payment methods list
		 */
		function initPaymentListEvents(params) {
			if ($cache.createPaymentInstrument.length===0) { return; }
			$cache.createPaymentInstrument.on("click", function(e){
				e.preventDefault();
				var options = {type: 'ajax', wrapCSS: "fancybox-payment-methods", afterShow: initPaymentCreateEvents};
				if ((params != undefined) && !jQuery.isEmptyObject(params)){
					$.extend(options, params);
				}
				app.fancybox.open(this.href, options);
			});
			$cache.removePaymentInstrument.on("click", function(e){
				e.preventDefault();
			var options = { type: 'ajax', wrapCSS: 'fancybox-payment-methods--delete', afterShow: initPaymentDeleteEvents };
				if ((params != undefined) && !jQuery.isEmptyObject(params)){
					$.extend(options, params);
				}
				app.fancybox.open(this.href, options);
			});
		}

	/**
	 * @private
	 * @function
	 * @description Binds the events of the payment methods list (create card)
	 */
	function initPaymentCreateEvents() {
		if('creditcard' in app.components.global){
			app.components.global.creditcard.init();
		}
		app.validator.init();
		$cache.createPaymentInstrumentForm = $(".js-add_credit_card-form");
		$cache.createPaymentInstrumentForm.on("click", ".js-apply_button", function(e){
			e.preventDefault();
			$cache.createPaymentInstrumentForm.validate();
			if (!$cache.createPaymentInstrumentForm.valid()) {
				return false;
			}
			var url = app.util.appendParamsToUrl($cache.createPaymentInstrumentForm.attr('action'),{format:"ajax"});
			var applyName = $cache.createPaymentInstrumentForm.find('.js-apply_button').val();
			var options = {
				url: url,
				data: $cache.createPaymentInstrumentForm.serialize()+"&"+applyName+'=x',
				type: "POST"
			};
			$.ajax( options ).done(function(data){
				if (typeof (data) !== 'string') {
					if (data.success) {
						app.fancybox.close();
						app.page.refresh();
					} else if (data.redirectURL) {
						app.page.redirect(data.redirectURL);
					} else {
						$('.fancybox-inner').html(data.message);

						return false;
					}
				} else {
					$('.fancybox-inner').html(data);
					app.components.account.paymentinstruments.initCreateEvents();
				}
			});
		});
	}

		/**
		 * @private
		 * @function
		 * @description Binds the events of the payment methods list (delete card)
		 */
		function initPaymentDeleteEvents() {
			if ($cache.paymentList.length===0) { return; }

			$cache.removePaymentForm = $(".js-payment_item-delete");
			$cache.removePaymentForm.on("click", ".js-confirm_delete-button", function(e){
				e.preventDefault();
				var url = app.util.appendParamsToUrl($cache.removePaymentForm.attr('action'),{format:"ajax"});
				var actionName = $(this).attr("name");
				$.ajax({
					type: "POST",
					url: url,
					data: $cache.removePaymentForm.serialize()+'&'+actionName+'=x'
				})
				.done(function(data) {
					if (typeof (data) !== 'string') {
						if (data.success) {
							app.fancybox.close();
							app.page.refresh();
						} else if (data.redirectURL) {
							app.page.redirect(data.redirectURL);
						} else {
							$('.fancybox-inner').html(data.message);
							return false;
						}
					} else {
						app.fancybox.close();
						app.page.redirect(app.urls.paymentsList);
					}
				});
			});
		}


		/**
		 * @namespace app.components.account.paymentinstruments public object
		 **/
		app.components = app.components || {};
		app.components.account = app.components.account || {};
		app.components.account.paymentinstruments = {
			init : function(params) {
				initializeCache();
				initializeEvents(params);
			},
			initCreateEvents : function() {
				initializeCreateEvents();
			}
		};

	}(window.app = window.app || {}, jQuery));
