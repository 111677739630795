import AmazonPayButtonMgr from 'amazon/AmazonPayButtonMgr';
import AmazonEditButton from 'amazon/AmazonEditButton';
import WorldpayCreditCard from 'worldpay/WorldpayCreditCard';
import CSRF from 'oneapp/src/utils/CSRF';

(function(app, $, dwObject) {
	var $cache = {},
		$document = $(document),
		submitForm = function(params, callback) {
			//check for dw integrations availability
			if (dwObject.applepay && !$.isEmptyObject(dwObject.applepay)) {
				var applePayEnabled = app.util.isApplePaySupported() || false;

				appendParameterToPayload(params, {
					name: $cache.formsNames.applePay,
					value: applePayEnabled
				});
			}

			appendParameterToPayload(params, {
				name: $cache.formsNames.csrfTokenName,
				value: $cache.checkoutFormCSRFToken
			});

			app.ajax.getJson({
				type: 'POST',
				url: $cache.formAction,
				data: params,
				beforeSend: function() {
					toggleActionElements();
				},
				callback: function(resp) {
					if (resp && resp.redirectURL) {
						app.page.redirect(resp.redirectURL);
					}

					if (resp && (resp.refresh || (resp.isLoginBanned && resp.isCustomerAuthenticated !== false))){
						toggleAllCheckoutActions();
						if (app.util.getParamFromUrl(window.location.href, 'bid')) {
							var clearUrl = app.util.removeParamFromURL(window.location.href,'bid');
							window.location.href = clearUrl;
						}else{
							window.location.reload();
						}
						return;
					}
					if (resp && resp.redirectLocation) {
						var blockWithRedirect = !app.util.getConfig('multidomain.allowconfirmationPopup') || app.preferences.enableMultiDomain;
						if (blockWithRedirect) {
							toggleAllCheckoutActions();
							return;
						}
					}
					toggleActionElements(false);
					if (resp && resp.models) {
						for (var key in resp.models) {
							$cache.models[key] = resp.models[key];
							updateViewWithModel(key);
						}
						$cache.stepSubmitButton &&
							resp.models.status &&
							$cache.stepSubmitButton.attr(
								'disabled',
								!!(resp.models.status.error || resp.models.basket.lineItemsNumber == 0) ? 'disabled' : false
							);
						if (resp.models.status && resp.models.status.error && resp.models.status.message) {
							$($cache.checkoutFormError).html(
								'<div class="f-form-error_message">'
								+ '<span class="js-cart_error_container">' + resp.models.status.message + '</span>'
								+ '</div>'
							);
							scrollToErrorMsg($cache.checkoutFormError.offset().top);
						} else if (resp.models.status && !resp.models.status.error) {
							var $errorMessage = $cache.checkoutFormError.find('.f-form-error_message');
							var isAdviseError = $errorMessage.data('is-advise-error');
							var isAmazonError = (
								$errorMessage.data('payment-method-id') === 'AMAZON_PAY_V2'
								&& 'dwfrm_cart_submitForm' in params
								&& params['dwfrm_cart_submitForm'] === 'cartCalculate'
							);

							if (!isAmazonError && !isAdviseError) {
								$cache.checkoutFormError.html('');
							}
						}

						if (resp.models.basket) {
							$document.trigger('cart.update.models', resp.models.basket);
						}

						if ($cache.ccNum.val()) {
							$cache.ccNum.trigger('keyup').trigger('blur');
						}
						if (resp.models.basket && resp.models.basket.bonusInBasket === 0 && !$cache.choiceOfBonusProducts.hasClass($cache.hHiddenClass)) {
							$cache.choiceOfBonusProducts.hide();
						}

						if (resp.models.basket && resp.models.basket.amazon && resp.models.basket.amazon.applicable) {
							const amazonPayButtons = document.querySelectorAll('.js-amazon-pay-button');

							if (amazonPayButtons.length > 0) {
								for (const amazonPayButton of amazonPayButtons) {
									var newAmazonPayButton = document.createElement('div');
									newAmazonPayButton.setAttribute('class', 'b-amazon-pay-button js-amazon-pay-button');
									newAmazonPayButton.setAttribute('data-model-basket', 'amazon.applicable');
									newAmazonPayButton.setAttribute('data-action', 'amazonPay');
									newAmazonPayButton.innerHTML = resp.models.basket.amazon.buttonBody;
									amazonPayButton.replaceWith(newAmazonPayButton);
									AmazonPayButtonMgr.render(newAmazonPayButton, resp.models.basket.amazon, 'Checkout');
								}
							}
						}
						renderQuantityOnMobile(resp);
					}
					callback && callback(resp);
				}
			});
		},
		fillBillingRegionField = function(regionType, response, data) {
			var options = '';
			var select = '';
			var selectOptiopnsTemplate;
			var selectTemplate;

			if (regionType === 'prefecture') {
				$cache.billingRegionSel = $cache.billingPrefectureSel;
				selectOptiopnsTemplate = $('#billingPrefecturesSelectoptions').html();
				selectTemplate = $('#billingPrefecturesSelect').html();
			} else {
				$cache.billingRegionSel = $cache.billingStateSel;
				selectOptiopnsTemplate = $('#billingStatesSelectoptions').html();
				selectTemplate = $('#billingStatesSelect').html();
			}

			var regionObjs = response[regionType];
			var currentRegion = data && data.stateValue;

			for (var i = 0; i < regionObjs.length; i++) {
				var currentRegionObj = regionObjs[i];

				if (currentRegionObj.code === currentRegion) {
					currentRegionObj.selected = "selected='selected'";
				}

				options += app.util.renderTemplate(selectOptiopnsTemplate, currentRegionObj);
			}

			select = app.util.renderTemplate(selectTemplate, { options: options });

			$($cache.billingRegionSel).html(select);

			$cache.billingAddress.stateCode = $cache.checkoutForm.find("[id$='_billingAddress_addressFields_states_state']");
			$cache.billingAddress.prefectureCode = $cache.checkoutForm.find("[id$='_billingAddress_addressFields_prefectures_prefecture']");
		},
		callbacks = {
			addBonusProductTemplates: function(modelTpl) {
				if (modelTpl == null) {
					var bonusToDelete = $('.js-lineitem-body-bonus.h-hidden');
					bonusToDelete.remove();
				} else {
					var $templates = $($.parseHTML(modelTpl)).filter($cache.lineItemBodySel);

					$templates.each(function() {
						var $this = $(this),
							$node = $('[data-model-basket="' + $this.data('model-basket') + '"]');
						if ($node.length) {
							$node.replaceWith(this);
						} else {
							$($cache.lineItemBodySel + ':last').after(this);
						}
					});
				}
			},
			klarnaOnSiteMessage: function(data) {
				if (data && window.KlarnaOnsiteService) {
					app.components.global.klarnapayments.siteMessageUpdate(data);
				}
			},
			elements: {
				select: function(modelVal) {
					var select = $(this);
					if (typeof modelVal === 'number' && modelVal > 0) {
						for (var qty = 1; qty <= modelVal; qty++) {
							if (!select.children("option[value='" + modelVal + "']").length) {
								select.append(
									$('<option></option>')
										.attr('value', modelVal)
										.text(modelVal)
								);
							}
						}

						if (select.children("option[value='" + modelVal + "']").attr('selected') !== 'selected') {
							select.children("option[value='" + modelVal + "']").attr('selected', 'selected');
						}
					}
				},
				input: function(modelVal) {
					$(this).val(modelVal);
				},
				default: function(modelVal) {
					$(this).html(modelVal);
				}
			}
		},
		actions = {
			cartCalculate: function() {
				var params = {
					source: 'ajax'
				};
				params[$cache.formsNames.cartSubmitFormAction] = 'cartCalculate';
				submitForm(params, function() {
					if (!app.device.isMobileView()) {
						$($cache.inputQty).removeAttr('readonly');
					}
				});
			},
			updLineItemQty: function(data) {
				var element = $(this);
				var params = {
					source: 'ajax'
				};
				params[$cache.formsNames.cartSubmitFormAction] = 'cart';
				params[$cache.formsNames.cartLineItem] = data.lineItemNum;
				params[$cache.formsNames.cartLineItemQty] =
					element.prop('tagName') === 'SELECT'
						? element.val()
						: +$('input[name=' + data.lineItemFieldName + ']').val() + +data.value;

				submitForm(params, function(resp) {
					if (resp && resp.success === false && resp.message) {
						$('.js-line_item_error[data-line-item-num=' + data.lineItemNum + ']').text(resp.message);
					}
				});
			},
			toStep: function(data) {
				if (data.stepNum == 0) {
					app.page.redirect(app.urls.cartShow);
				} else {
					if ($cache.backToStepButton.length) {
						if ($cache.activeStep.data().stepNum <= data.stepNum) {
							return false;
						} else {
							$cache.checkoutForm.off();
							$cache.backToStepButton.val(parseFloat(data.stepNum).toFixed(0));
							$cache.backToStepButton.click();
						}
					} else {
						$cache.stepSubmitButton.click();
					}
				}
			},
			removelineItem: function(data) {
				var params = {
					source: 'ajax'
				};
				params[$cache.formsNames.cartSubmitFormAction] = 'cart';
				params[$cache.formsNames.cartLineItem] = data.lineItemNum;
				params[$cache.formsNames.cartLineItemQty] = 0;
				params["pid"] = data.lineItemId;
				if(!data.showPopup){
					submitForm(params);
					if (app.fancybox) {
						app.fancybox.close();
					}
				}
				else{
					app.fancybox.open(app.util.appendParamsToUrl(app.urls.removeProductPopup, params), {type : 'ajax', wrapCSS: 'b-checkout-remove_popup'});
				}
			},
			removelineItemGiftCert: function(data) {
				var params = {
					source: 'ajax',
					giftCertLineItemNumber: data.lineItemNum
				};
				params[$cache.formsNames.cartSubmitFormAction] = 'giftcert';
				submitForm(params);
			},
			removeAfterAddToWishlist: function(data) {
				var params = {
					source: 'ajax'
				};
				params[$cache.formsNames.cartSubmitFormAction] = 'cart';
				if (
					$cache.wishlistBlock.length &&
					(app.currentCustomer.isAuthenticated() || app.preferences.enableWishlistForNotAuthUser)
				) {
					params[$cache.formsNames.cartLineItem] = data.lineItemNum;
					params[$cache.formsNames.cartLineItemQty] = 0;
					submitForm(params);
				}
			},
			removeWishlistItem: function(data) {
				var params = {
					source: 'ajax'
				};
				params[$cache.formsNames.cartSubmitFormAction] = 'wishlist';
				params[$cache.formsNames.wishlistItemId] = data.item;
				submitForm(params);
			},
			wishlistCartAdd: function(data) {
				var params = {
					source: 'ajax',
					format: 'ajax'
				};
				params[$cache.formsNames.cartSubmitFormAction] = 'addToCartFromWishlist';
				var element = $(this),
					wishlistTile = element.closest($cache.wishlistTileBlockSel);

				if (wishlistTile.length) {
					var wishlistTileData = wishlistTile.data();
					params[$cache.formsNames.cartLineItemId] = data.pid;
					params[$cache.formsNames.cartLineItemQty] = parseInt(wishlistTileData.itemQty);
					params[$cache.formsNames.wishlistItemId] = wishlistTileData.item;
					submitForm(params);
				}
			},
			quickShopEdit: function(data) {
				var $form = $(this).parents('form'),
					params = {
						format: 'promise',
						source: 'ajax',
						uuid: $form.find($cache.prodLineItemUuidSel).val(),
						pid: $form.find($cache.prodLineItemIdSel).val()
					};
				params[$cache.formsNames.cartSubmitFormAction] = 'updateLineItem';
				submitForm(params, function() {
					app.fancybox.close();
				});
			},
			editProductRefresh: function(data) {
				actions.editProduct({
					productId: $(this).val(),
					lineItemId: data.lineItemId
				});
			},
			editProduct: function(data) {
				app.ajax.getJson({
					url: app.urls.cartEditProduct,
					data: {
						pid: data['productId']
					},
					callback: function(resp) {
						if(resp == null){
							return;
						}

						var sizeOptionsHtml = '',
							colorOptionsHtml = '',
							sizeOptionTemplate = $('#SizeSelectOption').html(),
							colorOptionTemplate = $('#ColorSelectOption').html(),
							productPopupTemplate = $('#editProductPopup').html();
						if (resp.setOptions && resp.variantOptions.length > 0) {
							var optionsCount = resp.setOptions.length;
							for (var i = 0; i < optionsCount; i++) {
								colorOptionsHtml += app.util.renderTemplate(colorOptionTemplate, {
									text: resp.setOptions[i].color,
									productID: resp.setOptions[i].id,
									selected: resp.setOptions[i].id == resp.masterID ? 'selected="selected"' : ''
								});
							}
						}
						if (resp.variantOptions && resp.variantOptions.length > 0) {
							var isLetterSizes = isNaN(parseFloat(resp.variantOptions[0].size.replace(',', '.'))),
								sortFunc = null;

							if (isLetterSizes) {
								sortFunc = function(a, b) {
									var sizes = app.preferences.sizeOrder.split(',');
									return sizes.indexOf(a.size) - sizes.indexOf(b.size);
								};
							} else {
								sortFunc = function(a, b) {
									return parseFloat(a.size.replace(',', '.')) - parseFloat(b.size.replace(',', '.'));
								};
							}
							resp.variantOptions = resp.variantOptions.sort(sortFunc);

							var optionsCount = resp.variantOptions.length;
							for (var i = 0; i < optionsCount; i++) {
								sizeOptionsHtml += app.util.renderTemplate(sizeOptionTemplate, {
									text: resp.variantOptions[i].size,
									productID: resp.variantOptions[i].id,
									price: resp.variantOptions[i].price,
									selected: resp.variantOptions[i].id == data['productId'] ? 'selected="selected"' : '',
									disabled: resp.variantOptions[i].selectable === false ? 'disabled="disabled"' : ''
								});
							}
						}
						resp.sizeOptions = sizeOptionsHtml;
						resp.colorOptions = colorOptionsHtml;
						resp.productID = data['productId'];
						resp.lineItemId = data['lineItemId'];
						$('.js-edit_product-popup').html('');
						$('.js-edit_product-popup[data-line-item-id=' + data['lineItemId'] + ']').html(
							app.util.renderTemplate(productPopupTemplate, resp)
						);
					}
				});
			},

			closeEditProductPopup: function(data) {
				$('.js-edit_product-popup[data-line-item-id=' + data['lineItemId'] + ']').html('');
			},
			editProductSubmit: function(data) {
				app.ajax.getJson({
					url: app.urls.cartEditProductSubmit,
					data: {
						pid: $("select[data-size-pid='" + data.productId + "']").val(),
						oldid: $(this)
							.closest('.js-edit_product-popup')
							.data('productId')
					},
					callback: function(resp) {
						if (resp && resp.success) {
							app.page.refresh();
						}
					}
				});
			},
			selectShippingMethod: function(data) {
				var params = {
						dwfrm_checkout_submitForm: 'shippingMethods',
						source: 'ajax'
					},
					secureKeyInput = $('[data-form=singleShippingSecureKey]');

				params[$(this).attr('name')] = $(this).val();
				params[secureKeyInput.attr('name')] = secureKeyInput.val();
				submitForm(params, function(response) {
					if (response && response.models) {
						var checkedElement = response.models.shippingMethods.find(function(element) {
							return element.checked === 'checked="checked"';
						});

						$('#shipping-method-'+checkedElement.ID).focus();
					}
				});
			},
			selectPaymentMethod(_, {target}) {
				const secureKeyInput = document.querySelector('[data-form=billingSecureKey]');
				const params = {
					dwfrm_checkout_submitForm: 'paymentMethods',
					source: 'ajax',
					[target.getAttribute('name')]: target.getAttribute('value'),
					[secureKeyInput.getAttribute('name')]: secureKeyInput.getAttribute('value')
				}

				const callback = ({models}) => {
					if (!models) {
						return;
					}

					const checkedPaymentMethod = models.paymentMethods.find((element) => element.checked === 'checked="checked"');
					const { id: checkedPaymentMethodId } = checkedPaymentMethod;
					const checkedNode = document.getElementById(`is-${checkedPaymentMethodId}`);

					checkedNode.focus();
				}

				submitForm(params, callback);
			},
			selectShippingCountry: function() {
				var params = {
						dwfrm_checkout_submitForm: 'shippingCountry',
						source: 'ajax'
					},
					secureKeyInput = $('[data-form=singleShippingSecureKey]');

				params[$(this).attr('name')] = $(this).val();
				params[secureKeyInput.attr('name')] = secureKeyInput.val();

				if (app.abtests && app.abtests.klarnaEnable && !app.abtests.klarnaMember) {
					$.ajax({
						type: 'POST',
						url: app.urls.cartChangeCountry,
						data: { Country: $(this).val() },
						dataType: 'json'
					}).done(function() {
						$document.trigger('modal.removegiftcert.confirm', function() {
							submit(params);
						});
					});
				} else {
					$document.trigger('modal.removegiftcert.confirm', function() {
						submit(params);
					});
				}
				function submit(params) {
					submitForm(params, function(resp) {
						$document.trigger('cart.shippingCountryChange');
						if (resp && resp.isLoginBanned != null) {
							$('.js-header_service_menu-checkout').toggleClass($cache.jsHideClassName, resp.isLoginBanned);
						}

						app.preferences.applePayPublicConfigurations = resp.models.applepaybutton.publicConfigurations;
					});
				}
			},
			selectAddress: function(data) {
				var data = $(this)
						.find('option:selected')
						.data(),
					addressType = $(this).attr('id') == 'dwfrm_singleshipping_addressList' ? 'shippingAddress' : 'billingAddress';
				if ($(this).val() == 'new') {
					for (var key in $cache[addressType]) {
						key != 'address1' &&
							key != 'addressList' &&
							key != 'countryCode' &&
							key != 'phoneCode' &&
							$cache[addressType][key].val('');
						key == 'address1' && $cache.dynamic.address1[addressType]().val('');
					}
					$cache[addressType].addToAddressBook.prop('checked', true).val('true');
				} else {
					if (addressType === 'shippingAddress') {
						hideShipToStore();
					}
					Object.keys(data.address).forEach(function(addressField) {
						if (Object.prototype.hasOwnProperty.call(data.address, addressField)) {
							var element = addressField === 'address1' ? $cache.dynamic.address1[addressType]() : $cache[addressType][addressField];
							if (element && element.length && addressField !== 'postalCode') {
								element.attr('value', data.address[addressField]).valid();
								if (addressField === 'phone' || addressField === 'phoneCode') {
									element.trigger('change');
								}
								if (addressField === 'countryCode') {
									$cache[addressType].postalCode.attr('value', data.address.postalCode).valid();
									element.trigger('change', { stateValue: data.address.stateCode });
								}
							}
						}
					});
					app.validator.initForm($cache.checkoutForm);
					if (addressType === 'shippingAddress') {
						if ($cache.shippingAddress.storeid) {
							$cache.shippingAddress.storeid.val('');
						}
						$cache.deliveryType.val(false);
					}
					$document.trigger('address.select');
				}
			},

			selectCreditCard: function() {
				// load card details
				if (!$(this).val()) return;

				var creditCardList = $(),
					cardID = $(this).val(),
					url = app.util.appendParamToURL(app.urls.billingSelectCC, 'creditCardUUID', cardID);
				if ($(this).val() == 'new') {
					setCCFields({});
					$cache.ccSaveCard.prop('checked', true);
				} else {
					$cache.ccSaveCard.prop('checked', false);
					app.ajax.getJson({
						url: url,
						callback: function(data) {
							if (!data) {
								window.alert(app.resources.CC_LOAD_ERROR);
								return false;
							}
							$cache.ccList.data(cardID, data);
							setCCFields(data);
							$document.trigger('creditcard.select', data);
						}
					});
				}
			},
			giftWrap: function() {
				var params = {
					dwfrm_checkout_submitForm: 'giftWrap',
					source: 'ajax'
				};
				submitForm(params, function() {
					if ($cache.isGiftWrap.prop('checked')) {
						var $cashOnDeliveryContainer = $document.find('.cash_on_delivery');
						$cashOnDeliveryContainer.remove();
					}
				});
			},
			redeemGiftCert: function() {
				var params = {
						dwfrm_billing_giftCertCode: $cache.giftCertNo && $cache.giftCertNo.val(),
						dwfrm_checkout_submitForm: 'redeemGiftCert',
						source: 'ajax'
					},
					secureKeyInput = $('[data-form=billingSecureKey]');

				params[$(this).attr('name')] = $(this).val();
				params[secureKeyInput.attr('name')] = secureKeyInput.val();
				submitForm(params, function(resp) {
					$('.js-gift_cert_error').text((resp.success === false && resp.message) || '');
				});
			},
			removeGiftCertificate: function(data) {
				var params = {
						dwfrm_checkout_submitForm: 'removeGiftCert',
						source: 'ajax',
						dwfrm_billing_giftCertCode: data.gc
					},
					secureKeyInput = $('[data-form=billingSecureKey]');

				params[$(this).attr('name')] = $(this).val();
				params[secureKeyInput.attr('name')] = secureKeyInput.val();
				submitForm(params);
			},
			cashOnDeliverySendPhone: function(data) {
				if ($cache.cashOnDeliveryPhoneCode.valid() && $cache.cashOnDeliveryPhone.valid()) {
					var params = {
						dwfrm_billing_paymentMethods_cashOnDelivery_phoneCode: $cache.cashOnDeliveryPhoneCode.val(),
						dwfrm_billing_paymentMethods_cashOnDelivery_phone: $cache.cashOnDeliveryPhone.val(),
						dwfrm_checkout_submitForm: 'cashOnDeliveryPhone',
						source: 'ajax'
					};
					var secureKeyInput = $('[data-form=billingSecureKey]');
					params[secureKeyInput.attr('name')] = secureKeyInput.val();

					submitForm(params, function(resp) {
						if (resp) {
							if (resp.success) {
								$cache.cashOnDeliverySMSStatus.removeClass($cache.errorMessageClass).addClass($cache.validMessageClass);
							}
							else {
								$cache.cashOnDeliverySMSStatus.removeClass($cache.validMessageClass).addClass($cache.errorMessageClass);
							}

							if (resp.message) {
								$cache.cashOnDeliverySMSStatus.text(resp.message);
							}
						}
					});
				}
			},
			cashOnDeliveryVerifyCode: function() {
				if ($cache.cashOnDeliveryCode.val()) {
					$cache.cashOnDeliveryCodeVerifyStatus.empty();
					$cache.cashOnDeliveryCodeVerifyStatus.closest('div').find('.' + $cache.stateErrorClass).removeClass($cache.stateErrorClass);
					var params = {
						dwfrm_billing_paymentMethods_cashOnDelivery_code: $cache.cashOnDeliveryCode.val(),
						dwfrm_checkout_submitForm: 'payment',
						source: 'ajax'
					};
					var secureKeyInput = $('[data-form=billingSecureKey]');
					params[secureKeyInput.attr('name')] = secureKeyInput.val();

					submitForm(params, function(resp) {
						if (resp) {
							if (resp.success) {
								$cache.cashOnDeliveryCodeVerifyStatus.removeClass($cache.errorMessageClass).addClass($cache.validMessageClass);
							}
							else {
								$cache.cashOnDeliveryCodeVerifyStatus.removeClass($cache.validMessageClass).addClass($cache.errorMessageClass);
							}

							if (resp.message) {
								$cache.cashOnDeliveryCodeVerifyStatus.text(resp.message);
								$cache.cashOnDeliveryCode.valid();
							}
						}
					});
				}
				else {
					$cache.cashOnDeliveryCode.valid();
				}
			},
			checkoutLoginSubmit: function(data) {
				$('.js-checkout_login_error_form').hide();
				var checkoutLoginForm = $cache.checkoutLogin;
				if (data.wrapper) {
					checkoutLoginForm = $(data.wrapper + ' ' + $cache.checkoutLoginWrappedSel);
				}

				var params = checkoutLoginForm.serializeArray();
				params.push({
					name: $cache.formsNames.checkoutSubmitFormAction,
					value: 'login'
				});
				params.push({
					name: 'source',
					value: 'ajax'
				});

				checkoutLoginForm.validate();
				if (!checkoutLoginForm.valid()) {
					return false;
				}
				submitForm(params, function(resp) {
					if (resp && resp.success === false && resp.message) {
						$('.js-checkout_login_error_form')
							.text(resp.message)
							.show();
					} else if (resp && resp.success === true) {
						if (!$cache.checkoutRefreshForm.length) {
							window.location.assign(app.urls.cartShow);
						} else {
							CSRF.populateFormByCSRFToken($cache.checkoutRefreshForm[0], true)
								.then(() => $cache.checkoutRefreshForm.submit());
						}
					}
				});
			},
			applyCoupon: function(data) {
				if (!$cache.couponCodeField.val() && !data.coupon) {
					$('.js-coupon_error').text(app.resources.COUPON_CODE_MISSING);
				} else {
					submitForm(
						{
							dwfrm_cart_couponCode: data.coupon ? data.coupon : $cache.couponCodeField.val(),
							dwfrm_checkout_submitForm: 'applyCoupon',
							source: 'ajax'
						},
						function(resp) {
							if (resp) {
								resp.success === true && $cache.couponCodeField.val('');

								if (navigator.serviceWorker.controller) {
									navigator.serviceWorker.controller.postMessage({
										type: 'SW_EMPTY_CACHE'
									});
								}

								$('.js-coupon_error').text(resp && resp.success === false && resp.message ? resp.message : '');
							}
						}
					);
				}
			},
			removeCoupon: function(data) {
				submitForm({
					dwfrm_cart_couponCode: data.coupon,
					dwfrm_checkout_submitForm: 'removeCoupon',
					source: 'ajax'
				});
			},
			placeOrder: function(data) {
				$cache.stepSubmitButton.click();
			},
			printOrder: function(data) {
				window.print();
			},
			preOrderNotifyMe: function() {
				$cache.preorderNotifyMe.valid() &&
					submitForm({
						dwfrm_cart_preorder_notiftyMeEmail: $cache.preorderNotifyMe.val(),
						dwfrm_checkout_submitForm: 'notifyMeInStockPreorder',
						source: 'ajax'
					});
			},
			ddpOption: function() {
				var params = {
						dwfrm_checkout_submitForm: 'ddpOption',
						source: 'ajax'
					},
					secureKeyInput = $('[data-form=billingSecureKey]');

				params[$(this).attr('name')] = $(this).val();
				params[secureKeyInput.attr('name')] = secureKeyInput.val();
				submitForm(params);
			}
		};

	var mouseDownCache = {};

	function toggleActionElements(isDisable){
		var actionElements = $('[data-action]');
		isDisable = isDisable != null ? isDisable : true;

		actionElements.toggleClass($cache.actionBlockerClass, isDisable);
		actionElements.filter(function(){ return $(this).prop('tagName') === 'SELECT'; }).prop('disabled', isDisable);
	}

	function toggleCheckoutButton(isDisable){
		isDisable = isDisable != null ? isDisable : true;
		$cache.checkoutSubmitBtns.prop('disabled', isDisable);
	}

	function toggleAllCheckoutActions(isDisable){
		isDisable = isDisable != null ? isDisable : true;
		toggleActionElements(isDisable);
		toggleCheckoutButton(isDisable);
	}

	function setCCFields(data) {
		if ($.isEmptyObject(data)) {
			$cache.ccOwner.val('');
			$cache.ccType.val('');
			$cache.ccNum.val('');
			$cache.ccMonth.val('MM');
			$cache.ccYear.val('YY');
			$cache.ccCcv.val('');
			$cache.ccNum.removeData('cardNum');

			//remove errors and valid borders - for empty data
			var $ffieldCntr = $cache.ccContainer.find('.f-field');
			$ffieldCntr.find('span.f-error_text').remove();
			$ffieldCntr.filter('.f-state-valid').removeClass('f-state-valid');
		} else {
			$cache.ccOwner.val(data.holder).valid();
			$cache.ccType.val(data.type);
			$cache.ccNum.val(data.maskedNumber).valid();
			$cache.ccMonth.val(data.expirationMonth).valid();
			$cache.ccYear.val(data.expirationYear).valid();
			$cache.ccCcv.val('');
			$cache.ccNum.data('cardNum', data.number);
		}

		$cache.ccContainer
			.find('.f-state-error')
			.toggleClass('f-state-error')
			.filter('span:not(.f-label)')
			.remove();
	}

	function updateBillingWithShipping() {
		var postalCode = null;
		var countryUpdated = false;

		for (var key in $cache.shippingAddress) {
			if ($cache.billingAddress[key]) {
				if (key === 'postalCode') {
					postalCode = $cache.shippingAddress[key].val();
				} else if (key === 'countryCode') {
					var billingCountryValue = $cache.billingAddress[key].val();
					var shippingCountryValue = $cache.shippingAddress[key].val();
					var countryCached = $($cache.addressEditCountrySel).data('country-code');

					if (countryCached && billingCountryValue !== countryCached) {
						countryUpdated = true;
						$cache.billingAddress[key].val(countryCached);
					} else if (!countryCached && billingCountryValue !== shippingCountryValue) {
						$cache.billingAddress[key].val(shippingCountryValue);
						countryUpdated = true;
					}
				} else if (key === 'address1') {
					$cache.dynamic.address1['billingAddress']().val($cache.dynamic.address1['shippingAddress']().val());
				} else if (key === 'stateCode') {
					//temporary fix
					$cache.billingAddress['stateCode'].val($('#dwfrm_singleshipping_shippingAddress_addressFields_states_state').val());
				} else {
					$cache.billingAddress[key].val($cache.shippingAddress[key].val());
				}
			}
		}
		if (countryUpdated) {
			updateBillingStateWithShipping();
		}
		//postalCode must be updated after country field
		if (postalCode && $cache.billingAddress.postalCode.length) {
			$cache.billingAddress.postalCode.val(postalCode);
		}
		$cache.billingAddress.addToAddressBook.prop('checked', false);
	}

	function updateBillingStateWithShipping() {
		if ($cache.shippingAddress['stateCode'].length && $cache.shippingAddress['stateCode'].val()) {
			var stateCode = $cache.shippingAddress['stateCode'].val();
			$cache.billingAddress.countryCode.trigger('change',{stateValue:stateCode});
		} else {
			$cache.billingAddress.countryCode.trigger('change');
		}
	}

	function saveShipping() {
		var address = {},
			reg = /addressList|storid|phone|phoneCode|countryCode/;
		for (var key in $cache.shippingAddress) {
			if (!reg.test(key) && $cache.shippingAddress[key].length > 0) {
				address[key] =
					key == 'address1' ? $cache.dynamic.address1['shippingAddress']().val() : $cache.shippingAddress[key].val();
			}
		}
		return address;
	}

	function setShipping(address) {
		if (address) {
			for (var key in address) {
				key == 'address1'
					? $cache.dynamic.address1['shippingAddress']().val(address[key])
					: $cache.shippingAddress[key].val(address[key]);
			}
		}
	}

	function setStoreAddress(element) {
		var storeData = element.find('.js-store-address');
		if (storeData && storeData.length) {
			var isCCOn3rdCOStep = $cache.isCC && ($cache.isCOStep3 || $cache.isSummaryPage);
			for (var key in $cache.shippingAddress) {
				var keyData = storeData.data(key.toLowerCase());
				if (keyData) {
					if (key === 'address1') {
						key = isCCOn3rdCOStep ? $cache.shippingAddress.address1.attr('value', keyData) : $cache.dynamic.address1.shippingAddress().attr('value', keyData);
					} else if (key === 'stateCode') {
						key = $cache.shippingAddress[key].attr('value', keyData.toUpperCase());
					} else {
						key = $cache.shippingAddress[key].attr('value', keyData);
					}
				}
			}
		}
	}

	function cleanShipping() {
		var reg = /phone|phoneCode|countryCode|addToAddressBook|useForBilling/;
		for (var key in $cache.shippingAddress) {
			if (!reg.test(key)) {
				key == 'address1' ? $cache.dynamic.address1['shippingAddress']().val('') : $cache.shippingAddress[key].val('');
			}
		}
	}

	function showShipToStore() {
		var storeID = $cache.shipToStoreBtn.data().store,
			element = $('#' + storeID);
		if (storeID && element.length > 0) {
			$cache.selectedStore.html(element.html());
			setStoreAddress(element);
		} else {
			$cache.storelocator.removeClass($cache.jsHideClassName);
		}
		$cache.shipToStoreBtn.addClass('selected');
		$cache.shipToStoreWrap.removeClass($cache.jsHideClassName);
		$cache.shipToStoreHideFields.addClass($cache.jsHideClassName);
		$cache.shippingAddress.addToAddressBook.prop('checked', false).val('false');
		$cache.shippingAddress.addressList
			.find('option[value = new]')
			.prop('disabled', true)
			.hide();
	}

	function hideShipToStore() {
		var address = $cache.shipToHomeBtn.data('address');
		if (address) {
			setShipping(address);
		}
		$cache.shipToHomeBtn.addClass('selected');
		$cache.shipToStoreWrap.addClass($cache.jsHideClassName);
		$cache.shipToStoreHideFields.removeClass($cache.jsHideClassName);
		$cache.shippingAddress.addressList
			.find('option[value = new]')
			.prop('disabled', false)
			.show();
	}

	function scrollToErrorMsg(offsetElement) {
		var $anchor = $cache.errorMsg.parents($cache.errorAnchorSel);
		var $header = app.device.isMobileView() ? $cache.headerCheckout : $cache.header;
		$cache.bodySelector.animate(
			{
				scrollTop: $anchor.length ? $anchor.offset().top : (offsetElement - $header.height() - 30)
			},
			1000
		);
	}

	function appendParameterToPayload(payload, parameterAttributes) {
		if (Array.isArray(payload)) {
			payload.push(parameterAttributes);
		} else {
			payload[parameterAttributes.name] = parameterAttributes.value;
		}

		return payload;
	}

	function getCSRFToken(container) {
		return container.find("input[name$='csrf_token']")?.get(0)?.value;
	}

	function initializeCache() {
		$cache.cartHeaderClass = '.js-cart_table-header';
		$cache.actionBlockerClass = 'h-click-disable';
		$cache.headerCheckout = $('.js-header_checkout');
		$cache.wishlistBlock = $('.js-wishlistblock');
		$cache.wishlistTileBlockSel = '.js-wishlistblock-content_tile';
		$cache.stepSubmitButton = $('[name=dwfrm_checkout_submitStep]');
		$cache.activeStep = $('.js-active-step');
		$cache.backToStepButton = $('[name=dwfrm_checkout_backToStep]');
		$cache.js_login_error = $('.js_login_error');
		$cache.formAction = $('.js-checkout_form').attr('action');
		$cache.paypalButtonContainer = $('.js-paypalExpressButtonBigContainer');
		$cache.ccContainer = $('#PaymentMethod_CREDIT_CARD');
		$cache.ccList = $('#creditCardList');
		$cache.ccOwner = $cache.ccContainer.find("input[name$='creditCard_owner']");
		$cache.ccType = $cache.ccContainer.find("select[name$='_type']");
		$cache.ccNum = $cache.ccContainer.find("input[name$='_number']");
		$cache.ccMonth = $cache.ccContainer.find("[name$='_month']");
		$cache.ccYear = $cache.ccContainer.find("[name$='_year']");
		$cache.ccCcv = $cache.ccContainer.find("input[name$='_cvn']");
		$cache.ccSaveCard = $cache.ccContainer.find("input[name$='_saveCard']");
		$cache.checkoutForm = $('.js-checkout_form');
		$cache.checkoutFormCSRFToken = getCSRFToken($cache.checkoutForm);
		$cache.checkoutFormError = $('.l-checkout_cart-left > .f-form-error_message_container');
		$cache.giftCertNo = $('#dwfrm_billing_giftCertCode');
		$cache.couponCodeField = $('#dwfrm_cart_couponCode');
		$cache.choiceOfBonusProducts = $('.js-bonus_products_container');
		$cache.giftWrapImage = $('.js-gift_wrap_image');
		$cache.giftWrapPopupContent = $('.js-gift_wrap_popup_content');
		$cache.emptyCartRecommendationsTitle = $('.js-empty_cart-recommendations-slide-title');
		$cache.checkoutRefreshForm = $('#checkoutRefreshForm');
		$cache.worldpayApplicable = $('#worldpay-applicable');
		$cache.staticPathImage =
			$('.js_data-staticpath').data('imgdirectory') ||
			$('.js_data-staticpath').attr('data-imgdirectory') ||
			app.urls.staticPath + 'images/';
		$cache.shippingDate = $('.js-cart_shippingdate');
		if ($cache.checkoutForm.length) {
			$cache.userGalleryAuthorizedPublication = $("#dwfrm_cart_usergallery_iscpauthorizedpublication");
			$cache.shippingAddress = {
				addressList: $cache.checkoutForm.find(".js-single_shipping_wrap select[id$='_addressList']"),
				salutation: $cache.checkoutForm.find("input[name$='_shippingAddress_addressFields_salutation']"),
				firstName: $cache.checkoutForm.find("input[name$='_shippingAddress_addressFields_firstName']"),
				lastName: $cache.checkoutForm.find("input[name$='_shippingAddress_addressFields_lastName']"),
				companyName: $cache.checkoutForm.find("input[name$='_shippingAddress_addressFields_companyName']"),
				address1: $cache.checkoutForm.find("input[name$='_shippingAddress_addressFields_address1']"),
				address2: $cache.checkoutForm.find("input[name$='_shippingAddress_addressFields_address2']"),
				prefecture: $cache.checkoutForm.find("select[name$='_shippingAddress_addressFields_prefectures_prefecture']"),
				street: $cache.checkoutForm.find("input[name$='_shippingAddress_addressFields_street']"),
				addressNumber: $cache.checkoutForm.find("input[name$='_shippingAddress_addressFields_addressNumber']"),
				building: $cache.checkoutForm.find("input[name$='_shippingAddress_addressFields_building']"),
				district: $cache.checkoutForm.find("input[name$='_shippingAddress_addressFields_district']"),
				city: $cache.checkoutForm.find("input[name$='_shippingAddress_addressFields_city']"),
				packstation: $cache.checkoutForm.find("input[name$='_shippingAddress_addressFields_packstation']"),
				postnummer: $cache.checkoutForm.find("input[name$='_shippingAddress_addressFields_postnummer']"),
				postalCode: $cache.checkoutForm.find("input[name$='_shippingAddress_addressFields_zip']"),
				phone: $cache.checkoutForm.find("input[name$='_shippingAddress_addressFields_phone']"),
				phoneCode: $cache.checkoutForm.find("[id$='_shippingAddress_addressFields_phoneCode']:not('span'):not('div')"),
				countryCode: $cache.checkoutForm.find("[id$='_shippingAddress_addressFields_country']:not('span'):not('div')"),
				stateCode: $cache.checkoutForm.find("[id$='_shippingAddress_addressFields_states_state']:not('span'):not('div')"),
				storid: $cache.checkoutForm.find("input[name$='_shippingAddress_addressFields_storeID']"),
				shippinginfo: $cache.checkoutForm.find("input[name$='_shippingAddress_shippinginfo']"),
				addToAddressBook: $cache.checkoutForm.find("input[name$='_singleshipping_shippingAddress_addToAddressBook']"),
				useForBilling: $cache.checkoutForm.find("input[name$='_shippingAddress_useAsBillingAddress']")
			};
			$cache.billingAddress = {
				salutation: $cache.checkoutForm.find("input[name$='_billingAddress_addressFields_salutation']"),
				firstName: $cache.checkoutForm.find("input[name$='_billing_billingAddress_addressFields_firstName']"),
				lastName: $cache.checkoutForm.find("input[name$='_billing_billingAddress_addressFields_lastName']"),
				companyName: $cache.checkoutForm.find("input[name$='_billing_billingAddress_addressFields_companyName']"),
				address1: $cache.checkoutForm.find("input[name$='_billing_billingAddress_addressFields_address1']"),
				address2: $cache.checkoutForm.find("input[name$='_billing_billingAddress_addressFields_address2']"),
				prefecture: $cache.checkoutForm.find("select[name$='_billing_billingAddress_addressFields_prefectures_prefecture']"),
				street: $cache.checkoutForm.find("input[name$='_billing_billingAddress_addressFields_street']"),
				addressNumber: $cache.checkoutForm.find("input[name$='_billing_billingAddress_addressFields_addressNumber']"),
				building: $cache.checkoutForm.find("input[name$='_billing_billingAddress_addressFields_building']"),
				district: $cache.checkoutForm.find("input[name$='_billing_billingAddress_addressFields_district']"),
				state: $cache.checkoutForm.find("[name$='_billing_billingAddress_addressFields_states_state']"),
				city: $cache.checkoutForm.find("input[name$='_billing_billingAddress_addressFields_city']"),
				postalCode: $cache.checkoutForm.find("input[name$='_billing_billingAddress_addressFields_zip']"),
				phone: $cache.checkoutForm.find("input[name$='_billing_billingAddress_addressFields_phone']"),
				phoneCode: $cache.checkoutForm.find("[id$='_billing_billingAddress_addressFields_phoneCode']:not('span'):not('div')"),
				stateCode: $cache.checkoutForm.find("[id$='_billingAddress_addressFields_states_state']:not('span'):not('div')"),
				addToAddressBook: $cache.checkoutForm.find("input[name$='_billing_billingAddress_addToAddressBook']"),
				countryCode: $cache.checkoutForm.find("[id$='_billingAddress_addressFields_country']:not('span'):not('div')"),
				vatNumber: $cache.checkoutForm.find("input[name$='_billingAddress_addressFields_vatNumber']")
			};
			$cache.dynamic = {
				address1: {
					billingAddress: function() {
						return $cache.checkoutForm.find("input[name$='_billing_billingAddress_addressFields_address1']");
					},
					shippingAddress: function() {
						return $cache.checkoutForm.find("input[name$='_shippingAddress_addressFields_address1']");
					}
				},
				selectedPaymentMethod: function() {
					return $($cache.paymentMethodRadioSelector).find('input:checked');
				},
				shippingAddressPhoneSaved: false
			};
			$cache.cashOnDeliveryPhone = $('#dwfrm_billing_paymentMethods_cashOnDelivery_phone');
			$cache.cashOnDeliveryPhoneCode = $('#dwfrm_billing_paymentMethods_cashOnDelivery_phoneCode');
			$cache.cashOnDeliveryCode = $('#dwfrm_billing_paymentMethods_cashOnDelivery_code');
			$cache.cashOnDeliverySMSStatus = $('.js-cod_sms_status');
			$cache.cashOnDeliveryCodeVerifyStatus = $('.js-cod_code_verify_status');
			$cache.preorderWrapper = $('.js-cart_pre_order-wrapper');
			$cache.preorderAgreementCheckbox = $('#dwfrm_cart_preorder_agreement');
			$cache.preorderAgreementError = $('.js-preorder_agreement_error');
			$cache.preorderNotifyMe = $('#dwfrm_cart_preorder_notiftyMeEmail');
			$cache.preorderNotifyMeBlock = $('.js-preorder_notifyme_block');
			$cache.giftwrapform = $('.js-cart_giftwrap-form');
			$cache.giftwrapSubmitElements = ":input[type='checkbox']:checked, :input:not(:checkbox)";
			$cache.giftMessage = $cache.giftwrapform.find("[name$='_cart_giftwrap_giftMessage']");
			$cache.giftMessageSymbolsLeft = $('.js-giftwrap-message_left');
			$cache.isGiftWrap = $cache.giftwrapform.find("[name$='_cart_giftwrap_isGift']");
			$cache.checkoutSubmitBtns = $('.js-checkout-submit-button');
			$cache.checkoutSubmittedBtnClass = 'b-submitted';
			$cache.hasPersonalizedProductsClassName = 'js-has-personalizedproducts';
		}
		$cache.checkoutLogin = $('#checkoutLogin');
		$cache.checkoutLoginWrappedSel = '.js-checkout-login_account-form';
		$cache.models = {
			basket: {},
			taxinfo: {}
		};

		$cache.js_secure_code_information_title = $('.js_secure_code_information_title');
		$cache.js_secure_code_information_description = $('.js_secure_code_information_description');
		$cache.expirationdateSelect = $('.js-expirationdate');
		$cache.expirationdateLabel = $('.js-label-expiration-date');
		$cache.shippingCountrySelect = $('.js-shipping_selector select');
		$cache.shippingCountryLabel = $('.js-cart_shipping_method-title_country');
		$cache.storelocator = $('#storelocator');
		$cache.shipToStoreWrap = $('.js-store_selector_wrap');
		$cache.shipToStoreHideFields = $('.js-single_shipping_wrap').find('.js-shipToStoreHide');
		$cache.shippingCookie = 'shipping_anchor';
		$cache.shippingBlock = $('.js-shipping-anchor');
		$cache.shippingChange = '.js-country-change-click';
		$cache.shippingLink = $('a');
		$cache.shipToStoreBtn = $('#shipToStore');
		$cache.shipToHomeBtn = $('#shipToHome');
		$cache.selectedStore = $('.js-selected_store');
		$cache.deliveryType = $('.js-shiptostore_delivery_type');
		$cache.jsHideClassName = 'h-hidden';
		$cache.errorMsg = $('.js-cc-error_message, .js-cart_error_container');
		$cache.header = $('header');
		$cache.saleTaxIcon = $('.js-sale-tax-icon');
		$cache.saleTaxText = $('.js-sale-tax');
		$cache.saleTaxContentSel = '.js-sale-tax-content';
		$cache.shipToStoreWrapSel = '.js-store_selector_wrap';
		$cache.storelocatorSel = '#storelocator';
		$cache.selectedStoreSel = '.js-selected_store';
		$cache.storelocatorMapClass = '.js-storelocator_map';
		$cache.storesListClass = '.js-storelocator_list';
		$cache.storeIdFieldSel = "input[name$='_shippingAddress_addressFields_storeID']";
		$cache.phoneInfoIcon = $('.js-phone-info-icon');
		$cache.phoneInfoContent = $('.js-phone-info-content');
		$cache.requiredCls = 'f-state-required';
		$cache.bodySelector = $('html, body');
		$cache.events = {
			storeSelected: 'store.selected',
			storeChange: 'store.change'
		};
		$cache.paymentMethodLabelClass = 'js-payment_method_label';
		$cache.errorAnchorSel = '.js-error-anchor';
		$cache.paymentMethodRadioSelector = '.js-payment_method_radio';
		$cache.lineItemBodySel = '.js-lineitem-body';
		$cache.couponCodeTitle = '.js-cart_coupon_code-title';
		$cache.couponCodeTitleClosed = 'b-checkout_coupon_block-toggle_title--close';
		$cache.couponCodeTitleOpened = 'b-checkout_coupon_block-toggle_title--open';
		$cache.bagRecapBlock = $('.js-bag_recap');
		$cache.bagRecapProduct = '.js-bag_recap-product';
		$cache.prodLineItemUuidSel = '.js-product_uuid';
		$cache.prodLineItemIdSel = '.js-product_id';
		$cache.closeCheckoutPromoButton = $('.js-checkout-promo-close');
		$cache.mainMobile = $('.js-main_checkout');
		$cache.paddingTopMain = parseInt($cache.mainMobile.css('padding-top'));
		$cache.promoBanner = $('.js-checkout-promo-container');
		$cache.promoHideTimeout = 1 * 60 * 60 * 1000;
		$cache.hMinimizedClass = 'h-minimized';
		$cache.hHiddenClass = 'h-hidden';
		$cache.errorMessageClass = 'f-error_message';
		$cache.stateErrorClass = 'f-state-error';
		$cache.stateValidClass = 'f-state-valid';
		$cache.validMessageClass = 'f-valid_message';
		$cache.headerChangeEvent = 'header.change';
		$cache.promoBannerHeight = 0;
		$cache.formsNames = app.forms.names;
		$cache.orderConfirmRegister = '.js-confirmationregister';
		$cache.lineItemBodyBonusSel = ".js-lineitem-body-bonus";
		$cache.cartLineItemsSel = '.js-cart-lineitemslist';
		$cache.lineItemBodyBonusCls = 'js-lineitem-body-bonus';
		$cache.loginToApply = $('.js-checkout_login_to_apply');
		$cache.loginLink =  $('.js-login_dropdown');
		$cache.flyoutsContainer = $('.js-flyout-container');
		$cache.placeorderCondition = $('.js-checkout_placeorder-condition');
		$cache.placeorderConditionMinisummary = $cache.placeorderCondition.find('[name$=_privacyminisummary]');
		$cache.newsletterCheckbox = $('.js-newsletter_checkbox .f-checkbox');
		$cache.placeorderConditionCheckout = $cache.placeorderCondition.find('[name$=_privacy]');
		$cache.checkoutOrderSummaryWrapper = $('.js-checkout_order_summary_wrapper');
		$cache.isSummaryPage = !!$('.js-checkout_summary').length;
		$cache.isCOStep3 = !!$('.js-checkout_step3').length;
		$cache.miniAddress = $('.js-mini_address');
		$cache.miniAddress.shipping = $('.js-mini_address_shipping');
		$cache.miniAddress.billing = $('.js-mini_address_billing');
		$cache.addressesEditBtn = $('.js-addresses-edit');
		$cache.addressEditFieldsSel = '.js-address_edit-fields-box';
		$cache.addressEditCountrySel = '.js-address-country-name';
		$cache.addressSaveBtnSel = '.js-address_details-submit';
		$cache.fancyBoxCloseButtonSel = '.fancybox-close';
		$cache.miniAddresstemplate = $('#js-template-mini_address');
		$cache.billingFieldsSel = '.js-summary-billingAddress';
		$cache.shippingFieldsSel = '.js-summary-addressFields';
		$cache.recommendationTabsSel = '.js-recommendations-tabs';
		$cache.submitOverlay = $('.js-submit-overlay');
		$cache.buttonCODCodeSel = ".js-cash_on_delivery-code_block-button";
		$cache.buttonCODCodeClass = "js-cash_on_delivery-code_block-button";
		$cache.billingStateSel = ".js-billingState";
		$cache.billingPrefectureSel = '.js-billingPrefecture';
		$cache.amazonPlaceOrderClass = ".js-amazon-place-order";
		$cache.faqAnswersSel = ".js-faq_answers";
		$cache.faqPopupActiveLinkClass = "b-faq_popup-faq_questions-link--active";
		$cache.faqSectionSel = "#faq-section";
		$cache.inputQty = '.js-item_qty';
		$cache.onlyNumericSel = '.js-only-numeric';
		$cache.billingSecureKeyInputSel = '[data-form=billingSecureKey]';
		$cache.shippingSecureKeyInputSel = '[data-form=singleShippingSecureKey]';
		$cache.isCC = $('.js-cc-enabled').val() === 'true';
		$cache.storelocatorPostalCodeInputSel = '.js-storelocator_postalcode';
		$cache.storelocatorChangeStoreSel = '.js-change-store';
		$cache.klarnaWidgetContainer = $('#klarna-payments-container');
		$cache.checkoutFlags = $('input[name*="dwfrm_checkout_flags"], input[name="dwfrm_profile_customer_addtoemaillist"]');
		$cache.orderNumberValueSel = '.js-order_confirmation-order_number';
		$cache.extraInfoOrderNumberSel = '.js-oauthlogin_extrainfo_order-number';
		$cache.newsletterSubscribeCheckbox = '.js-newsletter_subscribe';
		$cache.subscribeGenders = '.js-subscribe-gender'
	}

	function initializeDom() {
		$cache.checkoutOrderSummaryWrapper.Stickyfill();
		if ($cache.errorMsg.length) {
			scrollToErrorMsg($cache.errorMsg.offset().top);
		}

		if ($cache.shippingBlock.length && $.cookie($cache.shippingCookie)) {
			$cache.bodySelector.animate(
				{
					scrollTop: $cache.shippingBlock.offset().top - ($cache.header.height() + 30)
				},
				1
			);
			$.removeCookie($cache.shippingCookie, { path: '/' });
		}

		if ($cache.bagRecapBlock.length) {
			var bagRecapProducts = $cache.bagRecapBlock.find($cache.bagRecapProduct),
				owerflowHeight = 0,
				maxProductCount = app.device.isMobileView()
					? app.preferences.mobileBagRecapViewProductCount
					: app.preferences.desktopBagRecapViewProductCount;
			if (bagRecapProducts.length > maxProductCount) {
				for (var i = 0; i < maxProductCount; i++) {
					owerflowHeight += $(bagRecapProducts[i]).outerHeight(true);
				}
				$cache.bagRecapBlock.css('height', owerflowHeight + 'px');
			}
		}
		if (app.device.isMobileView() && location.hash.indexOf('giftWrap') !== -1) {
			history.replaceState(null, document.title, document.location.href.split('#')[0]);
			$('html, body').animate(
				{
					scrollTop: $cache.giftwrapform.offset().top - ($cache.headerCheckout.outerHeight(true) + 20)
				},
				500
			);
		}
		$cache.promoBanner.toggleClass(
			$cache.hMinimizedClass + ' ' + $cache.hHiddenClass,
			!!$.cookie(app.preferences.promoCheckoutBannerCookieName)
		);
		//re-calculate padding-top for mobile, with active promo-banner
		if (app.device.isMobileView() && $cache.promoBanner.length !== 0 && !$cache.promoBanner.hasClass($cache.hHiddenClass) && app.configs.dynamicСheckoutHeaderPromoBanner) {
			var promoHeight = $cache.promoBanner.outerHeight();
			var newPaddingTop = $cache.paddingTopMain + promoHeight;
			if ($cache.paddingTopMain !== 0) {
				$cache.mainMobile.css('padding-top', newPaddingTop + 'px');
			}
			$cache.headerCheckout.css('top', promoHeight + 'px');
		}

		if ($($cache.orderConfirmRegister).length !== 0) {
			app.ajax.load({
				url: app.urls.confirmRegister,
				target: $cache.orderConfirmRegister,
				callback: function() {
					var $container = $($cache.orderConfirmRegister);
					$('.js-register-form').attr('action', $container.attr('data-url'));
					$($cache.extraInfoOrderNumberSel).attr('value', $($cache.orderNumberValueSel).data('order-no'));
					$(document.body).trigger('sticky_kit:recalc');
					app.components.account.login.init();
				}
			});
		}

		if (app.amazon && app.amazon.pay.enabled) {
			const amazonPayButtons = document.querySelectorAll('.js-amazon-pay-button');
			const amazonAddressEditButtons = document.querySelectorAll('.js-amazon-addresses-edit-button');
			const amazonPaymentEditButtons = document.querySelectorAll('.js-amazon-payment-edit-button');

			for (const amazonPayButton of amazonPayButtons) {
				AmazonPayButtonMgr.render(amazonPayButton, app.amazon.pay, 'Checkout');
			}

			for (const amazonAddressEditButton of amazonAddressEditButtons) {
				new AmazonEditButton(app.util.generateUniqueQuerySelector(amazonAddressEditButton), app.amazon.pay.checkoutSessionId);
			}

			for (const amazonPaymentEditButton of amazonPaymentEditButtons) {
				new AmazonEditButton(app.util.generateUniqueQuerySelector(amazonPaymentEditButton), app.amazon.pay.checkoutSessionId, 'changePayment');
			}
		}
	}

	function klarnaPrepearingAddressData(method, isDataAttribute, isSummaryPage) {
		var preparedData = {};
		if (isSummaryPage && isDataAttribute) {
			var addressShipping = $cache.miniAddress.shipping.length && $cache.miniAddress.shipping.data('address-shipping');
			var addressBilling = $cache.miniAddress.billing.length && $cache.miniAddress.billing.data('address-billing');

			if (addressShipping && addressBilling && method === 'authorize') {
				preparedData = {
					billing_address: {
						given_name: addressBilling.firstname,
						family_name: addressBilling.lastname,
						email: addressBilling.email,
						street_address: addressBilling.address1,
						street_address2: addressBilling.address2,
						postal_code: addressBilling.postalcode,
						city: addressBilling.city,
						region: addressBilling.stateCode,
						phone: addressBilling.phone,
						country: addressBilling.countryCode
					},
					shipping_address: {
						given_name: addressShipping.firstname,
						family_name: addressShipping.lastname,
						email: addressShipping.email,
						street_address: addressShipping.address1,
						street_address2: addressShipping.address2,
						postal_code: addressShipping.postalcode,
						city: addressShipping.city,
						region: addressShipping.stateCode,
						phone: addressShipping.phone,
						country: addressShipping.countryCode
					}
				};
			}
		} else {
			preparedData = {
				billing_address: {
					given_name: $cache.billingAddress.firstName.val(),
					family_name: $cache.billingAddress.lastName.val(),
					email: $cache.email = $cache.checkoutForm.find("input[name$='_emailAddress']").val(),
					street_address: $cache.dynamic.address1.billingAddress().val(),
					street_address2: $cache.billingAddress.address2.val(),
					postal_code: $cache.billingAddress.postalCode.val(),
					city: $cache.billingAddress.city.val(),
					region: $cache.checkoutForm.find("[id$='_billingAddress_addressFields_states_state']").length ? $cache.billingAddress.stateCode.val() : $cache.shippingAddress.stateCode.val(),
					phone: $cache.shippingAddress.phone.val(),
					country: $cache.billingAddress.countryCode.val() && $cache.billingAddress.countryCode.val().toUpperCase()
				},
				shipping_address: {
					given_name: $cache.shippingAddress.firstName.val(),
					family_name: $cache.shippingAddress.lastName.val(),
					street_address: $cache.dynamic.address1.shippingAddress().val() || $cache.shippingAddress.packstation.val(),
					street_address2: $cache.shippingAddress.address2.val(),
					postal_code: $cache.shippingAddress.postalCode.val(),
					city: $cache.shippingAddress.city.val(),
					region: $cache.shippingAddress.stateCode.val(),
					phone: $cache.shippingAddress.phone.val(),
					country: ($cache.shippingAddress.countryCode.val() && $cache.shippingAddress.countryCode.val().toUpperCase()) || $($cache.addressEditCountrySel).data('country-code'),
					email: $cache.email = $cache.checkoutForm.find("input[name$='_emailAddress']").val()
				}
			};
		}

		return preparedData;
	}

	function updateViewWithModel(modelName) {
		var model = $cache.models[modelName];
		if (model) {
			if (model.callback) {
				callbacks[model.callback](model.data);
			} else if (Array.isArray(model)) {
				var container = $('[data-model=' + modelName + ']'),
					createAction = container.data('createAction') || 'html',
					renderAction = container.data('renderAction');

				if (model.length) {
					var count = model.length;
					if (renderAction === 'ajax') {
						renderViewWithAjax(model, modelName, createAction, container);
					} else {
						renderViewWithTemplate(model, modelName, createAction, container);
					}
				} else {
					container[createAction]('');
				}
			} else {
				var elements = $('[data-model-'+modelName+']');
				elements.each(function(){
					var modelPath = $(this).data('model-'+modelName),
						modelVal;
					if(typeof modelPath !== 'string'){
						return true;
					}

					modelVal = app.util.getDeepProperty(modelPath, model);
					if(model.type === 'html') {
						modelVal && $(this).replaceWith(modelVal);
					}
					else if(typeof modelVal == "undefined" || modelVal === false) {
						$(this).addClass($cache.jsHideClassName);
					} else {
						var emptyActionObject = $.isPlainObject(modelVal) && 'type' in modelVal && typeof modelVal.value === 'undefined';
						!emptyActionObject && $(this).removeClass($cache.jsHideClassName);
						if (['object', 'boolean'].indexOf(typeof modelVal) === -1) {
							var tagName = this.tagName,
								elementModel;
							if (tagName) {
								elementModel = callbacks.elements[tagName.toLowerCase()] || callbacks.elements['default'];
								elementModel.call(this, modelVal);
							}
						} else if (Array.isArray(modelVal)) {
							if (modelVal.length) {
								renderViewWithTemplate(modelVal, $(this).data('render-template'), 'html', $(this));
							} else {
								$(this).html('');
							}
						} else if ($.isPlainObject(modelVal) && 'type' in modelVal) {
							if ('html' in modelVal) {
								$(this).html(modelVal.html);
							}
							var type = modelVal.type,
								value = modelVal.value;
							if (typeof value !== 'undefined') {
								switch (type) {
									case 'dataAttr':
										$(this).data(modelVal.target, value);
										break;
									case 'attr':
										$(this).attr(modelVal.target, value);
										break;
									case 'className':
										$(this)[modelVal.action](value);
										break;
									case 'checked':
										$(this).attr('checked', value);
										break;
								}
							}
						}
					}
				});
			}
		}
	}

	function renderViewWithAjax(model, modelName, createAction, container) {
		if (model && model.length) {
			var count = model.length,
				deffereds = [];
			for (var i = 0; i < count; i++) {
				var url = app.urls[modelName],
					ajax;
				if (model[i] != undefined && typeof model[i] === 'object') {
					url = app.util.appendParamsToUrl(url, model[i]);
				}

				ajax = app.ajax.load({ url: url });
				deffereds.push(ajax);
			}

			$.when.apply($, deffereds).done(function() {
				if (deffereds.length) {
					var args = Array.prototype.slice.call(arguments),
						html;
					if (deffereds.length === 1) {
						html = args[0];
					} else {
						html = '';
						for (var i = 0; i < args.length; i++) {
							html += args[i][0];
						}
					}
					container[createAction](html);
				}
			});
		}
	}

	function renderViewWithTemplate(model, modelName, createAction, container) {
		if (model && model.length) {
			var count = model.length,
				template = $('#' + modelName + 'Template'),
				template = template.length && template.html(),
				html = '';
			for (var i = 0; i < count; i++) {
				html += app.util.renderTemplate(template, model[i]);
			}
			container[createAction](html);
		}
	}

	function symbolsCounter(input, counter) {
		var maxSymbolsCount = 250;
		input &&
			input.on('keyup', function() {
				var symbolsLeft = maxSymbolsCount - $(this).val().length;
				counter.text(symbolsLeft);
			});
	}

	function createCcOwnerString(firstName, lastName) {
		var firstNameStr = $.trim(firstName),
			lastNameStr = $.trim(lastName);

		if (firstNameStr.length === 0 || lastNameStr.length === 0) {
			return '';
		}

		return firstNameStr + ' ' + lastNameStr;
	}

	function prepopulateCcOwner() {
		var firstName = $cache.shippingAddress.firstName.val(),
			lastName = $cache.shippingAddress.lastName.val();

		if (!$cache.shippingAddress.useForBilling.prop('checked')) {
			firstName = $cache.billingAddress.firstName.val();
			lastName = $cache.billingAddress.lastName.val();
		}

		var ccOwnerString = createCcOwnerString(firstName, lastName);

		if (ccOwnerString.length > 0) {
			$cache.ccOwner.val(ccOwnerString);
			$cache.ccOwner.valid();
			$cache.ccOwner.trigger('focusin');
		}
	}

	function isStoreSelectedOn3rdCOStep() {
		if ($cache.isCC
				&& $cache.isSummaryPage
				&& $cache.selectedStore.children().length
				&& $cache.shippingAddress.address1.length
				&& $cache.shippingAddress.address1.val().length >= 3
		) {
			return true;
		}
		return false;
	}

	/**
	* Returns payment method specific action that
	* executes on submit first step checkout
	* @returns {function|null} - Function that executes on submit first step checkout
	*/
	function getPaymentMethodActionOnSubmitForm() {
		var selectedPaymentMethodRadio = $cache.dynamic.selectedPaymentMethod();
		var result = null;

		if (selectedPaymentMethodRadio.length) {
			var onSubmitActionPath = selectedPaymentMethodRadio.data('onSubmitAction');

			if (onSubmitActionPath) {
				result = app.util.getAppComponentByPath(onSubmitActionPath);
			}
		}

		return result;
	}

	/**
	 * Render or remove quantity label on mobile
	 */
	function renderQuantityOnMobile(resp) {
		if (resp && resp.success !== true) {
			return;
		}

		if (resp.models.basket?.showCartQuantityOnMobile) {
			let holder = app.resources.CART_VIEW_ITEMS_HOLDER;
			let string = `${resp.models.basket.lineItemsNumber} ${(resp.models.basket.lineItemsNumber === 1
				? app.resources.CART_VIEW_ITEMS_ONE
				: app.resources.CART_VIEW_ITEMS_MULTI)}`;

			$('.js-number_of_items_quantity_value').text(holder.charAt(0) + string + holder.charAt(1));
		} else {
			$('.js-number_of_items_quantity_value').text('');
		}
	}

	function initializeEvents() {
		var newsletterSubscribeCheckbox = document.querySelector(`${$cache.newsletterSubscribeCheckbox} input`);

		$cache.submitOverlay.on('click', function() {
			return false;
		});

		if (newsletterSubscribeCheckbox) {
			newsletterSubscribeCheckbox.addEventListener('click', () => {
				document.querySelector($cache.subscribeGenders)?.classList.toggle($cache.hHiddenClass);
			});
		}

		//Add sticky header for product section on checkout
		app.device.isMobileView() || app.util.fixElement($cache.cartHeaderClass);

		app.device.isMobileView() || app.util.fixElement('.js-checkout_order_summary');

		$cache.closeCheckoutPromoButton.on('click', function() {
			var expiration = new Date();
			$cache.promoBannerHeight = parseInt($cache.promoBanner.outerHeight());
			$cache.promoBanner.addClass($cache.hMinimizedClass + ' ' + $cache.hHiddenClass);

			expiration.setTime($cache.promoHideTimeout + expiration.getTime());
			$.cookie(app.preferences.promoCheckoutBannerCookieName, true, { expires: expiration, path: '/' });

			$cache.header.trigger($cache.headerChangeEvent, { height: $cache.promoBannerHeight });
			if (app.device.isMobileView()) {
				$cache.mainMobile.css('padding-top', $cache.paddingTopMain + 'px');
				$cache.headerCheckout.css('top', $cache.promoBanner.outerHeight() + 'px');
			}
		});

		$cache.shippingLink.closest($cache.shippingChange).on('click', function() {
			$.cookie($cache.shippingCookie, true, { path: '/' });
		});

		$('.js-cart_fancybox_open').on('click', function() {
			var fancyboxContent = $(this).data('content');
			if (fancyboxContent) {
				app.fancybox.open($(fancyboxContent));
			}
		});

		$("[data-toggles='.js-checkout_contact_us_block'],[data-toggles='#faq-questions']").on('click', function() {
			$("[data-toggles='.js-checkout_contact_us_block'],[data-toggles='#faq-questions']")
				.removeClass('b-checkout_content_block-toggle_title--open')
				.addClass('b-checkout_content_block-toggle_title--close');
			if ($($(this).data('toggles')).hasClass($cache.jsHideClassName)) {
				$(this)
					.removeClass('b-checkout_content_block-toggle_title--close')
					.addClass('b-checkout_content_block-toggle_title--open');
			} else {
				$(this)
					.removeClass('b-checkout_content_block-toggle_title--open')
					.addClass('b-checkout_content_block-toggle_title--close');
			}
			setTimeout(function() {
				$(document.body).trigger('sticky_kit:recalc');
			}, 0);
		});

		$($cache.couponCodeTitle).on('click', function(e) {
			var $this = $(this);
			$this.removeClass($cache.couponCodeTitleOpened).addClass($cache.couponCodeTitleClosed);
			if ($($this.data('toggle')).hasClass($cache.jsHideClassName)) {
				$this.removeClass($cache.couponCodeTitleClosed).addClass($cache.couponCodeTitleOpened);
			} else {
				$this.removeClass($cache.couponCodeTitleOpened).addClass($cache.couponCodeTitleClosed);
			}
			$($this.data('toggle')).toggleClass($cache.jsHideClassName);
			setTimeout(function() {
				$(document.body).trigger('sticky_kit:recalc');
			}, 0);
		});

		$('.js-checkout_order_summary .js_view-all').on('click', function() {
			app.fancybox.open(app.urls.faqPopup, {
				type: 'ajax',
				afterShow: function(resp) {
					$('a[data-question-num]').removeClass($cache.faqPopupActiveLinkClass);
					$($cache.faqAnswersSel)
						.find('[data-answer-num]')
						.hide();
				}
			});
		});

		$cache.js_secure_code_information_title.on('click', function() {
			app.fancybox.open($cache.js_secure_code_information_description);
		});

		$('#faq-questions').on('click', 'a[id^=question]', function() {
			var id = $(this).attr('id');
			var quesiotnNum = id[id.length - 1];

			app.ajax.load({
				url: app.urls.faqPopup,
				callback: function (response) {
					if ($(response).find($cache.faqSectionSel).length) {
						app.fancybox.open(response, {
							afterShow: function () {
								var $answersContainer = $($cache.faqAnswersSel);
								$('a[data-question-num]').removeClass($cache.faqPopupActiveLinkClass);
								$('a[data-question-num=' + quesiotnNum + ']').addClass($cache.faqPopupActiveLinkClass);
								$answersContainer.find('[data-answer-num]').hide();
								$answersContainer.find('[data-answer-num=' + quesiotnNum + ']').show();
							}
						});
					}
				}
			});
		});

		$document.on('click', 'a[data-question-num]', function() {
			var $answersContainer = $($cache.faqAnswersSel);
			$('a[data-question-num]').removeClass($cache.faqPopupActiveLinkClass);
			$(this).addClass($cache.faqPopupActiveLinkClass);
			$answersContainer.find('[data-answer-num]').hide();
			$answersContainer.find('[data-answer-num=' + $(this).data('questionNum') + ']').show();
			return false;
		});


		$cache.shippingAddress &&
			$cache.shippingAddress.phone &&
			$cache.shippingAddress.phone.length &&
			$cache.shippingAddress.phone.on('change', function() {
				$cache.cashOnDeliveryPhone.val($(this).val());
				$document.trigger('addressfield.autofill');
			});

		$cache.shippingAddress &&
			$cache.shippingAddress.phoneCode.length &&
			$cache.shippingAddress.phoneCode.on('change', function() {
				$cache.cashOnDeliveryPhoneCode.val($(this).val());
				$document.trigger('addressfield.autofill');
			});

		$cache.giftWrapImage.on('click', function() {
			app.fancybox.open($cache.giftWrapPopupContent.html());
		});

		$cache.billingAddress &&
			$cache.billingAddress.countryCode.on('change', function(e, data) {
				var params = {
						dwfrm_checkout_submitForm: 'billingCountry',
						source: 'ajax'
					},
					secureKeyInput = $('[data-form=billingSecureKey]'),
					$postalElems = $cache.billingAddress.postalCode.add(
						$cache.billingAddress.postalCode.closest('.f-field-textinput')
					);
				$document.trigger('autocomplete.change.country', { id: $(this).val(), type: 'billing' });
				params[$(this).attr('name')] = $(this).val();
				params[secureKeyInput.attr('name')] = secureKeyInput.val();
				submitForm(params, function(resp) {
					if (resp && resp.state && resp.state.length > 0) {
						fillBillingRegionField('state', resp, data);
					} else {
						$($cache.billingStateSel).html('');
					}

					if (resp && resp.prefecture && resp.prefecture.length > 0) {
						fillBillingRegionField('prefecture', resp, data);
					} else {
						$($cache.billingPrefectureSel).html('');
					}

					$postalElems.toggleClass($cache.requiredCls, !(resp && resp.optionalZip));

					if (resp && resp.disableEditingZipCode){
						$postalElems.attr("disabled", "disabled");
					} else {
						$postalElems.removeAttr("disabled");
					}

					if (resp && resp.defaultZip){
						$postalElems.val(resp.defaultZip);
					}
				});

				prefillVatPrefix();
			});

		$document.on('click', '[data-toggles]', function() {
			//TODO: can be moved to global level
			var elements = $($(this).data('toggles'));
			elements.each(function() {
				$(this).toggleClass($cache.jsHideClassName);
				$(document.body).trigger('sticky_kit:recalc');
			});
		});
		$cache.checkoutForm.on('submit', function(event) {
			var isStoreNotSelected = app.components.global.storelocator.isStoreNotSelected();
			if ($cache.placeorderConditionMinisummary.length && $cache.placeorderConditionMinisummary.is(":visible") && !$cache.placeorderConditionMinisummary.valid()) {
				return false;
			}
			if (
				$cache.preorderWrapper.length > 0 &&
				!$cache.preorderWrapper.hasClass($cache.jsHideClassName) &&
				!$cache.preorderAgreementCheckbox.prop('checked')
			) {
				$cache.preorderAgreementError.removeClass($cache.jsHideClassName);
				if (!$cache.preorderNotifyMeBlock.hasClass($cache.hasPersonalizedProductsClassName)) {
					$cache.preorderNotifyMeBlock.removeClass($cache.jsHideClassName);
				}
				window.scrollTo(0, $cache.preorderWrapper.offset().top);
				return false;
			}
			if ($cache.ccMonth.val() === null || $cache.ccYear.val() === null) {
				$cache.expirationdateLabel.removeClass($cache.stateValidClass).addClass($cache.stateErrorClass);
			}
			if (!($cache.deliveryType && $cache.deliveryType.length && $cache.deliveryType.val() === 'true')) {
				if (
					$cache.shippingAddress.useForBilling &&
					$cache.shippingAddress.useForBilling.length &&
					$cache.shippingAddress.useForBilling.prop('checked') === true
				) {
					updateBillingWithShipping();
				}
			}

			var serializeCheckoutFormArray = function(form) {
				var formData = form.serializeArray();
				formData.push({ name: $cache.stepSubmitButton.attr('name'), value: $cache.stepSubmitButton.val() });
				return formData;
			};

			if($cache.userGalleryAuthorizedPublication.length && $cache.userGalleryAuthorizedPublication.prop('checked')) {
				$cache.checkoutForm.append($('<input/>')
					.attr('type', 'hidden')
					.attr('name', $cache.userGalleryAuthorizedPublication.attr('name'))
					.val($cache.userGalleryAuthorizedPublication.prop('checked'))
				);
			}

			if ($cache.giftwrapform.length && $cache.isGiftWrap.prop('checked')) {
				if ($cache.giftwrapform.valid()) {
					var elems = [];
					$cache.giftwrapform.find($cache.giftwrapSubmitElements).each(function() {
						elems.push(
							$('<input/>')
								.attr('type', 'hidden')
								.attr('class', 'giftemailhidden')
								.attr('name', this.name)
								.val($(this).val())
						);
					});
					$cache.checkoutForm.find('.giftemailhidden').remove();
					$cache.checkoutForm.append(elems);
				} else {
					$cache.bodySelector.animate({ scrollTop: $cache.giftwrapform.position().top }, 0);
					return false;
				}
			}

			if ($cache.shippingDate.length) {
				if (!$cache.shippingDate.valid()) {
					if (!app.util.elementInViewport($cache.shippingDate[0])) {
						$cache.bodySelector.animate(
							{
								scrollTop: $cache.shippingDate.closest($cache.lineItemBodySel).offset().top
							},
							500
						);
					}
					return false;
				} else {
					$cache.shippingDate.appendTo($(this)).hide();
				}
			}

			if (isStoreSelectedOn3rdCOStep()) {
				var validator = $cache.checkoutForm.validate();
				validator.settings.ignore = $cache.storelocatorPostalCodeInputSel;
			}

			const isCheckoutFormValid = () => {
				return Array.from($cache.checkoutForm).every(form => $(form).valid());
			}

			if (isCheckoutFormValid() && isStoreNotSelected) {
				var paymentMethodActionOnSubmitForm = getPaymentMethodActionOnSubmitForm();
				var paymentMethodActionResult = paymentMethodActionOnSubmitForm && paymentMethodActionOnSubmitForm();

				if (paymentMethodActionResult && paymentMethodActionResult.stopPropagation) {
					return false;
				}

				var addressData = {};

				function klarnaFinalize() {
					if (app.paymentInfo.klarnaPayment.finalizeRequire) {
						event.preventDefault();
						addressData = klarnaPrepearingAddressData('finalize', app.util.getConfig('klarnaPayment.dataAddressRequire'), true);
						app.components.global.klarnapayments.finalize(addressData).then(function() {
							$cache.checkoutForm.off();
							$cache.stepSubmitButton.click();
						}).fail(function() {
							$cache.checkoutForm.off();
							$cache.backToStepButton.click();
						});
					}
				}

				if ($cache.klarnaWidgetContainer.length && (!$cache.isSummaryPage || $cache.isCOStep3)) {
					event.preventDefault();
					addressData = klarnaPrepearingAddressData('authorize', app.util.getConfig('klarnaPayment.dataAddressRequire'), $cache.isSummaryPage || $cache.isCOStep3);
					app.components.global.klarnapayments.authorize(addressData).then(function() {
						if ($cache.isCOStep3) {
							klarnaFinalize();
						} else {
							$cache.checkoutForm.off();
							$cache.stepSubmitButton.click();
						}
					}).fail(function() {
						if ($cache.backToStepButton.length && ($cache.isSummaryPage || $cache.isCOStep3)) {
							$cache.checkoutForm.off();
							$cache.backToStepButton.click();
						} else {
							CSRF.populateFormByCSRFToken($cache.checkoutRefreshForm[0], true)
								.then(() => $cache.checkoutRefreshForm.submit());
						}
					});
				}

				if ($cache.isSummaryPage && !$cache.isCOStep3) {
					klarnaFinalize();
				}

				$cache.checkoutSubmitBtns.addClass($cache.checkoutSubmittedBtnClass);

				if ($cache.submitOverlay.length && ($cache.isSummaryPage || $cache.isCOStep3)) {
					$cache.submitOverlay.removeClass($cache.jsHideClassName);
				}

				app.progress.show($cache.checkoutSubmitBtns);
			}

			// create DDC session and submit form
			if (($cache.isSummaryPage || $cache.isCOStep3) && $cache.worldpayApplicable.length && $cache.checkoutForm.valid()) {
				WorldpayCreditCard.init().then(() => {
					form = $(this);
					let formData = serializeCheckoutFormArray(form);

					$.ajax({
						type: 'POST',
						url: form.attr('action'),
						data: $.param(formData),
						dataType : 'json',
						success: function(data) {
							if (data.success && data.challengeDetails && data.challengeDetails.threeDSJWT) {
								$cache.submitOverlay.addClass($cache.jsHideClassName);

								WorldpayCreditCard.process3DSModal(data.challengeDetails.threeDSJWT).then((redirectURL) => {
									$cache.submitOverlay.removeClass($cache.jsHideClassName);
									window.location.href = redirectURL;
								});
							} else if (data.success && data.challengeDetails && data.challengeDetails.reference) {
								window.location.href = data.challengeDetails.reference;
							} else {
								window.location.href = data.continueURL;
							}
						}
					});
				});

				return false;
			}

			//Safecharge only loaded on checkout page with CREDIT_CARD selected
			if (($cache.isSummaryPage || $cache.isCOStep3) && typeof SafeCharge !== 'undefined' && $cache.checkoutForm.valid()) {
				var form = $(this);
				var formData = form.serializeArray();
				formData.push({ name: $cache.stepSubmitButton.attr('name'), value: $cache.stepSubmitButton.val() });

				$.ajax({
					type: "POST",
					url: form.attr('action'),
					data: $.param(formData),
					dataType : 'json',
					success: function(data) {
						var submitReloadForm = function(url, value) {
							var form = $('<form></form>', {
								"method" : "post",
								"action" : url
							});
							var field = $('<input></input>', {
								"type" : "hidden",
								"name" : "paymentStatus",
								"value": value
							});

							form.append(field);
							form.hide();

							$(document.body).append(form);
							form.submit();
						};

						if (data.COErrors && data.COErrors.BasketStatus) {
							submitReloadForm(data.data.continueUrl, 'error');
							return;
						}

						if (data.error) {
							submitReloadForm(data.returnUrl, "error");
						} else {
							var sfc = SafeCharge(data.sdk);

							sfc.createPayment(data.payment, function(response) {
								submitReloadForm(data.returnUrl, JSON.stringify(response));
							});
						}
					}
				});

				return false;
			}

			if ($(this).find($cache.amazonPlaceOrderClass).length && $cache.checkoutForm.valid()) {
				event.preventDefault();
				var sellerId = app.preferences.amazonMerchantId;
				var amazonOrderReferenceId = $('[name$="_amazonpayments_orderReferenceID"]').val();
				OffAmazonPayments.initConfirmationFlow(sellerId, amazonOrderReferenceId, function(confirmationFlow) {
					app.components.global.amazonpayments.placeOrder(confirmationFlow);
				});
			}
		});
		if ($cache.expirationdateSelect.length) {
			$cache.expirationdateSelect.on('change', function() {
				if ($(this).val() === null) {
					$cache.expirationdateLabel.removeClass($cache.stateValidClass).addClass($cache.stateErrorClass);
				}
			});
		}
		if ($cache.preorderAgreementCheckbox) {
			$cache.preorderAgreementCheckbox.on('change', function() {
				if ($(this).prop('checked')) {
					$cache.preorderAgreementError.addClass($cache.jsHideClassName);
				}
			});
		}

		$cache.loginToApply.on('click', function () {
			if ($cache.loginLink.length > 0) {
				$cache.bodySelector.animate(
					{
						scrollTop: $cache.bodySelector.offset().top
					},
						500
				);
				app.components.account.fakelogin.showPopup();
			} else if ($cache.flyoutsContainer.length) {
				$cache.bodySelector.animate(
					{
						scrollTop: $cache.flyoutsContainer.offset().top
					},
					500
				);
				app.components.global.flyoutminimizer.show($cache.flyoutsContainer.find('.js-flyout-item'));
			}
		});

		$cache.checkoutLogin.length &&
			$cache.checkoutLogin.on('submit', function(e) {
				var params = $cache.checkoutLogin.serializeArray();
				params.push({
					name: 'dwfrm_checkout_submitForm',
					value: 'login'
				});
				params.push({
					name: 'source',
					value: 'ajax'
				});

				$cache.checkoutLogin.validate();
				if (!$cache.checkoutLogin.valid()) {
					return false;
				}
				submitForm(params, function(resp) {
					if (resp && resp.success === false && resp.message) {
						$('.js-checkout_login_error_form')
							.text(resp.message)
							.removeClass($cache.jsHideClassName);
					} else if (resp && resp.success === true) {
						CSRF.populateFormByCSRFToken($cache.checkoutRefreshForm[0], true)
								.then(() => $cache.checkoutRefreshForm.submit());
					}
				});
				e.preventDefault();
			});

		$document.on('click checkout.action', '[data-action]', function(e) {
			var $this = $(this);

			if ($this.hasClass($cache.actionBlockerClass)) {
				return false;
			}

			if (
				($this.prop('tagName') !== 'INPUT' || ($this.attr('type') !== 'radio' && $this.attr('type') !== 'checkbox'))
				&& $this.prop('tagName') !== 'SELECT'
			) {
				if ($this.hasClass($cache.paymentMethodLabelClass)) {
					$this
						.siblings($cache.paymentMethodRadioSelector)
						.find('input')
						.click();
					return false;
				}
				if (actions) {
					actions[$this.data('action')].call(this, $this.data(), e);
				}
				return false;
			}
		});
		if (!app.device.isMobileView()) {
			$document.on('focus', $cache.inputQty, function() {
				var data = $(this).data();
				$(this).on('keyup', function(e) {
					if (e.keyCode === 13) {
						$(this).blur();
					}
				});
				$(this).one('blur', function() {
					$(this).off('keyup');
					actions.updLineItemQty.call(this, data);
				});
			});
		}

		$document.mousedown(function(e) {
			mouseDownCache.currentElement = $(e.target);
		});

		$document.mouseup(function() {
			mouseDownCache = {};
		});

		if ($cache.cashOnDeliveryCode) {
			$cache.cashOnDeliveryCode.focusout(function() {
				var verifyButtonIsClicked = mouseDownCache.currentElement && mouseDownCache.currentElement.hasClass($cache.buttonCODCodeClass);
				if(verifyButtonIsClicked) {
					return false;
				}
			});

			$cache.cashOnDeliveryCode.on("field.validation.failed", function() {
				$cache.cashOnDeliveryCodeVerifyStatus
					.removeClass($cache.validMessageClass)
					.addClass($cache.errorMessageClass);
			});
		}

		if ($cache.cashOnDeliveryPhone) {
			$cache.cashOnDeliveryPhone.on("field.validation.failed", function() {
				$cache.cashOnDeliverySMSStatus
					.removeClass($cache.validMessageClass)
					.addClass($cache.errorMessageClass)
					.text("");
			});
		}

		$document.on('wishlist.cart.add', function(e, scope) {
			var element = $(scope);
			if (element && element.length) {
				actions.wishlistCartAdd.call(scope, element.data(), e);
			}
		});

		$document.on('cart.calculate', function() {
			actions.cartCalculate();
		});

		$document.on('recommendations.notfound', function(){
			$cache.emptyCartRecommendationsTitle.hide();
		});

		$('#p-cart').on('click', function(e) {
			if ($(e.target).closest('.js-edit_product-popup').length == 0) $('.js-edit_product-popup').html('');
		});

		$document.on(
			'change checkout.action',
			'select[data-action],input[data-action][type=radio],input[data-action][type=checkbox]',
			function(e) {
				actions && actions[$(this).data('action')].call(this, $(this).data(), e);
			}
		);

		// keypress not works with Chrome
		$document.on('keydown', function(e) {
			if (e.keyCode == 27) {
				// esc
				var $el = $('.js-edit_product-popup');
				if ($el.length) {
					$el.html('');
				}
			}
		});

		$document.on('cart.shippingCountryChange', function() {
			$cache.shippingCountryLabel.text($cache.shippingCountrySelect.find('option:selected').text());
			app.recommendations.updateCartRecommendations($($cache.recommendationTabsSel));
		});

		if ($cache.checkoutForm.length) {
			$cache.shippingAddress.useForBilling.on('click', function() {
				prefillVatPrefix();
			});
			prefillVatPrefix();
		}

		function prefillVatPrefix() {
			var country = $cache.billingAddress.countryCode.find('option:selected').val();
			if (!country) {
				return;
			}

			if ($cache.shippingAddress.useForBilling.is(':checked')) {
				$cache.billingAddress.vatNumber.val('');
				return;
			}

			var rulesJSON;

			if (app.preferences.vatCountries.toLowerCase().indexOf(country.toLowerCase()) === -1) {
				$cache.billingAddress.vatNumber.val('').closest('.f-field').hide();
				return;
			} else {
				$cache.billingAddress.vatNumber.closest('.f-field').show();
			}

			try {
				rulesJSON = JSON.parse(app.preferences.vatValidationRules);
			} catch (e) {
				return null;
			}

			$cache.billingAddress.vatNumber.val('');
		}

		// ship to store click handler
		$cache.shipToStoreBtn.on('click', function() {
			$(this).addClass('selected');
			$cache.shipToHomeBtn.data('address', saveShipping());
			if ($cache.shippingAddress.useForBilling.is(':checked')) {
				$cache.shippingAddress.useForBilling.trigger('click');
			}
			if ($cache.shippingAddress.addToAddressBook.is(':checked')) {
				$cache.shippingAddress.addToAddressBook.prop('checked', false);
			}
			cleanShipping();
			showShipToStore();
			$cache.deliveryType.val(true);
			$cache.shippingAddress.addressList.val('');
		});

		// ship to home click handler
		$cache.shipToHomeBtn.on('click', function() {
			$cache.selectedStore.html('');
			$(this).addClass('selected');
			hideShipToStore();
			$cache.deliveryType.val(false);
		});

		$document
			.on($cache.events.storeSelected, $cache.shipToStoreWrapSel, function(event, data) {
				$($cache.storelocatorSel).addClass($cache.jsHideClassName);
				$($cache.selectedStoreSel).html($('#' + data.storeid).html());
				$cache.shipToStoreBtn.data('store', data.storeid);
				setStoreAddress($('#' + data.storeid));

				if ($(this).parents('.fancybox-wrap').length) {
					app.fancybox.update();
				}
			})
			.on('click', $cache.storelocatorChangeStoreSel, function(event) {
				var store = $($cache.storeIdFieldSel).val();

				$cache.shippingAddress.storid.val('');
				$($cache.selectedStoreSel).html('');
				$($cache.storelocatorMapClass).empty();
				app.components.global.storelocator.storeLocatorDisplayMap($($cache.storesListClass).html());

				var markersArray = app.components.global.storelocator.getMarkers();
				var infoWindowsArray = app.components.global.storelocator.getInfoWindows();

				$($cache.storelocatorSel).removeClass($cache.jsHideClassName);
				$cache.shipToStoreBtn.data('store', '');
				$($cache.shipToStoreWrapSel).trigger($cache.events.storeChange);
				if ($(event.delegateTarget).parents('.fancybox-wrap').length) {
					app.fancybox.update();
				}

				for (var i = 0; i < infoWindowsArray.length; i++) {
					if (infoWindowsArray[i].content.className.indexOf(store) > 0) {
						app.components.global.storelocator.showInfoWindow(markersArray[i].map, infoWindowsArray[i], markersArray[i], store);
						break;
					}
				}
			});

		if (app.device.isMobileUserAgent() || app.device.isTabletUserAgent()) {
			$cache.saleTaxText.on('click', function() {
				if (!$cache.saleTaxIcon.hasClass($cache.jsHideClassName)) {
					openSalesTaxPopup($(this));
				}
			});
			$cache.saleTaxIcon.on('click', function(){
				openSalesTaxPopup($(this));
			});
			$cache.phoneInfoIcon.on('click', openPhoneInfoPopup);
		} else {
			$cache.saleTaxIcon.on('mouseenter mouseleave', function(e) {
				$(this).parent().find($cache.saleTaxContentSel).toggleClass($cache.jsHideClassName, e.type !== 'mouseenter');
			});
			$cache.phoneInfoIcon.on('mouseenter mouseleave', function(e) {
				$cache.phoneInfoContent.toggleClass($cache.jsHideClassName, e.type !== 'mouseenter');
			});
		}

		$cache.ccContainer.on('click', function() {
			if ($.trim($cache.ccOwner.val()).length === 0) {
				prepopulateCcOwner();
			}
		});

		function openSalesTaxPopup($target){
			app.fancybox.open($cache.saleTaxIcon, {
				content : $target.parent().find($cache.saleTaxContentSel).html(),
				wrapCSS : 'sale-tax-popup',
				autoSize : false
			})
		}

		function openPhoneInfoPopup(){
			app.fancybox.open($cache.phoneInfoIcon, {
				content : $cache.phoneInfoContent.html(),
				wrapCSS : 'phone-info-popup',
				autoSize : false
			});
		}

		//Check if current Checkout Page is Cart -> and Trigger cart.calculate
		if($cache.stepSubmitButton.val() === '0') {
			$document.trigger('cart.calculate');
		}

		if ($cache.placeorderConditionMinisummary.length) {
			$cache.placeorderCondition.on('change', function() {
				$cache.placeorderCondition.find('input[type=checkbox]').attr('checked', $(this).find('input[type=checkbox]').attr('checked') === 'checked');
				$cache.placeorderConditionCheckout.length && $cache.placeorderConditionCheckout.valid();
				$cache.placeorderConditionMinisummary.length && $cache.placeorderConditionMinisummary.valid();
			});
			if ($cache.placeorderConditionCheckout.length) {
				$cache.placeorderConditionMinisummary.attr('checked', $cache.placeorderConditionCheckout.attr('checked') === 'checked');
			}
			$cache.placeorderConditionMinisummary.parent().validate({});
		}

		if($cache.newsletterCheckbox.length > 1) {
			$cache.newsletterCheckbox.on('change', function() {
				$cache.newsletterCheckbox.prop('checked', $(this).prop('checked'));
			});
		}

		symbolsCounter($cache.giftMessage, $cache.giftMessageSymbolsLeft);

		/**
		 * Block related Summary step logic
		 */
		$cache.addressesEditBtn.on('click', function(e) {
			e.preventDefault();

			openEditAddressPopup(this.dataset.formId);
		});

		function updateMiniAddress(form, formId, syncFlag) {
			var $miniAdress = $('.js-summary-' + formId + '>.js-mini_address');
			var formObject = app.util.form2Object(form, true, true);
			var countryField = form.find($cache.addressEditCountrySel);
			var countryName = countryField.length && countryField.text().trim();

			if (countryName) {
				formObject.country = countryName;
			} else {
				formObject.country = form.find("option[value='" + formObject.country + "']").text();
			}

			// DHL adaptation

			var countrySpecificAddress1 = '';

			if (formObject.addressNumber && formObject.street) {
				countrySpecificAddress1 = formObject.addressNumber + ' ' + formObject.street;
			} else if (formObject.street) {
				countrySpecificAddress1 = formObject.street;

				if (formObject.building) {
					countrySpecificAddress1 += ' ' + formObject.building;
				}
			}

			formObject.address1 = formObject.address1 || formObject.packstation || countrySpecificAddress1;
			formObject.address2 = formObject.address2 || formObject.postnummer || formObject.prefecture || formObject.district;

			formObject.showCompanyName = formObject.companyName ? '' : $cache.jsHideClassName;
			formObject.showAddress2 = formObject.address2 ? '' : $cache.jsHideClassName;
			formObject.showCity = formObject.city ? '' : $cache.jsHideClassName;
			formObject.showPhone = formObject.phone ? '' : $cache.jsHideClassName;

			if (isStoreSelectedOn3rdCOStep()) {
				formObject.address2 = '';
			}

			var miniAddressHtml = app.util.renderTemplate($cache.miniAddresstemplate.html(), formObject);
			$miniAdress.html(miniAddressHtml);

			if (formId === 'addressFields' && formObject.useAsBillingAddress === 'true') {
				updateBillingWithShipping();
				var $billingFields = $($cache.billingFieldsSel).find($cache.addressEditFieldsSel);
				// add value as it's can't be taken dynamically from shipping address as at the moment form is in fancybox wrapper
				$billingFields.find("input[name$='_billing_billingAddress_addressFields_address1']").val(formObject.address1);
				$billingFields.wrap('<form>');
				updateMiniAddress($billingFields.parent(), 'billingAddress', true);
				$billingFields.unwrap();
			}

			// if billing form saved - uncheck useAsBillingAddress in shipping form
			if (formId === 'billingAddress' && !syncFlag) {
				$($cache.shippingFieldsSel).find("input[name$='_shippingAddress_useAsBillingAddress']").prop('checked', false);
			}
		}

		function compareAddresses() {
			var countryField = $($cache.shippingFieldsSel).find($cache.addressEditCountrySel);
			var shCountryCode = countryField.length && countryField.data('country-code');

			for (var field in $cache.billingAddress) {
				var val = $cache.billingAddress[field].val();

				if(val && $cache.shippingAddress[field] && $cache.shippingAddress[field].val() !== val) {
					if(field === 'countryCode' && shCountryCode && shCountryCode === val) {
						continue;
					}
					return false;
				}
			}

			return true;
		}

		function uncheckFlags(flags) {
			if (typeof flags === 'string') {
				flags = [flags];
			}
			$cache.checkoutFlags.filter(function(k, flag) {
				return flags.indexOf(flag.name.split('_').pop()) !== -1;
			}).prop('checked', false).trigger('change');
		}

		function openEditAddressPopup(addressType, forceValidation, forceBillingSync, forceBillingAfter, isAddressesSame) {
			const $addressFields = $document.find(`.js-address_edit-fields-box[data-form-id=${addressType}]`);
			let $wrapperForm = null;
			const $saveButton = $addressFields.find($cache.addressSaveBtnSel);

			app.fancybox.open($addressFields, {
				wrapCSS: 'b-checkout_summary',
				keys: {
					close: []
				},
				closeClick: false,
				afterShow: function() {
					$addressFields.removeClass($cache.jsHideClassName);
					$addressFields.wrap("<form id='wrapper'>");
					$wrapperForm = $addressFields.parent();

					const initialValues = $wrapperForm.serializeArray();
					const syncFlag = $wrapperForm.find("input[name$='_shippingAddress_useAsBillingAddress']");

					if ($cache.shippingAddress.phone.val()) {
						$cache.dynamic.shippingAddressPhoneSaved = true;
					}

					app.validator.initForm($wrapperForm);

					$saveButton.on('click', function(e) {
						e.preventDefault();

						if (app.components.global.storelocator.isStoreNotSelected()) {
							$($cache.fancyBoxCloseButtonSel).trigger('click', [true]);
						}
					});

					syncFlag.prop('checked', isAddressesSame);

					if (forceBillingSync) {
						syncFlag.prop('checked', true);
						syncFlag.attr("disabled", true);
					}

					if (forceValidation) {
						$wrapperForm.valid();
					}

					//as return false from beforeClose not working, overwriting processing
					$($cache.fancyBoxCloseButtonSel)
						.off('click')
						.on('click', function(e, save) {
							e.preventDefault();

							if ($wrapperForm.valid()) {
								if (!save && app.paymentInfo.selectedPaymentID === 'PAYPAL' && !$cache.dynamic.shippingAddressPhoneSaved) {
									return false;
								}

								if (forceBillingSync) {
									syncFlag.attr("disabled", false);
								}

								if (save !== true) {
									// restore fields values
									for (let i = 0, len = initialValues.length; i < len; i++) {
										const $originalAddressField = $addressFields.find('[name="' + initialValues[i].name + '"]');

										if ($originalAddressField.val() !== initialValues[i].value) {
											$originalAddressField.val(initialValues[i].value).trigger('change');
										}
									}

									$addressFields.find('input[type="checkbox"]').each(function(index, item) {
										const $item = $(item);
										const filteredInitialValue = initialValues.filter(function(initialValue) {
											return initialValue.name === item.name;
										});

										if (filteredInitialValue.length) {
											$item.attr('checked', 'checked');
										} else {
											$item.removeAttr('checked');
										}
									});
								} else {
									const formId = $addressFields.data('form-id');
									updateMiniAddress($wrapperForm, formId);
									const secureKeyInput = formId === 'billingAddress' ? $($cache.billingSecureKeyInputSel) : $($cache.shippingSecureKeyInputSel);

									if (secureKeyInput.length) {
										var params = $wrapperForm.serializeArray();
										params.push({
											name: 'dwfrm_checkout_submitForm',
											value: 'updateAddressForms'
										});
										params.push({
											name: 'source',
											value: 'ajax'
										});
										params.push({
											name: secureKeyInput.attr('name'),
											value: secureKeyInput.val()
										});

										submitForm(params, function(resp) {
											if (!resp.success && resp.backToStep !== undefined) {
												actions.toStep({ stepNum: resp.backToStep });
											}
										});
									}
								}

								$addressFields.unwrap();
								$wrapperForm = null;
								$saveButton.off('click')
								app.fancybox.close();

								if ((forceBillingAfter || forceBillingSync) && !syncFlag.prop('checked')) {
									openEditAddressPopup('billingAddress');
								}
							}
						});
				},
				helpers: {
					overlay: {
						closeClick: false
					}
				}
			});
		}

		// logic related to validation errors processing on summary page
		if ($cache.isSummaryPage) {
			// ready wrapper required for correct validator emulation
			$document.ready(function() {
				var isAddressesSame = compareAddresses();
				var validator = $cache.checkoutForm.validate();
				// trigger validation for form under display:none
				validator.settings.ignore = '';

				var billingErrors = false;
				var shippingErrors = false;
				if(!$cache.checkoutForm.valid()) {
					for (var field in validator.errorMap) {
						if (field.indexOf('shippingAddress') !== -1 || field.indexOf('storelocator') !== -1) {
							shippingErrors = true;
							if (field.indexOf('storelocator') !== -1) {
								$($cache.storelocatorChangeStoreSel).trigger('click');
							}
						}
						if (field.indexOf('billingAddress') !== -1) {
							billingErrors = true;
						}
					}
				}

				validator.resetForm();

				if (shippingErrors || billingErrors) {
					var addressType = '';
					var forceBillingSync = false;
					var forceBillingAfter = false;
					//scenario S - errors in shipping address only
					if (shippingErrors && !billingErrors) {
						addressType = 'addressFields';
					}
					//scenario B - errors in billing address only
					if (!shippingErrors && billingErrors) {
						addressType = 'billingAddress';
					}
					//scenario S == B - errors on address is equal
					if (shippingErrors && billingErrors && isAddressesSame) {
						addressType = 'addressFields';
						forceBillingSync = true;
					}
					//scenario S != B - errors in both, address differ
					if (shippingErrors && billingErrors && !isAddressesSame) {
						addressType = 'addressFields';
						forceBillingAfter = true;
					}

					if (addressType) {
						openEditAddressPopup(addressType, true, forceBillingSync, forceBillingAfter, isAddressesSame);
					}
				}

				if (app.configs.incompatibleFlags) {
					$cache.checkoutFlags.on('change', function() {
						if (this.checked) {
							var flagName = this.name.split('_').pop();
							if (flagName in app.configs.incompatibleFlags) {
								uncheckFlags(app.configs.incompatibleFlags[flagName]);
							} else {
								var objectKeys = Object.keys(app.configs.incompatibleFlags);
								for (var i = 0, len = objectKeys.length; i < len; i++) {
									if (app.configs.incompatibleFlags[objectKeys[i]].indexOf(flagName) !== -1) {
										uncheckFlags(objectKeys[i]);
									}
								}
							}
						}
					});
				}
			});
		}
	}

	app.checkout = {
		init: function() {
			initializeCache();
			initializeDom();
			initializeEvents();
		}
	};
})(window.app || {}, jQuery, window.dw = window.dw || {});
