// app.components.global.multicurrency
(function (app, $) {
	var $cache = {};
		
	function initializeCache() {
		$cache.currencySwitcher = $('.currency-converter');
		$cache.currencySwitcherSelect = $('select.currency-converter');
		$cache.switcherContainer = $('.mc-class');
	}

	function initializeEvents() {
		//listen to the drop down, and make a ajax call to mulitcurrency pipeline
		$cache.currencySwitcher.on("change", function () {
 			// request results from server
 	 		app.ajax.getJson({
 	 		 	url: app.util.appendParamsToUrl(app.urls.currencyConverter , {format:"ajax",currencyMnemonic:$cache.currencySwitcherSelect.val()}),
 	 		 	callback: function(data){
 	 				location.reload();
 	 		 	}// end ajax callback
 	 		 });
		});
		
		//hide the feature if user is in checkout
		if(app.page.title=="Checkout"){
			$cache.switcherContainer.css('display','none');
		}
	}
	
	
	/*************** app.components.cart.minicart public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.multicurrency = {
			// during page loading, the Demandware URL is stored here
			url : "", 
			// initializations
			init : function (params) {
				
				initializeCache();
				initializeEvents();

			}
	};
}(window.app = window.app || {}, jQuery));