(function (app, $) {
	var $cache;
	
	function initializeCache(){
		$cache = {
			document: $(document),
			recommendationCarousel: $('.js-productrecommendation_general'),
			recommendationPredictiveCarouselSel: '.js-products_recommender',
			recommendationsTitleBlockSel: '.js-empty-cart-recommendations-block, .js-pdp-recommendations',
			recommendationsCollapseTitleSel: '.js-empty_cart-recommendations-slide-title, .js-pdp-recommendations-slide-title',
			staticRecommendationBlocks: $('[data-recommendation-static-amount]')
		};
	}
	
	function initializeDOM(){
		if(!$cache.recommendationCarousel.length){
			$($cache.recommendationsCollapseTitleSel).hide();
		}
		else{
			$cache.staticRecommendationBlocks.each(function(){
				var staticRecommendationBlock = $(this);
				if(parseInt(staticRecommendationBlock.data('recommendationStaticAmount')) === 0){
					staticRecommendationBlock.closest($cache.recommendationsTitleBlockSel).find($cache.recommendationsCollapseTitleSel).hide();
				}
			});
		}
	}
	
	function initializeEvents(){
		$cache.document.on('recommendations.loaded', function(){
			if($($cache.recommendationPredictiveCarouselSel).length){
				$($cache.recommendationsCollapseTitleSel).show();
			}
		});
	}
	
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.recommendationblock = {
		init: function(){
			initializeCache();
			initializeEvents();
			initializeDOM();
		}	
	};
	
})(window.app = window.app || {}, jQuery);