(function (app, $) {

	var $cache = {};
	var _params;
	var queue = [];
	var inited = false;

	function initializeCache(params) {}

	function initializeEvents(params) {
		if (params.recalcOn) {
			$(document).on(params.recalcOn, function(){
				$(document.body).trigger("sticky_kit:recalc");
			});
		}
	}
	
    function stick(selector, options) {
      	if (typeof(options) == 'undefined') {
      		if (_params[selector]) {
	      		// skip stick
	      		if (_params[selector]['skip']) {
	      			// skip, do nothing
	      			return;
	      		}
	      		// use init options
	      		switch (typeof(_params[selector]['options'])) {
		      	case "function":
		      		options = (_params[selector]['options'])();
		      		break;
		      	
		      	case "object":
		      		options = _params[selector]['options'];
		      		break;
		      	}
      		} else {
      			// _params[selector] not defined, do nothing
      			return;
      		}
      	}
      	$(selector).stick_in_parent(options);
	}
	

  /*************** app.components.components.global.stickykit ***************/
  app.components = app.components || {};
  app.components.global = app.components.global || {};
  app.components.global.stickykit = {
      // initializations
	  init : function (params) {
	  	params  = params || {};
	  	_params = params;
	    initializeCache(params);
	    initializeEvents(params);
	    
	    // do ation for items in queue
	    for (var i=0; i<queue.length; i++) {
	    	stick(queue[i].selector, queue[i].options);
	    }
	    inited = true;
	  },
	  
	  stick : function(selector, options) {
	  	if (inited) {
	  		// do action immediately
	  		stick(selector, options);
	  	} else {
		  	// do nothing, just add to queue, they will wait for init
		  	queue.push({selector: selector, options: options});
	  	}
	  }
      
  };
})(window.app = window.app || {}, jQuery);
