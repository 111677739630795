(function (app, $) {
	var debug = (app.debugMode === app.constants.DEVELOPMENT_SYSTEM);
	var trackerData = app.trackerData;
	var collectCodeEnabled = app.configs.marketingCloud.isCollectCodeEnabled && app.configs.marketingCloud.collectCodeOrgId;
	var isMCTrackingAllowed = collectCodeEnabled && trackerData && typeof _etmc !== 'undefined' && _etmc;
	var MCMapping = {};


	function initEvents() {
		console.debug('mc: init events');
		if (app.mc && app.mc.config) {
			app.mc.config.global && app.mc.config.global.init();
			app.mc.config.custom && app.mc.config.custom.init();
		}
	}

	function pushStartedData() {
		console.debug('mc: is inited');
		_etmc.debug = debug;
		pushOrganisationData();
		pushUserInfo();

		if (MCMapping.trackPageView) {
			_etmc.push(MCMapping.trackPageView);
			console.debug('mc: push', MCMapping.trackPageView);
		}
	}

	function pushEvent(data) {
		pushOrganisationData();
		if (data.type === 'cart') {
			pushUserInfo();
		}
		_etmc.push(data.info);
		console.debug('mc: push', data.info);
	}

	function initMapping() {
		MCMapping.orgId = ['setOrgId', app.configs.marketingCloud.collectCodeOrgId];
		MCMapping.userInfo = [
			'setUserInfo',
			{
				email: trackerData.userInfo._email ? trackerData.userInfo._email : trackerData.userInfo.customerID
			}
		];

		if (!trackerData.userInfo.authorizationDataCollection) {
			MCMapping.doNotTrack = ['doNotTrack'];
		}
		var trackPageData = getTrackPageData();
		if (trackPageData) {
			if (trackerData.section !== 'confirmation') {
				MCMapping.trackPageView = ['trackPageView', trackPageData];
			} else {
				MCMapping.trackPageView = ['trackConversion', trackPageData];
			}
		}
	}

	function getTrackPageData() {
		var data = {};
		switch (trackerData.section) {
			case 'plp':
				data.category = trackerData.catID;
				break;
			case 'search':
				data.search = trackerData.searchKeyword;
				break;
			case 'product':
				data.item = trackerData.productMasterID;
				break;
			case 'confirmation':
				data.cart = getConfirmationData();
				break;
		}
		return data;
	}

	function pushCart() {
		var data = {};
		data.type = 'cart';
		var cartExist = trackerData.cartInfo && trackerData.cartInfo.cartProducts;

		data.info = [
			'trackCart',
			(cartExist ? getMapCart() : { clear_cart: true })
		];
		pushEvent(data);
	}


	function pushUserInfo() {
		_etmc.push(MCMapping.userInfo);
		console.debug('mc: push', MCMapping.userInfo);
	}

	function pushOrganisationData() {
		_etmc.push(MCMapping.orgId);
		console.debug('mc: push', MCMapping.orgId);

		if (MCMapping.doNotTrack) {
			_etmc.push(MCMapping.doNotTrack);
			console.debug('mc: push', MCMapping.doNotTrack);
		}
	}

	function getConfirmationData() {
		var orderItems = [];
		if (trackerData.ecommerce) {
			trackerData.ecommerce.purchase.products.forEach(function(item) {
				var itemObj = {
					unique_id: item.variant,
					quantity: item.quantity,
					item: item.id,
					price: item.price
				};
				orderItems.push(itemObj);
			});
		}
		return orderItems;
	}

	function getMapCart() {
		var cartItems = [];
		if (trackerData.cartInfo && trackerData.cartInfo.cartProducts) {
			trackerData.cartInfo.cartProducts.forEach(function(item) {
				var itemObj = {
					unique_id: item.variantId,
					quantity: item.quantity,
					item: item.masterId,
					price: item.price
				};
				cartItems.push(itemObj);
			});
		}

		return cartItems;
	}

	app.mc = $.extend(app.mc || {}, {
		init: function () {
			if (isMCTrackingAllowed) {
				initMapping();
				initEvents();
				pushStartedData();
			} else {
				console.debug('mc: is disabled');
			}
		},
		pushEvent: pushEvent,
		pushCart: pushCart
	});
}(window.app = window.app || {}, jQuery));
