import CSRF from 'oneapp/src/utils/CSRF';

const FALLBACK_DDC_TIME = 12000; // 12 sec
const DDC_RETRIES_COUNT = 3;

class WorldpayDDC {
	getDDC() {
		const cardBin = getWorldpayCardNumber().slice(0, 6);

		return getDDCJWT().then(function (response) {
			const iframeLayout = getIframeDDCLayout(cardBin, response.JWT);

			return CSRF.getCSRFToken((csrfData) => {
				const loggerData = {
					csrf_token: csrfData.token,
					warningBody: {
						level: 'warning',
						type: 'WORDLPAY',
						title: 'DDC request attempt',
						stackTrace: {}
					},
					errorBody: {
						level: 'error',
						type: 'WORDLPAY',
						title: 'DDC request failed all retries',
						stackTrace: {}
					}
				};

				return app.util.promiseWithRetry(attemptGetDDC.bind({ body: iframeLayout }), DDC_RETRIES_COUNT, loggerData);
			});
		}).catch(err => Promise.reject(err));
	}
}

function attemptGetDDC() {
	return new Promise((resolve, reject) => {
		const iframe = createIframe({ body: this.body });

		window.addEventListener('message', onDDCResponse, false);

		const fallbackTimeout = setTimeout(() => {
			iframe.remove();
			window.removeEventListener('message', onDDCResponse, false);

			reject(new Error('DDC failed'));
		}, FALLBACK_DDC_TIME);

		function onDDCResponse(event) {
			if (event.origin === app.preferences.worldpayCardinalCommerceURL) {
				const data = JSON.parse(event.data);

				if (data && data.Status && data.SessionId) {
					iframe.remove();
					window.removeEventListener('message', onDDCResponse, false);

					$('#worldpay-dfReferenceId').val(data.SessionId);

					resolve(data.SessionId);
					clearTimeout(fallbackTimeout);
				}
			}
		}

		iframe.contentWindow.document.forms.devicedata.submit();
	});
}

function getWorldpayCardNumber() {
	const worldpayCardNumber = document.getElementById('worldpay-card-number');
	const inputCardNumber = document.getElementById('dwfrm_billing_paymentMethods_creditCard_number');

	return (worldpayCardNumber?.value || inputCardNumber?.value || '').replaceAll(' ', '');
}

function getDDCJWT() {
	const url = new URL(app.urls.worldpayDDC)

	url.searchParams.set('cardNumber', getWorldpayCardNumber());

	return fetch(url)
		.then(response => response.json());
}

function createIframe({ src = 'about:blank', body, iframeConfig = {} }) {
	const iframe = document.createElement('iframe');

	iframe.setAttribute('src', src);
	iframe.style.width = (iframeConfig.width || '0') + 'px';
	iframe.style.height = (iframeConfig.height || '0') + 'px';
	iframe.style.display = 'none';

	document.body.appendChild(iframe);

	if (body) {
		iframe.contentWindow.document.body.innerHTML = body;
	}

	return iframe;
}

function getIframeDDCLayout(cardBin, ddcJWT) {
	return `
		<form name="devicedata" method="POST" action="${app.preferences.worldpayCardinalCommerceURL}/V1/Cruise/Collect" onload="this.submit();">
			<input type="hidden" name="Bin" value="${cardBin}" />
			<input type="hidden" name="JWT" value="${ddcJWT}" />
		</form>
	`;
}

export default new WorldpayDDC();
