;(function(app, $) {
	function NotificationsMgr() {
		this.notifications = [];
		this.container = getContainer.call(this);
		this.stackNotifications = false;
	}
	
	NotificationsMgr.prototype.setContainer = function(container) {
		this.container = container;
	};

	NotificationsMgr.prototype.show = function(type, text) {
		var notification = app.notificationFactory.create(type, text);
		if ( !this.stackNotifications && this.notifications.length === 1 ) {
			this.notifications[0].remove();
		}
		this.notifications.push(notification);
		this.container.append(notification.getHTML());
		notification.show();
	};

	NotificationsMgr.prototype.hideAll = function() {
		for( var i = this.notifications.length-1; i >= 0; i--) {
			this.notifications[i].hide();
		}
	};

	NotificationsMgr.prototype.removeNotification = function(notification) {
		if ( this.notifications.indexOf(notification) !== -1 ) {
			this.notifications.splice(this.notifications.indexOf(notification), 1);
		}
	}

	function getContainer() {
		if (!this.container) {
			this.setContainer($("body"));
		}
		
		return this.container;
	}

	app.notificationsMgr = new NotificationsMgr();
})((window.app = window.app || {}), jQuery);