( function(app, $) {
    var $cache = {};

    function initializeCache(params) {
        $cache = {
            frequencyRadioForm : $('.js-newsletter_frequency-form:first'),
            customerSubscribed : $('.js-customer_subscribed:first').val()
        };
        $cache.frequencyRadio = $cache.frequencyRadioForm.find('input[type=radio]')
    }

    function initializeEvents() {
        if (!app.preferences.enableNewsletterFrequency || !$cache.frequencyRadioForm.length) {
            return;
        }
        if ($cache.customerSubscribed === "true") {
            var customerFrequency = $cache.frequencyRadio.filter(':checked').attr('id');
            if (!customerFrequency) {
                customerFrequency = $cache.frequencyRadio.first().attr('id');
            }
            $cache.frequencyRadioForm.find('label[for="'+customerFrequency+'"]').addClass('active');
        }
        $cache.frequencyRadio.on('click', function () {
            $cache.frequencyRadioForm.find('label').removeClass('active');
            $cache.frequencyRadioForm.find('label[for="'+$(this).attr('id')+'"]').addClass('active');
            $cache.frequencyRadioForm.find('input[name$="_addtoemaillist"]:first').prop('checked', 'true');
            $cache.frequencyRadioForm.submit();
        });
    }

    /**
     * @namespace app.components.account.newsletter public object
     **/
    app.components = app.components || {};
    app.components.account = app.components.account || {};
    app.components.account.newsletter = {
        init : function(params) {
            initializeCache(params);
            initializeEvents(params);
        }
    };
}(window.app = window.app || {}, jQuery));

