(function (app, $) {

	var $cache = {};

	function initializeCache() {	
		$cache = {
			selectRegion : $('.js-select_region').find('select'),
			selectCountry : $('.js-select_country').find('select'),
			selectLanguage : $('.js-select_language').find('select'),
			countryInput : $('input[name="Country"]'),
			languageInput : $('input[name="Language"]')
		}
		
	}

	function initializeEvents() {
		//load countries of selected region
		$cache.selectRegion.on("change", function() {
			// hide all countries
			$cache.selectCountry.find('option[data-region]').hide(); 
			
			// reset previous value to the first option
			$cache.selectCountry.find('option[data-region!="' + $cache.selectRegion.val() + '"]').each(function(){
				$(this).detach().appendTo($cache.selectCountry);
			}); 
			
			$cache.selectCountry.find("option[disabled]").detach().prependTo($cache.selectCountry).attr('selected','selected');

			// find and show only country of region
			$cache.selectCountry.find('option[data-region="' + $cache.selectRegion.val() + '"]').show();
		});

		// set selected country to hidden input
		$cache.selectCountry.on("change", function() { 
			$cache.countryInput.val($cache.selectCountry.val());
		});

		// set selected language to hidden input
		$cache.selectLanguage.on("change", function() {
			$cache.languageInput.val($cache.selectLanguage.val());
		});
		app.validator.init();
	}

	/*************** app.components.global.fbcountrylangselector public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.fbcountrylangselector = {
		// initializations
		init : function () {
			initializeCache();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);
