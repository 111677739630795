/**
 * @class search.js-refinement
 */

(function (app, $) {

	/**
	 * @function
	 * @description Initializes the DOM of the Product Landing Page
	 */

	var $cache = {
		refinementContainer: '.js-refinement_container',
		activeRefinementLink: '.js-refinement-link-active'
	};
	var parentLocation = {};

	function initializeDom() {
		$(document).on('refinements-update', function() {
			var searchFoundResult = $('.js-search-foundresult_title');

			if (searchFoundResult.length > 0) {
				$('.js-search-foundresult_container').html(searchFoundResult.html());
				searchFoundResult.remove();
			}

			var mainBreadcrumbs = $('main').children('.js-breadcrumbs');

			if (mainBreadcrumbs.length > 0) {
				$('.js-header_breadcrumbs').html(mainBreadcrumbs.find('.js-breadcrumb-refinement_list'));
				$('.js-breadcrumb-refinement_container').html(mainBreadcrumbs.find('.js-breadcrumb-refinement_selected'));
				mainBreadcrumbs.remove();
			}

			$cache.allRefiments = $('.js-refinements').children('.js-refinement');

			if ($cache.allRefiments.length > 0 && $('.js-refinements').data('productsearch-count') > 0) {
				$('#js-refinement_container').html($cache.allRefiments);
				$('.js-refinements').remove();
			}
		});
	}
	function initializeEvents() {
		$('.js-refinement_visibility').on('click', '.js-refinement a, .js-breadcrumb_refinement-link', function(e) {
			if (!parentLocation.length) {
				parentLocation = $('.js-subcategory_refinement_list').data('parentCategoryUrl');
				parentLocation = parentLocation || window.location.href;
			}

			var $that = $(this);

			if ($that.parent().hasClass('js-unselectable')) { return; }

			if ($that.hasClass('js-breadcrumb_refinement-link')) {
				var uri = this;
				app.search.updateProductListing(uri.href);
			} else {
				if ($that.hasClass('js-category_refinement-link') && !$that.hasClass('js-refinement-link-active')) {
					$cache.allRefiments.find('.js-category_refinement-link.js-refinement-link-active')
						.removeClass('b-refinement-link--active')
						.removeClass('js-refinement-link-active');
				}

				$that.toggleClass("b-refinement-link--active js-refinement-link-active");
			}
			// prevents closing popup event
			// e.preventDefault();
			// e.stopPropagation();

			return false;
		});

		$('.js-refinement_visibility').on('click', '.js-filter-clear_button', function(e) {
			$('.js-refinement a').removeClass('b-refinement-link--active js-refinement-link-active');
			e.stopPropagation();
		});

		$('.js-refinement_visibility').on('click', '.js-min_refinement_selector', function(e) {
			// code smell - preventer of closing popup
			e.stopPropagation();
		});

		$('.js-refinement_visibility').on('click', '.js-filter-submit_button', function(e) {
			var activeRefinementsLink = $($cahce.refinementContainer).find($cache.activeRefinementLink);

			if (activeRefinementsLink.length > 0) {
				var result = {};
				var counter = 1;

				$.each(activeRefinementsLink, function() {
					var linkData = $(this).data();

					if (linkData && 'prefn' in linkData && 'prefv' in linkData) {
						var prefn = linkData.prefn;
						var	prefv = linkData.prefv;

						if (prefn in result) {
							result[prefn] = result[prefn] + '|' + prefv;
						} else {
							result[prefn] = prefv;
						}
					}
				});
				var queryObj = {};

				for (key in result) {
					queryObj['prefn' + counter] = key;
					queryObj['prefv' + counter] = result[key];
					counter++;
				}

				var newLocation = $cache.allRefiments.find('a.js-category_refinement-link.b-refinement-link--active.js-refinement-link-active').attr('href');
				var	newUrl = newLocation || app.util.removeCountedParametersFromURL(parentLocation, ['prefn', 'prefv']);

				app.search.updateProductListing(app.util.appendParamsToUrl(newUrl, queryObj), false);
			} else {
				var newUrl = app.util.removeCountedParametersFromURL(app.search.startUrl, ['prefn', 'prefv']);

				app.search.updateProductListing(newUrl, false);
			}

			$('.js-refinement_visibility .js-toggler').trigger(Modernizr.touchevents ? 'touchstart' : 'click');
			e.stopPropagation();
		});
	}

	app.components = app.components || {};
	app.components.search = app.components.search || {};
	app.components.search.refinement = {
		init : function () {
			initializeDom();
			jQuery(document).trigger('refinements-update');
			initializeEvents();
		}
	};
}(window.app = window.app || {}, jQuery));
