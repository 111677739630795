/**
 * @class app.components.global.notification
 */
;(function (app, $) {
	'use strict';

	var $cache = {},
		initialized = false,
		preferences = {
			event : 'touchstart click',
			notificationActionElementsSelector : '.js-notification',
			dataPreffix: 'notification',
			defaultOptions : {
				cssClass: '',
				position: 'right,bottom',
				onOpen: $.noop, //this = wrapper
				onClose: $.noop,
				close : {
					timeout : false, //can be null or false
					closeButton : true,
					closeOnClickOutside : true,
					closeOnEsc : false,
					clearTimerOnMouseEnter : false
				}
			}
		};
	
	/**
	 * @private
	 * @function
	 * @description initialize class cache
	 */
	function initCache() {
		$cache = {
			document : $(document),
			notificationActionElements : $(preferences.notificationActionElementsSelector)
		};
	}
	
	/**
	 * @private
	 * @function
	 * @description initialize class preferences
	 */
	function initializeConfig(_preferences) {
		preferences = $.extend(true, preferences, _preferences || {});
	}

	/**
	 * @private
	 * @function
	 * @param {String} position Position string
	 * @returns {String} Returns converted css string
	 * 
	 * @description convert position string to CSS classes
	 */
	function convertPositionToCss(position) {
		var css = '',
			posArr = position.split(',');
		if(posArr.length == 2) {
			css = 'm-position-h-' + posArr[0].trim() +' m-position-v-'+ posArr[1].trim();
		} else if(posArr.length == 1) { //for simple positions, like center
			css = 'm-position-v-' + posArr[0].trim();
		}
		return css;
	}

	/**
	 * @private
	 * @function
	 * @description initialize class events
	 */
	function initEvents() {
		$cache.notificationActionElements.on(preferences.event, function (e) {
			e.preventDefault();
			var actionElement = $(this),
				options = $.extend(true, {}, preferences.defaultOptions, actionElement.data(preferences.dataPreffix + '-options') || {});

			if (actionElement.data(preferences.dataPreffix + '-content')) {
				displayContent(actionElement.data(preferences.dataPreffix + '-content'), options);
			} else if (actionElement.data(preferences.dataPreffix + '-message')) {
				display(actionElement.data(preferences.dataPreffix + '-message'), options);
			} else if (actionElement.data(preferences.dataPreffix + '-url')) {
				displayFromUrl(actionElement.data(preferences.dataPreffix + '-url'), options);
			} else if (actionElement.data(preferences.dataPreffix + '-source')) {
				var sourceElement = $(actionElement.data(preferences.dataPreffix + '-source'));
				if (sourceElement.length === 1) {
					displayFromElementHtml(sourceElement, options);
				}
			}
			return false;
		});
		
		$cache.document.on('notification.show', function( event, data ) {
			event.preventDefault();
			var options;
			if( !data ) return;
			options = $.extend(true, {}, preferences.defaultOptions, data.options || {});
			
			if(data.html) {
				display(data.html, options);
			} else if(data.url) {
				displayFromUrl(data.url, options);
			} else if(data.content) {
				displayContent(data.content, options);
			}
		});
	}

	/**
	 * @private
	 * @function
	 * @param {String} cid Content asset Id
	 * @param {Object} options Options object
	 *
	 * @description display content
	 */
	function displayContent(cid, options) {
		var url = app.util.appendParamToURL(app.urls.pageInclude, 'cid', cid);
		displayFromUrl(url, options);
	}
	/**
	 * @private
	 * @function
	 * @param {Object} element jQery element
	 * @param {Object} options Options object
	 *
	 * @description display notification from html exists container
	 */
	function displayFromElementHtml(element, options) {
		display(element.html(), options);
	}

	/**
	 * @private
	 * @function
	 * @param {String} url Url of loaded content
	 * @param {Object} options Options object
	 *
	 * @description Load notification content from url and display it
	 */
	function displayFromUrl(url, options) {
		app.ajax.load({
			url : url,
			callback : function (response) {
				display(response, options);
			}
		});
	}

	/**
	 * @private
	 * @function
	 * @param {String} html Html what will be displayed
	 * @param {Object} options Options object
	 *
	 * @description Display notification content
	 */
	function display(html, options) {
		
		if(!options) {
			var options = preferences.defaultOptions;
		}
		
		var wrapper = $('<div/>', {
			'class': 'b-notification-wrapper ' + convertPositionToCss(options.position) + ' ' + (options.cssClass || '')
		}),
			notificationAdded; // async function to check if DOM was updated with a notification wrapper

		var closeEvent = function(e) {
			options.onClose();
			wrapper.remove();
		};

		if(options.close.closeButton) {
			wrapper.append(
				$('<div/>', {
					'class': 'b-notification-close_btn js-notification-close'
				})
			);
			$cache.document.on('click', '.js-notification-close', function() {
				closeEvent();
			});
		}
		
		if(options.close.closeOnEsc) {
			document.onkeydown = function(e) {
			    if (e.keyCode == 27) {
			    	wrapper.remove();
			    }
			};
		}

		wrapper.append(
			$('<div/>', {
				'class': 'b-notification-content',
				html: html
			})
		);
		
		notificationAdded = setInterval(function(){
			if ($cache.document.find(wrapper).length) {
				wrapper.addClass('m-opened');
				clearInterval(notificationAdded);
			}
		}, 100);
		
		$(document.body).append(wrapper)
		
		if(options.close.closeOnClickOutside) {
			wrapper.on('clickoutside', closeEvent);
		}

		options.onOpen.apply(wrapper);


		if(options.close.timeout) {
			var timer = setTimeout( function() { closeEvent() } , options.close.timeout);
		}
		
		$cache.document.trigger('flyout.reload', {
			wrapper : wrapper
		});
		
		if(options.callback && 'function' == typeof options.callback) {
			options.callback(wrapper);
		}

		if(options.close.clearTimerOnMouseEnter) {
			wrapper.on('mouseenter', function() {
				clearTimeout( timer );
			}).on('mouseleave', function(){
				clearTimeout( timer );
				setTimeout( function() { closeEvent() } , options.close.timeout);
			});
		}
	}

	/*************** app.components.global.notification public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.notification = {
		/**
		 * @public
		 * @function
		 * @description Initialize component
		 */
		init : function (_preferences) {
			initializeConfig(_preferences)
			initCache();
			initEvents();
			initialized = true;
		},

		/**
		 * @public
		 * @function
		 * @param {String} html Html what will be displayed
		 * @param {Object} options Options object
		 *
		 * @description Display notification content
		 */
		display : display,

		/**
		 * @public
		 * @function
		 * @param {String} url Url of loaded content
		 * @param {Object} options Options object
		 *
		 * @description Load notification content from url and display it
		 */
		displayFromUrl : displayFromUrl,

		/**
		 * @public
		 * @function
		 * @param {String} cid Content asset Id
		 * @param {Object} options Options object
		 *
		 * @description display content
		 */
		displayContent : displayContent,

		/**
		 * @public
		 * @function
		 * @param {Object} element jQery element
		 * @param {Object} options Options object
		 *
		 * @description display notification from html exists container
		 */
		displayFromElementHtml : displayFromElementHtml,
		
		/**
		 * @public
		 * @Variable
		 *
		 * @description true if script is initialized
		 */
		initialized : initialized
	};
}(window.app = window.app || {}, jQuery));