import WorldpayDDC from './WorldpayDDC';

class WorldpayCreditCard {
	init() {
		collectBrowserData();

		return $('#worldpay-dfReferenceId[data-enabled3ds="true"]').length ? WorldpayDDC.getDDC() : Promise.resolve();
	}

	process3DSModal(threeDSJWT) {
		app.popupsMgr.open('WorldpayThreeDS', threeDSJWT);

		return new Promise((resolve) => {
			window.addEventListener('message', on3DSResponse, false);

			function on3DSResponse(event) {
				if (event.origin === window.location.origin) {
					const data = event.data;

					if (data && data.queryParams && data.queryParams.redirectURL) {
						app.fancybox.close();
						window.removeEventListener('message', on3DSResponse, false);
						resolve(data.queryParams.redirectURL);
					}
				}
			}
		});
	}
}

function collectBrowserData() {
	$('#worldpay-browser-data').val(JSON.stringify({
		browserScreenHeight: screen.height,
		browserScreenWidth: screen.width
	}));
}

export default new WorldpayCreditCard();
