(function (app, $) {
	var $cache = {};
	
	function initializeCache(params) {
		var settings = {
				type : 'ajax',
				width : '100%',
				margin : 0,
				padding : 0,
				wrapCSS : 'b-product_compare',
				autoSize : true,
				afterShow : initializePopupEvents
			},
			extendSettings = params.settings || {};
		$.extend(settings, extendSettings);
		
		$cache = {
			document : $(document),
			headerSel : "header",
			breadcrumbsSel : ".js-breadcrumbs",
			widgetSel : ".js-compare-widget",
			widgetSelClose : '.js-compare-widget_close',
			compareSel : ".js-compare-now",
			productGridPage: '.js-product_list',
			notifyMeFormClose: '.fancybox-notify_me .fancybox-close',
			compareTableSel : ".js-product-compare-table",
			compareItemSel : ".js-compare-item",
			checkBoxSel : ".js-product-compare",
			errorCompareSel : ".js-compare-error",
			nextButtonSel : ".js-comparison-next",
			prevButtonSel : ".js-comparison-prev",
			sizeSel : ".js-compare-swatchanchor",
			sizeValSel : ".js-compare-swatch-value",
			lowProductInput: ".js-product_pid",
			sizeListSel : ".js-swatches",
			addToCartSel : ".js-add_to_cart",
			productIdSel : ".js-product_id",
			addContainerSel : ".js-add_",
			sizeContainerSel : ".js-size_",
			errorSel : ".js-error_variations",
			imageSlideSel : ".js-compare-image_slide",
			activeSlideCls : "active",
			checkedClass : "checked",
			hiddenClass : "h-hidden",
			variationMessageClass: "b-variation-few_left_message-not_empty",
			productCompareTableSel : '.js-b-product_compare-table',
			notifyMeLinkSel : '.js-notifyme_link',
			compareSwatchanchor : '.js-compare-swatchanchor',
			isCss3Finish : true,
			settings : settings
		}
	}

	function initializeEvents() {
		$cache.document
			.on("click", $cache.checkBoxSel, function(){
				var $this = $(this),
					pid = $this.data("product"),
					cid = $this.data("category");
				if ($this.hasClass($cache.checkedClass)){
					remove(pid, cid);
					if ($this.closest($cache.compareTableSel).length){
						updatePopup(cid);
					}
				} else {
					add(pid, cid);
				}
				return false;
			})
			.on("click", $cache.compareSel, showComparePopup)
			.on('body-padding-updated refinements-update', function(e, params){
				bindWidget(params && params.paddingTop || null);
			})
			.on('click', $cache.widgetSelClose, function(){
				var $comareItems = $($cache.widgetSel).find($cache.checkBoxSel);

				$.each($comareItems , function() {

					var $compareItem = $(this),
						$compareItemActive = $compareItem.hasClass($cache.checkedClass),
						pid = $compareItem.data('product'),
						cid = $compareItem.data('category');

					if ($compareItemActive) {
						remove(pid, cid)
					}
				});
			});
	}
	
	function initializeDOM() {
		bindWidget();
		if (app.isMobileView()){
			app.components.product.mobile.init();
		}
	}
	
	function initializePopupEvents() {
		
		displayLowInStockMsg();

		$($cache.compareTableSel).on("click", $cache.addToCartSel, function (e){
			var $this = $(e.target),
				container=$this.closest("td"),	
				index = container.data("index"),
				sizeContainer = $($cache.sizeContainerSel+index);
			if (!container.find($cache.productIdSel).val()){
				sizeContainer.find($cache.errorSel).show();
			} else {
				app.product.setAddToCartHandler.call(e.target, e);
			}
			return false;
		});
		
		$($cache.productCompareTableSel).on("click", $cache.notifyMeLinkSel, app.product.openNotifyMePopup);
		$($cache.compareSwatchanchor).on("change", function(e) {
			var $selectedOption = $(this).find(':selected');
			
			if (!$selectedOption.data('selectable')) {
				app.product.openNotifyMePopup.call($selectedOption, e, {container: $(this).closest($cache.productCompareTableSel)});
				return false;
			}
		});
		
		$($cache.nextButtonSel).on("click", function(){
			if ($cache.isCss3Finish) {
				var elem = $($cache.imageSlideSel + '.' + $cache.activeSlideCls),
					$next = elem.next().length ?  elem.next() : $($cache.imageSlideSel + ":first-child");
				slide($next);
				return false;
			}
		});
		$($cache.prevButtonSel).on("click", function(){
			if($cache.isCss3Finish){
				var elem = $($cache.imageSlideSel + '.' + $cache.activeSlideCls),
					$next = elem.prev().length ?  elem.prev() : $($cache.imageSlideSel + ":last-child");
				slide($next);
				return false;
			}
		});
		
		$($cache.sizeSel).on("click change", function(){
			var $this = $(this),
				isSelect =  $this.is("select"),
				productID = isSelect ? $(this).val() : $(this).data("product"),
				container=$this.closest("td"),	
				index = container.data("index"),
				addContainer = $($cache.addContainerSel+index);
			container.find($cache.errorSel).hide();
			if (productID){
				addContainer.find($cache.productIdSel).val(productID);
			} else {
				addContainer.find($cache.productIdSel).val("");
			}
			if (!isSelect){
				container.find($cache.sizeValSel).text($this.text());
				container.find($cache.sizeListSel).addClass($cache.hiddenClass);
			}
			return false;
		});
	}
	function initNotifyMeFormEvents(){
		if(app.device.isMobileUserAgent() && $($cache.productGridPage).length){
			$($cache.notifyMeFormClose).on("click", showComparePopup);
		}
	}
	function displayLowInStockMsg(){
		var lowProductInputs = $($cache.lowProductInput),
			container;
		for(var i = 0, len = lowProductInputs.length; i<len; i++){
			var productPid = lowProductInputs.eq(i).val();
			(function(productPid){
				app.preferences.productShowLowInStockMsg && app.ajax.getJson({
					url : app.util.appendParamToURL(app.urls.productInStockLevelMsg, "pid", productPid),
					callback : function(resp) {
						container = $($cache.compareTableSel + " " + $cache.sizeListSel + "[data-masterid='" + productPid + "']");
						if(resp) {
							for (var size in resp) {
								if (resp[size]) {
									var elem = container.find(".js_low-in-stock-msg[data-attr-value='"+size+"']");
									if (elem.text() !== resp[size]) {
										elem.append(" " + resp[size]).addClass($cache.variationMessageClass);
									}
								}
							}
						}
					}
				});
			})(productPid);
		}
	}
	function showComparePopup(){
		var $this =  $($cache.compareSel),
			url = app.util.appendParamToURL($this.attr("href"), "category", $this.data("category"));
		app.fancybox.open(url, $cache.settings);
		return false;
	}
	function slide($next){
		$cache.isCss3Finish = false;
		$($cache.imageSlideSel).removeClass($cache.activeSlideCls).fadeOut(parseInt(app.preferences.comparAnimSpeed));
		$next.fadeIn(parseInt(app.preferences.comparAnimSpeed), function(){
			$cache.isCss3Finish = true;
			$(this).addClass($cache.activeSlideCls);
		});
	}
	function add (productID, categoryID){
		var data = {
				pid : productID,
				category : categoryID
			};
		$.getJSON(app.urls.compareAdd, data)
			.done(function(response){
				if (response.success && !response.errors.count){
					$("a.cc-"+productID).addClass($cache.checkedClass);
					$("span.cc-"+productID).text(app.resources.COMPARE_LABEL_SELECTED);
					updateWidget(categoryID);
				} else {
					showError();
				}
			})
			.fail(function(){
				location.href = location.href;
			});
	}
	function remove (productID, categoryID){
		var data = {
				pid : productID,
				category : categoryID
			};
		$.getJSON(app.urls.compareRemove, data)
			.done(function(response){
				if (response.success && !response.errors.count){
					$("a.cc-"+productID).removeClass($cache.checkedClass);
					$("span.cc-"+productID).text(app.resources.COMPARE_LABEL_COMPARE);
					updateWidget(categoryID);
				} else {
					showError();
				}
			})
			.fail(function(){
				location.href = location.href;
			});
	}
	function updateWidget(categoryID){
		var data = {category : categoryID};
		$.get(app.urls.compareWidget, data)
		.done(function(response){
			$($cache.widgetSel).replaceWith(response);
			bindWidget();
		})
		.fail(function(){
			location.href = location.href;
		});
	}
	function updatePopup(categoryID){
		var data = {
				category : categoryID,
				format : 'ajax'
		};
		$.get(app.urls.compareShow, data)
		.done(function(response){
			$($cache.compareTableSel).replaceWith(response);
		})
		.fail(function(){
			location.href = location.href;
		});
	}
	function bindWidget (padding){
		if (!app.isMobileView()){
			var padding = padding || $($cache.headerSel).outerHeight() + $($cache.breadcrumbsSel).outerHeight(),
				widget = $($cache.widgetSel);
			widget.css({"padding-top" : padding +"px"});
			if (app.components.search && app.components.search.custom && app.components.search.custom.afterWidgetUpdate) {
				app.components.search.custom.afterWidgetUpdate(widget.height());
			}
		}
	}
	function showError (){
		$($cache.errorCompareSel).removeClass($cache.hiddenClass);
	}
	/**
	* namespace app.components.search.compare public object
	**/
	app.components = app.components || {};
	app.components.search = app.components.search || {};
	app.components.search.compare = {
		// initializations
		init : function (params) {
			initializeCache(params);
			initializeDOM();
			initializeEvents();
		},
		initNotifyMeFormEvents: initNotifyMeFormEvents
	};
})(window.app = window.app || {}, jQuery);