/**
 *   
 */
(function (app, $) {
	var $cache;

	function initializeCache() {  
		$cache = {
			refinementListWrapperSel: '.js-refinement-list-wrapper',
			hiddenCls: 'h-hidden',
			invisibleCls: 'h-invisible' 
		};
	}
	
	function initializeDOM(){
		hideWrapper($($cache.refinementListWrapperSel));
	}
	
	function hideWrapper(refinementWrapper){
			if(refinementWrapper.length){
				refinementWrapper.addClass($cache.hiddenCls);
			}
	}

	function initializeEvents() {
		$(document).on('click', '.js-refinement-label', function(){
			var $this = $(this);
			
			var id = $this.attr('data-refndef');
			$('.js-refinement-list[data-refndef = '+id+']').closest($cache.refinementListWrapperSel).toggleClass($cache.hiddenCls);
			$this.toggleClass('b-refpanel-opened');
		});
		
		$(document).on('mouseleave', '.js-refinement', function(){
			var $this = $(this);
			
			$this.find($cache.refinementListWrapperSel).addClass($cache.hiddenCls);
			$this.find('.js-refinement-label').removeClass('b-refpanel-opened');
		});
	
		$(document).on("click", ".js-refinebar_reset", function(event){
			var $this = $(this);
			
			var resetURL = $this.closest('form').attr("action");
			if (resetURL) {
				event.preventDefault();
				event.stopPropagation();
				window.location = resetURL;
			}
		});
		
		$(document).on('track-updated-refinement', function(evt, pageX, pageY, refinementWrapper){
			var isInsideTheElement = !!refinementWrapper.filter(function(){
				var wrapper = $(this);
				return pageX >= wrapper.offset().left && pageX <= wrapper.offset().left + wrapper.outerWidth()
							&& pageY >= wrapper.offset().top && pageY <= wrapper.offset().top + wrapper.outerHeight();
			}).length;
			if(!isInsideTheElement){
				hideWrapper(refinementWrapper);
			}
			refinementWrapper.removeClass($cache.invisibleCls);
		});
		
		
		$(document).on('hide-ajax-refinement-delay', function(evt, refinementWrapper){
			refinementWrapper.addClass($cache.invisibleCls);
		});
	}

	/**
	 * @namespace app.components.sw.searchrefinements public object
	 **/
  
  app.components = app.components || {};
  app.components.search = app.components.search || {};
  app.components.search.refinementsdropdown = {
      // initializations
      init : function (params) {
        initializeCache();
        initializeDOM();
        initializeEvents();
      }
  };
  
})(window.app = window.app || {}, jQuery);

