/**
 * The component shows correspond banner for each 2-nd level category. 
 * If category does not have a banner then top category banner showed. 
 * 
 * Menu structure
 * 
 * - TopCategory1       (banner1default)
 *   - Level2category11 (banner11)
 *   - Level2category12 (banner12)
 * - TopCategory2       (banner2default)
 *   - Level2category21 (banner21)
 *   - Level2category22 (banner22)
 *   
 */
(function (app, $) {

	function initializeCache() {  
		$cache ={
			navigationWrap: $('.js-menu_subcategory_wrapper'),
		}
	}

	function initializeEvents() {
		// top level on-mouseenter
		$('.js-menu_category-item').on('mouseenter', function(){
			var $thisLevel = $(this);
			var $defaultBanner = $thisLevel.find('.js-menu_category-level_2-banner-default');
			var $allBanners    = $thisLevel.find('.js-menu_category-level_2-banner');
			var $wrapper = $thisLevel.find('.js-menu_subcategory_wrapper');
			$wrapper.show();
			
			$cache.navigationWrap.removeClass('m-without_banner');
			$allBanners.hide();
			$defaultBanner.show();

			//if there is no default banner for category
			if(!$defaultBanner.html()){
				$cache.navigationWrap.addClass('m-without_banner');
			}
			
			// bind mouseenter event handler for 2nd level onetime only
			if (!$thisLevel.data('isInitedlevel2')) {
				$thisLevel.data('isInitedlevel2', true);
				
				$thisLevel.find('.js-menu_category-level_2-link').on('mouseenter', function(){
					var $this = $(this);
					
					var $banner = $defaultBanner;
					var index = $this.attr('data-slot');
					if (index) {
						var $curBanner = $thisLevel.find('.js-menu_category-level_2-banner-' + index);
						if ($curBanner.html()) {
							$banner = $curBanner;
							$cache.navigationWrap.removeClass('m-without_banner');
						} else{
							$cache.navigationWrap.addClass('m-without_banner');
						}
					}					
					
					$allBanners.hide();
					$banner.show().addClass('m-active').siblings('div').removeClass('m-active');
				});
			};
			
		});

		$('.js-menu_category-item').on('mouseleave', function(){
			var $thisLevel = $(this);
			var $wrapper = $thisLevel.find('.js-menu_subcategory_wrapper');
			$wrapper.hide();
		});
	}

	/**
	 * @namespace app.components.global.categoryflyout public object
	 **/
  
  app.components = app.components || {};
  app.components.global = app.components.global || {};
  app.components.global.categoryflyout = {
      // initializations
      init : function (params) {
        initializeCache();
        initializeEvents();
      }
  };
  
})(window.app = window.app || {}, jQuery);

