(function (app, $) {
	var $cache	= {};
	var $config	= {};
	var ZIP_MIN_LENGTH = 4;
	
	function initializeConfig() {
		$config = {
			enableZipResolver 			: app.preferences.enableZipResolver || false,
			zipResolverConfiguration	: {},
			googleAPIUrl				: 'https://maps.googleapis.com/maps/api/geocode/json',
			googleAPIKey				: app.preferences.googleAPIKey,
			locale						: app.preferences.currentLocale,
			country						: app.user.country.value ? app.user.country.value.toLowerCase() : ''
		};
		
		if(app.preferences.zipResolverConfiguration && $config.googleAPIKey) {
			try {
				$config.zipResolverConfiguration = JSON.parse(app.preferences.zipResolverConfiguration);
				if(!$config.zipResolverConfiguration['city'] || typeof $config.zipResolverConfiguration['city'] !== "object") {
					throw "ZipResolver. JSON is invalid. City not specified or it is not an object";
				}
				if(!$config.zipResolverConfiguration['state'] || typeof $config.zipResolverConfiguration['state'] !== "object") {
					throw "ZipResolver. JSON is invalid. State not specified or it is not an object";
				}
				$config.cityType 	= $config.zipResolverConfiguration['city'][$config.country] || '';
				$config.stateType 	= $config.zipResolverConfiguration['state'][$config.country] || '';
			} catch(err) {
				$config.enableZipResolver = false;
			}
		} else {
			$config.enableZipResolver = false;
		}
	}
	
	function initializeCache() {
		$cache = {
			checkoutForm 			: $(".js-checkout_form"),
			fieldSelector			: '.f-field',
			fieldErrorClass			: 'f-state-error',
			fieldValidClass			: 'f-state-valid',
			changeFields			: $([]),
			postalCodes				: $([])
		};
		if($cache.checkoutForm.length) {
			$cache.shippingAddress = {
				state: $cache.checkoutForm.find("[name$='_shippingAddress_addressFields_states_state']"),
				city : $cache.checkoutForm.find("input[name$='_shippingAddress_addressFields_city']"),
				postalCode : $cache.checkoutForm.find("input[name$='_shippingAddress_addressFields_zip']")
			};
			$cache.billingAddress = {
				state: $cache.checkoutForm.find("[name$='_billing_billingAddress_addressFields_states_state']"),
				city : $cache.checkoutForm.find("input[name$='_billing_billingAddress_addressFields_city']"),
				postalCode : $cache.checkoutForm.find("input[name$='_billing_billingAddress_addressFields_zip']")
			};
			if(!$cache.shippingAddress.city || !$cache.billingAddress.city) {
				$config.enableZipResolver = false;
				return;
			}
			if($cache.shippingAddress.city.val() && $cache.shippingAddress.city.val().trim().length != 0) {
				$cache.shippingAddress.city.data('modified', true);
			}
			if($cache.billingAddress.city.val() && $cache.billingAddress.city.val().trim().length != 0) {
				$cache.billingAddress.city.data('modified', true);
			}
			if($cache.shippingAddress.state && $cache.shippingAddress.state.val() && $cache.shippingAddress.state.val().trim().length == 0) {
				$cache.shippingAddress.state.data('modified', true);
			}
			if($cache.billingAddress.state && $cache.billingAddress.state.val() && $cache.billingAddress.state.val().trim().length == 0) {
				$cache.billingAddress.state.data('modified', true);
			}
			
			$cache.shippingAddress.postalCode.data('changeFields', [$cache.shippingAddress.city, $cache.shippingAddress.state]);
			$cache.billingAddress.postalCode.data('changeFields', [$cache.billingAddress.city, $cache.billingAddress.state]);
			
			$cache.changeFields = $cache.changeFields.add($cache.billingAddress.city)
									.add($cache.billingAddress.state)
									.add($cache.shippingAddress.city)
									.add($cache.shippingAddress.state);
			$cache.postalCodes = $cache.postalCodes.add($cache.shippingAddress.postalCode)
									.add($cache.billingAddress.postalCode);
		} else {
			$config.enableZipResolver = false;
		}
	}

	function initializeEvents() {
		if ($config.enableZipResolver) {
			$cache.changeFields.on('change', function() {
				$(this).data('modified', true);
			});
			
			$cache.postalCodes.on('focus', function() {
				$(this).data('oldValue', $(this).val());
			});
			
			$cache.postalCodes.on('blur', function() {
				var changed = false;
				var that = $(this);
				var zipValue = that.val().trim();
				if(zipValue.length < ZIP_MIN_LENGTH || zipValue == that.data('oldValue')) {
					return;
				}
				
				$.each(that.data('changeFields'), function() {
					changed = changed || $(this).data('modified'); 
				});
				if(!changed) {
					retrieveComponentsFromZip(that.data('changeFields')[0], that.data('changeFields')[1], that);
				}
			});
		}
	}
	
	function retrieveComponentsFromZip(cityField, stateField, zipField) {
		var foundCity = false;
		var foundState = false;
		var zipCode = zipField.val().trim();
		app.ajax.getJson({
			type : "GET",
			url : $config.googleAPIUrl,
			data : { key: $config.googleAPIKey, address: zipCode, language: $config.locale, components: 'country:' + $config.country},
			callback : function(resp) {
				if(!resp.status || resp.status.toLowerCase() != 'ok' || resp.results.length == 0) {
					zipField.closest($cache.fieldSelector).hasClass($cache.fieldErrorClass) && cityField.val('') && stateField.val('');
					return;
				}
				
				var i, j,
					foundCity = false,
					foundState = false,
					address_components = resp.results[0].address_components,
					length = address_components.length,
					isZipValid = zipField.closest($cache.fieldSelector).hasClass($cache.fieldErrorClass) ? false : true;
				
				for(i = 0; isZipValid && i < length; i++) {
					var types = address_components[i].types;
					
					var tLength = types.length;
					for(j = 0; j < tLength; j++) {
						if(types[j] === $config.cityType) {
							cityField.val(address_components[i].short_name);
							var container = cityField.closest($cache.fieldSelector);
							container.removeClass($cache.fieldErrorClass).addClass($cache.fieldValidClass);
							foundCity = true;
						}
						if(types[j] === $config.stateType) {
							stateField.val(address_components[i].short_name);
							var container = stateField.closest($cache.fieldSelector);
							container.removeClass($cache.fieldErrorClass).addClass($cache.fieldValidClass);
							foundState = true;
						}
					}
				}
				!foundCity 	&& cityField.val('');
				!foundState && stateField.val('');
				return;
			}
		});
	}

	/*************** app.components.global.zipresolver public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.zipresolver = {
		init : function () {
			initializeConfig();
			initializeCache();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);