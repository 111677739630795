(function (app, $) {
	var $cache = {}
	function initializeCache() {
		$cache ={
			images : $('.js-lazy-retina'),
			initClass : "js-lazy-retina",
			maxDisDimention: 2000
		}
	};	
	function initializeDOM(params){
		var srcAttr = "";
		var originalUrlAttr = "";
		var initArea = $cache.images;
		var pixelRate = app.device.getPixelRate();
		if(!jQuery.isEmptyObject(params) && params.selector){
			initArea = $(params.selector).find($cache.images);
		}
		initArea.each(function(){
			var $this = $(this);
			if(!$this.is("img") && $this.data("altimage")){// case for alt image
				srcAttr = "data-altimage";
				originalUrlAttr = "altimage";
			}
			else{
				srcAttr = "src";
				originalUrlAttr = "originalurl";
			}
			var originalUrl = $this.data(originalUrlAttr);
			var dimentions = $this.data('dimentions');
			$this.attr(srcAttr, generateUrl(originalUrl, dimentions, pixelRate));
			$this.removeClass($cache.initClass);
		})
	};
	
	function generateUrl(originalUrl, dimentions, pixelRate){
		var dimentions = getJsonForCurrentDevice(dimentions);
		var generateUrl = originalUrl;
		if(!dimentions){
			return generateUrl;
		}
		for (key in dimentions) {
			var value = dimentions[key];
			if( key == "sw" || key == "sh"){
				value = dimentions[key] * pixelRate;// case for retina devices
				value = value >= $cache.maxDisDimention ? $cache.maxDisDimention-1 : value;
			}
			generateUrl = app.util.appendParamToURL(generateUrl, key, value);
		};
		return generateUrl;
	}
	
	function checkImageWidth(image, width, PIXEL_RATIO){
		var imageWidth = width ? Math.round(width * PIXEL_RATIO) : 0;
			var tagWidth = image.width() ? image.width() : 0,				
				widthRatio = 1;		
			if (!isNaN(tagWidth) && !isNaN(imageWidth) && imageWidth !=0){
				widthRatio = Math.abs((imageWidth-tagWidth)/imageWidth);
				if (widthRatio > 0.2 && widthRatio != 1){
					imageWidth = tagWidth;
				} 
			}
		return imageWidth;
	}
	
	/*
	 * input data example {"mobile":{"sw":"200","sh":"200"},"desktop":{"sw":"200","sh":"200"}}
	 */
	function getJsonForCurrentDevice(dimentions){
		var isMobile = app.device.isMobileView();
		if(dimentions.mobile && isMobile){
			return dimentions.mobile;
		}
		else if(dimentions.desktop && !isMobile){
			return dimentions.desktop;
		}
		else{
			return false;
		}
	}
	
	function imageLoaded($image) {
		var deferred = $.Deferred();
		
		if ($image.get(0).complete) {
			deferred.resolve();
		}
		else {
			$image.on('load', function() {
				deferred.resolve();
			});
			$image.on('error', function() {
				deferred.reject();
			});
		}
		
		return deferred.promise();
	}
	
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.images = {
		init : function (params) {
			initializeCache();
			initializeDOM(params);
		},
		imageLoaded : imageLoaded
	};

}(window.app = window.app || {}, jQuery));