/**
 * @class app.search
 */
(function(app, $) {
	var $cache = {};
	var popped = 'state' in window.history && window.history.state !== null;

	var storage = {
		getUserCountryCode: function() {
			return app.user.country.value;
		},
		set: function(urlKey, data) {
			try {
				sessionStorage['scroll-cache_' + this.getUserCountryCode() + '_' + urlKey] = data;
			} catch (e) {
				// nothing to catch in case of out of memory of session storage
				// it will fall back to load via ajax
			}
		},
		get: function(urlKey) {
			return sessionStorage['scroll-cache_' + this.getUserCountryCode() + '_' + urlKey];
		},
	};

	/**
	 * @private
	 * @function
	 * @description Fix for ie8 Infinite Scroll Bar issue and QuickView Fix
	 *				(along with CSS changes)
	 */

	function initializeCache() {
		$cache = {
			document: $(document),
			window: $(window),
			main: $('main'),
			items: $('#search-result-items'),
			feedGreedIcon: $('.js-feed_button, .js-grid_button'),
			subViewConteinerSel: '.js-sub-view-conteiner',
			footer: $('footer'),
			tileSel: '.js-product_tile',
			tileContainerSel: '.js-product_tile_container',
			loadrClassKey: 'searchLoaderClass',
			hHidden: 'h-hidden',
			pageContainer: '.js-list_item_page',
			seoLastPage: '.js-last_page',
			searchResultContainer: '.js-search_result-content',
			infiniteScrollLoadingCircle: 'js-infinite_scroll-loading b-infinite_scroll_icon',
			viewUnloadFirstSel: '.js-sub-view-conteiner.unloaded:first',
			viewFirstLoadedSel: '.js-sub-view-conteiner.firstLoaded:first',
			refinementSel: '.js-refinement',
			stickyRefinementsClass: '.js-refinements-sticky',
			refinementListWrapper: '.js-refinement-list-wrapper',
			relaxRefineLinkClass: 'js-breadcrumb_refinement-link',
			refinementSectionSelector: app.device.isMobileUserAgent() ? '.js-search_actions_top_bar'
				: '.js-search_result-options',
			noHitsPlaceholderSelector: '.js-nohits-refinements-placeholder',
			lastProductClass: 'b-product_tile-last',
			last_scroll: 0,
			scrollUp: 0,
			scrollDown: 0,
			productWasClicked: false,
			lastScrollTime: 0,
			minScrollTime: 150, //ms
			filterParamURL: 'prefn1',
			lazyloadxtSizerSel: 'img.js-lazyloadxt-sizer',
			lastHistoryHref: null,
			ajaxUpdatingPLP: {},
			subviewInfiniteLoadsSel: '.js-subview-infinite-loads',
			loadNextCtrlSel: '.js-load-next-control',
			loadNextPageSel: '.js-load_next_page',
			loadedHitsTextSel: '.js-paging-controls-loaded',
			filtersBlockSel: '.js-min_refinement_selector',
			showLoadBtnCls: 'show-load-btn',
			pageParameterPattern: '[?&]page=([^&#]*)',
			baseUrl: window.location.href,
			header: $('.js-header_main'),
			scrollTimeout: false,
			listItemPage: $('.js-list_item_page'),
			buttonWithLoadingClass: app.util.getConfig('plp.loadMoreBtnCls'),
			progressBarSel: '.js-progress_bar',
			loadingIconSel: '.js-loading_icon',
			plpMobilePreload: app.configs.plpPreload && app.device.isMobileView(),
			languageLinkSel: '.js-language_selector_link',
			browser: app.device.currentBrowser(),
			isCached: false,
			refinementContainer: '.js-refinement_container',
			refinementLink: '.js-refinements a',
			refinementButton: '.js-refinements button',
			paginationLink: '.js-pagination-link a',
			refinementWrapper: $('.js-refinements-container'),
			refinementFilterLink: '.js-refinement-link'
		};
		$cache.headerHeight = $cache.header.css('position') === 'fixed' ? $cache.header.height() : 0;
		$cache.content = $cache.main.find($cache.searchResultContainer);
		$cache.infinityScrollShiftTop = $cache.plpMobilePreload ? $cache.window.height() * 10 : app.preferences.infinityScrollShiftTop;
		$cache.isViewportExpanded = $cache.plpMobilePreload && app.configs.enableLoadMorePlpMobile;
		initTextCache();
	}

	function initGrid() {
		app.wishlist.updateWishlistButtons($cache.listItemPage);
	}

	function initTextCache() {
		$cache.loadedHitsText = $($cache.subviewInfiniteLoadsSel).data('loaded-msg');
		$cache.loadedHitsCount = parseInt(
			$($cache.viewUnloadFirstSel)
				.find($cache.loadedHitsTextSel)
				.data('curpagesize')
		);
	}

	function initInfiniteScroll_ie8() {
		$(window).scroll(function() {
			// getting the hidden div, which is the placeholder
			// for the next page
			var loadingPlaceHolder = $('.js-infinite_scroll-placeholder[data-loading-state="unloaded"]');

			if (
				loadingPlaceHolder.length == 1 &&
				app.util.elementInViewport(loadingPlaceHolder.get(0), $cache.infinityScrollShiftTop)
			) {
				app.search.init();
				// switch state to 'loading'
				// - switches state, so the above selector is
				// only matching once
				// - shows loading indicator
				loadingPlaceHolder.attr('data-loading-state', 'loading');
				loadingPlaceHolder.addClass('js-infinite_scroll-loading b-infinite_scroll_icon');

				// get url hidden in DOM
				var gridUrl = loadingPlaceHolder.attr('data-grid-url');

				/**
				 * named wrapper function, which can either be
				 * called, if cache is hit, or ajax repsonse is
				 * received
				 */
				var fillEndlessScrollChunk = function(content) {
					var $content = $(content);
					app.wishlist.updateWishlistButtons($content);

					loadingPlaceHolder.removeClass('js-infinite_scroll-loading b-infinite_scroll_icon');
					loadingPlaceHolder.attr('data-loading-state', 'loaded');
					$cache.content.append($content);
					$cache.document.trigger('grid-update');
				};

				var storedGrid = storage.get(gridUrl);

				if (storedGrid && !app.preferences.enableServiceWorker) {
					// if we hit the cache
					fillEndlessScrollChunk(storedGrid);
				} else {
					// else do query via ajax
					jQuery.ajax({
						type: 'GET',
						dataType: 'html',
						url: gridUrl,
						success: function(response) {
							if (!app.preferences.enableServiceWorker) {
								// put response into cache
								storage.set(gridUrl, response);
							}
							// update UI
							fillEndlessScrollChunk(response);
							$cache.document.trigger('nextpage-loaded');
						},
					});
				}
			}
		});
	}

	/**
	 * @private
	 * @function
	 * @description replaces breadcrumbs, lefthand nav and product listing with
	 *			  ajax and puts a loading indicator over the product listing
	 */
	function updateProductListing(url, inHistory, extParams, loaderClassKey, showLoader) {
		var hash = encodeURI(decodeURI(window.location.hash)),
			showLoader = typeof showLoader === 'undefined' ? true : showLoader,
			currentNoHitsPlaceholder = $cache.main.find($cache.noHitsPlaceholderSelector);
		if (hash === '#results-content' || hash === '#results-products') {
			return;
		}

		if (!inHistory) {
			app.search.updateUrl(url);
			if (!app.search.pushAvailable) {
				return;
			}
		}

		var refineUrl = url;

		if (!refineUrl) {
			return;
		}

		//could not be cached because content is replaced by AJAX
		//@TODO: refactor PLP AJAX update part
		showLoader && app.progress.show($('main').find($cache.searchResultContainer), loaderClassKey);
		$.get(app.util.appendParamToURL(refineUrl, 'format', 'ajax'), function(response) {
			var responseObj = $(response),
				responseRefinementPlaceholder = responseObj.find($cache.noHitsPlaceholderSelector),
				refinementListWrapper;

			var urlObj = new URL(app.util.getAbsoluteUrl(url));
			var urlObjSrule = urlObj.searchParams.get('srule');
			var langUrlObj;
			$($cache.languageLinkSel).each(function() {
				langUrlObj = new URL($(this).attr('href'));
				langUrlObj.searchParams.set('srule', urlObjSrule);
				$(this).attr('href', langUrlObj.href);
			});

			if (responseRefinementPlaceholder.length) {
				var refinementsToTake = currentNoHitsPlaceholder.length ? currentNoHitsPlaceholder.children() : $($cache.refinementSectionSelector);
				refinementsToTake.appendTo(responseRefinementPlaceholder);
				refinementListWrapper = $cache.main.find($cache.refinementListWrapper);
				extParams = $.extend({}, extParams, {noHitsPage: true});
			}
			else{
				refinementListWrapper = responseObj.find($cache.refinementListWrapper);
			}

			$cache.document.trigger('hide-ajax-refinement-delay', [refinementListWrapper]);
			$cache.main.html(responseObj);
			$($cache.searchResultContainer).addClass($cache.hHidden);
			app.componentsMgr.loadComponent('search.priceslider');
			showLoader && app.progress.hide();
			if (app.clientcache.LISTING_INFINITE_SCROLL) {
				$cache.document.trigger('grid-update');
			}
			$cache.document.trigger('refinements-update', extParams);
			$($cache.searchResultContainer).removeClass($cache.hHidden);
			if ($cache.feedGreedIcon.length) {
				var feedGrid = $($cache.feedGreedIcon);
				for (var i = 0; i < feedGrid.length; i++) {
					if ($(feedGrid[i]).hasClass('m-active_header_icon')) {
						$(feedGrid[i]).trigger('click');
						break;
					}
				}
			}
			$cache.document.one('mousemove', function(evt) {
				$cache.document.trigger('track-updated-refinement', [evt.pageX, evt.pageY, refinementListWrapper]);
			});
			initTextCache();
			$cache.document.trigger('refinements-loaded', extParams);
			document.dispatchEvent(new CustomEvent('lazyload-reinit'));
			document.dispatchEvent(new CustomEvent('price-reinit'));
		});
	}
	/**
	 * @private
	 * @function
	 * @description
	 */

	function abortAjaxUpdatingPLP() {
		for (var key in $cache.ajaxUpdatingPLP) {
			$cache.ajaxUpdatingPLP[key].abort();
		}
	}

	/**
	 * Returns loadMoreData that depends on data attributes
	 * of given HTMLElement (first param)
	 * @param {el} HTMLElement
	 * @returns {Object} data
	 */
	const getLoadMoreData = (el) => {
		const data = {
			format: 'page-element'
		};

		if (!el) {
			return data;
		}

		const dataAttributes = {
			position: el.getAttribute('data-position'),
			loaderbar: el.getAttribute('data-loaderbar'),
			subview: el.getAttribute('data-subview'),
			comparecgid: el.getAttribute('data-comparecgid'),
			subcategory: el.getAttribute('data-issubcategory'),
			viewtype: el.getAttribute('data-viewtype'),
			sz: el.getAttribute('data-sz'),
			start: el.getAttribute('data-start'),
			direction: el.getAttribute('data-direction'),
			loadall: el.getAttribute('data-loadall')
		};

		for (const key in dataAttributes) {
			if (dataAttributes[key]) {
				data[key] = dataAttributes[key];
			}
		}

		return data;
	};

	function initInfiniteScroll() {
		$cache.document.on('scroll grid-update scrolldown.finished grid-preload-update grid-update-afterLoad', function(e) {
			// getting the hidden div, which is the placeholder
			// for the next page
			var eventType = e.type,
				subviewLoadsElem = $($cache.subviewInfiniteLoadsSel),
				loadingPlaceHolder = null,
				subCategoryContainer = null,
				subCategoryID = '';
			if (
				$($cache.subViewConteinerSel).length &&
				(!app.preferences.subViewFilterDisable || location.search.indexOf($cache.filterParamURL) === -1)
			) {
				if (app.search.lockLoading) {
					return false;
				}
				firstLoadedSubCategoryContainer = $($cache.viewFirstLoadedSel);
				subCategoryContainer = firstLoadedSubCategoryContainer.prev($cache.viewUnloadFirstSel);
				if (!subCategoryContainer.length) {
					subCategoryContainer = $($cache.viewUnloadFirstSel);
				}
				if (subCategoryContainer.find('.js-infinite_scroll-placeholder[data-loading-state="unloaded"]').length === 0) {
					subCategoryContainer.removeClass('unloaded').addClass('loaded');
					subCategoryContainer.find($cache.tileSel + ':last').addClass($cache.lastProductClass);
					subCategoryContainer = $($cache.viewUnloadFirstSel);
				}
				loadingPlaceHolder = subCategoryContainer
					.find('.js-infinite_scroll-placeholder[data-loading-state="unloaded"]')
					.first();
				subCategoryID = loadingPlaceHolder.data('subcategory');
			} else {
				loadingPlaceHolder = $('.js-infinite_scroll-placeholder[data-loading-state="unloaded"]');
			}

			if (
				app.preferences.enableInfiniteScrollForSEO &&
				eventType === 'scroll' &&
				(!subviewLoadsElem.length || app.util.getConfig('seo.paginationOnUrl'))
			) {
				var scroll_pos = getWindowScrollTop();

				//Disable anchor back functional if user manual scroll page
				$cache.document.off('grid-preload-updated');

				// Adjust the URL based on the top item shown
				// for reasonable amounts of items
				if (Math.abs(scroll_pos - $cache.last_scroll) > 0 && !$cache.productWasClicked) {
					$cache.last_scroll = scroll_pos;
					var currentUrlParameters = new RegExp($cache.pageParameterPattern).exec(window.location.href),
						currentPage = currentUrlParameters ? currentUrlParameters[1] : 0,
						scrollBottom = scroll_pos + getWindowInnerHeight(),
						dataGridUrl,
						dataPageNumber;

					if (
						!loadingPlaceHolder.length &&
						$($cache.seoLastPage).length &&
						currentPage !== $($cache.seoLastPage).data('page') &&
						getWindowScrollTop() + getWindowInnerHeight() >= $cache.document.height()
					) {
						dataGridUrl = $($cache.seoLastPage).data('grid-url');
						if ($cache.lastHistoryHref !== dataGridUrl) {
							window.history.replaceState({}, '', dataGridUrl);
							$cache.lastHistoryHref = dataGridUrl;
						}
						return false;
					} else {
						clearTimeout($cache.scrollTimeout);
						$cache.scrollTimeout = setTimeout(function() {
							var elementsCount = $($cache.pageContainer).length;
							$($cache.pageContainer).each(function(key) {
								if (mostlyVisible(this, key + 1, elementsCount)) {
									var stateObject = {};
									stateObject.distance = getWindowScrollTop() + $cache.headerHeight * 2 - $(this).offset().top;

									if (!subviewLoadsElem.length) {
										dataGridUrl = $(this).data('grid-url');
									} else {
										dataGridUrl = app.util.appendParamToURL(
											app.util.removeParamFromURL($cache.baseUrl, 'subcategoryID'),
											'subcategoryID',
											$(this).data('subcategory-id')
										);
									}
									dataPageNumber = $(this).data('page');
									if (dataPageNumber > 1) {
										history.replaceState(stateObject, '', dataGridUrl);
									} else {
										history.replaceState(
											stateObject,
											'',
											dataGridUrl.replace(new RegExp($cache.pageParameterPattern), '')
										);
									}
									$cache.lastHistoryHref = dataGridUrl;
									return false;
								}
							});
						}, 100);
					}
				}
			}

			loadingPlaceHolder.each(function(index) {
				var $that = $(this);
				var previousListItem = $that.prevAll($cache.pageContainer + ':first');

				if ($that.data('direction') == 'prev' && previousListItem.data('gridUrl')) {
					var pageNumberInPlaceholder = app.util.getQueryStringParams($that.data('gridUrl')).page;
					var pageNumberInClosestPreviousListItem = app.util.getQueryStringParams(previousListItem.data('gridUrl'))
						.page;

					if (pageNumberInPlaceholder < pageNumberInClosestPreviousListItem) {
						$that.attr('data-loading-state', 'loaded');
						return true;
					}
				}
				if (
					app.util.elementInViewport($that.get(0), $cache.infinityScrollShiftTop)
					|| eventType === 'grid-preload-update' || $cache.isViewportExpanded
				) {
					// switch state to 'loading'
					// - switches state, so the above selector is
					// only matching once
					// - shows loading indicator
					$this = $(this);
					if ($($cache.subViewConteinerSel).length) {
						app.search.lockLoading = true;
					}
					$this.add(subviewLoadsElem).attr('data-loading-state', 'loading');
					$this.addClass($cache.infiniteScrollLoadingCircle);
					$cache.document.trigger('search.gridupdate.start');

					// get url hidden in DOM
					var gridUrl = $this.data('gridUrl');

					//can be used custom tiles container
					var customContainer =
						$($cache.subViewConteinerSel).length &&
						(!app.preferences.subViewFilterDisable || location.search.indexOf($cache.filterParamURL) === -1)
							? subCategoryContainer
							: $($this.data('gridContainer'));
					var gridContainer = customContainer.length ? customContainer : $cache.main.find($cache.searchResultContainer);

					/**
					 * named wrapper function, which can either be
					 * called, if cache is hit, or ajax repsonse is
					 * received
					 */
					var fillEndlessScrollChunk = function(content) {
						var $content = $(content);
						app.wishlist.updateWishlistButtons($content);

						$('.js-subcategory-' + subCategoryID).removeClass($cache.hHidden);
						$that.removeClass($cache.infiniteScrollLoadingCircle);
						$that.add(subviewLoadsElem).attr('data-loading-state', 'loaded');
						if ($that.hasClass('js-next')) {
							gridContainer.append($content);
							$cache.document.trigger('infiniteScroll.content.appended', {
								container: gridContainer,
								content: content
							});
							contentPostLoad();
							var contentImages = [];

							$content.find('img').each(function(key) {
								contentImages[key] = key;

								$(this).load(function() {
									contentImages.splice(contentImages.indexOf(key), 1);

									if (contentImages.length === 0) {
										contentScrollDown();
									}
								});
							});
						} else {
							var currentScrollTop = getWindowScrollTop();

							gridContainer.prepend($content);

							$cache.document.trigger('infiniteScroll.content.prepended', {
								container: gridContainer,
								content: content
							});

							if ($cache.scrollUp < 0) {
								window.scrollTo(0, 0);
							}
							if (currentScrollTop === getWindowScrollTop()) {
								var positionFirst = $($cache.pageContainer).first().offset();
								var positionSecond = $($cache.pageContainer).eq(1).length ? $($cache.pageContainer).eq(1).offset() : { top: 0 };

								window.scrollBy(0, (positionSecond.top !== 0 ? (positionSecond.top - positionFirst.top) : 0) + $cache.scrollUp);
							}

							$cache.scrollUp = 0;
							contentPostLoad();
						}

						function contentScrollDown() {
							if ($cache.scrollDown) {
								if (typeof $cache.scrollDown === 'function') {
									$cache.scrollDown = $cache.scrollDown();
								}
								window.scrollBy(0, $cache.scrollDown);
								$cache.scrollDown = 0;
							}
						}

						function contentPostLoad() {
							$that.remove();
							if (subviewLoadsElem.length) {
								subviewLoadsElem.data('infinite-loads', new Number(subviewLoadsElem.data('infinite-loads')) + 1);
							}
							$cache.document.trigger('grid-update', {
								content: content,
								container: $content
							});
							app.search.lockLoading = false;
							//Next event generate a special for anchor Back function.
							$cache.document.trigger('grid-preload-updated');
							document.dispatchEvent(new CustomEvent('lazyload-reinit'));
						}
					};

					var storedGrid = storage.get(gridUrl);

					if (storedGrid && !app.preferences.enableServiceWorker) {
						fillEndlessScrollChunk(storedGrid);
						app.progress.hide();
					} else {
						$cache.ajaxUpdatingPLP[$(this).data('direction')] = $.ajax({
							type: 'GET',
							url: gridUrl,
							data: getLoadMoreData(this)
						})
							.done(function(response) {
								if (!app.preferences.enableServiceWorker) {
									// put response into cache
									storage.set(gridUrl, response);
								}
								// update UI
								fillEndlessScrollChunk(response);
								$cache.document.trigger('nextpage-loaded');
							})
							.fail(function(xhr, textStatus) {
								// failed
								if (textStatus === 'parsererror') {
									window.alert(resources.BAD_RESPONSE);
								}
							})
							.always(function() {
								app.progress.hide();
							});
					}
				}
			});
		});

		// fix behavior for SEO pagination if AnchorBack enabled this style attr was added in appresources.isml
		// TODO: Refactoring: remove add style attr in appresources.isml
		$cache.content.removeAttr('style');
		//SEO initInfiniteScroll ancor Back functionality
		if (app.preferences.enableInfiniteScrollForSEO) {
			var subCatId = app.util.getParamFromUrl(window.location.href, 'subcategoryID');

			var historyState = (window.history && window.history.state) || {};
			var updateAfterLoad = true;
			if (subCatId !== 0) {
				var element = $('*[data-subcategory-id="' + subCatId + '"]');

				if (element.length) {
					var distance = 0;
					var elementPosition = 0;
					if ('distance' in historyState) {
						distance = window.history.state.distance;
						elementPosition = distance;
					}
					elementPosition += element.offset().top - $cache.headerHeight;
					$cache.window.scrollTop(elementPosition);

					$cache.scrollDown = function() {
						return element.offset().top - $cache.headerHeight * 2 - getWindowScrollTop() + distance;
					};
				}
			} else if (historyState.backPosition) {
				if (historyState.backPosition.y > 0) {
					window.scrollTo(0, historyState.backPosition.y);
					$cache.scrollDown = historyState.backPosition.y - getWindowScrollTop() || 0;

					if (popped && $cache.browser === 'safari') {
						// Safari when starts from a position >0 doesn't load the images above the current position
						$cache.window.trigger('lazyloadall');
					}
				} else {
					$cache.scrollUp = historyState.backPosition.y;
				}
			} else if ('url' in historyState && historyState.url === window.location.href) {
				//AnchorBack for "Sub category view" case without #anchorBack.
				if (historyState.infiniteLoads) {
					$cache.document.on('grid-preload-updated', function() {
						if ($($cache.subviewInfiniteLoadsSel).data('infiniteLoads') < historyState.infiniteLoads) {
							anchorBackLoadNextPage();
						} else if (historyState.position) {
							window.scrollTo(0, historyState.position);
							//Disable anchor back functional after done
							$cache.document.off('grid-preload-updated');
						}
					});
					anchorBackLoadNextPage();
					updateAfterLoad = false;
				} else if (historyState.position) {
					//Scroll to element on first page if no pages was loaded
					window.scrollTo(0, historyState.position);
				}
			}
			//Update grid after document is ready
			updateAfterLoad && $cache.document.trigger('grid-update-afterLoad');
		}

		function anchorBackLoadNextPage() {
			if (app.preferences.isHitsAutoLoad || $($cache.subViewConteinerSel).length) {
				$cache.document.trigger('grid-preload-update');
			} else {
				app.search.loadNextPage($($cache.loadNextPageSel)[0] || null);
			}
		}

		function mostlyVisible(element, elementNumber, elementsCount) {
			// if ca 25% of element is visible
			var $elem = $(element),
				$next = $elem.nextAll($cache.pageContainer + ':first'),
				scroll_pos = getWindowScrollTop(),
				el_top = $elem.offset().top,
				el_height = $elem.height() || ($next.length && $next.offset().top - el_top),
				el_bottom = el_top + el_height,
				ret = el_bottom - el_height * 0.25 > scroll_pos && el_top < scroll_pos + 0.5 * getWindowInnerHeight();

			if (elementNumber === 1) {
				ret = ret || scroll_pos < el_top;
			} else if (elementNumber === elementsCount) {
				ret = ret || (scroll_pos > el_bottom && el_top <= scroll_pos);
			}

			return ret;
		}


	}
	/**
	 * @private
	 * @function
	 * @description Initializes events for the following elements:<br/>
	 *	<p>
	 *		refinement blocks
	 *	</p>
	 *	<p>
	 *		updating grid: refinements, pagination, breadcrumb
	 *	</p>
	 *	<p>
	 *		item click
	 *	</p>
	 *	<p>
	 *		sorting changes
	 *	</p>
	 */

	/*
	 * Next function return current scroll top
	 */
	function getWindowScrollTop() {
		return (
			window.scrollY ||
			window.pageYOffset ||
			document.body.scrollTop + ((document.documentElement && document.documentElement.scrollTop) || 0)
		);
	}

	/*
	 * Next function return inner window height
	 */
	function getWindowInnerHeight() {
		return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
	}

	function setPlpState($currentLink) {
		var $infiniteLoadsEl = $($cache.subviewInfiniteLoadsSel);
		var stateObject = (window.history && window.history.state) || {};

		if (app.preferences.enableInfiniteScrollForSEO) {
			if ($infiniteLoadsEl.length) {
				stateObject.infiniteLoads = $infiniteLoadsEl.data('infiniteLoads');
				stateObject.position = getWindowScrollTop();
				stateObject.url = window.location.href;

				window.history.replaceState(stateObject, '', window.location.href);
			} else {
				var $closestPageCntr = $currentLink.closest($cache.pageContainer);

				if (!$closestPageCntr.length) {
					$closestPageCntr = $currentLink.closest($cache.tileSel).prevAll($cache.pageContainer + ':first');
				}

				if (!$closestPageCntr.length) {
					var $container = $currentLink.parents($cache.tileContainerSel);
					$closestPageCntr = $container.prevAll($cache.pageContainer + ':first');

					if (!$closestPageCntr.length) {
						$closestPageCntr = $container.parent().prevAll($cache.pageContainer + ':first');
					}
				}

				var pageSEOIndex = +$closestPageCntr.data('page');

				if (pageSEOIndex) {
					var firstPos = $($cache.pageContainer + ':first').offset().top;
					var elementPos = $closestPageCntr.offset().top;


					stateObject.backPosition = {
						y: firstPos + getWindowScrollTop() - elementPos
					};
					stateObject.preventPageReload = true;

					window.history.replaceState(stateObject, '', $currentLink.closest($cache.pageContainer).data('grid-url'));
				}
			}
		}
	}

	function setSearchParamsToStorage($currentLink) {
		var plpParams = app.util.getQueryStringParams(window.location.search.substr(1));
		var $tile = $currentLink.closest($cache.tileSel);
		var index = null;

		if (app.preferences.enableInfiniteScrollForSEO) {
			index = $currentLink
				.closest($cache.searchResultContainer)
				.find($cache.tileSel)
				.index($tile);
		} else {
			index = $cache.content.find($cache.tileSel).index($tile);
		}

		plpParams.start = index > 0 ? (+index + 1) : 1;
		plpParams.page = plpParams.page || 1;

		if (!plpParams.cgid) {
			var cgid = app.page.pageData && app.page.pageData.currentCategoryID;

			if (cgid && typeof cgid !== 'object') {
				plpParams.cgid = cgid;
			}
		}

		try {
			sessionStorage.setItem('searchParams', app.util.convertMapToQueryString(plpParams));
			return true;
		} catch (e) {
			return false;
		}
	}

	function initializeEvents() {
		// compare checked
		$cache.main.on('click', "input[type='checkbox'].compare-check", function(e) {
			var cb = $(this);
			var tile = cb.closest('.js-product_tile');
			var func = this.checked ? app.product.compare.addProduct : app.product.compare.removeProduct;
			var itemImg = tile.find('div.product-image a img').first();
			func({
				itemid: tile.data('itemid'),
				uuid: tile[0].id,
				img: itemImg,
				cb: cb,
			});
		});

		// handle load next items
		$cache.main.on('click', $cache.loadNextPageSel, function() {
			app.search.loadNextPage(this);

			var button = $(this);
			if($cache.buttonWithLoadingClass && button.hasClass($cache.buttonWithLoadingClass)) {
				app.progress.show(button);
			}

			return false;
		});

		// handle toggle refinement blocks
		$cache.main.on('click', '.js-refinement_title', function(e) {
			$(this)
				.toggleClass('expanded')
				.siblings('ul')
				.toggle();
		});

		// handle events for updating grid
		$cache.main.on('click', `${$cache.refinementButton}, ${$cache.refinementLink}, ${$cache.paginationLink}`, function(e) {
			var $this = $(this);
			var catParent = $this.parents('.js-category_refinement');
			var folderParent = $this.parents('.js-folder_refinement');
			var extParams = {};

			if (!$this.hasClass($cache.relaxRefineLinkClass)) {
				extParams.refineParent = $this.closest($cache.refinementSel);
			}

			if ($this.parent().hasClass('js-unselectable')) {
				return;
			}

			// if the anchor tag is uunderneath a div with the
			// class names & , prevent the double encoding of
			// the url
			// else handle the encoding for the url
			if (catParent.length > 0 || folderParent.length > 0) {
				return true;
			} else {
				e.preventDefault();

				if (this.href) {
					updateProductListing(this.href, false, extParams);
				} else if (this.dataset.href) {
					updateProductListing(this.dataset.href, false, extParams);
				}

				return false;
			}
		});

		// handle events item click. append params.
		$cache.main.on('click', '.js-product_tile a', function() {
			var $this = $(this);
			$cache.productWasClicked = true;
			$cache.document.trigger('product.tile.click', $this);

			setPlpState($this);
			setSearchParamsToStorage($this);
		});

		if (app.preferences.anchorBackEnable && !app.preferences.enableInfiniteScroll) {
			$cache.window.load(function() {
				var historyState = (window.history && window.history.state) || {};

				if (historyState.backPosition) {
					if (historyState.backPosition.y > 0) {
						window.scrollTo(0, historyState.backPosition.y);
						$cache.scrollDown = historyState.backPosition.y - getWindowScrollTop() || 0;
					} else {
						$cache.scrollUp = historyState.backPosition.y;
					}
				}
			});
		}

		// handle clicking on search refinement filters
		$cache.refinementWrapper.on('click', $cache.refinementFilterLink, function() {
			window.location.href = $(this).data('href');
		});

		// handle sorting change
		$cache.main
			.on('change', '.sort-by select', function(e) {
				e.preventDefault();
				updateProductListing(
					$(this)
						.find('option:selected')
						.val(),
					false
				);
			})
			.on('change', '.items-per-page select', function(e) {
				var refineUrl = $(this)
					.find('option:selected')
					.val();
				if (refineUrl == 'INFINITE_SCROLL') {
					jQuery('html').addClass('infinite-scroll');
					jQuery('html').removeClass('disable-infinite-scroll');
				} else {
					jQuery('html').addClass('disable-infinite-scroll');
					jQuery('html').removeClass('infinite-scroll');
					var uri = app.util.getUri(refineUrl);
					window.location.hash = uri.query.substr(1);
				}
				return false;
			})
			.on('click', '.js-sort_select-link', function(e) {
				e.preventDefault();
				var sortRule = $(this).data('srule');
				var href = sortRule
					? app.util.appendParamToURL(window.location.href, 'srule', sortRule)
					: this.href;

				href = app.util.appendParamToURL(href, 'isSortByOpened', 'true');
				updateProductListing(href, false, { isSortByOpened: true }, $cache.loadrClassKey);
			})
			.on('click', '.js-clear_search_filters a', function(e) {
				e.preventDefault();
				var filtersBlock = $(this).closest($cache.filtersBlockSel);
				var tabCls = filtersBlock.data('tab-class');
				var tabFilters = app.components.search.filters.getTabRefinements(tabCls);
				var url = tabCls ? window.location.href : this.href;
				var params = app.util.getUri(url).queryParams;

				for (key in params) {
					if (key.indexOf('prefn') !== -1 && tabFilters.indexOf(params[key]) !== -1) {
						url = app.util.removeParamsFromURL(url, [key, 'prefv' + key.slice(-1)]);
					}
				}
				updateProductListing(url, false, { tabClass: tabCls }, $cache.loadrClassKey);
			});

		$cache.window.on('pageshow', function(event) {
			/* Safari <= 13.1 caches regardless of the cache-control header	*/
			if (event.originalEvent.persisted) {
				window.history.scrollRestoration = 'auto';
				$cache.isCached = true;
			}
		});

		$cache.window.on('popstate', function() {
			if ($cache.isCached || $cache.browser === 'safari' || window.location.href.indexOf('#') > -1) {
				return;
			}
			//Solution from http://stackoverflow.com/questions/6421769/popstate-on-pages-load-in-chrome
			var initialPop = !popped && location.href === app.search.startUrl;
			popped = true;
			if (initialPop) {
				return;
			}

			if (app.configs.filters.updateFromHistory) {
				updateProductListing(window.location.href, true);
			}
		});

		// handle hash change
		$(window).hashchange(function() {
			if (window.location.hash == '#' || (window.location.hash != '' && window.location.hash.slice(0, 2) != '#!')) {
				return;
			}

			updateProductListing(app.search.getRealUrl(window.location.href), true);
		});

		// Sticky left refinements
		if (app.preferences.stickyLeftNavigation) {
			var bottomOffset = parseInt($cache.main.css('marginBottom')) + parseInt($cache.main.css('paddingBottom'));
			bottomOffset += $cache.footer.outerHeight();
			app.util.fixElement($cache.stickyRefinementsClass);
		}
	} /** ***** app.search public object ******* */
	app.search = {
		init: function() {
			initializeCache();
			initGrid();
			window.history.scrollRestoration = 'manual';
			app.product.compare.init();

			$cache.window.load(function() {
				if (window.pageXOffset == null && app.clientcache.LISTING_INFINITE_SCROLL) {
					initInfiniteScroll_ie8();
				}
				if (window.pageXOffset != null && app.clientcache.LISTING_INFINITE_SCROLL) {
					initInfiniteScroll();
				}
			});
			initializeEvents();
			app.search.startUrl = window.location.href;
		},
		lockLoading: false,
		updateProductListing: updateProductListing,
		abortAjaxUpdatingPLP: abortAjaxUpdatingPLP,
		updateUrl: function(url) {
			if (url.indexOf('?') > -1 && url.indexOf('%') == -1) {
				url = url.replace('+', ' ');
				var _url = url.split('?');
				var _urlParams = [];
				$(String(_url[1]).split('&')).each(function() {
					var parts = this.split('=');
					if (parts.length > 0) {
						_urlParams.push(parts[0] + '=' + (parts.length == 2 ? encodeURI(parts[1]) : ''));
					}
				});
				url = _url[0];
				if (_url.length > 1 && _urlParams.length > 0) {
					url += '?' + _urlParams.join('&');
				}
			}
			if (this.pushAvailable) {
				history.pushState(null, null, url);
			} else {
				app.search.updateHash(url);
			}
		},

		pushAvailable: !!(window.history && history.pushState),

		updateHash: function(url) {
			var hostAndPath = url.split('?')[0].split('#')[0];
			var query = url.replace(hostAndPath, '');
			var pathParts = hostAndPath.split('/');

			window.location.hash = '!' + pathParts[pathParts.length - 1] + query;
		},

		getRealUrl: function(url) {
			if (url.indexOf('#!') > -1) {
				var pathParts = url.split('/');
				pathParts[pathParts.length - 1] = url.split('#!')[1];
				url = pathParts.join('/');
				return url;
			}

			return url;
		},

		loadNextPage(el) {
			if (!el) {
				return false;
			}

			const $this = $(el);

			$this.addClass('js_hide next_page_load_progress');
			$($cache.loadingIconSel).removeClass($cache.hHidden);
			$($cache.progressBarSel).addClass($cache.hHidden);

			if ($this.parents($cache.subViewConteinerSel).length) {
				app.progress.show($this.parents($cache.subViewConteinerSel));
			} else {
				app.progress.show($cache.main.find($cache.searchResultContainer));
			}

			const storedGrid = storage.get(el.href);

			if (storedGrid && !app.preferences.enableServiceWorker) {
				renderResponse(storedGrid);
			} else {
				$.ajax({ url: el.href, data: getLoadMoreData(el) })
					.done((response) => {
						if (!response) {
							return;
						}

						if (!app.preferences.enableServiceWorker) {
							// put response into cache
							storage.set(el.href, response);
						}

						// render response
						renderResponse(response);
						$cache.document.trigger('nextpage-loaded');
					})
					.fail(() => {
						location.reload();
					});
			}

			const renderResponse = (response) => {
				const $response = $(response);
				const pos = getWindowScrollTop();

				app.wishlist.updateWishlistButtons($response);

				if ($($cache.subViewConteinerSel).length) {
					const subCategoryContainer = app.preferences.isSubViewHitsAutoLoad ? $this.closest($cache.subViewConteinerSel) : $($cache.viewUnloadFirstSel);
					const subViewLoadsEl = $($cache.subviewInfiniteLoadsSel);
					const loadedHitsTextEl = subCategoryContainer.find($cache.loadedHitsTextSel);

					subCategoryContainer.append($response);
					subViewLoadsEl.data('infinite-loads', +subViewLoadsEl.data('infinite-loads') + 1);
					$('.js-subcategory-' + $this.data('subcategory')).removeClass($cache.hHidden);
					$this.closest($cache.loadNextCtrlSel).remove();
					$cache.loadedHitsCount += parseInt(loadedHitsTextEl.data('curpagesize'));

					if (subCategoryContainer.find($cache.loadNextPageSel + '[data-loading-state="unloaded"]').length === 0) {
						subCategoryContainer.removeClass('unloaded ').addClass('loaded');
						subCategoryContainer.find($cache.tileSel + ':last').addClass($cache.lastProductClass);

						const unloadedCntr = $this.closest($cache.subViewConteinerSel);

						if (unloadedCntr.length) {
							unloadedCntr.addClass($cache.showLoadBtnCls);
							subCategoryContainer.removeClass($cache.showLoadBtnCls);
						}
					}

					if (loadedHitsTextEl.length) {
						loadedHitsTextEl
							.add($($cache.viewUnloadFirstSel).find($cache.loadedHitsTextSel))
							.text($cache.loadedHitsText.replace('|loaded|', $cache.loadedHitsCount));
					}
				} else {
					$($cache.loadNextCtrlSel).replaceWith($response);
					$cache.document.trigger('infiniteScroll.content.appended', {
						container: $response
					});

					$cache.window.scrollTop(pos);
				}

				app.progress.hide();
				$cache.document.trigger('grid-preload-updated');
				$cache.document.trigger('grid-update', { container: $response });
				document.dispatchEvent(new CustomEvent('lazyload-reinit'));
			};

			return false;
		},
	};
})((window.app = window.app || {}), jQuery);
