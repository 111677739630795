import CSRF from 'oneapp/src/utils/CSRF';

(function(app, $) {
	var $cache = {};
	var delay = 500;
	var toggledClass = 'h-toggled';
	var updateAfter = true;

	function initializeCache() {
		$cache = {
			document: $(document),
			addToCart: $('.js-add_to_cart_from_wishlist'),
			wishlistFlyoutContainer: $('.js-wishlist_flyout_container'),
			wishlistQtyVal: $('.js-wishlist-quantity_value'),
			flyOutQtyContainerSel: '.js-wishlist_qty'
		};
	}

	function initializeEvents() {
		$cache.addToCart.on("click", function() {
			// add product to cart and show minicart
			var $form = $(this).closest("form");
			var url = $form.attr("action");
			var data = $form.serialize();

			app.cart.update(data, function(response) {
				var uuid = $form.find('.js-product_uuid');

				if (uuid.length > 0 && uuid.val().length > 0) {
					app.cart.refresh();
				} else {
					if (updateAfter) {
						$cache.document.trigger('wishlist.updated', 'header');
					}

					$cache.document.trigger('minicart.show', {
						html: response
					});
					$cache.document.trigger('minicart.afterload');
				}
			});
		});

		$cache.document.on('wishlist.updated', function(event, target, doNotToggleBlock, wishlistQuantity, autoUpdateQty) {
			$.ajax({
				url: app.urls.getFlyoutWishlist,
				type: 'get'
			}).done(function(data) {
				if (data) {
					$cache.wishlistFlyoutContainer.html(data);

					if (autoUpdateQty) {
						const newQtyContainer = $cache.wishlistFlyoutContainer.find($cache.flyOutQtyContainerSel);
						const newQty = newQtyContainer.length ? parseInt($cache.wishlistFlyoutContainer.find($cache.flyOutQtyContainerSel).text()) : 0;

						$cache.wishlistQtyVal.text(newQty);
					}

					app.components.global.headerwishlist.init({
						updateAfter: false
					});
					$cache.document.trigger('headerwishlist.initialized', 'header');
					$cache.document.trigger('wishlist.uploaded');

					if (target === 'wishlistblock') {
						$cache.document.trigger('wishlistblock.removed', [doNotToggleBlock, data]);
					}
					if (target === 'wishlistflyout') {
						$cache.document.trigger('wishlistblock.removed', [wishlistQuantity, doNotToggleBlock, data]);
					}
					if (target === 'header' && app.page.ns === 'wishlist') app.page.refresh();
				}
			});
		});
	}

	/*************** app.components.global.headerwishlist public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.headerwishlist = {
		// initializations
		init : function (params) {
			params = params || {};
			if ( params.hasOwnProperty('updateAfter')) {
				updateAfter = params.updateAfter;
			}
			initializeCache();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);
