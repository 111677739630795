/* global amazon */

/**
 * This crutch is added due to the fact that Amazon Pay script doesn't have any event
 * we can subscribe to, to get notification that Amazon Pay script is loaded and
 * initialized. So, we try to init button within next 5 seconds.
 */
let countOfReinitCalls = 0;

/**
 * Represents AmazonEditButton
 * http://amazonpaycheckoutintegrationguide.s3.amazonaws.com/amazon-pay-checkout/display-shipping-payment-info.html#2-enable-shipping-and-payment-updates
 * @constructor
 * @param {String} selector Amazon  edit button HTML element selector
 * @param {String} checkoutSessionId Checkout session ID
 */
class AmazonEditButton {
	constructor(selector, checkoutSessionId, changeActionType = 'changeAddress') {
		initButton(selector, checkoutSessionId, changeActionType);
	}
}

function initButton(selector, checkoutSessionId, changeActionType) {
	if (typeof amazon !== 'undefined') {
		amazon.Pay.bindChangeAction(selector, {
			amazonCheckoutSessionId: checkoutSessionId,
			changeAction: changeActionType
		});
	} else if (countOfReinitCalls < 10) {
		setTimeout(() => {
			countOfReinitCalls++;
			initButton(selector, checkoutSessionId);
		}, 500);
	}
}

export default AmazonEditButton;
