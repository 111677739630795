( function(app, $) {
	var $cache;
	var mainUrlForAllAjaxCalls;
	
	var productSetID = null;
	var masterProductID = null;
	var variationProductID = null;
	
	var selectedSize = null;
	var selectedColor = null;
	
	// Main
	function checkValue(value){
		return value || null;
	}
	
	function getModel(){
		return productSetID;
	}
	
	function setModel(modelID){
		productSetID = checkValue(modelID); 
	}
	
	function getColor(){
		return masterProductID;
	}
	
	function setColor(colorID){
		masterProductID = checkValue(colorID); 
	}
	
	function getSize(){
		return variationProductID;
	}
	
	function setSize(sizeID){
		variationProductID = checkValue(sizeID); 
	}
	
	function getSelectedColorValue(){
		return selectedColor;
	}
	
	function setSelectedColorValue(color){
		selectedColor = checkValue(color);
	}
	
	function getSelectedSizeValue(){
		return selectedSize;
	}
	
	function setSelectedSizeValue(size){
		selectedSize = checkValue(size);
	}
	
	/**
	 * @private
	 * @function
	 * @description Show customization step
	 * @param {Integer} Step ID
	 */
	function stepShow(stepID){
		if( stepID ){
			$('.js-step-'+stepID).addClass('h-show').siblings().removeClass('h-show');
		}else{
			$cache.step.removeClass('h-show');
		}
	}
	
	/**
	 * @private
	 * @function
	 * @description Show customization tab for step
	 * @param {Integer} Step ID
	 */
	function tabShow(stepID){
		var tabs = $cache.tabSteps;
		tabs.hide();
		$('.js-step'+stepID).show();
	}
	
	/**
	 * @private
	 * @function
	 * @description Mark step "Completed"
	 * @param {Integer} Step ID
	 */
	function stepComplete(stepID){
		$('.js-step-'+stepID).addClass('m-completed_step').removeClass('m-disabled_step');
	}
	
	/**
	 * @private
	 * @function
	 * @description Disable mark step "Completed"
	 * @param {Integer} Step ID
	 */
	function stepUncomplete(stepID){
		if( stepID ){
			$('.js-step-'+stepID).removeClass('m-completed_step');
		}else{
			$cache.step.removeClass('m-completed_step');
		}
	}
	
	/**
	 * @private
	 * @function
	 * @description Lock step
	 * @param {Integer} Step ID
	 */
	function stepDisable(stepID){
		$('.js-step-'+stepID).addClass('m-disabled_step');
	}
	
	/**
	 * @private
	 * @function
	 * @description Activate selected model (Step 1)
	 * @param {String} Model ID
	 */
	function selectedModelActive(modelID){
		$('.js-select-style').removeClass('m-active_style m-unactive_style');
		if( modelID ){
			$('.js-select-style[data-pid='+modelID+']').addClass('m-active_style').siblings().addClass('m-unactive_style');
		}
	}
	
	/**
	 * @private
	 * @function
	 * @description Activate selected color (Step 2)
	 * @param {String} Color ID
	 */
	function selectedColorActive(colorID){
		$('.js-color-model').hide();
		$('.js-main-variant').hide();
		colorID = checkValue(colorID);
		if( colorID ){
			$('.js-main-variant[data-id='+colorID+']').show();
	   		setColorName(getSelectedColorValue());
		}else{
			$('.js-color-model').show();
			setColorName();
		}
	}
	
	/**
	 * @private
	 * @function
	 * @description Get color ID by color name
	 * @param {String} Color name
	 */
	function getColorIdByName(colorName){
		var colorID = $('.js-main-variant[data-colorname="'+colorName+'"]').data('id');
		return checkValue(colorID);
	}
	
	/**
	 * @private
	 * @function
	 * @description Set color name over color selector
	 * @param {String} Color name
	 */
	function setColorName(name){
		$cache.colorName.text(name || '');
	}
	
	/**
	 * @private
	 * @function
	 * @description Init social networks buttons 
	 */
	function loadSocial(){
		app.components.global.socials.init();
	}
	
	/**
	 * @private
	 * @function
	 * @description Select active step
	 * @param {Integer} Step ID
	 */
	function stepChange(stepID){
		colorFlyoutHide();
		mydesignsHide();
		
		var stepItem = $('.js-step-'+stepID);
		
		if(stepID != '4'){
			$cache.step4.hide();
		}
		if(stepItem.hasClass('h-show')){
			return false;
		}
		
		if(stepID == '2' && getModel() == null){
			return false;
		}
		
		if(stepID == '3' && (getModel() == null || getColor() == null)){
			return false;
		}
		
		stepShow(stepID);
		tabShow(stepID);
		
		if(stepID == '2' && getColor() == null){
			app.ajax.load({
				url: app.util.appendParamsToUrl(mainUrlForAllAjaxCalls, {'pid': getModel(), 'step': stepID, 'format': 'ajax'}),
				callback : function (data) {
					$cache.loadColors.html(data);
					$('.js-main-variant:first').show();
					$('.js-load-colors-img, .js-load-colors-img-left').html($('.js-load-colors .js-get-colors').html());
					copyToStep3();
				}
			});
		}
		
		if(stepID == '3' && getSize() == null){
			updateSizeFromSelector();
			loadStep3(getSelectedSizeValue());
			copyToStep3();
		}
		
		if( getModel() ){
			stepComplete(1);
			$cache.changeModel.addClass('h-opaque');
		}
		if( getColor() ){
			stepComplete(2);
			$cache.changeColor.addClass('h-opaque');
		}
		if( getSize() ){
			stepComplete(3);
			activeMydesignsButton();
		}
	}
	
	/**
	 * @private
	 * @function
	 * @description Load customization result (Step 4)
	 */
	function loadResult(){
		app.ajax.load({
			url: app.util.appendParamsToUrl(mainUrlForAllAjaxCalls, {'pid': getColor(), 'step': 4, 'size':getSelectedSizeValue(), 'format': 'ajax'}),
			callback : function (data) {
				$('.js-main-result').html(data);
				$('.js-name-replace').text($('.js-item-title').text());
				$cache.changeModelAndColor.removeClass('h-opaque');
				loadSocial();
			}
		});
	}
	
	/**
	 * @private
	 * @function
	 * @description Hide customization popups
	 */
	function hidePopups(){
   		$cache.allPopups.hide();
   	}
	
	/**
	 * @private
	 * @function
	 * @description Show popup "Product added to bag"
	 */
	function showPopupAddedToBag(){
   		$cache.popupAddedToCart.show();
   	}
	// END Main
	
	// Left menu
	
	/**
	 * @private
	 * @function
	 * @description Disable "Next step" button
	 * @param {Integer} Step ID
	 */
	function stepButtonDisable(stepID){
		if( stepID ){
			$('.js-custom-next-button[data-step='+stepID+']').addClass('m-disabled_button');
		}else{
			$cache.nextButton.addClass('m-disabled_button');
		}
	}
	
	/**
	 * @private
	 * @function
	 * @description Enable "Next step" button
	 * @param {Integer} Step ID
	 */
	function stepButtonEnable(stepID){
		$('.js-custom-next-button[data-step='+stepID+']').removeClass('m-disabled_button');
	}
	
	/**
	 * @private
	 * @function
	 * @description Hide color selector in flyout
	 */
	function colorFlyoutHide(){
		$cache.changeColorFlyout.hide();
	}
	// END Left menu
	
	// Cookies
	function getMydesignsCookie(){
		return JSON.parse($.cookie("selectedMydesign") || null) || [];
	}
	
	function setMydesignsCookie(value){
		var jsonSelectedMydesign = JSON.stringify(value);
		var expiration = new Date();
		var time = 10 * 24 * 60 * 60;
		expiration.setTime(expiration.getTime() + (time * 1000));
		$.cookie("selectedMydesign", jsonSelectedMydesign, { expires: expiration });
	}
	
	function deleteItemFromCookie(itemId){
		var selectedMydesign = getMydesignsCookie();
		selectedMydesign.remove(itemId);;
		setMydesignsCookie(selectedMydesign);
	}
	// END Cookies
	
	// Step 3
	
	/**
	 * @private
	 * @function
	 * @description Set size from size selector (Step 3) 
	 */
	function updateSizeFromSelector(){
		var selected = $('.js-size-selector select option:selected');
		setSize(selected.attr('data-id'));
	}
	
	/**
	 * @private
	 * @function
	 * @description Set value for size selector (Step 3)
	 * @param {String} Size value
	 */
	function setSizeSelectorValue(size) {
		if ($('.js-size-selector select option[value=' + size + ']').length) {
			$('.js-size-selector select').val(size);
			return true;
		} else {
			$('.js-size-selector select').val('');
			setSelectedSizeValue();
			updateSizeInProductBlock(false);
			return false;
		}
	}
	
	/**
	 * @private
	 * @function
	 * @description Update size in right block (in product description)
	 * @param {String} Size value
	 */
	function updateSizeInProductBlock(size) {
		var size = size || getSelectedSizeValue();
		if (size) {
			$('.js-size-block .js-size-print').text(size);
			$('.js-size-block').show();
			$('.js-size-print').each(function () {
				if ($(this).text() == '') {
					$('.js-size-print').parent('span').hide();
				}
			});
		} else {
			$('.js-size-print').parent('span').hide();
		}
	}
	
	/**
	 * @private
	 * @function
	 * @description Load sizes for size selector (Step 3)
	 * @param {String} Size value
	 */
	function loadStep3(tmpSize){
		var stepID = 3;
		
		app.ajax.load({
			url: app.util.appendParamsToUrl(mainUrlForAllAjaxCalls, {'pid': getColor(), 'step': stepID, 'format': 'ajax'}),
			callback : function (data) {
				$('.js-size-select').html(data);
				if (setSizeSelectorValue(tmpSize)) {
					updateSizeInProductBlock(tmpSize);
				}
				
				if (tmpSize) {
					updateSizeFromSelector();
				}
				
				getSize() ? stepButtonEnable(4) : stepButtonDisable(4);
				
				if( !getColor() ){
					stepUncomplete(2);
					stepShow(2);
					stepButtonDisable(stepID)
				}
			}
		});
	}
	
	/**
	 * @private
	 * @function
	 * @description Copy right block data from step 2 to step 3 (Init step 3)
	 */
	function copyToStep3(){
		$('.js-main-size').html($cache.loadColors.html());
		$('.js-main-size .js-main-variant').removeData('colorname').removeAttr('data-colorname');
		setSize(null);
	}
	// END Step 3
	
	// Mydesigns
	/**
	 * @private
	 * @function
	 * @description Load content on Mydesigns page
	 */
	function loadMydesigns(){
		app.ajax.load({
			url: app.util.appendParamsToUrl(mainUrlForAllAjaxCalls, {'step': 'mydesigns', 'format': 'ajax'}),
			callback : function (data) {
				$cache.mydesignesContent.html(data);
				if($('.m-scrolled-item').length){
					$cache.mydesignesContent.addClass('m-scrolled');
				}
			}
		});
	}
	
	/**
	 * @private
	 * @function
	 * @description Show Mydesigns
	 */
	function mydesignsShow(){
		$('.js-steps-block, .js-main-customize-block').hide();
		$('.js-mydesignes-block').show();
	}
	
	/**
	 * @private
	 * @function
	 * @description Hide Mydesigns
	 */
	function mydesignsHide(){
		$('.js-mydesignes-block').hide();
		$('.js-steps-block, .js-main-customize-block').show();
	}
	
	/**
	 * @private
	 * @function
	 * @description Add product to bag from Mydesiges
	 */
   	function initAddToCartMyDesign(){
   		$('.js-mydesign-add-cart').on('click', function(e){
   			e.preventDefault();
   			var pid = $(this).attr('data-pid');
	   		app.cart.add("Quantity=1&cartAction=add&pid="+pid.toString(), function(response){
	   			var $minicart = $(response).filter('#app-components-global-minicart-template');
	   			if ($minicart.length) {
	   				app.components.global.minicart.init();
					app.components.global.minicart.show( app.util.renderTemplate($minicart.html()) );
				}
				hidePopups();
				showPopupAddedToBag();
			});
   		});
   	}
   	
   	/**
   	 * @private
	 * @function
	 * @description Activate Mydesigns button in left panel
   	 */
   	function activeMydesignsButton(){
   		var selectedMydesign = getMydesignsCookie();
		if(selectedMydesign.length > 0){
			$('.js-show-mydesigns').addClass('h-opaque');
		}else{
			$('.js-show-mydesigns').removeClass('h-opaque');
		}
   	}   	
	// END Mydesigns
   	
	function initializeCache() {
		$cache = {
			mainBlocks				: $('.js-main-customize-block, .js-steps-block, .js-new-design, .js-change-model, .js-show-mydesigns'),
			popupDesignesAddedAndOverlay : $('.js-popup-designes-added, .js-customization-popup-overlay'),
			popupDesignesAdded		: $('.js-popup-designes-added'),
			mydesignesContent		: $('.js-mydesignes-content'),
			addToDesignes			: $('.js-customization-add-to-designes'),
			addToCartButton			: $('.js-customization-add-to-bag'),
			changeColorFlyout		: $('.js-change-color-flyout'),
			loadColors				: $('.js-load-colors'),
			changeModel				: $('.js-change-model'),
			changeColor				: $('.js-change-color'),
			changeModelAndColor		: $('.js-change-model, .js-change-color'),
			newDesign				: $('.js-new-design'),
			leftButtons				: $('.js-customization-left > a '),
			step					: $('.js-step'),
			mainBlockWrapper		: $('.js-main-block-wrapper'),
			customization			: $('.js-customization'),
			customizationLanding	: $('.js-customization-landing'),
			tabSteps				: $('.js-tab-steps'),
			colorName				: $('.js-color-name'),
			step4					: $('.js-step-4'),
			allPopups				: $('.js-customization-popup-overlay, .js-popup-added-to-designes, .js-popup-added-to-bag, .js-popup-designes-added'),
			popupAddedToDesignes	: $('.js-popup-added-to-designes, .js-customization-popup-overlay'),
			popupAddedToCart		: $('.js-popup-added-to-bag, .js-customization-popup-overlay'),
			nextButton				: $('.js-custom-next-button'),
		};
		
		mainUrlForAllAjaxCalls = $cache.loadColors.data('url');
	}
	
	function initializeEvents() {
		/**
		 * @public
		 * @event
		 * @description Click on landing page shows main block
		 */
		$cache.customizationLanding.on('click', function(){
			$(this).hide();
			$cache.mainBlockWrapper.show();
			stepButtonEnable(1);
		});
		
		/**
		 * @public
		 * @event
		 * @description Click "Next step" button
		 */
		$cache.step.on('click', '.js-custom-next-button', function(){
			if($(this).hasClass('m-disabled_button')){
				return false;
			}
			var stepID = $(this).data('step');
			if(stepID == '4'){
				loadResult();
			}
			
			stepChange(stepID);
			return false;
		});
		
		/**
		 * @public
		 * @event
		 * @description Click on step
		 */
		$cache.step.on('click', function(){
			stepChange($(this).data('step'));
		});
	  
		/**
		 * @public
		 * @event
		 * @description Prevent link by clicking on action-icons
		 */
	   	$cache.leftButtons.on('click', function(e){
	   		e.preventDefault();
	   	});
	   	
	   	/**
		 * @public
		 * @event
		 * @description Click on model (Step 1)
	   	 */
	   	$('.js-select-style').on('click', function(){
	   		setModel($(this).attr('data-pid'));
	   		selectedModelActive(getModel());
	   		setColor(null);
	   		setSize(null);
	   		
	   		stepUncomplete(2);
	   		stepComplete(1);
	   		
	   		stepButtonEnable(2);
	   		setColorName("");
	   	});
	   	
	   	/**
		 * @public
		 * @event
		 * @description Click on color (Step 2)
	   	 */
	   	$('.js-load-colors-img, .js-load-colors-img-left').on('click', '.js-color-select', function(){
	   		var colorID = $(this).data('variantid');
	   		setSelectedColorValue($('.js-main-variant[data-id='+colorID+']').data('colorname'));
	   		selectedColorActive(colorID);
	   		setColor(colorID);
	   		setSize(null);
	   		
	   		stepUncomplete(3);
	   		stepComplete(2);
	   		stepButtonEnable(3);
	   		
	   		loadStep3(getSelectedSizeValue());
	   	});
	   	
	   	// Left panel
	   	
	   	/**
		 * @public
		 * @event
		 * @description Click on button "New Design" (Left panel)
	   	 */
	   	$cache.newDesign.on('click', function(){
	   		window.location.hash = '';
	   		
	   		setModel(null);
	   		setColor(null);
	   		setSize(null);
	   		selectedModelActive(null);
	   		stepUncomplete(null);
	   		stepShow(null);
	   		stepButtonDisable(null);
	   		
	   		$cache.changeModelAndColor.removeClass('h-opaque'); 		
	   		stepChange(1);	
	   	});
	   	
	   	/**
		 * @public
		 * @event
		 * @description Click on button "change model" (Left panel)
	   	 */
	   	$cache.changeModel.on('click', function(){
	   		if(!$(this).hasClass('h-opaque')){
	   			return false;
	   		}
	   		var modelsSelector = '.js-style-buttons .js-select-style';
	   		
	   		var activeModel = $(modelsSelector+'.m-active_style');
	   		var newActiveModel = activeModel.next().length > 0 ? activeModel.next() : $(modelsSelector+':first');
	   		
	   		var modelID = newActiveModel.data('pid');
	   		selectedModelActive(modelID);
	   		setModel(modelID);
	   		
	   		var colorName = getSelectedColorValue();
	   		
	   		app.ajax.load({
				url: app.util.appendParamsToUrl(mainUrlForAllAjaxCalls, {'pid': getModel(), 'step': '2', 'format': 'ajax'}),
				callback : function (data) {
					$cache.loadColors.html(data);
					$('.js-load-colors-img, .js-load-colors-img-left').html($('.js-load-colors .js-get-colors').html());
					
					var colorID = getColorIdByName(colorName);
					setColor(colorID);
					selectedColorActive(colorID);
					
					if(colorID != null){
						stepButtonEnable(3);
						updateSizeInProductBlock();
					}else{
						stepButtonDisable(3);
					}
					
					var tmpSize = getSelectedSizeValue();
					setSize(null);
					if( tmpSize ){
						loadStep3(tmpSize);
					}
					copyToStep3();
				}
			});
	   	});
	   	
	   	/**
		 * @public
		 * @event
		 * @description Click on button "change color" (Left panel)
	   	 */
	   	$cache.changeColor.on('click', function(event){
	   		if($cache.changeColor.hasClass('h-opaque') && !$(event.target).hasClass('js-color-select')){
	   			$cache.changeColorFlyout.toggle();
	   		}
		});
	   	// End left panel
	   	
	   	/**
		 * @public
		 * @event
		 * @description Change size selector (Step 3)
	   	 */
	   	$('.js-size-select').on('change', '.js-size-selector select', function(){
	   		var buttonStepTo = 4;
	   		setSelectedSizeValue($(this).val());
	   		if(getSelectedSizeValue() == null){
	   			stepButtonDisable(buttonStepTo);
	   			return false;
	   		}
	   		
	   		updateSizeInProductBlock();
	   		updateSizeFromSelector();
	   	    stepButtonEnable(buttonStepTo);
	   	});
	   	
	   	/**
		 * @public
		 * @event
		 * @description Click on button "Add to bag" (Step 4)
	   	 */
	   	$cache.addToCartButton.on('click', function(e){
	   		e.preventDefault();
   			var pid = getSize();
	   		app.cart.add("Quantity=1&cartAction=add&pid="+pid.toString(), function(response){
	   			var $minicart = $(response).filter('#app-components-global-minicart-template');
	   			if ($minicart.length) {
	   				app.components.global.minicart.init();
					app.components.global.minicart.show( app.util.renderTemplate($minicart.html()) );
				}
				showPopupAddedToBag();
			});
		});
	   	
	   	/**
		 * @public
		 * @event
		 * @description Click on button "Add to my designs" (Step 4)
	   	 */
	   	$cache.addToDesignes.on('click', function(){
	   		var selectedMydesign = getMydesignsCookie();
			selectedMydesign.push(getSize());
			setMydesignsCookie(selectedMydesign);
			
			$cache.popupAddedToDesignes.show();
			activeMydesignsButton();
		});
	   	
	   	/**
		 * @public
		 * @event
		 * @description Click on button "Delete" (item on Mydesign page)
	   	 */
	   	$cache.customization.on('click', '.js-mydesignes-item-delete', function(){
	   		var pid = $(this).attr('data-pid');
	   		deleteItemFromCookie(pid);
	   		
	   		$(this).closest('.js-mydesigns-item').remove();
	   		loadMydesigns();
	   		
	   		if($('.js-mydesigns-item').length < 9){
	   			$cache.mydesignesContent.removeClass('m-scrolled');
	   			$('.js-mydesigns-item').removeClass('m-scrolled-item');
	   		} 
	   		activeMydesignsButton();
	   		return false;
	   	});
	   	
	   	/**
		 * @public
		 * @event
		 * @description Outside click should hide popup or click on close button
	   	 */
		$cache.customization.on('click', '.js-customization-popup-overlay, .js-close-customization-popup', function(e){
			hidePopups();
			$('.js-mydesigns-item').removeClass('m-hovered');
		});
		
		/**
		 * @public
		 * @event
		 * @description Click button "Go to my designs" in popup
		 */
		$cache.customization.on('click', '.js-show-mydesigns.h-opaque, .js-show-mydesigns-url', function(){
			hidePopups();
			loadMydesigns();
			mydesignsShow();
			window.location.hash = "#mydesigns";
			return false;
		});
		
		/**
		 * @public
		 * @event
		 * @description Hover on item (Mydesignes page)
		 */
		$cache.mydesignesContent.on('hover', '.js-mydesigns-item', function(){
			$(this).toggleClass('m-hovered');
		});
		
		$cache.mydesignesContent.on('click', '.js-add-to-cart-mydesign', function(){
   			var pid = $(this).attr('data-pid');
   			
   			app.ajax.load({
				url: app.util.appendParamsToUrl(mainUrlForAllAjaxCalls, {'pid': pid, 'step': 'addtocart', 'format': 'ajax'}),
				callback : function (data) {
					$cache.popupDesignesAdded.html(data);
	   				initAddToCartMyDesign();
	   				loadSocial();
	   				$cache.popupDesignesAddedAndOverlay.show();
				}
			});
   			
   			return false;
   		});
		
		/**
		 * @public
		 * @event
		 * @description Hide flyout color selector
		 */
		$cache.mainBlocks.on('click', function(){
			colorFlyoutHide();
		});
		
		/**
		 * @public
		 * @event
		 * @description Load Mydesigns if URL have #mydesigns
		 */
		var selectedMydesign = getMydesignsCookie();
		if(selectedMydesign.length > 0){
			if(window.location.hash == "#mydesigns"){
				$cache.customizationLanding.hide();
				$cache.mainBlockWrapper.show();
				loadMydesigns();
				mydesignsShow();
			}
			activeMydesignsButton();
		}else{
			window.location.hash = '';
		}
	}
	
	/**
	 * @namespace app.components.global.customization public object
	 **/
	app.components = app.components || {};
	app.components.customization = app.components.customization || {};
	app.components.customization.main = app.components.customization.main || {
	    init : function() {
	        initializeCache();
	        initializeEvents();
	    }
	};

}(window.app = window.app || {}, jQuery));