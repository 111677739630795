(function (app, $) {
	var $cache = {},
		context;
	
	function initializeCache() {  
		$cache.document = $(document);
		$cache.window = $(window);
	}
	
	function disableScrollHandler_IE() {
		$cache.window.off('scroll', scrollWindowUp_IE);
	}
	
	function scrollWindowUp_IE () {
		if($(context).scrollTop() > 0) {
			$(context).scrollTop(0);
		}
		disableScrollHandler_IE();
	}
	
	function initializeEvents(params) {
		if ((!app.preferences.anchorBackEnable || (params && params.disabledAnchor == true)) && app.preferences.enableInfiniteScrollForSEO) {
			if('scrollRestoration' in history) {
					history.scrollRestoration = 'manual';
			} else {
				context = 'html, body';
				$cache.window.on('scroll', scrollWindowUp_IE);
				
				$cache.document.on('mousewheel keydown', function(){
					disableScrollHandler_IE();
				});
			}
		}
	}

  /*************** app.components.global.history public object ***************/
  app.components = app.components || {};
  app.components.global = app.components.global || {};
  app.components.global.history = {
      // initializations
      init : function (params) {
        initializeCache();
        initializeEvents(params);
      }
  };
})(window.app = window.app || {}, jQuery);
