(function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			addBonusProductsButton: $('.js-add_bonus_products'),
			selectedQty: $('.js-bonus_products_selected'),
			maxQty: $('.js-max_bonus_items'),
			bonusProductSelect: $('.js_bonus-product-select'),
			bonusDiscountContainer: $('.js-bonus_discount_container'),
			selectors: {
				bonusProductSelect: '.js_bonus-product-select',
				bonusSwatchanchor: '.js-bonus_swatchanchor',
				bonusProductItem: '.js-bonus_product_item',
				bonusProductForm: '.js-bonus_product_form',
				productQuantity: '.js-product_quantity'
			},
			classes: {
				bonusProductSelected: 'selected'
			}
		};
	}

	function initializeEvents() {
		$(document).on('click', $cache.selectors.bonusSwatchanchor, function(e) {
			e.preventDefault();
			var anchor = $(this);
			var bpItem = anchor.closest($cache.selectors.bonusProductItem);
			var bpForm = bpItem.find($cache.selectors.bonusProductForm);
			var qty = bpForm.find($cache.selectors.productQuantity).first().val();
			var params = {
				Quantity: isNaN(qty) ? '1' : qty,
				format: 'ajax',
				source: 'bonus'
			};
			var url = app.util.appendParamsToUrl(this.href, params);

			app.progress.show(bpItem);
			app.ajax.load({
				url: url,
				callback: function(data) {
					bpItem.html(data);
				}
			});
		});

		$(document).on('click', $cache.selectors.bonusProductSelect, function() {
			var $this = $(this);
			var	pids = [];
			var	url = app.urls.addBonusProduct;
			var pidToRemove;

			if ($this.hasClass($cache.classes.bonusProductSelected)) {
				pidToRemove = $this.data('pid');
			} else {
				pids.push($this.data('pid'));
			}

			$cache.bonusProductSelect.each(function() {
				var $that = $(this);
				var	pid = $that.data('pid');

				if ($that.hasClass($cache.classes.bonusProductSelected) && pids.indexOf(pid) === -1 && pid !== pidToRemove) {
					pids.push(pid);
				}
			});

			if (pids.length > +$cache.maxQty.val()) {
				return false;
			}

			$.ajax({
				type: 'POST',
				url: url,
				data: {
					pids: pids.join(',')
				},
				success: function(response) {
					if (response.success) {
						if (pidToRemove) {
							$this.removeClass($cache.classes.bonusProductSelected);
							$cache.selectedQty.text(+$cache.selectedQty.text() - 1);
						} else {
							$this.addClass($cache.classes.bonusProductSelected);
							$cache.selectedQty.text(+$cache.selectedQty.text() + 1);
						}
					}
				}
			});
		});
	}

	/* ************** app.components.global.newsletter public object ************** */
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.bonusproducts = {
		// initializations
		init: function() {
			initializeCache();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);
