//customerservice.custom
( function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			container : '.js-cs_navigation',
			active : 'b-customer_service_navigation-list-li--active',
			cscontent : $('.js-cs-content'),
			contentContainer : $('div'),
			contactusFormsSet: ['.js-contactus_form', '.js-customerservice-in-store_without-receipt_form_tag',
			                    '.js-customerservice-press_form_tag'],
			breadcrumbLastLink : $('.js-breadcrumb_refinement-link'),
			document: $(document),
			header: 'header',
			anchor: window.location.hash,
			recaptchaRenderBlockSel: '.js-recaptcha-render-block',
			accordionCntrSel: '.js-accordion_cntr'
		};
	}
	
	function initializeDom() {
		var param = app.util.getQueryStringParams(window.location.search);
		if(param.hasOwnProperty('cid')){
			var linkInCSMNavigation = $('a[name=' + param.cid + ']');
			linkInCSMNavigation.closest($cache.container).addClass($cache.active);
			if ($cache.breadcrumbLastLink.text() && !$cache.breadcrumbLastLink.text().trim()) {
				$cache.breadcrumbLastLink.text(' ' + linkInCSMNavigation.attr('title'));
			}
		}
		if($cache.contentContainer.is($cache.cscontent)){
			var cidInMenu = $('a[name=' + param.cid + ']').data('content'),
				cid = '',
				geoAssetsEnable = false;
			
			if ( param.hasOwnProperty('cid') && cidInMenu) {
				cid = cidInMenu;
			} else if (param.hasOwnProperty('cid') && !cidInMenu){
				cid = param.cid;
			} else{
				cid = 'cs-landing';
				geoAssetsEnable = true;
			}
			app.ajax.load({
				'url' : app.urls.pageContent,
				data : {
					cid : cid,
					geoAssetsEnable : geoAssetsEnable
				},
				callback : function (data) {
					$cache.cscontent.html(data).promise().done(function(){
						var cacheAnchor = $($cache.anchor);
						if (cacheAnchor.length){
							var headerElement = $($cache.header),
								headerHeight = headerElement.css('position')  === 'fixed' ? headerElement.height() : 0,
								scrollPos = cacheAnchor.offset().top - headerHeight;
							if (app.preferences.anchorBackEnable || !app.preferences.enableInfiniteScrollForSEO) {
								if('scrollRestoration' in history) {
									// disable browser scroll
									history.scrollRestoration = 'manual';
								}
							}
							$cache.document.scrollTop(scrollPos);
						}
						if (app.preferences.recaptchaEnabled) {
							app.grecaptcha.init($cache.recaptchaRenderBlockSel);
						}

						app.accordion.initAccordions($cache.cscontent.find($cache.accordionCntrSel));
					});
					
					var areFormsPresent = false;
					for(var i = 0; i<$cache.contactusFormsSet.length; i++){
						var formSel = $cache.contactusFormsSet[i];
						if($(formSel).length){
							areFormsPresent = true;
							app.validator.initForm(formSel);
						}
					}
					if(areFormsPresent){
						app.components.customerservice.contactus.init();
					}
					if ( typeof app.components.customerservice.custom !== 'undefined') {
						app.components.customerservice.custom.init();
					}
					$cache.document.trigger('customerservice.pagecontent.loaded');
				}
			});
		}
	}
	
	/**
	 * @namespace app.components.customerservice.custom public object
	 **/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.customerservice = {
		init : function () {
			initializeCache();
			initializeDom();
		}
	};

}(window.app = window.app || {}, jQuery));
