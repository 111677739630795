/**
 * Components specific config
 **/
( function(app, $) {

	var settings =	{
		pdp_main : {
			lazy : true
		},
		pdp_thumbnail : {
			vertical : true,
			visibleItems : 5
		},
		pdp_zoom : {
			lazy : true
		},
		pdp_last_visited : {
			lazy : true,
			visibleItems : 5
		},
		pdp_also_like : {
			lazy : true,
			visibleItems : 5
		}
	};
	
	/*************** app.jcarousel.settings ***************/
	app.jcarousel = app.jcarousel || {};
	app.jcarousel.settings = app.jcarousel.settings || {};
	app.jcarousel.settings = $.extend(true, app.jcarousel.settings, settings);

}(window.app = window.app || {}, jQuery));