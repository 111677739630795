(function(app, $) {

	var $cache = {
			document: $(document),
			quickViewBlockSel: '.js-quickview',
			quickViewMainSel: '.js-quick_view_main',
			htmlSel: 'html',
			quickViewHtmlClass: 'html_quickview_opened'
		},
		default_options = {
			type: 'ajax',
			beforeShow: function () {
				$cache.document.trigger('quickview.beforeShow');
			},
			afterShow: function() {
				app.product.init($cache.quickViewMainSel);
				app.components.global.quickview.isOpened = true;
				$cache.document.trigger('quickview.opened');

				$($cache.quickViewMainSel).on('product.master.reloaded', function () {
					app.product.init($cache.quickViewMainSel, true);
				});
			},
			beforeClose: function(){
				app.components.global.quickview.isOpened = false;
				$cache.document.trigger('quickview.beforeClose');
			},
			afterClose: function () {
				$cache.document.trigger('quickview.closed');
				$($cache.htmlSel).removeClass($cache.quickViewHtmlClass);
			},
			autoWidth: true
		};

	function initializeConfigs(params) {

		if (params && params.quickViewBlockSel) {
			$cache.quickViewBlockSel = params.quickViewBlockSel;
		}
	}

	function initializeEvents() {
		$cache.document.on('click', $cache.quickViewBlockSel, function (e) {
			var $this = $(this);
			$($cache.quickViewBlockSel).removeClass("js-active");
			$this.addClass("js-active");
			$cache.document.trigger("toggle.hideall");
			$cache.document.trigger("quickview.beforeOpen", {target: e.target});
			showQuickView($this.data("url") || $this.attr("href"), $this.data("options"), $this.data("source"));
			return false;
		});
		$cache.document.on('quickview.open', function(event, data){
			if (data && data.targetUrl) {
				$cache.document.trigger("quickview.beforeOpen", {target: data.target});
				showQuickView(data.targetUrl, data.options);
			}
		});
	}

	function showQuickView(_url, _options, source){
		$($cache.htmlSel).addClass($cache.quickViewHtmlClass);

		var pdpUrl = app.util.appendParamsToUrl(_url, {
			source: source || "quickview"
		});
		var custom_options = _options ? $.extend(true, default_options, _options) : default_options;
		app.fancybox.open(pdpUrl, custom_options);
	}

	/*************** app.components.global.quickview public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.quickview = {
		// initializations
		init: function(params) {
			initializeConfigs(params);
			initializeEvents();
		},
		isOpened : false
	};
})(window.app = window.app || {}, jQuery);
