/**
 * @class app.grecaptcha
 */
(function (app, $) {
	app.grecaptcha = {
		widgets: {},
		
		init : function(selector) {
			var _this = this;
			
			if (window.isRecaptchaLoaded) {
				renderRecaptcha()
			} else {
				$(document).on('recaptcha-api-loaded', function() {
					renderRecaptcha();
				});
			}
			
			function renderRecaptcha() {
				$(selector).each(function() {
					var $this = $(this);
					var recaptchaId = $this.attr('id');
					if ($this.data('recaptchaprocessed') === 'processed') {
						return;
					}
					
					$this.attr('data-recaptchaprocessed', 'processed');
					
					var uniqueId = recaptchaId;
					
					// two same forms on the page
					if(recaptchaId in _this.widgets) {
						var uniqueId = recaptchaId + '-additional';
						$this.attr('id', uniqueId);
					}
					_this.render(uniqueId);
				});
			}
		},
		
		render : function(elementId) {
			var config = {};
			var $container = $('#'+elementId);
			config['sitekey'] = $container.data('sitekey');
			config['size'] = $container.data('size');
			config['badge'] = $container.data('badge');
			config['callback'] = function(token) {
				 $(document).trigger('captcha-'+elementId+'-solved', token);
			 }
			if (config['size'] && config['sitekey']) {
				this.widgets[elementId] = grecaptcha.render(
					elementId,
					config
				)
			}
		},
		
		execute : function(elementId) {
			grecaptcha.execute(this.widgets[elementId]);
		},
		
		reset : function(elementId) {
			grecaptcha.reset(this.widgets[elementId]);
		}
	}	
}(window.app = window.app || {}, jQuery));