/**
 * @class app.callmeback
 */
(function (app, $) {
	var $cache = {};

	function initializeCache (){
		$cache = {
			document : $(document),
			callMeWrapper : $(".js-checkout-call_me-slot_wrapper"),
			callMeCntr : $("#js-checkout-call_me-container"),
			callMeFormWrapSel : ".js-checkout-call_me-form_wrapper",
			callMeHeaderSel : ".js-checkout-call_me-header",
			js_ccErrorMsg : $(".js-cc-error_message"),
			hiddenCls : "h-hidden"
		};
		$cache.callMeFormWrap = $($cache.callMeFormWrapSel);
	}
	
	function initializeDOM () {
		if (!app.device.isMobileView()) showCallMePopup($cache.js_ccErrorMsg, $cache.callMeCntr);
	}
	
	function initializeEvents (){		
		$cache.document.on("submit", $cache.callMeFormWrapSel + " form", function(e){
			var $this = $(this),
				orderNo = $cache.callMeWrapper.data("order-no"),
				amount = $cache.callMeWrapper.data("amount");
			app.ajax.getJson({
				type : "POST",
				url :  $this.attr('action'),
				data : $this.serialize() + (orderNo && amount ? "&orderNo=" + orderNo + "&amount=" + amount : ''),
				callback : function(resp) {
					$cache.callMeFormWrap.html(resp.msg);
				}
			});	
			e.preventDefault();
		});
		
		$cache.document.on("click", $cache.callMeHeaderSel, function(){
			$cache.callMeFormWrap.toggleClass($cache.hiddenCls);
		});
	}
	
	function initializeMobileEvents (){
		$cache.callMeWrapper.on('click', 'a', function(){
			$(this).attr('href', $(this).attr('href') + '?orderNo=' + $cache.callMeWrapper.data("order-no") + '&amount=' + $cache.callMeWrapper.data("amount"));
		});
	}
	
	function showCallMePopup (errorElem, popupCntr) {
		if(errorElem.length && popupCntr.length) {
			app.ajax.load({
				url : app.urls.showCallMe,
				target : popupCntr,
				callback : function(){
					updateCache();
					$cache.callMeFormWrap.find('form').validate(app.validator.settings);
				}
			});
		}
	}
	
	function updateCache () {
		$cache.callMeFormWrap = $($cache.callMeFormWrapSel);
	}
	
	/*************** app.components.global.callmeback public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.callmeback = {
		// initializations
		init : function () {
			initializeCache();
			initializeDOM();
			initializeEvents();
			if (app.device.isMobileView()) {
				initializeMobileEvents();
			}
		}
	};
	
}(window.app = window.app || {}, jQuery));