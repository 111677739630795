/* eslint-disable class-methods-use-this */

const APPLE_PAY_API_SUPPORTED = {
	MIN: 3,
	MAX: 4
};

/**
 * Represents ApplePayDefaultHandler
 */
class ApplePayDefaultHandler {
	/**
	 * @constructor
	 * @param {Object} config - ApplePay configuration
	 */
	constructor(config) {
		this._config = config;
		this._session = null;
		this._endpoints = {
			validation: app.urls.applePayValidation,
			...window.dw.applepay.action
		};
	}

	/**
	 * Handle Apple Pay payment processing
	 * @param {String} productID - product ID
	 * @returns {Promise} - promise with payment result
	 */
	handlePayment(productID) {
		if (productID) {
			this._prepareBasket(productID);
		}

		this._createSession();

		return new Promise((resolve) => {
			this._handlePaymentAuthorized = (redirectURL) => {
				resolve({
					success: true,
					status: 'completed',
					redirectURL: redirectURL
				});
			};

			this._handleError = (error) => {
				resolve({
					success: false,
					status: 'error',
					error: error
				});
			};

			this._handleCancel = () => {
				resolve({
					success: false,
					status: 'canceled'
				});
			};

			this._session.begin();
		});
	}

	/**
	 * Prepares basket for Apple Pay payment processing
	 * @protected
	 * @param {String} [productSku] - product ID for preparing a basket with
	 */
	_prepareBasket(productSku) {
		postJSON(this._endpoints.prepareBasket, { sku: productSku })
			.catch((error) => this._onHandleError(error));
	}

	/**
	 * Creates Apple Pay session
	 * @protected
	 */
	_createSession() {
		this._session = new window.ApplePaySession(this._getSupportedVersion(), this._getInitialRequest());

		this._session.onvalidatemerchant = this._onValidateMerchant.bind(this);
		this._session.onpaymentmethodselected = this._onPaymentMethodSelected.bind(this);
		this._session.onshippingcontactselected = this._onShippingContactSelected.bind(this);
		this._session.onshippingmethodselected = this._onShippingMethodSelected.bind(this);
		this._session.onpaymentauthorized = this._onPaymentAuthorized.bind(this);
		this._session.oncancel = this._onCancel.bind(this);
	}

	/**
	 * Returns Apple Pay session API supported version
	 * @protected
	 * @returns {number|null} - Apple Pay session API version
	 */
	_getSupportedVersion() {
		for (let apVersion = APPLE_PAY_API_SUPPORTED.MAX; apVersion >= APPLE_PAY_API_SUPPORTED.MIN; apVersion--) {
			if (window.ApplePaySession.supportsVersion(apVersion)) {
				return apVersion;
			}
		}

		return null;
	}

	/**
	 * Returns Apple Pay payment request
	 * @protected
	 * @returns {Object} - a request for payment, which includes information about payment-processing capabilities, see the @see {@link https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentrequest|ApplePayPaymentRequest - Apple Developer Documentation} for more information
	 */
	_getInitialRequest() {
		return {
			countryCode: this._config.countryCode,
			currencyCode: this._config.currencyCode,
			merchantCapabilities: this._config.merchantCapabilities,
			supportedNetworks: this._config.supportedNetworks,
			requiredShippingContactFields: this._config.requiredShippingContactFields,
			requiredBillingContactFields: this._config.requiredBillingContactFields,
			total: {
				label: this._config.merchantName,
				type: 'final',
				amount: '0'
			}
		};
	}

	/**
	 * Handles Apple Pay session merchant validation event
	 * @protected
	 * @see {@link https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession/1778021-onvalidatemerchant|onvalidatemerchant - Apple Developer Documentation}
	 * @param {Object} eventObj - Apple Pay session validation event object that contains the validation URL, see the @see {@link https://developer.apple.com/documentation/apple_pay_on_the_web/applepayvalidatemerchantevent|ApplePayValidateMerchantEvent - Apple Developer Documentation} for more information
	 */
	_onValidateMerchant(eventObj) {
		postJSON(this._endpoints.onvalidatemerchant,
			{
				isTrusted: eventObj.isTrusted,
				validationURL: eventObj.validationURL,
				hostname: window.location.host
			})
			.then((merchantSession) => {
				if (this._session) {
					this._session.completeMerchantValidation(merchantSession.session);
				}
			})
			.catch((error) => this._onHandleError(error));
	}

	/**
	 * Handles the Apple Pay session payment method selection event
	 * @protected
	 * @see {@link https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession/1778013-onpaymentmethodselected|onpaymentmethodselected - Apple Developer Documentation}
	 * @param {Object} eventObj - an event object that contains the payment method, see the @see {@link https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentmethodselectedevent|ApplePayPaymentMethodSelectedEvent - Apple Developer Documentation} for more information
	 */
	_onPaymentMethodSelected(eventObj) {
		postJSON(this._endpoints.onpaymentmethodselected, convertApplePayEventObject(eventObj))
			.then((response) => {
				if (this._session) {
					const completeObject = {
						newTotal: response.total,
						newLineItems: response.lineItems
					};

					this._cachedTotal = response.total;
					this._session.completePaymentMethodSelection(completeObject);
				}
			})
			.catch((error) => this._onHandleError(error));
	}

	/**
	 * Handles the Apple Pay shipping contact selection event
	 * @protected
	 * @see {@link https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession/1778009-onshippingcontactselected|onshippingcontactselected - Apple Developer Documentation}
	 * @param {Object} eventObj - an event object that contains the shipping address the user selects, see the @see {@link https://developer.apple.com/documentation/apple_pay_on_the_web/applepayshippingcontactselectedevent|ApplePayShippingContactSelectedEvent - Apple Developer Documentation} for more information
	 */
	_onShippingContactSelected(eventObj) {
		postJSON(this._endpoints.onshippingcontactselected, convertApplePayEventObject(eventObj))
			.then((response) => {
				if (this._session) {
					const completeObject = {
						newTotal: response.total,
						newLineItems: response.lineItems,
						newShippingMethods: response.shippingMethods,
						errors: this._getApplePayErrorsFromResponseEvent(response.event)
					};

					this._cachedTotal = response.total;
					this._session.completeShippingContactSelection(completeObject);
				}
			})
			.catch((error) => this._onHandleError(error));
	}

	/**
	 * Handles the Apple Pay shipping method selection event
	 * @protected
	 * @see {@link https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession/1778028-onshippingmethodselected|onshippingmethodselected - Apple Developer Documentation}
	 * @param {Object} eventObj - an event object that contains the shipping address the user selects, see the @see {@link https://developer.apple.com/documentation/apple_pay_on_the_web/applepayshippingcontactselectedevent|ApplePayShippingContactSelectedEvent - Apple Developer Documentation} for more information
	 */
	_onShippingMethodSelected(eventObj) {
		postJSON(this._endpoints.onshippingmethodselected, convertApplePayEventObject(eventObj))
			.then((response) => {
				if (this._session) {
					/**
					 * Perhaps there is a bug with Apple Pay, as we CAN NOT remove all shipping methods by
					 * submitting object with an empty array assigned to the "newShippingMethods" property
					 * through Apple Pay session methods:
					 * @see {ApplePaySession.completePaymentMethodSelection}
					 * @see {ApplePaySession.completeShippingContactSelection}
					 * @see {ApplePaySession.completeShippingMethodSelection}
					 * @see {ApplePaySession.completeCouponCodeChange}
					 *
					 * As a result, we may encounter a situation where there are NO available shipping methods for the selected
					 * shipping contact. Yet, the user can still see and select a shipping method that was previously rendered.
					 * If the user selects an unavailable shipping method, we receive an error from the BE:
					 * {"error": "Unknown shipping method: <SHIPPING_METHOD_ID>", "status": "Failure"}
					 *
					 * However, to conclude a Apple Pay session callback, we MUST submit at least the "newTotal" property of the
					 * object to the "ApplePaySession.complete..." method.
					 *
					 * Hence, to improve the UI and user experience, we submit the previous total value
					 * rather than a dummy value.
					 */
					let completeObject = {
						newTotal: this._cachedTotal
					};

					if (!('error' in response)) {
						completeObject = {
							newTotal: response.total,
							newLineItems: response.lineItems
						};

						this._cachedTotal = response.total;
					}

					this._session.completeShippingMethodSelection(completeObject);
				}
			})
			.catch((error) => this._onHandleError(error));
	}

	/**
	 * Handles the Apple Pay payment authorization event
	 * @protected
	 * @see {@link https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession/1778020-onpaymentauthorized|onpaymentauthorized - Apple Developer Documentation}
	 * @param {Object} eventObj - an event object that contains the shipping address the user selects, see the @see {@link https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentauthorizedevent|ApplePayPaymentAuthorizedEvent - Apple Developer Documentation} for more information
	 */
	_onPaymentAuthorized(eventObj) {
		postJSON(this._endpoints.validation, convertApplePayEventObject(eventObj.payment))
			.then(({ validationResult }) => {
				let errors = [];

				if (!validationResult.shippingContact.valid) {
					errors = errors.concat(
						this._getContactValidationErrors(validationResult.shippingContact, 'shippingContactInvalid')
					);
				}

				if (!validationResult.billingContact.valid) {
					errors = errors.concat(
						this._getContactValidationErrors(validationResult.billingContact, 'billingContactInvalid')
					);
				}

				return errors;
			})
			.then((errors) => {
				if (errors.length) {
					this._session.completePayment({
						status: window.ApplePaySession.STATUS_FAILURE,
						errors: errors
					});
				} else {
					postJSON(this._endpoints.onpaymentauthorized, convertApplePayEventObject(eventObj))
						.then((response) => {
							if (response.error) {
								this._session.completePayment({ status: window.ApplePaySession.STATUS_FAILURE });
								this._handleError(new Error(response.error));
							} else {
								this._session.completePayment({ status: window.ApplePaySession.STATUS_SUCCESS });
								this._handlePaymentAuthorized(response.redirect);
							}
						})
						.catch((error) => this._onHandleError(error));
				}
			})
			.catch((error) => this._onHandleError(error));
	}

	/**
	 * Handles the Apple Pay payment cancel event
	 * @protected
	 * @see {@link https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession/1778029-oncancel|oncancel - Apple Developer Documentation}
	 */
	_onCancel() {
		this._session = null;

		postJSON(this._endpoints.cancel)
			.then(this._handleCancel)
			.catch(this._handleError);
	}

	/**
	 * Handles errors during processing Apple Pay payment
	 * @protected
	 * @param {Error} error - error
	 */
	_onHandleError(error) {
		if (this._session) {
			this._session.abort();
		}

		this._handleError(error);
	}

	/**
	 * Returns Apple Pay errors from contact validation result
	 * @private
	 * @param {Object} contactValidationResult - object with contact validation result
	 * @param {boolean} contactValidationResult.valid - indicates whether the contact is valid
	 * @param {Object<string, Object>} contactValidationResult.fields - object with fields validation result, where a key is a field name that contain validation errors
	 * @param {boolean} [contactValidationResult.fields.valid=false] - indicates whether the field is valid
	 * @param {Object<string, Object>} [contactValidationResult.fields.rule] - a rule validation result that contains validation errors. Note: instead of 'rule', there will be dynamic rule names, and the number of rules may vary
	 * @param {boolean} contactValidationResult.fields.rule.valid=false - indicates whether the field rule is valid
	 * @param {boolean} contactValidationResult.fields.rule.errorMessage - rule error message
	 * @param {String} errorSection - error section name
	 * @returns {Array<ApplePayError>} - an array of Apple Pay errors
	 */
	_getContactValidationErrors(contactValidationResult, errorSection) {
		let errors = [];

		if (!contactValidationResult.valid) {
			const contactFields = Object.keys(contactValidationResult.fields);

			errors = contactFields.map((fieldName) => {
				const {valid, ...fieldRules} = contactValidationResult.fields[fieldName];
				const errorMessages = Object.values(fieldRules).map((rule) => rule.errorMessage).toString();

				return new window.ApplePayError(
					errorSection,
					fieldName,
					errorMessages
				);
			});
		}

		return errors;
	}

	/**
	 * Returns Apple Pay errors from the BE response of the handled event
	 * @private
	 * @param {Object} event - response event
	 * @param {Object} event.detail - response event detail
	 * @param {String} event.detail.errorSection - error section name
	 * @param {Array<String>} event.detail.errorFields - an array of error field names where the index of each field name corresponds to the index of the error message
	 * @param {Array<String>} event.detail.errorMessages - an array of error messages where the index of each message corresponds to the index of the error field name
	 * @returns {Array<ApplePayError>} - an array of Apple Pay errors
	 */
	_getApplePayErrorsFromResponseEvent(event) {
		const eventData = event.detail;
		const errors = eventData.errorFields.map((errorField, index) =>
			new window.ApplePayError(
				eventData.errorSection,
				errorField,
				eventData.errorMessages[index]
			)
		);

		return errors;
	}
}

/**
 * Performs a port request with JSON data
 * @private
 * @param {String} response - object with contact validation result
 * @param {Object} [data] - request object with data
 * @returns {Promise} - promise with response data
 */
function postJSON(url, data) {
	return fetch(url, {
		method: 'POST',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json'
		},
		body: data && JSON.stringify(data)
	}).then((response) => response.json());
}

/**
 * Performs a port request with JSON data
 * @private
 * @param {ApplePayEvent} eventObj - Apple Pay event object
 * @returns {Object} - promise with response data
 */
function convertApplePayEventObject(eventObj) {
	const filteredEvent = {};

	for (const prop in eventObj) {
		if (!Event.prototype.hasOwnProperty(prop)) {
			filteredEvent[prop] = eventObj[prop];
		}
	}

	return filteredEvent;
}

export default ApplePayDefaultHandler;
