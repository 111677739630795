/* global define */
define('app.components.global.scrollToTop', function(require, exports) {
	const $ = require('$');
	const $cache = {
		elements: {
			footer: document.querySelector('.js-footer_container')
		}
	};
	const $window = require('$win');
	const $document = require('$doc');
	const hiddenClass = require('device').isMobileView() ? 'h-hidden' : 'h-opaque';
	const scrollToTopScrollButtonTransformClass = 'js-scroll_to_top_transform';
	const isFullPageMobile = !app.util.getConfig('fullpageMobile.disable');
	let scrollScreensToShowMobile = !isFullPageMobile ? 1.5 : 1;

	const getOffset = (option) => {
		const calculationOptions = {
			default: screen.availHeight / 4,
			defaultMobile: () => {
				const headerHeight = !isFullPageMobile && $cache.content.offset() ? parseInt($cache.content.offset().top) : 0;
				return $window.height() * scrollScreensToShowMobile - headerHeight;
			},
			footer: $cache.elements.footer ? $cache.elements.footer.getBoundingClientRect().top - screen.availHeight + window.pageYOffset : 0
		};

		const defaultKey = (require('device').isMobileView() ? 'defaultMobile' : 'default');
		const calculationKey = option in calculationOptions ? option : defaultKey;

		if (typeof calculationOptions[calculationKey] === 'function') {
			return calculationOptions[calculationKey]();
		} else {
			return calculationOptions[calculationKey];
		}
	};

	const hideButton = require('device').isMobileView() ? () => $cache.scrollToTopButton.addClass(hiddenClass) : () => $cache.scrollToTopButton.removeClass(hiddenClass);
	const showButton = require('device').isMobileView() ? () => $cache.scrollToTopButton.removeClass(hiddenClass) : () => $cache.scrollToTopButton.addClass(hiddenClass);

	function initializeCache() {
		$cache.scrollToTopButton = require('device').isMobileView() ? $('.js-scroll_to_top_mobile') : $('.js-scroll_to_top');
		$cache.content = $('.js-search_result-content');
		$cache.content = $cache.content.length ? $cache.content : $('main');
	}

	function initializeEvents(config) {
		let bodyPadding = 0;

		if (require('device').isMobileView()) {
			if (!config.offset && config.scrollScreensToShowMobile) {
				scrollScreensToShowMobile = config.scrollScreensToShowMobile;
			}

			$window.on('fullpage.scroll.transform', function(e, v){
				if ((v.sectionIndex + 1) * $window.innerHeight() > offset && stateHidden) {
					showButton();
					stateHidden = false;
				}
				else if ((v.sectionIndex + 1) * $window.innerHeight() <= offset && !stateHidden) {
					hideButton();
					stateHidden = true;
				}
				$cache.scrollToTopButton.addClass(scrollToTopScrollButtonTransformClass);

				$window.one('scroll', function() {
					$cache.scrollToTopButton.removeClass(scrollToTopScrollButtonTransformClass);
				});
			});
		} else {
			$document.on('body-padding-updated', (e, data) => {
				bodyPadding = data.paddingTop || 0;
				offset = getOffset(config.offset) + bodyPadding;
			});
		}

		let stateHidden = true;
		let offset = getOffset(config.offset);
		$document.on('grid-update', () => { offset = getOffset(config.offset) + bodyPadding; });
		$window.on('load resize', () => { offset = getOffset(config.offset) + bodyPadding; });

		$window.on('scroll', function() {
			if ($document.scrollTop() > offset && stateHidden) {
				showButton();
				stateHidden = false;
			}
			else if ($document.scrollTop() < offset && !stateHidden) {
				hideButton();
				stateHidden = true;
			}
		});

		$cache.scrollToTopButton.on('click', scrollToTop);
	}
	
	function scrollToTop() {
		if ($(this).hasClass(scrollToTopScrollButtonTransformClass)) {
			$.fn.fullpage.moveTo(1);
		} else {
			$('html, body').animate({ scrollTop: 0 }, 500);
		}
		$document.trigger('scrolled.totop');
	}

	exports.init = function (config) {
		initializeCache();
		if ($cache.scrollToTopButton.length === 0) {
			return;
		}

		initializeEvents(config);
	};
});
