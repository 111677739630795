(function (app, $) {
	var $cache = {},
		configs = {};

	function initializeCache() {
		$cache = {
			document: $(document),
			submitSel:'.js-send-cart_submit',
			jsHideClassName: 'h-hidden',
			emailBasketContent: '.js-email-basket-content',
			emailBasketIcon: '.js-email-basket-icon',
			openFormButton: '.js-sendcart_button',
			sendEmailFormSel: '.js-send-cart-by-email-form'
		};
	};

	function initializeConfigs(params) {
		if (params) {
			configs = $.extend(true, {}, configs, params);
		}
	}

	function initializeEvents() {
		$cache.document.on('submit', $cache.sendEmailFormSel, function (e){
			e.preventDefault();
			var form = $(e.target);
			var url = app.urls.sendCartByEmail;
			url = app.util.appendParamsToUrl(form.attr('action'), {format: 'ajax'});
			var data = form.serialize();
			$.ajax({
				url: url,
				type: 'POST',
				data: data
			})
				.done(function(data) {
					app.fancybox.open({
						content: data,
						type: 'html'
					});
				})
				
				.fail(function() {
					location.href = location.href;
				});
			
			return false;
		});
		
		if (app.device.isMobileUserAgent() || app.device.isTabletUserAgent()) {
			$cache.document.on('click', $cache.emailBasketIcon, function() {
				app.fancybox.open($($cache.emailBasketIcon), {
					content: $($cache.emailBasketContent).html(),
					wrapCSS: 'fancybox-sendcart-tooltip',
					arrows: false
				});
			});
		} else {
			$cache.document.on('mouseenter mouseleave', $cache.emailBasketIcon, function(e) {
				$(this).siblings($cache.emailBasketContent).toggleClass($cache.jsHideClassName, e.type !== 'mouseenter');
			});
		};
		
		$cache.document.on('click', $cache.openFormButton, function (){
			var options = {
				type: 'ajax',
				wrapCSS : 'fancybox-sendcart',
				afterShow: function() {
					app.validator.init();
					app.util.limitCharacters();
				}
			};

			if ((configs !== undefined) && !jQuery.isEmptyObject(configs)) {
				$.extend(options, configs);
			}
			app.fancybox.open(app.urls.sendCartByEmail, options);
		});
		
	};

	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.sendcartbyemail = {
		init : function (params) {
			initializeConfigs(params);
			initializeCache();
			initializeEvents();
		}
	};
	
})(window.app = window.app || {}, jQuery);