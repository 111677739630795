/**
 * @class app.storelocator
 * @description Loads gift certificate details
 */
(function (app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
				container : $( '.js-storelocator' ),
				list : $( '.js-storelocator_list' )
		};
	}

	function initializeEvents() {
		$cache.container.on("click", ".js-widget_mapOnClick", function(e){
			var $this = $(this);
			if(!$this.data("loaded")) {
				var url = app.util.appendParamToURL(app.util.appendParamToURL(app.preferences.googleMapIframeUrl, "q", $(this).data('address')), "h1", $.cookie('language') ? $.cookie('language').substring(0,2) : 'en');
				$this.closest(".js-storelocator-item").find(".js-storelocator_image").addClass("h-hidden");
				$this.closest(".js-storelocator-item").find(".js-widget_map_iframe").append($('<iframe width="330" scrolling="no" height="228" frameborder="0" marginheight="0" marginwidth="0" src="' + url + '">'))
				$this.data("loaded", true)
				$this.text(app.resources.HIDE_MAP);
			}
			else{
				var iframe = $this.closest(".js-storelocator-item").find("iframe");
				var image = $this.closest(".js-storelocator-item").find(".js-storelocator_image");
				if(iframe.hasClass("h-hidden")) {
					iframe.removeClass("h-hidden");
					image.addClass("h-hidden");
					$this.text(app.resources.HIDE_MAP);
				}
				else {
					iframe.addClass("h-hidden");
					image.removeClass("h-hidden");
					$this.text(app.resources.SHOW_MAP);
				}
			}
		});
		$cache.container.on("click",".js-storelocator_image", function(){
			var $showMapLink = $(this).parents(".js-storelocator-item").find(".js-widget_mapOnClick");
			$showMapLink.trigger("click");
		});
		
		$cache.list.on( 'click', '.js-add_store_to_favorite', function( e ){
			e.preventDefault();
			
			var $this = $( this ),
				url = app.util.appendParamToURL( $this.attr( 'href' ), 'storeID', $this.data( 'id' ) ),
				dialog = app.dialog.create({
					options : {position:{my: "center center",at: "center center",of: window}}
				});
				
			app.ajax.load({
				url : url,
				target : dialog,
				callback : function() {
					dialog.dialog( 'open' );
				}
			});
		});
	}

	app.storelocator = {
		init : function(){
			initializeCache();
			initializeEvents();
		}
	};
}(window.app = window.app || {}, jQuery));