/**
 * @class app.product.video
 */
(function(app, $) {
	var $cache;
	var iframeDefaults = {
		class: 'js-product-vimeo b-product-vimeo-iframe',
		frameborder: 0,
		width: '100%',
		height: '100%'
	};
	var videoDefaults = {
		autoplay: 1,
		background: 1
	};
	var playEventToListen = app.device.browser().safari && app.device.isIOS() ? 'bufferend' : 'play';

	function initializeCache(params) {
		$cache = {
			document : $(document),
			window: $(window),
			main: $('main'),
			pdpMainSel : ".js-pdp_main",
			thumbnailsSel : ".js-thumbnails",
			thumbnailSel : ".js-thumbnail",
			productTileSel: '.js-product_tile',
			loadingSpinnerSel: '.js-loading-spinner',
			thumbnailSelectedClass : "b-product_thumbnail-selected",
			thumbnailsSliderSel : ".js-thumbnails_slider",
			mobileThumbnailsSel: '.js-mobile_thumbnails_slider',
			imageContainerSel: ".js-container_main_image",
			imagePrimarySel : ".js-product_primary_image",
			imagesContainerSel : '.js-product_images_container',
			imagesContainerForZoomSel : '.js-product_images_container-zoom',
			thumbnailVideoSel : ".js-video_product_thumbnail",
			thumbnailVideoLabelSel: '.js-label_product_thumbnail',
			primaryVideoClass: 'js-primary_video',
			primaryVideoSel: '.js-primary_video',
			arrowsSel: '.js-arrow_up, .js-arrow_down',
			vimeoThumbnailSel: '.js-thumbnail-vimeo',
			videoContainer: $('.js-product-video'),
			iframeCls: 'js-product-vimeo b-product-vimeo-iframe',
			playIconSel: '.js-play-icon',
			updateThumbnail: null,
			simpleVimeoPlayerContainer: $('.js-vimeo-player'),
			simpleVimeoPlayerContainerSelector: '.js-vimeo-player',
			simpleVimeoContainerSelector: '.js-vimeo-container',
			vimeoLoadedClass: 'm-loaded',
			imeddiateVimeoVideoContainerSel: '.js-show_vimeo_immediately',
			loadedContainerClass: 'b-vimeo-loaded',
			playVideoButton: $('.js-play_video'),
			playVideoContainer: $('.js-video-play-container'),
			noImmediateSel: '.js-no-immediate',
			hiddenClass: 'h-hidden',
			videoClass: 'm-video',
			carouselWrapperSel: '.js-owl_carousel',
			productMainCarouselItemSel: '.b-owl_carousel-item'
		};

		$cache.params = $.extend({}, videoDefaults);
		if(app.resources.PDP_VIDEO_PROPS !== null){
			try{
				$cache.params = $.extend({}, videoDefaults, JSON.parse(app.resources.PDP_VIDEO_PROPS));
			}
			catch(e) {
				console.warn("Cannot parse JSON of PDP_VIDEO_PROPS", e);
			}
		}
		$cache.queryString = "?" + $.param($cache.params);

		if ((params !== undefined) && !jQuery.isEmptyObject(params)){
			$cache.params = $.extend($cache.params, params);
		}

		$cache.pdpMain = $($cache.pdpMainSel);
		$cache.thumbnails = $($cache.thumbnailsSel);
		$cache.thumbnailsSlider = $($cache.thumbnailsSliderSel);
		$cache.mobileThumbnails = $($cache.mobileThumbnailsSel);
		$cache.imagePrimary = $($cache.imagePrimarySel);
		$cache.imagesContainer = $($cache.imagesContainerSel);
		$cache.imagesContainerForZoom = $($cache.imagesContainerForZoomSel);
		$cache.primaryImgSel = $cache.imagesContainer.data("primaryimage-sel") || ".js-primary_image";
		$cache.player = {};
	}

	function initializeDom() {
		initThumbnailsSlider($cache.thumbnailsSlider);
		initImmediateVideosContainers();
		initPlayVideoContainer();
		initPdpThumbnails();
		initThumbnails();
	}

	function initPdpThumbnails() {
		var videoIDs = [];
		var j = 0;
		$.each($($cache.thumbnailVideoSel), function(i, thumb){
			if (i % 7 === 0) {
				j++;
				videoIDs[j] = [];
			}
			if ($(thumb).data('video')) {
				if (videoIDs[j].indexOf($(thumb).data('video')) === -1 ) {
					videoIDs[j].push($(thumb).data('video'));
				}
			} else {
				$(thumb).parent().remove();
			}
		});

		var promises = [];
		for (var k = 1; k <= j; k++) {
			var promise = $.ajax(app.urls.productVideos + '?videoID=' + videoIDs[k].join(',')).done(function(result) {
				$.each(result, function(i, thumb) {
					var $this = $($cache.thumbnailVideoSel + '[data-video=' + i + ']');
					var $thumbnailTile = $this.parent();
					var $label = $thumbnailTile.find($cache.thumbnailVideoLabelSel);
					if (thumb) {
						$this.attr('src', thumb);
						$thumbnailTile.addClass($cache.videoClass);
						$label.removeClass($cache.hiddenClass);
						$label.height($thumbnailTile.height()).css('top', -$thumbnailTile.height() + 'px');
					} else {
						$thumbnailTile.remove();
					}
				});
			});
			promises.push(promise);
		}

		$.when.apply($, promises).done(function() {
			$cache.document.trigger('thumbnail.srcs.loaded.replaceImages');
		});
	}

	function initializeEvents() {
		$cache.videoContainer.on('click', function() {
			var that = $(this);
			var image = that.find($cache.vimeoThumbnailSel);
			var playIcon = that.find($cache.playIconSel);

			var frame = $('<iframe></iframe>',
				{
					'class': $cache.iframeCls,
					'frameborder': 0,
					'id': 'video-frame-'+image.data('vimeo-id'),
					'src': 'https://player.vimeo.com/video/'+image.data('vimeo-id') + $cache.queryString,
					'width': $cache.videoContainer.width(),
					'allowfullscreen': true
				}
			);

			$cache.videoContainer.off('click');
			$cache.videoContainer.prepend(frame);
			image.addClass($cache.hiddenClass);
			playIcon.addClass($cache.hiddenClass);
		});

		$cache.window.on('resize', function(){
			if ($($cache.primaryVideoSel).length === 0) {
				return false;
			}
			var $container = $cache.pdpMain.find($cache.imageContainerSel);
			$($cache.primaryVideoSel).css({'height': $container.height()});
		});

		$cache.document.on('product.thumbnail.change', function(){
			$($cache.imagePrimarySel).find($cache.primaryVideoSel).remove();
		});

		$cache.imagesContainerForZoom.on('images.container.cloned', function(event, params){
			var clone = params.clone;
			clone.find('.js-video_product_thumbnail').parent().remove();
			clone.find($cache.arrowsSel).remove();
		});

		if (app.util.getConfig('product.pdp.vimeo.createNewOnThumbClick')) {
			$($cache.thumbnailVideoSel).parent().off('click').on('click', function(e) {
				e.stopPropagation();
				var $this = $(this).find($cache.thumbnailVideoSel);
				var dataVideo = $this.data('video');
				var $container = $cache.pdpMain.find($cache.imageContainerSel);
				var $selected = $this.parents($cache.thumbnailsSel).find($cache.thumbnailSel + '.' + $cache.thumbnailSelectedClass);

				$cache.document.trigger('product.thumbnail.change', { currentIndex: $this.data('thumbnailindex'), isZoomed: $this.closest('.js-zoomed').length });

				$container.css('position', 'relative');

				const configObjVideo = {
					class: 'b-product_video ' + $cache.primaryVideoClass,
					frameborder: 0,
					id: $cache.primaryVideoClass,
					src: 'https://player.vimeo.com/video/' + dataVideo + '?autoplay=1&player_id=' + $cache.primaryVideoClass,
					height: $container.height(),
					title: app.resources.PRODUCT_VIDEO_TITLE,
					'aria-label': app.resources.PRODUCT_VIDEO_TITLE
				};

				if (app.configs.product.pdp && app.configs.product.pdp.vimeo && app.configs.product.pdp.vimeo.carouselItemVideo) {
					configObjVideo.src = 'https://player.vimeo.com/video/' + dataVideo + '?' + new URLSearchParams(app.configs.product.pdp.vimeo.carouselItemVideo).toString() + '&player_id=' + $cache.primaryVideoClass;
				}

				var frame = $('<iframe></iframe>', configObjVideo);

				$container.find($cache.primaryImgSel).after(frame);
				$this.parents($cache.thumbnailsSel).find($cache.thumbnailSel).removeClass($cache.thumbnailSelectedClass);
				$this.parent($cache.thumbnailSel).addClass($cache.thumbnailSelectedClass);

				if ($container.find($cache.primaryVideoSel).length === 0) return false;

				var player = new Vimeo.Player($container.find($cache.primaryVideoSel)[0]);
				player.setVolume(0);

				player.on('ended', function() {
					$container.find($cache.primaryVideoSel).remove();
					$this.parents($cache.thumbnailsSel).find($cache.thumbnailSel).removeClass($cache.thumbnailSelectedClass);
					$selected.addClass($cache.thumbnailSelectedClass);
				});
			});
		}

		$cache.document.on('changeVariation.changed', function() {
			initImmediateVideosContainers();
			initPlayVideoContainer();
			initThumbnails();
			initPdpThumbnails();
		});

		$cache.document.on('recommendations.loaded last.visited.loaded', function(e, container) {
			var $recommendationContainer = $(container);
			initThumbnails({
				thumbnails: $recommendationContainer.find($cache.vimeoThumbnailSel)
			});

			initImmediateVideosContainers({
				videosContainers: $recommendationContainer
			});
		});

		$cache.document.on('init.vimeo.thumbnails', function(e, params) {
			initThumbnails(params);
		});

		$cache.document.on('refinements-update', function() {
			var $mainThumbnail = $cache.main.find($cache.vimeoThumbnailSel);
			initThumbnails({
				thumbnails: $mainThumbnail
			});
			initImmediateVideosContainers({
				videosContainers: $cache.main
			});
		});

		$cache.document.on('grid-update', function(e, param) {
			if (param && param.container) {
				var $containerThumbnail = param.container.find($cache.vimeoThumbnailSel);
				initThumbnails({
					thumbnails: $containerThumbnail
				});
				initImmediateVideosContainers({
					videosContainers: param.container
				});
			}
		});

		$($cache.imagesContainerSel).on('owl.afterInit', function(e, params) {
			if (params && params.$element) {
				initThumbnails({
					thumbnails: params.$element.find($cache.vimeoThumbnailSel)
				});
			}
		});

		$cache.document.on('changed.owl.carousel', function(e) {
			const currentSlide = $(e.target).find($cache.productMainCarouselItemSel).eq(e.item.index).find($cache.simpleVimeoContainerSelector);

			if (currentSlide.length && !currentSlide.data('vimeo-initialized')) {
				app.components.product.video.initImmediateVideosContainer.call(currentSlide.get(0));
				currentSlide.data('vimeo-initialized', true);
			}
		});
	}

	function simpleVimeoInit(thumbnail, options) {
		var vimeoPlayer = getPlayer({
			id: thumbnail.data('vimeo-video-id'),
			queryParams: {
				autoplay: options && options.autoplay ? 1 : 0,
				autopause: options && options.autopause ? 1 : 0,
				muted: options && options.muted ? 1 : 0,
				loop: options && options.loop ? 1 : 0,
				background: options && options.background ? 1 : 0
			},
			iframe: {
				allow: 'autoplay'
			}
		});

		var loader = thumbnail.closest($cache.productTileSel).find($cache.loadingSpinnerSel);
		var simpleVimeoPlayerContainer = thumbnail.closest($cache.simpleVimeoContainerSelector).find($cache.simpleVimeoPlayerContainerSelector);
		var simpleVimeoContainer = simpleVimeoPlayerContainer.closest($cache.simpleVimeoContainerSelector);

		function triggerVimeoResize() {
			//Fix to trigger Vimeo resize and fit to its own container
			simpleVimeoContainer.css('width', '');
			simpleVimeoContainer.css('height', Math.ceil(simpleVimeoContainer.outerHeight()));
			simpleVimeoContainer.css('width', Math.ceil(simpleVimeoContainer.outerWidth()));
			simpleVimeoContainer.css('height', '');
		}

		vimeoPlayer.on('ended', function () {
			simpleVimeoPlayerContainer.html('');
			simpleVimeoPlayerContainer.removeClass($cache.vimeoLoadedClass);
			thumbnail.removeClass($cache.vimeoLoadedClass);
			loader.removeClass($cache.vimeoLoadedClass);
		});

		vimeoPlayer.on(playEventToListen, function () {
			simpleVimeoPlayerContainer.addClass($cache.vimeoLoadedClass);
			thumbnail.addClass($cache.vimeoLoadedClass);
			loader.addClass($cache.vimeoLoadedClass);
			triggerVimeoResize();
		});

		simpleVimeoPlayerContainer.append(vimeoPlayer.element);

		$cache.window.on('resize', function () {
			triggerVimeoResize();
		});
	}

	function initThumbnailsSlider(element) {
		element.thumbnailsSlider({
			itemCount : app.preferences.pdpThumbnailsSliderCount,
			arrowUpClass : 'b-product_thumbnails-arrow_up',
			arrowDownClass : 'b-product_thumbnails-arrow_down'
		});
		if ($($cache.arrowsSel).length > 0)
			$cache.thumbnails.css('margin-bottom', 0);
		element.on('height_changed', function(){
			$.each($($cache.thumbnailVideoSel), function(){
				var label = $(this).parent().find($cache.thumbnailVideoLabelSel);
				$(label).height($(this).parent().height()).css('top', -$(this).parent().height() + 'px');
			});
		});
	}

	function initPlayVideoContainer() {
		if (!app.configs.product.showVideoButton) {
			$cache.playVideoButton.addClass($cache.hiddenClass);

			return false;
		}

		if (app.configs.product.pdp && app.configs.product.pdp.videoPlayButton) {
			if ($cache.playVideoContainer.length) {
				$cache.playVideoButton.removeClass($cache.hiddenClass);

				$cache.playVideoButton.one('click', function() {
					var $video = $cache.playVideoContainer.find($cache.simpleVimeoPlayerContainerSelector);

					if (!$video.data('vimeo-initialized')) {
						var vimeoPlayer = initImmediateVideo.call($video, $.extend(videoDefaults, app.configs.product.pdp.videoPlayButtonOptions.video), $.extend(iframeDefaults, app.configs.product.pdp.videoPlayButtonOptions.iframe));

						$video.data('vimeo-initialized', true);
						$cache.playVideoContainer.removeClass($cache.hiddenClass);
						vimeoPlayer.play();
					}
				});
			}
		}
	}

	function initImmediateVideosContainers(params) {
		params = params || {};
		var $videosContainers = params.videosContainers || $($cache.imeddiateVimeoVideoContainerSel);
		$videosContainers.each(initImmediateVideosContainer);
	}

	function initImmediateVideosContainer() {
		var $videosContainer = $(this);
		var videoSettings = $videosContainer.data('vimeo');

		var $videos = $videosContainer.find($cache.simpleVimeoPlayerContainerSelector).not($cache.noImmediateSel);
		$videos.each(function() {
			initImmediateVideo.call(this, videoSettings);
		});
	}

	function initImmediateVideo(videoSettings, iframeParams) {
		iframeParams = iframeParams || {
			allow: 'autoplay'
		};

		var $playerContainer = $(this);
		var vimeoPlayer = getPlayer({
			id: $playerContainer.data('vimeo-video-id'),
			queryParams: videoSettings,
			iframe: iframeParams
		});

		vimeoPlayer.ready().then(function() {
			$(vimeoPlayer.element)
				.closest($cache.simpleVimeoContainerSelector)
				.addClass($cache.loadedContainerClass);
		});

		if (app.configs.product.pdp && app.configs.product.pdp.videoPlayButton && $playerContainer.closest($cache.playVideoContainerSel).length) {
			vimeoPlayer.on('fullscreenchange', function(e) {
				if (e.fullscreen === false) {
					$cache.playVideoContainer.addClass($cache.hiddenClass);
					vimeoPlayer.pause();
				}
			});

			$cache.playVideoButton.off('click').on('click', function() {
				$cache.playVideoContainer.removeClass($cache.hiddenClass);
				vimeoPlayer.setCurrentTime(0);
				vimeoPlayer.play();
			});
		} else {
			vimeoPlayer.on('pause', function() {
				if (app.device.isIOS()) {
					vimeoPlayer.play();
				}
			});
		}

		$playerContainer.append(vimeoPlayer.element);

		return vimeoPlayer;
	}

	function initThumbnails(params) {
		params = params || {};
		var $thumbnails = params.thumbnails ? params.thumbnails : $($cache.vimeoThumbnailSel);

		$thumbnails.each(function() {
			var thumbnail = $(this);

			var vimeoID = thumbnail.data('vimeo-video-id');
			var vimeoSize = thumbnail.data('vimeo-size');

			getThumbnail(vimeoID, vimeoSize).done(function(src) {
				if (params.dataProp) {
					thumbnail.data(params.dataProp, src);
				} else {
					thumbnail.prop('src', src);
				}
			});

			if ($cache.simpleVimeoPlayerContainer.length) {
				thumbnail.click(function() {
					if (!thumbnail.hasClass($cache.vimeoLoadedClass)) {
						var config = {
							muted: 1,
							autoplay: 1
						};

						simpleVimeoInit(thumbnail, config);
					}
				});
			}
		});
	}

	function getPlayer(parameters) {
		var queryParams = parameters.queryParams || {};
		var queryString = "?" + $.param($.extend({}, videoDefaults, queryParams));
		var iframeExtendedParams = parameters.iframe || {};
		var iframeParams = $.extend({
			id: 'video-frame-' + parameters.id,
			src: 'https://player.vimeo.com/video/' + parameters.id + queryString
		}, iframeDefaults, iframeExtendedParams);
		var iframe = $('<iframe>', iframeParams);
		
		return new Vimeo.Player(iframe);
	}
	
	function getThumbnail(id, size) {
		var deferred = $.Deferred();
		
		$.get('https://vimeo.com/api/v2/video/' + id + '.json', function(response) {
			var vimeoData = response[0];
			var src = null;
			
			switch(size) {
				case 'small':
					src = vimeoData.thumbnail_small;
					break;
				case 'medium':
					src = vimeoData.thumbnail_medium;
					break;
				case 'large':
					src = vimeoData.thumbnail_large;
					break;
				default:
					if(size && vimeoData.thumbnail_medium) {
						src = vimeoData.thumbnail_medium.split('_')[0] + '_' + size;
					} else {
						src = vimeoData.thumbnail_large;
					}
					break;
			}

			deferred.resolve(src);
		});
		
		return deferred.promise();
	}
	
	function getInstance($element) {
		return new Vimeo.Player($element);
	}

	app.components = app.components || {};
	app.components.product = app.components.product || {};
	app.components.product.video = {
		init : function(params) {
			if (!app.isMobileView() || "mobileEnabled" in params && params.mobileEnabled === true) {
				initializeCache(params);
				initializeDom();
				initializeEvents();
			}
		},
		simpleVimeoInit: simpleVimeoInit,
		getPlayer: getPlayer,
		getThumbnail: getThumbnail,
		getInstance: getInstance,
		initImmediateVideosContainer: initImmediateVideosContainer
	};

}(window.app = window.app || {}, jQuery));
