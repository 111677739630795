/* 
 * app.components.global.placeholder
 * Global component for placeholder attribute fallback
 */
(function (app, $) {

	var $cache = {};

	function initializeCache() {
		$cache.document = $(document);
	}

	function initializeEvents() {
		initPlaceholders();
	}
	
	function initPlaceholders() {
		if ($.fn.PlaceholderFallback) {
			$.valHooks['text'] = $.extend($.valHooks['text'] || {}, {
				set : function (element, value) {
					element.value = value;
					$(element).trigger("change");
				}
			});
			
			$('input[placeholder], textarea[placeholder]').PlaceholderFallback();
		}
	}
	
	/*************** app.components.global.placeholder public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.placeholder = {
		init : function (params) {
			if ('placeholder' in document.createElement('input')) return;
			initializeCache();
			initializeEvents();
		}
	};
}(window.app = window.app || {}, jQuery));