/*
jQuery Credit Card Validator Custom Edition

*/


(function() {
	var $,
		__indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };
	
	$ = jQuery;
	
	$.fn.validateCreditCard = function(callback, options) {
		var defaults, card, card_type, card_types, get_card_type, is_valid_length, is_valid_luhn, set_max_length, set_caret_position,
			normalize, format, validate, validate_number, startPos, _i, _len, _ref, _ref1, regexpmask;
		regexpmask = /\*{10,21}\d{4}/gi;
		defaults = {
			accept : null,
			formatting : false
		};
		card_types = [
			{
				name: 'amex',
				pattern: /^3[47]/,
				valid_length: [15],
				format: [4, 10]
			}, {
				name: 'diners_club_carte_blanche',
				pattern: /^30[0-5]/,
				valid_length: [14],
				format: [4, 10]
			}, {
				name: 'diners_club_international',
				pattern: /^36/,
				valid_length: [14],
				format: [4, 10]
			}, {
				name: 'jcb',
				pattern: /^35(2[89]|[3-8][0-9])/,
				valid_length: [16],
				format: [4, 8, 12]
			}, {
				name: 'laser',
				pattern: /^(6304|670[69]|6771)/,
				valid_length: [16, 17, 18, 19],
				format: [4, 8, 12, 16]
			}, {
				name: 'visa_electron',
				pattern: /^(4026|417500|4508|4844|491(3|7))/,
				valid_length: [16],
				format: [4, 8, 12]
			}, {
				name: 'visa',
				pattern: /^4/,
				valid_length: [16],
				format: [4, 8, 12]
			}, {
				name: 'master',
				pattern: /^((5[1-5])|(222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720))/,
				valid_length: [16],
				format: [4, 8, 12]
			}, {
				name: 'maestro',
				pattern: /^(5018|5020|5038|6304|6759|5641|676[1-3])/,
				valid_length: [12, 13, 14, 15, 16, 17, 18, 19],
				format: [4, 8, 12, 16]
			}, {
				name: 'discover',
				pattern: /^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/,
				valid_length: [16],
				format: [4, 8, 12]
			}
		];
		options = $.extend(defaults, options);
		if ((_ref = options.accept) == null) {
			options.accept = (function() {
				var _i, _len, _results;
				_results = [];
				for (_i = 0, _len = card_types.length; _i < _len; _i++) {
					card = card_types[_i];
					_results.push(card.name);
				}
				return _results;
			})();
		}
		_ref1 = options.accept;
		for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
			card_type = _ref1[_i];
			if (__indexOf.call((function() {
				var _j, _len1, _results;
				_results = [];
				for (_j = 0, _len1 = card_types.length; _j < _len1; _j++) {
					card = card_types[_j];
					_results.push(card.name);
				}
				return _results;
			})(), card_type) < 0) {
				throw "Credit card type '" + card_type + "' is not supported";
			}
		}
		get_card_type = function(number) {
			var _j, _len1, _ref2;
			_ref2 = (function() {
				var _k, _len1, _ref2, _results;
				_results = [];
				for (_k = 0, _len1 = card_types.length; _k < _len1; _k++) {
					card = card_types[_k];
					if (_ref2 = card.name, __indexOf.call(options.accept, _ref2) >= 0) {
						_results.push(card);
					}
				}
				return _results;
			})();
			for (_j = 0, _len1 = _ref2.length; _j < _len1; _j++) {
				card_type = _ref2[_j];
				if (number.match(card_type.pattern)) {
					return card_type;
				}
			}
			return null;
		};
		is_valid_luhn = function(number) {
			var digit, n, sum, _j, _len1, _ref2;
			sum = 0;
			_ref2 = number.split('').reverse();
			for (n = _j = 0, _len1 = _ref2.length; _j < _len1; n = ++_j) {
				digit = _ref2[n];
				digit = +digit;
				if (n % 2) {
					digit *= 2;
					if (digit < 10) {
						sum += digit;
					} else {
						sum += digit - 9;
					}
				} else {
					sum += digit;
				}
			}
			return sum % 10 === 0;
		};
		is_valid_length = function(number, card_type) {
			var _ref2;
			return _ref2 = number.length, __indexOf.call(card_type.valid_length, _ref2) >= 0;
		};
		set_max_length =function(input, card_type){
			if (input && "setAttribute" in input){
				var maxlength = card_type.valid_length[card_type.valid_length.length-1] + card_type.format.length;
				input.setAttribute("maxlength", maxlength);
			}
		};
		set_caret_position = function(elem, caretPos){
			if(elem != null) {
				if(elem.createTextRange) {
					var range = elem.createTextRange();
					range.move('character', caretPos);
					range.select();
				} else {
					if(elem.selectionStart) {
						elem.focus();
						elem.setSelectionRange(caretPos, caretPos);
					} else {
						elem.focus();
					}
				}
			}
		};
		validate_number = function(number) {
			var length_valid, luhn_valid;
			card_type = get_card_type(number);
			luhn_valid = false;
			length_valid = false;
			if (card_type) {
				luhn_valid = is_valid_luhn(number);
				length_valid = is_valid_length(number, card_type);
				set_max_length(this, card_type);
			}
			format.call(this, number, card_type);
			return callback({
				card_type: card_type,
				luhn_valid: luhn_valid,
				length_valid: length_valid
			});
		};
		validate = function() {
			var number = $(this).val();
			if (regexpmask.test(number)){
				return {
					card_type: true,
					luhn_valid: true,
					length_valid: number.length
				};
			}
			number = normalize(number);
			return validate_number.call(this, number);
		};
		normalize = function(number) {
			return number.replace(/\D+/g, '');
		};
		format = function(number, card_type) {
			if ("tagName" in this){
				if (card_type && options.formatting) {
					var arr = [],
						card_format = card_type.format,
						format_str = number,
						inputLength = this.value.length,
						maxLength = this.getAttribute("maxlength"),
						i, len, start, end;
					for (var i = 0, len = card_format.length; i <= len; i++) {
						start = i ? card_format[i - 1] : 0;
						end = i === len && card_format[i] < len ? len : card_format[i];
						arr.push(number.substring(start, end));
						if (number.length <= card_format[i]){
							break;
						}
					}
					
					this.value = arr.join(" ").trim().substring(0, maxLength);
					if (this.value.length - inputLength == 1 && inputLength == startPos || 
							startPos != this.value.length && startPos - (i ? (i > card_format.length ? card_format.length-1 : i-1) : i) == card_format[i >= card_format.length ? card_format.length-1 : i-1] && this.value[startPos] == " "){
						startPos += 1;
					}
					set_caret_position(this, startPos);
				} else {
					this.value = number ? number : "";
				}
			}
		};
		this.on('keydown', function(e) {
			startPos = this.selectionStart;
			if(e.keyCode === 8 && this.value[startPos-1] == " "){
				set_caret_position(this, startPos-1);
			}
			if(e.keyCode === 46 && this.value[startPos] == " "){
				set_caret_position(this, startPos+1);
			}
		});
		this.on('keyup input', function(e) {
			startPos = this.selectionStart;
			if(!e.keyCode || e.keyCode !== 37 && e.keyCode !== 39 && e.keyCode !== 16){
				return validate.call(this);
			}
		});
		if (this.length !== 0) {
			validate.call(this);
		}
		return this;
	};

}).call(this);
