//app.components.account.navigation
(function(app, $) {
	var $cache = {};

	function initializeEvents() {
		if ($cache.navigation) {
			$cache.navigation.find($cache.linkClass).each(function(i, el) {
				if (
					$cache.currentUrl == $(this).attr('href') ||
					$cache.currentUrl.indexOf($(this).attr('href') + '?') != -1 ||
					$cache.currentUrl.indexOf($(this).attr('href') + '/') != -1
				) {
					$(this).addClass($cache.selectedClass);
				}
			});
		}
	}

	function initializeCache() {
		$cache = {
			navigation: $('.js-menu_account'),
			linkClass: '.js-menu_account-link',
			selectedClass: 'h-selected',
			currentUrl: window.location.href
		};
	}

	/**
	 * @namespace app.components.account.navigation public object
	 **/
	app.components = app.components || {};
	app.components.account = app.components.account || {};
	app.components.account.navigation = {
		init: function(params) {
			initializeCache();
			initializeEvents();
		}
	};
})((window.app = window.app || {}), jQuery);
