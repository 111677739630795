/**
 * @class app.components.global.producttile
 */
(function (app, $) {
	var $cache = {};
	var configs = {
		swatchesEvents : 'mouseenter',
		changeViewTypeEnabled : $('.js-change_viewtype-switcher').length > 0
	};

	function initializeConfigs(params) {
		if(params) {
			configs = $.extend(true, {}, configs, params);
		}
	}

	/**
	 * @function
	 * @description Initializes the DOM of the Product Detail Page
	 */
	function initializeDom() {
		if ($cache.tiles.length===0) { return; }
		$cache.tiles.each(function (idx) {$(this).data("idx",idx);});
		if ( app.preferences.productTileColorsCarousel ) {
			app.owlcarousel.initCarousel( $( '.b-swatches_color' ) );
		}
	}
	/**
	 * @private
	 * @function
	 * @description Initializes events on the product-tile for the following elements:<br/>
	 * <p>swatches</p>
	 * <p>thumbnails</p>
	 */
	function initializeEvents() {
		$cache.document.bind('producttiles.changed', function (e, data) {
			if (app.preferences.productTileColorsCarousel) {
				//rebuild color swatches carousels
				var carousel  = null,
					container = data && data.container;

				if(!container) {
					carousel = $($cache.swatchesSel);
				} else if(typeof container.find === 'function') {
					carousel = container.find($cache.swatchesSel);
				}

				if (!carousel) {
					return;
				}

				if(carousel.data('owlCarousel')){
					carousel.data('owlCarousel').destroy();
				}
				app.owlcarousel.initCarousel(carousel);
			}
		});

		$cache.document.on("mouseenter click", '.js-variations', function (e) {
			$(document).trigger('flyout.reload', {
				wrapper : $(this)
			});
		});

		$cache.document.on('viewType.changed', function(event, eventData){
			$cache.resultContent.find($cache.owlElemSelector).each(function() {
				var printSelector = $(this);
				var viewTypeImageUrl = printSelector.data(eventData.viewType);

				if(viewTypeImageUrl !== undefined) {
					var lgimg = printSelector.data('lgimg');
					lgimg.url = printSelector.data(eventData.viewType);
					printSelector.data('lgimg', lgimg);

					var thumb = printSelector.closest($cache.tilesSelector).find($cache.productTileImage).filter(":first");
					var data = thumb.data("current");

					if (data !== undefined) {
						data.src = thumb.data("viewtype-" + eventData.viewType);
						thumb.data("current", data);
						thumb.attr({
							src : data.src,
							alt : data.alt,
							title : data.title
						});
					}
				}

			});
		});

		$cache.document.on(configs.swatchesEvents, $cache.owlElemSelector, function(e) {
			var $that = $(this);
			var data = $that.data('lgimg');
			var keepHoveredDevice = configs.keepHoverTabletOnly ? app.device.isTabletUserAgent() : true;
			var tile = $that.closest($cache.tilesSelector);
			var vimeoContainer = tile.find($cache.vimeoMainContainerSel);
			var thumb = null;
			var isVimeoVideoCurrentlyMainTile = vimeoContainer.length > 0;

			data.srcSet = $that.data('srcset');

			if (isVimeoVideoCurrentlyMainTile) {
				if ($that.closest($cache.selectedColorSwatchSel).length === 0) {
					vimeoContainer.addClass($cache.vimeoSwatchHoverClass);
				}

				thumb = vimeoContainer.find($cache.vimeoThumbSelector);
			} else if (configs.changeViewTypeEnabled) {
				thumb = tile.find($cache.tileLinkActiveSel).find($cache.productTileImage);
			} else {
				thumb = tile.find($cache.productTileImage);
			}
			var current = thumb.data('current');
			if ($that.data('vimeoThumbnailSrc')) {
				data.url = $that.data('vimeoThumbnailSrc');
				thumb.closest($cache.mainContainerSel).addClass($cache.vimeoPreviewClass);
			}

			if (configs.keepHovered && keepHoveredDevice) {
				var quickviewurl = $that.data('quickviewurl');
				var altquickviewurl = $that.data('altquickviewurl');
				var producturl = $that.attr('href');
				if (quickviewurl) {
					tile.find($cache.quickviewSelector).data('url', quickviewurl);
				}
				if (producturl) {
					tile.find($cache.tilesLinkSelector).attr('href', producturl);
				}

				if (altquickviewurl) {
					tile.find($cache.tilesLinkSelector).data('altimage', altquickviewurl);
				}

				thumb.data('current', {
					src : data.url,
					alt : data.alt,
					title : data.title
				});
			}
			else if (!current) {
				// If this is the first time, then record the current img
				thumb.data('current', {
					src : thumb[0].src,
					alt : thumb[0].alt,
					title : thumb[0].title
				});
			}

			if (app.preferences.isTileAjaxUpdateEnabled) {
				// save current data
				var swatchItemSelected = tile.find('.js-swatches_color-item-selected a');
				if (swatchItemSelected.length && !$cache.tilesCache[swatchItemSelected.data('pid')]) {
					var productInfo = tile.find($cache.productInfoBlockSel).html();
					$cache.tilesCache[swatchItemSelected.data('pid')] = productInfo;
				}
				updateProductTileInfo(tile, $that)
					.done(function() {
						updateProductTileImage(thumb, data);
					});
			} else {
				updateProductTileImage(thumb, data);
			}

			var dataPid = $(this).data('pid');

			if(app.util.getConfig('product.showSizeOnVariationHover')) {
				tile.find($cache.sizeListsItem + ':not(' + '.' + $cache.hiddenClass + ')').addClass($cache.hiddenClass);
				tile.find('#' + dataPid).closest($cache.sizeListsItem).removeClass($cache.hiddenClass);
			}

			e.preventDefault();
		}).on("mouseleave", $cache.owlElemSelector, function(){
			// Restore current thumb image
			var thumb;
			var tile = $(this).closest(".js-product_tile");
			var loader = tile.find('.loader');
			var loaderIsVisible = false;
			if (loader.length) {
				loaderIsVisible = loader.is(":visible");
			}
			if (!loaderIsVisible) {
				var vimeoContainer = tile.find($cache.vimeoMainContainerSel);
				if (vimeoContainer.length > 0) {
					vimeoContainer.removeClass($cache.vimeoSwatchHoverClass);
					thumb = vimeoContainer.find($cache.vimeoThumbSelector);
				}
				if (configs.changeViewTypeEnabled) {
					thumb = tile.find($cache.tileLinkActiveSel).find($cache.productTileImage).filter(':first');
				} else {
					thumb = tile.find($cache.productTileImage).filter(':first');
				}
				var data = thumb.data("current");
				if (data !== undefined) {
					thumb.attr({
						src : data.src,
						alt : data.alt,
						title : data.title
					});

					var originalSrcSet = thumb.parent('picture').data($cache.originalSrcSetDataAttr);
					var source = thumb.siblings('source');

					if (source.length) {
						source.attr('srcset', originalSrcSet);
					}
				}
			}

			if (thumb) {
				thumb
					.closest($cache.mainContainerSel)
					.removeClass($cache.vimeoPreviewClass);
			}

			if (app.util.getConfig('product.showSizeOnVariationHover')) {
				tile.find($cache.sizeListsItem + ':not(.' + $cache.hiddenClass + ')').addClass($cache.hiddenClass);
				tile.find($cache.sizeListsItem + '.m-default').removeClass($cache.hiddenClass);
			}
		});

		if ( !app.device.isTouchDevice() ) {
			$cache.document.on('mouseenter', $cache.tilesSelector, function () {
				var tile = $(this),
					productColorsBlock = tile.find($cache.productColorsSel),
					pid = tile.data('pid');

				if (productColorsBlock.hasClass($cache.jsLoadedCls) || !pid || !productColorsBlock.length) {
					return;
				}

				app.progress.show(tile);
				app.ajax.load({
					'url' : app.util.appendParamsToUrl(app.urls.getProductColors, {'pid': pid}),
					'type' : 'GET',
					callback : function (data) {
						productColorsBlock.html(data);
						productColorsBlock.addClass($cache.jsLoadedCls);
						$cache.document.trigger('producttiles.changed', {
							container : tile
						});
					}
				});
			});
			$cache.main.on('mouseenter', $cache.productHoverBoxSel, function() {
				var $target = $(this).find($cache.productTileImage);
				if(!$target.hasClass($cache.lazyLoadHiddenCls) ){
					var $altImage = $target.closest($cache.productTileLink).attr('data-altimage');

					if ($altImage && $altImage.length && ($target.attr('src') !== $altImage)) {
						$cache.originImage = $target.attr('src');
						$target.attr('src', $altImage);

						var imageWrapper = $target.closest($cache.tilesLinkSelector);
						var altImageSrcSet = imageWrapper.data('altimagesrcset');
						var source = imageWrapper.find('source');

						if (source.length) {
							source.attr('srcset', altImageSrcSet);
						}
					}
				}
			}).on('mouseleave', $cache.productHoverBoxSel, function() {
				var $target = $(this).find($cache.productTileImage);

				if ($cache.originImage) {
					$target.attr('src', $cache.originImage);
					$cache.originImage = '';

					var imageWrapper = $target.closest($cache.imagePictureSel);
					var altImageSrcSet = imageWrapper.data($cache.originalSrcSetDataAttr);
					var source = imageWrapper.find('source');

					if (source.length) {
						source.attr('srcset', altImageSrcSet);
					}
				}

			});
		}

		$cache.document.on('click', $cache.variationLinkSel, function(e){
			var $this = $(this),
				variantsBlock = $this.closest($cache.variantsBlockSel),
				url = $this.attr('href');

			app.progress.show(variantsBlock);
			app.ajax.load({
				url : app.util.appendParamToURL(url, 'Quantity', 1),
				format : 'json',
				callback : function(data) {
					if (data && data.pid) {
						var item = $this.closest('li'),
							tile = $this.closest($cache.tilesSelector);

						tile = tile.length ? tile : $('[data-product-uuid="' + variantsBlock.data('tile-uuid') + '"]');
						tile.add(variantsBlock).find('[data-pid]').each(function(){
							$(this).data('pid', data.pid);
						});

						item.addClass($cache.sizeSelectedCls).siblings('li').removeClass($cache.sizeSelectedCls);
						variantsBlock.removeClass('error-variant_unchosen').addClass('variant_chosen').data('variant-chosen', true);
						app.progress.hide();
					}
				}
			});
			e.preventDefault();
		});

		$cache.document.on('click', $cache.addToCartSel, function(){
			var $this = $(this),
				postdata = {'Quantity': '1', 'cartAction': 'add', 'pid': $this.data('pid')},
				variantsBlock = $this.closest($cache.variantsBlockSel);

			if (variantsBlock.data('variant-chosen') === true) {
				app.cart.update(postdata, function(response) {
					$cache.document.trigger('minicart.show', { html: response });
				});
			} else {
				variantsBlock.addClass('error-variant_unchosen');
			}
		});
	}

	function updateProductTileImage(thumb, thumbData) {
		var pictureSib = thumb.siblings('source');
		// Set the tile image to the values provided on the swatch data attributes
		thumb.attr({
			src: thumbData.url,
			alt: thumbData.alt,
			title: thumbData.title
		});

		if (pictureSib.length) {
			pictureSib.attr('srcset', thumbData.url + ' 1x,' + thumbData.url + ' 2x');
		}
	}

	function updateProductTileInfo(tile, owlElement) {
		var deffered = $.Deferred();
		var pid = owlElement.data('pid');
		if (!pid || !tile) {
			return deffered.reject();
		}

		if ($cache.tilesCache[pid]) {
			tile.find($cache.productInfoBlockSel).html($cache.tilesCache[pid]);
			return deffered.resolve();
		}

		app.progress.show(tile);

		$.ajax({
			url : app.util.appendParamsToUrl(app.urls.GetProductTileData, {'pid': pid}),
			type : 'GET',
			dataType : 'html',
		})
		.done(function (data) {
			if(data) {
				$cache.tilesCache[pid] = data;
				tile.find($cache.productInfoBlockSel).html($cache.tilesCache[pid]);
				deffered.resolve();
			} else {
				deffered.reject();
			}
		})
		.always(function () {
			app.progress.hide();
		});
		return deffered;
	}

	/*************** app.components.producttile public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.producttile = {
		/**
		 * @function
		 * @description Cache, events and initialization
		 */
		init : function (params) {
			initializeConfigs(params);
			$cache = {
				tiles : $(".js-product_tile"),
				document: $(document),
				main: $("main"),
				owlElemSelector: ".js-product_tile .js-swatches .js-swatches_color-link",
				tilesSelector : ".js-product_tile",
				variantsBlockSel : '.js-product_tile-variations',
				variationLinkSel : '.js-product_tile-variations .js-swatches li:not(.js-unselectable) a',
				addToCartSel : '.js-product_tile-variations .js-add_to_cart_button',
				quickviewSelector : '.js-quickview',
				tilesLinkSelector : '.js-producttile_link',
				productHoverBoxSel : configs.productHoverBoxSel || '.js-product-hover_box',
				productTileImage : ".js-producttile_image",
				productTileLink : ".js-producttile_link",
				sizeSelectedCls : 'b-swatches_size-item-selected',
				lazyLoadHiddenCls: 'lazy-hidden',
				originImage : '',
				tilesCache : [],
				productInfoBlockSel : '.js-productinfo_block',
				productColorsSel : '.js-product-variations',
				swatchesSel : '.js-swatches',
				jsLoadedCls : 'js-loaded',
				sizeListsSelector : '.js-size_list-container',
				sizeListsItem : '.js-size_list-item',
				hiddenClass : 'h-hidden',
				resultContent : $('.js-search_result-content'),
				tileLinkActiveSel: '.js-producttile_link.m-active',
				vimeoMainContainerSel: '.js-image-container .js-vimeo-container',
				vimeoThumbSelector: '.js-thumbnail-vimeo',
				vimeoSwatchHoverClass: 'b-vimeo-swatch-hover',
				selectedColorSwatchSel: '.js-swatches_color-item-selected',
				mainContainerSel: '.js-image-container',
				vimeoPreviewClass: 'b-vimeo_hover_preview',
				originalSrcSetDataAttr: 'originalsrcset',
				imagePictureSel: '.js-product-image_picture'
			};

			initializeEvents();
			initializeDom();
		}
	};

}(window.app = window.app || {}, jQuery));
