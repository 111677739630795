//app.components.customerservice.newsletter
( function(app, $) {
	var $cache = {};

	function initializeEvents() {
		$(document).on('submit', $cache.newsletterFormSel, function(event) {
			event.preventDefault();
			submitNewsletterForm.call(this);
		});
	}

	function initializeDom() {
		$cache.csContent.addClass($cache.newsletterProfileClass);
		app.validator.init();
	}

	function initializeCache() {
		$cache = {
			csNamespace: 'customerservice',
			csContent: $('.js-cs-content'),
			newsletterProfileClass: 'b-newsletter_profile',
			newsletterFormSel: '.js-newsletter_subscription_form',
			newsletterPageWrapperSel: '.js-newsletter_wrapper',
			spinClass: 'm-spin_bar',
			window: $(window)
		};
	}

	function submitNewsletterForm() {
		var $this = $(this);
		var submitButton = $this.find('button');
		var data = $this.serialize();

		if ($this.valid()) {
			submit();
		}

		function submit() {
			submitButton.addClass($cache.spinClass).attr('disabled', true);
			data += '&' + submitButton.attr('name') + '=' + submitButton.attr('value');
			var url = $this.attr('action');
			$.ajax({
				url: url,
				type: 'POST',
				cache: false,
				dataType: 'html',
				data: data
			})
				.done(function(response) {
					$($cache.newsletterPageWrapperSel).replaceWith(response);
					$cache.window.scrollTop(0);
				});
		}
	}

	/*
	 * @namespace app.components.customerservice public object
	 */
	app.components = app.components || {};
	app.components.customerservice = app.components.customerservice || {};
	app.components.customerservice.newsletter = {
		init: function() {
			initializeCache();
			if (app.page.ns === $cache.csNamespace) {
				initializeDom();
				initializeEvents();
			}
		}
	};
}(window.app = window.app || {}, jQuery));
