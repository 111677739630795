(function(app, $) {
	var $cache = {};

	function initializeCache(params) {
		$cache.t, ($cache.l = new Date().getTime()), ($cache.scrolling = false);
		$cache.scrollingTimeOut = params.scrollingTimeOut ? params.scrollingTimeOut : 1000;
	}

	function initializeEvents() {
		$(window).scroll(function() {
			var now = new Date().getTime();

			if (now - $cache.l > 1000 && !$cache.scrolling) {
				$cache.scrolling = true;
				$(this).trigger('scrollStart');
				$cache.l = now;
			}

			clearTimeout($cache.t);

			$cache.t = setTimeout(function() {
				if ($cache.scrolling) {
					$cache.scrolling = false;
					$(window).trigger('scrollEnd');
				}
			}, $cache.scrollingTimeOut);
		});
	}

	/*************** app.components.global.toggler public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.scrollevents = {
		// initializations
		init: function(params) {
			initializeCache(params);
			initializeEvents();
		}
	};
})((window.app = window.app || {}), jQuery);
