/**
 * @class app.fancybox.modal
 */
(function(app, $) {
	var $cache,
		settings = {
			'attachCloseButton' : true,
			'closeButtonText' : 'Close',
			'closeButtonCssClass' : 'b-modal-btn close',
			'modalContainerCssClass' : 'b-modal-container',
			'modalTitleCssClass' : 'b-modal-title',
			'modalContentCssClass' : 'b-modal-content',
			'modalControlsCssClass' : 'b-modal-controls',
			'modalWrapperCssClass' : 'b-modal-wrapper',
			'additionalButtons' : []
		};

	/** ************* app.fancybox.modal private vars and functions ************** */

	/**
	 * @private
	 * @function
	 * @description Initializes the cache on the modal module.
	 */
	function initializeCache() {
		$cache = {
			settings : $.extend(settings),
			modalContainerDiv : $(document.createElement('div')),
			modalTitleDiv : $(document.createElement('div')),
			modalContentDiv : $(document.createElement('div')),
			modalControlsDiv : $(document.createElement('div')),
			modalCloseButton : $(document.createElement('button'))
		};
	}

	function resetContents(resetStyle){
		if(typeof resetCss === 'undefined'){
			resetStyle = false;
		}

		if(resetStyle){
			$cache.modalContainerDiv.removeClass().addClass(settings.modalContainerCssClass);
			$cache.modalTitleDiv.removeClass().addClass(settings.modalTitleCssClass);
			$cache.modalContentDiv.removeClass().addClass(settings.modalContentCssClass);
			$cache.modalControlsDiv.removeClass().addClass(settings.modalControlsCssClass);
			$cache.modalCloseButton.removeClass().addClass(settings.closeButtonCssClass);
		}

		$cache.settings.modalWrapperCssClass = settings.modalWrapperCssClass;
		$cache.settings.additionalButtons = [];
		$cache.modalContainerDiv.html("");
		$cache.modalTitleDiv.html("");
		$cache.modalContentDiv.html("");
		$cache.modalControlsDiv.html("");
		$cache.modalCloseButton.html("").on('click', function () { app.fancybox.close(); });
	}

	function loadSettings(newSettings){
		$cache.settings = $.extend({}, settings, newSettings);

		$cache.modalContainerDiv.removeClass().addClass($cache.settings.modalContainerCssClass);
		$cache.modalTitleDiv.removeClass().addClass($cache.settings.modalTitleCssClass);
		$cache.modalContentDiv.removeClass().addClass($cache.settings.modalContentCssClass);
		$cache.modalControlsDiv.removeClass().addClass($cache.settings.modalControlsCssClass);
		$cache.modalCloseButton.removeClass().addClass($cache.settings.closeButtonCssClass);
	}

	/**
	 * @private
	 * @function
	 * @description Opens the modal.
	 */
	function open(title, content, options) {
		if(typeof options !== 'undefined'){
			resetContents(false);
			loadSettings(options);
		}
		else{
			resetContents();
		}

		$cache.modalTitleDiv.append(title);
		$cache.modalContentDiv.append(content);

		if($cache.settings.attachCloseButton){
			$cache.modalControlsDiv.append($cache.modalCloseButton.text($cache.settings.closeButtonText));
		}

		$.each($cache.settings.additionalButtons, function (key, buttonConfig) {
			var newButton = $(document.createElement('button'));
			if(buttonConfig.hasOwnProperty('text')){
				newButton.text(buttonConfig.text);
			}
			if(buttonConfig.hasOwnProperty('cssClass')){
				newButton.addClass(buttonConfig.cssClass);
			}
			if(buttonConfig.hasOwnProperty('callbackFunction') && typeof buttonConfig.callbackFunction === 'function'){
				newButton.click(buttonConfig.callbackFunction);
			}

			$cache.modalControlsDiv.append(newButton);
		});

		$cache.modalContainerDiv.append($cache.modalTitleDiv);
		$cache.modalContainerDiv.append($cache.modalContentDiv);
		$cache.modalContainerDiv.append($cache.modalControlsDiv);

		app.fancybox.setAdditionalWrapperCssClass($cache.settings.modalWrapperCssClass).open($cache.modalContainerDiv);
	}

	/** ************* app.fancybox.modal public object ************** */
	
	if(app.hasOwnProperty('fancybox')){
		app.components = app.components || {};
		app.components.fancybox = app.components.fancybox || {};
		app.components.fancybox.modal = {
			init: function() {
				initializeCache();
			},
	
			loadSettings : loadSettings,
			
			open : open,
	
			close : app.fancybox.close
		};
	}
	else{
		console.warn('this components needs app.fancybox to work');
	}
})((window.app = window.app || {}), jQuery);
