/**
 * @class app.mediaplayer
 */

(function (app, $) {
	//Documentation can be find here:
	//https://github.com/mediaelement/mediaelement/blob/master/docs/api.md
	
	var $cache = {},
		configs = app.util.getConfig('mediaplayer', {});
	
	//Set optional parameters for success callback of MediaElementPlayer
	var callbackSettings = {
		autoplay: false,
		muted: false
	};
	
	var settings = {
		//The basic set of mediaplayer parameters
		//loop
		//Specifies that the video will start over again, every time it is finished
		loop: false,
		
		//videoVolume
		//Position of volume slider on video element
		videoVolume: 'vertical',
		
		//features
		//List of features to show in control bars
		features: ['playpause','progress','current','duration','tracks','volume','fullscreen'],
		
		//playText
		//Title for Play/Pause button for WARIA purposes when media is playing
		playText: null,
		
		//pauseOtherPlayers
		//When focused player starts, it will pause other players
		pauseOtherPlayers: false,
		
		//pauseText
		//Title for Play/Pause button for WARIA purposes when media is paused
		pauseText: null,
		
		//tracksText
		//Title for Closed Captioning button for WARIA purposes
		tracksText: null,
		
		//chaptersText
		//Title for Chapters button for WARIA purposes
		chaptersText: null,
		
		//muteText
		//Title for Mute button for WARIA purposes
		muteText: null,
		
		//unmuteText
		//Title for Mute button for WARIA purposes
		unmuteText: null,
		
		//allyVolumeControlText
		//Title for Volume slider for WARIA purposes
		allyVolumeControlText: null,
		
		//fullscreenText
		//Title for Fullscreen button for WARIA purposes
		fullscreenText: null,
		
		//success
		//Action(s) that will be executed as soon as the source is loaded
		success: function(mediaElement, domObject, instance){
			var videoDomNode = instance.$node,
				autoplay = videoDomNode.data('callbackSettings') && videoDomNode.data('callbackSettings').autoplay ? videoDomNode.data('callbackSettings').autoplay : callbackSettings.autoplay,
				muted = videoDomNode.data('callbackSettings') && videoDomNode.data('callbackSettings').muted ? videoDomNode.data('callbackSettings').muted : callbackSettings.muted;
			if(autoplay){
				mediaElement.addEventListener('canplay', function(){
					mediaElement.play();
				});
			}
			if(muted){
				mediaElement.setVolume(0);
			}
			videoDomNode.trigger('video.loaded');
		}
	};
	
	function initCache(options){
		$cache.playerWrapperSel = configs.wrapperSel || '.js-media_player';
	}

	/**
	 * Sets a content with configured media block
	 * @return undefined
	 **/
	
	function initMediaplayer(){
		var mediaElement = $(this),
			dataSettings = mediaElement.data('settings') ? mediaElement.data('settings') : {};
			
		var mediaSettings = $.extend({}, settings, dataSettings);
		if(typeof MediaElementPlayer !== "undefined"){
			new MediaElementPlayer(mediaElement, mediaSettings);
		}
	}

	/**
	 * Init all media players in page by the selector
	 * @return undefined
	 **/
	function initAllMedia(){
		$($cache.playerWrapperSel).each(initMediaplayer);
	}
	
	app.mediaplayer = {
		init: function(){
			initCache();
			initAllMedia();	
		},
		initMediaPlayer : function($player){
			initMediaplayer.call($player);
		}
	};
	
}(window.app = window.app || {}, jQuery));