//app.components.account.addresses
( function(app, $) {
		var $cache = {};
		
		function initializeEvents() {
			
			toggleFullOrder();
			$cache.storeCreditChecbox.on("change", function(){						
				$cache.returnCodFields.html($(this).prop("checked") ? "" : $cache.returnCodFieldsInner);				
			});
		}
		
		function initializeCache() {
			
			$cache = {
				orderItems :  $('.order-items'),
				storeCreditChecbox : $("[name=dwfrm_returnauth__create_storeCredit]"),
				returnCodFields : $(".js-return_cod_fields"),
				returnCodFieldsInner : $(".js-return_cod_fields").html()
			};
			
		}
		
		function initializeDom(){			
			if($cache.storeCreditChecbox.prop("checked")) {				
				$cache.returnCodFields.html("");
			}
		}
		
		/**
		 * @private
		 * @function
		 * @description Toggles the list of Orders
		 */
		function toggleFullOrder () {
			$cache.orderItems
				.find('li.hidden:first')
					.prev('li')
						.append('<a class="toggle">View All</a>')
						.children('.toggle')
							.click(function() {
								$(this).parent().siblings('li.hidden').show();
								$(this).remove();
							});
		}
		
		
		/**
		 * @namespace app.components.account.addresses public object
		 **/
		app.components = app.components || {};
		app.components.account = app.components.account || {};
		app.components.account.orderhistory = {
			init : function (params) {				
				
				initializeCache();
				initializeDom();
				initializeEvents();

			}
		};

	}(window.app = window.app || {}, jQuery));
