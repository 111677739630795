(function (app, $) {
	var inited = false,
		$cache = {},
		$document = $(document),
		warningConfigs = [
		    {
				"class" : ".js-warning_validation",
				"handler" : attemptAccessLimitHandler
			}
		]
		;
	
	function initializeCache() {

	}
	
	function hideWarning($element) {
		$element.removeClass('b-warning-light');
	}
	
	function showWarning($element) {
		$element.addClass('b-warning-light');
	}
	
	function isInvalidElement($element) {
		return $element.hasClass('f-state-error');
	}
	
	function constructWarningMessage(massageMask, addrMaxLength) {
		return massageMask.replace("{0}", addrMaxLength);
	}
	
	function setWarningMessage($element, warningMsg) {
		$element.find('.f-warning_text').text(warningMsg);
	}
	
	function initializeEvents() {
		for ( var i = 0; i < warningConfigs.length; i++ ) {
			var $inputs = $(warningConfigs[i]["class"]);
			if( $inputs.length ) {
				warningConfigs[i]["handler"]($inputs);
			}
		}
	}
	
	function attemptAccessLimitHandler($collection) {
		var messageMask =  app.resources.WARNING_ACCESSLIMIT;
		
		if(messageMask == null) {
			return;
		}
		$collection.each(function() {
        	
			var $inputItem = $(this),
        		$notedElement = $inputItem.parents(':eq(1)'),
        		addrMaxLength = $inputItem.attr('maxlength');
        	
        	if( addrMaxLength ) {
        		var previousCount = 0,
        			warningMsg = '';
        		
        		warningMsg = constructWarningMessage(messageMask, addrMaxLength);
        		setWarningMessage($notedElement, warningMsg);
        		
        		$inputItem.on('keyup', function(event) {
        			
        			if( isInvalidElement($notedElement) ) {
        				hideWarning($notedElement);
        				return;
        			}
        			
        	    	if( $(this).val().length >= addrMaxLength && previousCount >= addrMaxLength ) {
        	    		showWarning($notedElement);
        	    	} else {
        	    		hideWarning($notedElement);
        	    	}
        	    	
        	    	previousCount = $(this).val().length;
        	    })
        	    .on('keydown', function (e) {
        	    	// maxlength attr. isn't working on some android devices
        	    	// purpose of this handler is to limit quantity of symbols
        	    	if (e.keyCode !== 8) {
        	    		var $this = $(this),
        	    			currentVal = $this.val();
            	    	if ( currentVal.length >= addrMaxLength ) {
            	    		$this.val( currentVal.substr(0, addrMaxLength) );
            	    	}
        	    	}
        	    });
        		
        		$inputItem.on('focusout', function(event) {
        			$(this).parents(':eq(1)').removeClass('b-warning-light');
        		});
        		
        	}
        });
		
	}
	
  /**
   * @namespace app.components.global.warning public object
   **/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.warning = {
		init : function() {
			initializeCache();
			initializeEvents();
		},
		reinit: function(params) {
			attemptAccessLimitHandler(params)
		}
	};
}(window.app = window.app || {}, jQuery));