/**
 * @class app.datepicker
 */

(function (app, $) {
	
	var $cache = {};
	
	function initializeCache(){
		$cache = {
			document: $(document),
			datepickerElementSel: '*[data-datepicker="true"]'
		};
	}
	
	// Documentation can be find here:
	// http://api.jqueryui.com/datepicker/

	var settings = {
		// The basic set of datepicker parameters
		// altField
		// An input element that is to be updated with the selected date from the datepicker
		altField: '',
		// altFormat
		// The dateFormat to be used for the altField option
		// altFormat
		altFormat: '',
		// dateFormat
		// The format for parsed and displayed dates
		dateFormat: 'mm/dd/yy',
		// autoSize
		// Set to true to automatically resize the input field to accommodate
		// dates in the current dateFormat
		autoSize: false,
		// beforeShow
		// takes an input field and current datepicker
		// instance and returns an options object to update the datepicker with
		beforeShow: null,
		// maxDate
		// The maximum selectable date
		maxDate: null,
		// minDate
		// The minimum selectable date
		minDate: null,
		// defaultDate
		// Set the date to highlight on first opening if the field is blank
		defaultDate: null,
		// onSelect
		// Called when the datepicker is selected
		onSelect: function() {
			$cache.document.trigger('datepicker.selected');
		}
	};
	
	/**
	 * Sets DOM elements with toggled calendar widgets
	 * @return undefined
	 **/
	
	function initAllDatepickers(){
		$(this).find($cache.datepickerElementSel).each(initDatepicker);
	}
	
	/**
	 * Personally generates datepicker and applies settings for
	 * each needed element
	 * @return undefined
	 **/
	
	function initDatepicker(){
		var datepickerElement = $(this),
			customSettings = datepickerElement.data('datepickerSettings') ? datepickerElement.data('datepickerSettings') : {};
		
		var datepickerSettings = $.extend({}, settings, customSettings);
		
		datepickerElement.datepicker(datepickerSettings);
	}
	
	app.datepicker = {
		init: function(){
			initializeCache();
			initAllDatepickers.call($cache.document);
		}
	};
	
}(window.app = window.app || {}, jQuery));