;(function(app, $) {
	function Notification(config) {
		this.text = config.text;
		this.timeout = config.timeout || 5000;
		this.animationSpeed = config.animationSpeed || 'slow';
		this.$html = createHTML.call(this);
		this.hideTimeoutID = false;
	}
	
	Notification.prototype.getHTML = function() {
		return this.$html;
	};
	
	Notification.prototype.show = function() {
		this.$html.fadeIn(this.animationSpeed, function() {
			this.$html.addClass('faded');
		}.bind(this));
		
		this.hideTimeoutID = setTimeout(function(){
			this.hide();
		}.bind(this), this.timeout);
	};
	
	Notification.prototype.hide = function() {
		this.$html.fadeOut(this.animationSpeed, function(){
			this.remove();
		}.bind(this));
	};

	Notification.prototype.remove = function() {
		app.notificationsMgr.removeNotification(this);
		window.clearTimeout( this.hideTimeoutID );
		this.$html.remove();
	}
	
	function createHTML() {
		var notification = this;
		
		var $html = $('<div>', {
			'class': 'b-product-configurator-notification-item js-product-configurator-notification-item'
		});
		
		var $text = $('<span>',{
			'class': "b-product-configurator-notification-item-text"
		}).html(notification.text);
		
		var $closeButton = $('<button>',{
			'class': "b-product-configurator-notification-close js-configurator-notification-close"
		});
		
		$closeButton.on('click', function() {
			notification.hide();
		});
		
		$html.append($text);
		$html.append($closeButton);
		
		return $html;
	}
	
	app.configurator = app.configurator || {};
	app.configurator.Notification = Notification;
})((window.app = window.app || {}), jQuery);