/**
 * @class app.product
 */
(function(app, $) {
	var $cache;
	var methods;
	var eventHandlers;
	var settings = {
		scroll: {
			speed: 500,
			animate: 'swing'
		},
		thumbnailSlider: {
			loop: true
		}
	};
	var disableFancyBox = (app.device.isMobileView() ? app.preferences.productDisableFancyboxMobile : app.preferences.productDisableFancybox) === 'true';
	var beforeChangeVariationEventFunction = null;
	var beforeAddToCartFunction = null;

	/**
	 * @private
	 * @function
	 * @description Initializes the cache on the product detail page.
	 */
	function initializeCache(container) {
		var pdpSelectors = app.util.getConfig('pdpSelectors', {});
		$cache = {
			document: $(document),
			header: $('header'),
			container: container,
			languageSelectorLinks: $('.js-language_selector_link'),
			navContainer: container.find('.js-product_nav_container'),
			lastVisited: container.find('.js-last-visited-wrapper'),
			notifyMeOnSaleDialog: container.find('.js-notify_me_on_sales_dialog'),
			notifyMeOnSaleContainer: $('.js-notifyme_on_sale_container'),
			productSetList: container.find('.js-product_set_list'),
			addToCart: container.find('.js-add_to_cart'),
			addAllToCart: container.find('.js-add_all_to_cart'),
			notifymeContainer: container.find('.js-notifyme_container'),
			thumbnailsSlider: container.find('.js-thumbnails_slider'),
			thumbnailsAdditional: container.find('.js-thumbnails_additional'),
			addToCartWrapper: container.find('.js-add_to_cart_wrapper'),
			tabSetTmplActive: $('#js-tabs_product_set-element__active').html(),
			tabSetTmpl: $('#js-tabs_product_set-element').html(),
			productTabs: container.find('.js-product_tabs'),
			oneSizeProduct: $('.js-pdp_primary_content .js-one_size_product'),
			useToggler: app.util.getConfig('product.variationItems.dropdown.useToggler', false),
			addToBagOnSizeSelect: app.device.isMobileView() && app.util.getConfig('product.variationItems.addToBagOnSizeSelect'),
			notifyMeAutoclose: app.util.getConfig('product.notifyMe.autoClose', 0),
			originalSrcSetDataAttr: 'originalsrcset',
			//$cache.selectors
			selectors: {
				sizeListsSel: '.js-size_list-container',
				sizeListsItem: '.js-size_list-item',
				addToCart: '.js-add_to_cart',
				selectSizeAddToCartButton: '.js-size-popup-container .js-swatchanchor ',
				addToCartData: '.js-add-to-cart-data',
				backButton: '.js-pdp-back_button, .js-pdp-back',
				primaryContent: '.js-pdp_primary_content',
				primaryContentWrapper: '.js-pdp_primary_content-wrapper',
				primaryImg: '.js-primary_image',
				primaryImgContainer: '.js-primary_image',
				imagesContainer: '.js-product_images_container',
				productContent: '.js-product_content',
				pdpForm: '.js-form_pdp',
				productID: '.js-product_id',
				zoomed: '.js-zoomed',
				thumbnails: '.js-thumbnails',
				personalizedInput: '.js-personalized-input',
				personalizedText: '.js-personalized-text',
				personalizedLength: '.js-personalized-length',
				errorVariationsSel: '.js-error_variations',
				notifyMeLink: '.js-notifyme_link',
				notifyMeSubmit: '.js-notify_me_submit',
				notifyMeSelect: '.js-notifyme_link-in_use',
				notifyMeContainer: '.js-notifyme_container',
				marketingNotify: '.js-notifyme_marketingnotify',
				notifyMeOnSaleLink: '.js-notify_me_on_sales_link',
				notifyMeOnSaleDialog: '.js-notify_me_on_sales_dialog',
				notifyMeOnSaleSubmit: '.js-notify_me_on_sale_submit',
				fieldOnSaleEmail: 'input[name=dwfrm_onsale_email]',
				imagesContainerForZoom: '.js-product_images_container-zoom',
				sizeChartLink: '.js-size_chart_link',
				sizeChartTabs: '.js-size_chart-tabs',
				carePopupLink: '.js-care_details-popup',
				carePopupContent: '.js-care_details-content',
				sendTofriendSel: '.js-send_to_friend',
				variationSelect: '.js-va_select',
				shippingRestrictionsLink: '.js-product_shipping_restrictions_link',
				shippingRestrictionsContent: '.js-product_shipping_restrictions_content',
				carouselWrapper: '.js-owl_carousel',
				mainImgCntr: '.js-container_main_image',
				tabProductSet: '.js-tab_product_set-cont .js-tab_product_set',
				fancyBoxInner: '.js-zoomed .fancybox-inner',
				swatchesColorLink: '.js-swatches_color-link:not(.js-quickview-swatchanchor-color), .js-change-variation:not(.js-quickview-swatchanchor-color)',
				productSetTabCont: '.js-tab_product_set-cont',
				productSetTabLink: '.js-tab_product_set-link',
				thumbnailImg: app.util.getConfig('product.pdp.thumbnailImgSel'),
				zoomThumbnailImg: '.js-thumbnail img',
				leftStockCntr: pdpSelectors.leftStockCntr || '.js-swatches',
				availabilityMsg: '.js-availability_msg',
				productTile: '.js-product_tile',
				variationDropdown: '.js-variation-dropdown',
				colorSwatches: '.js-swatches .js-swatches_color-link',
				imagesContainerForZoomCustom: '.js-product_images_container-zoom-custom',
				thumbnailsSlider: '.js-thumbnails_slider',
				thumbnailItemSel: '.js-thumbnail',
				thumbnailsAdditional: '.js-thumbnails_additional',
				pid: '#pid',
				thumbnailsCntrSel: '.js-thumbnails_slider',
				addtoWishListPlaceholder: '.js-add_to_wishlist_placeholder',
				addtoWishList: '.js-add_to_wishlist, .js-hp_wishlist-add',
				productZoomImage: '.js-product_images_container-zoom .js-producttile_image',
				productTabs: '.js-product_tabs',
				productTileImageSel: '.js-producttile_image',
				lastVisitedItem: '.js-last_visited-item',
				lastVisitedTitle: '.js-last_visited-title',
				thumbnailImgItem: '.js-thumbnail_img-item',
				allImagesContainer: '.js-product_primary_image',
				fancyBoxCntr: '.js-zoom_fancybox',
				zoomThumbnailsSlider: '.js-zoomed .js-thumbnails_slider',
				vimeoContainer: '.js-vimeo-container',
				selectedColorItem: '.js-swatches_color-item-selected',
				navContainer: '.js-product_nav_container',
				sliderImgs: 'img:not(.js-play_video_icon)',
				videoThumb: '.js-video_product_thumbnail',
				imageThumb: '.js-img_product_thumbnail',
				productLabels: '.js-product_labels',
				navigationProductLink: '.js-product_nav-link',
				carouselImage: '.b-owl_carousel-image',
				fullscreenDiv: '.fullscreenDiv',
				notifyMeGender: '.js-notifyme-gender',
				paypalExpressButtonPDPContainer: '.js-paypalExpressButtonPDPContainer'
			},
			//$cache.classes
			classes: {
				containerPDP: 'js-pdp_main',
				addToCartActive: 'm-active',
				notifyMeFancyWrap: 'l-notifyme_form-wrap',
				recomVisible: 'b-recom-tabs-visible',
				recomTabHidden: 'b-recom-tab-hidden',
				errorState: 'error-state',
				notifymePopupWrapper: 'fancybox-notify_me',
				notifyMe: 'js-notifyme_link-in_use',
				tabsProductSetPdp: 'tabs_product_set-pdp',
				notifymeSuccessPopupWrapper: 'fancybox-notify_me_success',
				quickviewColorSelect: 'js-swatches_select--quickview',
				primaryImageClass: 'js-primary_image',
				notifyMeInitialized: 'js-notify-me-initialized',
				toggledClass: 'h-toggled',
				addOnSizeSelect: 'js-add_on_size_select',
				hiddenClass: 'h-hidden',
				invisibleClass: 'h-invisible',
				target: 'target',
				productBannedB: 'js-banned_b',
				fancyBoxWrapper: 'b-product_image_zoomed js-zoomed',
				zoomMainCntr: 'zoom-container',
				zoomImgCntr: 'js-container_main_image',
				thumbSelectedCls: 'b-product_thumbnail-selected',
				vimeoSwatchHover: 'b-vimeo-swatch-hover',
				vimeoPreviewThumbnail: 'b-vimeo_hover_preview',
				loadedContainerClass: 'b-vimeo-loaded',
				notifyMeBtn: 'js-notifyme_popup',
				thumbnailItemClass: 'js-thumbnail',
				notifyMeButton: 'js-notifyme-button'
			}
		};

		$cache.selectors.primaryImg = $($cache.selectors.imagesContainer).data('primaryimage-sel') || $cache.selectors.primaryImageSel;
		$cache.currentSearchParams = getSearchParamsFromStorage();
		var primaryImage = container.find(getImgSelector());
		$cache.primaryImage = {
			src: primaryImage.attr('src'),
			alt: primaryImage.attr('alt'),
			title: primaryImage.attr('title')
		};
	}
	/**
	 * @private
	 * @function
	 * @description Initializes the DOM of the product detail page (tabs, product-navigation).
	 */
	function initializeDom() {
		/** anchor back */
		$cache.document.on('click', $cache.selectors.backButton, function(e) {
			e.preventDefault();
			var lastViewedCategory = app.urls.lastViewedCategory;

			if (document.referrer.indexOf(lastViewedCategory) !== -1 || app.util.getParamFromUrl(window.location.search, 'backButtonBehavior') === 'history-back') {
				window.history.back();
			} else {
				window.location.href = lastViewedCategory;
			}
		});

		if (app.enabledStorePickup) {
			app.storeinventory.buildStoreList($('.js-product_number span').html());
		}
		$cache.container.find($cache.selectors.addToCart + '[disabled]').attr(
			'title',
			$cache.container.find($cache.selectors.availabilityMsg).text().trim()
		);
		loadProductNavigation();

		if ($cache.productSetList.length > 0) {
			var unavailable = $cache.productSetList.find('form').find($cache.selectors.addToCart + '[disabled]');
			if (unavailable.length > 0) {
				$cache.addAllToCart.attr('disabled', 'disabled');
				$cache.addToCart.attr('disabled', 'disabled'); // this may be a bundle
			}
		}
		displayLowInStockMsg($($cache.selectors.pid).val());
		app.components.global.tooltips.init();
		if (app.preferences.iseLinkedProductSets){
			createProductSetTabsData($cache.container);
		}

		if ($($cache.selectors.variationSelect).eq(0).find('option[value=""]').length) {
			$($cache.selectors.variationSelect).eq(0).val('');
		}

		if ('scrollRestoration' in history) {
			history.scrollRestoration = 'manual';
		} else {
			setTimeout(function() {
				app.components.global.history.init({
					disabledAnchor: true
				});
			}, 0);
		}
		loadLastVisited($cache.container);
	}

	/**
	 * @private
	 * @function
	 * @description Initializes events on the product detail page or quickview
	 */
	function initializeEvents() {
		app.product.initAddToCart();

		initProductTabs($cache.container);
		initVariantsHoverEvent();

		$cache.document.on('changeVariation.changed', function (event, eventData) {
			initVariantsHoverEvent({
				'reinit': true
			});
			initProductTabs(eventData.container);
			$cache.document.trigger('configuratorStep2.changed');
		});

		initThumbnailsClickEvent($cache.container.find($cache.selectors.thumbnailImg));//!

		$cache.container
			.on('keyup input', $cache.selectors.personalizedInput, eventHandlers.productPersonalize)
			// prevent default behavior of thumbnail link and add this Button
			.on('click', '.js-thumbnail_link, .js-addthis_toolbox a', false)
			.on('click', '.js-unselectable a', false)
			.on('click', $cache.selectors.sizeChartLink, eventHandlers.sizeChartLink)
			.on('keydown', $cache.selectors.sizeChartLink, function(e){
				if (e.keyCode === 13) eventHandlers.sizeChartLink.call(e.target);
			})
			.on('keydown', $cache.selectors.addtoWishList, function(e) {
				if (e.keyCode === 13) app.wishlist.addProductToWhishlist(e.target, e);
			})
			.on('click', $cache.selectors.notifyMeOnSaleLink, eventHandlers.notifyMeOnSaleLink)
			.on('click', $cache.selectors.imagesContainerForZoom, eventHandlers.processZoom)
			.on('product.master.reloaded', eventHandlers.masterReloaded)
			.on('click', $cache.selectors.notifyMeLink, eventHandlers.openNotifyMePopup)
			.on('click', $cache.selectors.carePopupLink, eventHandlers.openPopupLinkEvents);

		$cache.document.on('click', $cache.selectors.notifyMeOnSaleSubmit, eventHandlers.notifyMeOnSaleSubmit);

		$cache.document
			.on('click', '.js-pdp_fancybox_open', function(e) {
				e.preventDefault();
				var fancyboxContent = $(this).data('content'),
					options = $(this).data('options');
				if (fancyboxContent) {
					app.fancybox.open($(fancyboxContent), options);
				}
			})
			.on('minicart.load', function() {
				if (app.preferences.isMinicartAddToWishlistEnabled) {
					app.wishlist.init();
				}
			})
			.on('click', $cache.selectors.shippingRestrictionsLink, function() {
				app.fancybox.open($($cache.selectors.shippingRestrictionsContent).html(), {
					wrapCSS: 'b-product_shipping_restrictions-overlay'
				});
			})
			.on('product.invalid', function(event, params) {
				params.parent().find($cache.variationDropdown).addClass($cache.classes.errorState);
			})
			.on('product.valid', function(event, params) {
				params.parent().find($cache.variationDropdown).removeClass($cache.classes.errorState);
			});

		$cache.languageSelectorLinks.on('click', eventHandlers.languageSelector);

		initVariantsClickEvent();

		if (!app.device.isMobileView() && app.preferences.isMasterAjaxUpdateEnabled || app.device.isMobileView() && app.preferences.isMasterAjaxUpdateEnabledMobile) {
			$cache.container.on('click', $cache.selectors.swatchesColorLink, eventHandlers.changeMainContent);
			if (app.preferences.iseLinkedProductSets){
				$cache.container.on('click', $cache.selectors.productSetTabLink, eventHandlers.linkedSetTab);
			}
		}

		initProductListSetClickEvent();
		initAddAllToCardClickEvent();
		app.components.global.sendToFriend.initializeDialog($cache.container, $cache.selectors.sendTofriendSel);
		initThumbnailsSlider($cache.thumbnailsSlider);
	}

	function initSettings() {
		var pageConfigs = app.configs[app.page.type];
		var thumbnailSliderParams = pageConfigs && pageConfigs.thumbnailSlider;

		if (thumbnailSliderParams) {
			$.each(thumbnailSliderParams, function(key, value) {
				settings.thumbnailSlider[key] = value;
			});
		}
	}

	/** ************* app.product private vars and functions ************** */

	/**
	 * @private
	 * @function
	 * @description Hides/shows ui tabs
	 */
	function hideShowTab($tabContainer, tabIndex, action) {
		var $targetTab, indexToActivate;
		var active = $tabContainer.tabs( "option", "active" );
		$targetTab = $tabContainer.tabs('instance').tabs.eq(tabIndex);
		if (action === 'hide') {
			$targetTab.addClass($cache.classes.recomTabHidden).hide();
			if (active === tabIndex){
				indexToActivate = $tabContainer.not($targetTab).find('a').attr('href').replace(/^\D*/, '') - 1;
				$tabContainer.tabs('option', 'active', indexToActivate);
			}
		}
		if (action === 'show') {
			$targetTab.removeClass($cache.classes.recomTabHidden).show();
			if (!$tabContainer.tabs('instance').tabs.not($targetTab).is(':visible')) {
				indexToActivate = $targetTab.find('a').attr('href').replace(/^\D*/, '') - 1;
				$tabContainer.tabs('option', 'active', indexToActivate);
			}
		}
	}

	function isProductBannedB() {
		return $("." + $cache.classes.containerPDP ).hasClass($cache.classes.productBannedB);
	}

	/**
	 * @private
	 * @function
	 * @description Loads product's navigation on the product detail page
	 */
	function loadProductNavigation(params, leaveCurrentState) {
		params = params || {};
		var $navContainer = params.navContainer || $cache.navContainer;

		var searchParams = !leaveCurrentState ? getSearchParamsFromStorage() : $cache.currentSearchParams;
		var pidInput = $cache.container.find($cache.selectors.pdpForm + ' ' + $cache.selectors.productID).last();
		var pid = pidInput.val();

		if (!searchParams || (pidInput.length === 0 && !isProductBannedB()) || $cache.navContainer.length === 0) {
			return;
		}

		if (searchParams.indexOf('pid=') > -1 && searchParams.indexOf(pid) < 0) {
			clearSearchParamsInStorage();

			return;
		}

		searchParams += '&pid=' + pid;
		sessionStorage.setItem('searchParams', searchParams);

		var url = app.urls.productNav + window.location.search;
		url += (url.indexOf('?') === -1 ? '?' : '&') + searchParams;

		app.ajax.load({
			url: url,
			target: $navContainer,
			callback: initProductNavigation.bind($navContainer)
		});
	}

	function getSearchParamsFromStorage() {
		try {
			var paramsObject = sessionStorage.getItem('searchParams');
			return paramsObject;
		} catch (e) {
			return '';
		}
	}

	function clearSearchParamsInStorage() {
		try {
			sessionStorage.removeItem('searchParams');
		} catch (e) {
			// sessionStorage is disabled
		}
	}

	function switchThumbnail(index) {
		var $thumbImg = $($cache.selectors.thumbnailsCntrSel).find('[data-thumbnailindex='+index+']');
		if ($thumbImg.length) {
			$($cache.selectors.thumbnailsCntrSel).find($cache.selectors.thumbnailItemSel).removeClass($cache.classes.thumbSelectedCls);
			$thumbImg.parent($cache.selectors.thumbnailItemSel).addClass($cache.classes.thumbSelectedCls);
		}
	}

	/**
	 * @private
	 * @function
	 * @description Loads last visited products
	 */
	function loadLastVisited(container) {
		var url = app.util.appendParamsToUrl(app.urls.getLastVisited, {
			pid: container.find($cache.selectors.pdpForm + " " + $cache.selectors.productID).last().val(),
			LastVisitedCount: app.preferences.pdpLastVisitedCount
		});
		if ($cache.lastVisited.length) {
			app.ajax.load({
				url: url,
				target: $cache.lastVisited,
				callback: function() {
					$cache.document.trigger('last.visited.loaded', $cache.lastVisited);
					if ( !($cache.lastVisited.find($cache.selectors.lastVisitedItem).length) ){
						$cache.lastVisited.find($cache.selectors.lastVisitedTitle).addClass($cache.classes.hiddenClass);
					};
					document.dispatchEvent(new CustomEvent('price-reinit'));
				}
			});
		}
	}
	/**
	 * @private
	 * @function
	 * @description Displays low in stock message if it is necessary
	 */
	function displayLowInStockMsg(pid) {
		if (app.preferences.productShowLowInStockMsg){
			app.ajax.getJson({
				url: app.util.appendParamToURL(app.urls.productInStockLevelMsg, 'pid', pid),
				callback: function(resp) {
					var container = $($cache.selectors.productContent + ' ' + $cache.selectors.leftStockCntr);
					if (resp) {
						for (var size in resp) {
							if (resp[size]) {
								var elem = container.find(".js_low-in-stock-msg[data-attr-value='" + size + "']");
								if (elem.text() !== resp[size]) {
									elem.append(' ' + resp[size]).addClass('b-variation-few_left_message-not_empty');
								}
							}
						}
						$cache.document.trigger('product.lowinstock.load');
					}
				}
			});
		}
	}
	/**
	 * @private
	 * @function
	 * @description scroll zoom image to bottom
	 * @param $image - primary image in zoom
	 */
	function zoomScrollTo($image, mode) {
		//default behavior is scroll to bottom
		var margin = $(window).innerHeight() - $image.height();
		if (mode === 'center') {
			margin /= 2;
		}
		if (!app.device.isTabletUserAgent()) {
			$image.css('margin-top', margin + 'px');
		} else {
			$($cache.selectors.fancyBoxInner).scrollTop(Math.abs(margin));
		}
	}

	/**
	 * @private
	 * @function
	 * @description replaces the images in the image container. for example when a different color was clicked.
	 */
	function replaceImages(container) {
		var newImages = $('.js-update_images');
		var imageContainer = container.find('.js-product_images_container');

		imageContainer.html(newImages.html());
		newImages.remove();
		initThumbnailsClickEvent(container.find($cache.selectors.thumbnailImg));
		initThumbnailsSlider($($cache.selectors.thumbnailsSlider));
	}
	/**
	 * @function
	 * @description Sets the main image attributes
	 * @param {Object}
	 *            atts Simple object with url, alt, title properties
	 */
	function setMainImage(atts, element, container) {
		var imgZoom = container.find($cache.selectors.mainImgCntr);

		if (element && $(element).closest('main').length) {
			imgZoom = $(element)
				.closest('main')
				.find($cache.selectors.mainImgCntr);
		}

		var imgElem = imgZoom.find(getImgSelector(container));
		var source = imgElem.siblings('source');

		if (source.length) {
			source.attr('srcset', atts.srcSet);
		}

		imgZoom.find(getImgSelector(container)).attr({
			src: atts.hires,
			alt: atts.alt,
			title: atts.title
		});
	}

	/**
	 * @function
	 * @description helper function for swapping main image on swatch hover
	 * @param {Element}
	 *            element DOM element with custom data-lgimg attribute
	 */
	function swapImage(element, container) {
		var $element = $(element);
		var lgImg = $element.data('lgimg');
		var srcSet = {
			srcSet: $element.data('srcset')
		};

		if (lgImg) {
			var newImg = $.extend({}, lgImg, srcSet);
			setMainImage(newImg, element, container);
		}
	}

	function initThumbsSrc(thumbs) {
		thumbs.find($cache.selectors.thumbnailImgItem).each(function() {
			var img = $(this);
			if(!img.prop("src") && img.data("thumbnailsrc")) {
				img.prop("src", img.data("thumbnailsrc"));
			}
		});
	}

	function changeMainImage(params) {
		var attributeValues = params.attributeValues;
		if(attributeValues && (params.primaryImage.prop("src") !== attributeValues.hires)) {
			var allImagesContainer = params.primaryImage.closest($cache.selectors.allImagesContainer);

			var newPrimaryImg = allImagesContainer.children($cache.selectors.productTileImageSel+"[src$='"+attributeValues.hires+"']");
			if(!newPrimaryImg.length) {
				newPrimaryImg = params.primaryImage.clone();
				newPrimaryImg.attr({
					src: attributeValues.hires,
					alt: attributeValues.alt,
					title: attributeValues.title
				});
				newPrimaryImg.appendTo(allImagesContainer);
			}
		}
	}

	/**
	 * @private
	 * @function
	 * @description Initialize superZoom functionality
	 *
	 */
	function initSuperZoom() {
		var $image = this.find('img').css('position', 'relative'),
			deltaPrc = 0,
			startPosition,
			scope = this,
			moveActive = false;

		function mouseMove() {
			$image.css('margin-top', -deltaPrc + 'px');
		}

		scope.off('mousemove');
		scope.on('mousemove', function(e) {
			if (!startPosition) {
				startPosition = e.clientY;
			}

			if (!moveActive && Math.abs(startPosition - e.clientY) > 10) {
				moveActive = true;
				scope.on('mousemove', function(e) {
					// currently works for CoSTUME NATIONAL
					// if another brands will have product images with width > height then need another formula
					if ($image.height() > window.innerHeight) {
						deltaPrc = parseInt(($image.height() - window.innerHeight) * parseFloat(e.clientY / window.innerHeight));
					}

					app.util.throttle(mouseMove, 1);
				});
			}
		});
	}
	/**
	 * @private
	 * @function
	 * @description Initialize changing main and thumbnails images on hover event on variants
	 */
	function initVariantsHoverEvent(params) {
		var reInit = params !== undefined && 'reinit' in params ? params.reinit : false;
		// thumbnailsAdditional is hidden by default, slider is showed
		$cache.thumbnailsSlider.show();
		$cache.thumbnailsAdditional.hide();
		if (app.preferences.disableGlobalVariantsHover) {
			return;
		}
		//disable hover effect on mobile and tablet
		if (!((app.preferences.disablePdpVariantsHoverTablet && (app.device.isTabletUserAgent() || app.device.isMobileUserAgent())) || $($cache.selectors.imagesContainerForZoomCustom).length)) {
			var $primaryContentBlock = $cache.container.find($cache.selectors.primaryContentWrapper);

			if (!$primaryContentBlock.length) {
				$primaryContentBlock = $cache.container.find($cache.selectors.primaryContent);
			}

			if (app.util.getConfig('product.useCustomVariationHover')) {
				eventHandlers.hoverVariant = app.components.product.custom.hoverVariant;
			}

			if (reInit) {
				$primaryContentBlock.off('mouseenter mouseleave', $cache.selectors.colorSwatches, eventHandlers.hoverVariant);
			}
			$primaryContentBlock.on('mouseenter mouseleave', $cache.selectors.colorSwatches, eventHandlers.hoverVariant);
		}
	}

	/**
	 * @private
	 * @function
	 * @description change main image on pdp or zoomed images
	 * @param $thumbs - collection of thumbnail elements
	 */
	function initThumbnailsClickEvent($thumbs) {
		if ($thumbs && $thumbs.length){
			$thumbs.on('click', eventHandlers.clickThumbnails);
		}
	}
	function changeVariationEventWrapper(e, options){
		if(typeof beforeChangeVariationEventFunction === 'function'){
			beforeChangeVariationEventFunction.call(beforeChangeVariationEventFunction, changeVariationEvent.bind(this), e, options);
			return false;
		}else{
			return changeVariationEvent.call(this, e, options);
		}
	}
	/**
	 * @private
	 * @function
	 * @description change main image when selected new color variants
	 */
	function changeVariationEvent(e, options) {
		var options = options || {},
			container = $(e.delegateTarget),
			$el = options.self ? $(options.self) : $(this),
			isSelect = $el.is('select'),
			variantAttribute = $el.data('variantattribute'),
			pageSource = container.hasClass($cache.classes.containerPDP) ? 'pdp' : 'quickview',
			baseURL,
			scrollTo = true,
			startPosition =
				$el
					.parents('.owl-stage')
					.find('.b-owl_carousel-item_active:first')
					.index() || 0;
		if ($el.hasClass('js-unavailable-color')) {
			return false;
		}
		if ($(e.target).data('scrollto') !== undefined) {
			scrollTo = $(e.target).data('scrollto');
		}
		// just go to new page if clicked on last visited tile
		if (app.util.getConfig('product.pdp.styleItWithSelectSize') && ($el.closest('.js-last_visited').length || $el.closest('.js-recommendations_block').length)) {
			return;
		}
		// prevent click on low in stock msg
		if ($(e.target).is('.js_low-in-stock-msg')) {
			e.stopPropagation();
			return false;
		}
		e.preventDefault();

		if (isSelect) {
			var $selectedOption = $el.find(':selected'),
				baseURL = $el.attr('value');

			if (!$selectedOption.data('selectable')) {
				eventHandlers.openNotifyMePopup.call($selectedOption, e, {wrapCSS: $cache.classes.notifymePopupWrapper});
			}
		} else {
			baseURL = (
				options.url
				|| $el.data('href')
				|| $el.attr('href')
				|| $el.find('a').first().attr('href')
			);
		}

		if ($el.is('.js-va_onesize_click')) {
			return false;
		}

		if (!isSelect && $el.parents('li').hasClass('js-unselectable')) {
			return;
		}

		if ($el.hasClass($cache.classes.notifyMeButton)) {
			return;
		}

		var lgImgAttr = isSelect ? $el.find(':selected').data('lgimg') : $el.attr('data-lgimg'),
			hasSwapImage = lgImgAttr !== undefined && lgImgAttr !== null,
			qty = container.find($cache.selectors.pdpForm + ' .js-product_quantity').first().val(),
			listid = container.find($cache.selectors.pdpForm + ' .js-product_list_id').first().val(),
			productSet = $el.closest('.js-sub_product'),
			params = { Quantity: isNaN(qty) ? '1' : qty },
			target,
			banning;

		if (listid) {
			params.productlistid = listid;
		}

		if (productSet.length > 0 && productSet.children.length > 0) {
			target = productSet;
		} else if (options.isMaster || $el.hasClass('js-quickview-swatchanchor-color') || $el.hasClass($cache.classes.quickviewColorSelect) || (isSelect && !$el.attr('value'))) {
			target = container;
		} else {
			target = $el.closest($cache.selectors.productContent); //use closest in case of QV on pdp
		}

		banning = target.data('banning') || false;
		params.banning = banning;
		params.pageSource = pageSource;
		if (!$('.js-product_shopthelook').length && !(pageSource === 'pdp' && !app.util.getConfig('product.variationItems.variationChanged.showLogoSpinner'))) {
			app.progress.show(container);
		}

		app.ajax.load({
			url: app.util.appendParamsToUrl(baseURL, params),
			callback: function(data) {
				var $wrapper = $('<DIV/>');
				$wrapper.html(data);
				if (app.device.isMobileView() && pageSource !== 'quickview') {
					history.replaceState({}, '', baseURL);
					var $newProductLabels = $wrapper.children($cache.selectors.productLabels);
					if ($newProductLabels.length) {
						$($cache.selectors.primaryContent).children($cache.selectors.productLabels).replaceWith($newProductLabels);
					}
				}
				var newPid = $wrapper.find('#pid').val(),
					masterPid = $wrapper.find('#masterPid').val();

				if (app.preferences.iseLinkedProductSets) {
					var productSetTabsContainer = $wrapper.find($cache.selectors.productSetTabCont),
						productSetTabsData = container.data('productSetTabs');
					if (productSetTabsData && productSetTabsContainer) {
						productSetTabsContainer.html(renderProductSetTabsTmpl(productSetTabsData));
					}
				}

				var $data = $wrapper.find('main');
				if ($data.length > 0) {
					target.html($data.html());
				} else {
					target.html($wrapper.html());
				}
				if($cache.useToggler && 'toggler' in app.components && 'custom' in app.components.toggler){
					app.components.toggler.custom.attachTogglerOnContainer(target);
				}

				$cache.document.trigger('product.variation.reloaded', {
					attribute: variantAttribute,
					mode: container.hasClass($cache.classes.containerPDP) ? 'pdp' : 'quickview',
					start: startPosition,
					sizeChanged: isSelect,
					pid: newPid,
					masterPid: masterPid,
					target: target
				});

				if (options.isMaster) {
					container.trigger('product.master.reloaded', {
						mode: container.hasClass($cache.classes.containerPDP) ? 'pdp' : 'quickview'
					});
				}

				if (isSelect) {
					var $elem = $('.js-va_select');
					var selectedOption = $elem.find(':selected');
					var selectable = selectedOption.data('selectable');

					if (!selectable && $elem.attr('value')) {
						$elem.addClass($cache.classes.notifyMe).data('variantid', selectedOption.data('variantid'));
					}
					$cache.document.trigger('product.sizes.reloaded', {
						attribute: variantAttribute,
						mode: container.hasClass($cache.classes.containerPDP) ? 'pdp' : 'quickview',
						start: startPosition,
						sizeChanged: isSelect
					});
				}
				initThumbnailsClickEvent(container.find($cache.selectors.thumbnailImg));
				if (hasSwapImage) {
					if (container.find($cache.selectors.videoThumb).length > 0) {
						$cache.document.on('thumbnail.srcs.loaded.replaceImages', function() {
							replaceImages(container);
							$cache.document.off('thumbnail.srcs.loaded.replaceImages');
						});
					} else {
						replaceImages(container);
					}
				}
				app.components.global.tooltips.init();
				if (isSelect && !$el.attr('value') && 'slider' in app.components.product) {
					app.components.product.slider.reinit();
				}
				var qvOpened = app.components.global.quickview.isOpened;
				var stlOpened = app.components.global.shopthelook && app.components.global.shopthelook.isOpened;

				if (!(qvOpened || stlOpened) && !app.device.isMobileView() && scrollTo) {
					$('html, body').animate({ scrollTop: 0 }, settings.scroll.speed, settings.scroll.animate);
				}
				if($(productSetTabsContainer).find($cache.selectors.notifyMeLink).length){
					$(productSetTabsContainer).find($cache.selectors.notifyMeLink).hide();
				}
				displayLowInStockMsg(newPid);
				if ('togglerhover' in app.components.global) {
					app.components.global.togglerhover.init();
				}
				if($cache.addToBagOnSizeSelect && $cache.addToCart.hasClass($cache.classes.addOnSizeSelect) && !$cache.oneSizeProduct.length){
					if($($cache.selectors.addtoWishListPlaceholder).hasClass($cache.classes.toggledClass)){
						app.wishlist.addProductToWhishlist($cache.selectors.addtoWishList, e);
						$($cache.selectors.addtoWishListPlaceholder).removeClass($cache.classes.toggledClass);
					} else {
						setAddToCartHandlerWrapper.call($($cache.selectors.addToCart));
					}
				}
				$cache.document.trigger('changeVariation.changed', {
					container: container
				});
				if (app.preferences.isMiniCartEnabled && app.isMobileView()) {
					app.components.global.minicart.init();
				}
				loadProductNavigation({
					navContainer: $cache.container.find($cache.selectors.navContainer)
				}, true);

				if ($($cache.selectors.paypalExpressButtonPDPContainer).length) {
					app.components.global.paypal?.initExpressPayPalButtonPDP();
				}
			}
		});
	}
	/**
	 * @private
	 * @function
	 * @description change main image when selected new color variants
	 */
	function initVariantsClickEvent() {
		var swatchAnchorEvent = 'touch click';
		// swatch anchor onclick()
		$cache.container
			.on(swatchAnchorEvent, '.js-swatchanchor, .js-quickview-swatchanchor-color, .js-va_onesize_click', function (e) {
				changeVariationEventWrapper.call(this, e, {
					isMaster: $(this).data('ismaster'),
					url: $(this).data('url')
				});
			})
			.on('change', $cache.selectors.variationSelect + ',.' + $cache.classes.quickviewColorSelect, eventHandlers.selectVariation)
			.on('change', '.js-swatches_select', function(e) {
				e.preventDefault();
				var newValue = $(this).val();
				newValue && location.assign(newValue);
			});

		if (app.device.isMobileView()) {
			var $notifyMeSelect = $($cache.selectors.notifyMeSelect);
			if ($notifyMeSelect.hasClass('js-va_onesize_click')) {
				$notifyMeSelect.on('click', function() {
					$(this).blur();
				});
			}
		}
	}

	function changeProductSetTabsData(e, tabsModelArray, tabIndex, newProductData) {
		var container = $(e.delegateTarget);
		for (var i = 0, length = tabsModelArray.length; i < length; i++) {
			if (tabsModelArray[i]['tabIndex'] === tabIndex) {
				tabsModelArray[i] = $.extend(tabsModelArray[i], newProductData);
				tabsModelArray[i]['active'] = true;
			} else {
				tabsModelArray[i]['active'] = false;
			}
		}
		container.data('productSetTabs', tabsModelArray);
	}

	function createProductSetTabsData(container) {
		var tabsModelArray = [];
		$($cache.selectors.tabProductSet).each(function() {
			tabsModelArray.push({
				tabIndex: this.getAttribute('data-tab-index'),
				url: this.getAttribute('data-href'),
				title: this.getAttribute('title'),
				active: this.getAttribute('data-active') === 'true'
			});
		});
		if (tabsModelArray.length) {
			container.data('productSetTabs', tabsModelArray);
			//Add specific class for front-end to detect that feature is activated and we have case with linked Product Sets
			//This make sense make with JavaScript because most part of feature relate from JavaScript correct work.
			container.addClass($cache.classes.tabsProductSetPdp);
		}
	}

	function renderProductSetTabsTmpl(tabsModelArray) {
		var tabsArray = [];
		for (var i = 0, length = tabsModelArray.length; i < length; i++) {
			if (tabsModelArray[i]['active']) {
				tabsArray.push(app.util.renderTemplate($cache.tabSetTmplActive, tabsModelArray[i]));
			} else {
				tabsArray.push(app.util.renderTemplate($cache.tabSetTmpl, tabsModelArray[i]));
			}
		}
		return tabsArray.join('');
	}

	/**
	 * @private
	 * @function
	 * @description change main image when selected new color variants
	 */
	function initProductListSetClickEvent() {
		$cache.productSetList.on('click', '.js-product_set_item .js-swatchanchor', function(e) {
			e.preventDefault();
			// get the querystring from the anchor element
			var params = app.util.getQueryStringParams(this.search);
			var psItem = $(this).closest('.js-product_set_item');

			// set quantity to value from form
			var qty = psItem
				.find('form')
				.find('.js-product_quantity')
				.first()
				.val();
			params.Quantity = isNaN(qty) ? '1' : qty;

			var url = app.urls.getSetItem + '?' + $.param(params);

			// get container
			var ic = $(this).closest('.js-product_set_item');
			ic.load(url, function() {
				app.progress.hide();
				if ($cache.productSetList.find($cache.selectors.addToCart + '[disabled]').length > 0) {
					$cache.addAllToCart.attr('disabled', 'disabled');
					$cache.addToCart.attr('disabled', 'disabled'); // this
					// may
					// be a
					// bundle
				} else {
					$cache.addAllToCart.removeAttr('disabled');
					$cache.addToCart.removeAttr('disabled'); // this
					// may
					// be a
					// bundle
				}
				app.product.initAddToCart(ic);
				app.components.global.tooltips.init();
			});
		});
	}

	/**
	 * @private
	 * @function
	 * @description Initialize thumbnails slider with options
	 * @param {Element}
	 *            element : DOM element which represents thumbnail slider wrapper
	 */
	function initThumbnailsSlider(element) {
		var images = $(element).find($cache.selectors.sliderImgs);
		var promises = [];

		for (var i = 0; i < images.length; i++) {
			promises.push(app.components.global.images.imageLoaded(images.eq(i)));
		}

		$.when.apply($, promises).done(function() {
			element.thumbnailsSlider({
				itemCount: app.preferences.pdpThumbnailsSliderCount,
				arrowUpClass: 'b-product_thumbnails-arrow_up',
				arrowDownClass: 'b-product_thumbnails-arrow_down',
				heightRecalculate: app.util.getConfig('disableThumbHeightRecalculate'),
				reverse: app.preferences.pdpThumbnailsSliderReverse,
				loop: settings.thumbnailSlider.loop
			});
		});
	}

	function setAddToCartHandlerWrapper(e){
		if(typeof beforeAddToCartFunction === 'function'){
			beforeAddToCartFunction.call(beforeAddToCartFunction, eventHandlers.addToCart.bind(this), e);
			return false;
		} else {
			return eventHandlers.addToCart.call(this, e);
		}
	}
	function getImgSelector(container){
		container = container || $cache.container;
		var selector = app.util.getConfig('primaryImageSelector');
		if (!selector) {
			selector = container.find($cache.selectors.imagesContainer).data('primaryimage-sel') || '.js-primary_image';
		}
		return selector;
	}

	/**
	 * @private
	 * @function
	 * @description initialize 'add all to card' button
	 */
	function initAddAllToCardClickEvent() {
		$cache.addAllToCart.on('click', function(e) {
			e.preventDefault();
			var psForms = $cache.productSetList.find('form').toArray(),
				miniCartHtml = '',
				addProductUrl = app.util.ajaxUrl(app.urls.addProduct);

			// add items to cart
			function addItems() {
				var form = $(psForms.shift());
				var itemid = form.find($cache.selectors.productID).val();

				$.ajax({
					dataType: 'html',
					url: addProductUrl,
					data: form.serialize()
				})
					.done(function(response) {
						// success
						miniCartHtml = response;
					})
					.fail(function(xhr, textStatus) {
						// failed
						var msg = app.resources.ADD_TO_CART_FAIL;
						$.validator.format(msg, itemid);
						if (textStatus === 'parsererror') {
							msg += '\n' + app.resources.BAD_RESPONSE;
						} else {
							msg += '\n' + app.resources.SERVER_CONNECTION_ERROR;
						}
						window.alert(msg);
					})
					.always(function() {
						if (psForms.length > 0) {
							addItems();
						} else {
							app.quickView.close();

							$cache.document.trigger('minicart.show', {
								html: miniCartHtml
							});
						}
					});
			}
			addItems();
			return false;
		});
	}

	function initNotifyMeFancyboxEvents($container) {
		$container.on('keypress', function(e) {
			var key = e.which;
			if (key === 13) {
				e.preventDefault();
				eventHandlers.submitNotifyMeEmail(e);
			}
		});

		$container.on('click', $cache.selectors.notifyMeSubmit, eventHandlers.submitNotifyMeEmail);

		document.querySelector(`${$cache.selectors.marketingNotify} input`).addEventListener('click', () => {
			document.querySelector($cache.selectors.notifyMeGender)?.classList.toggle($cache.classes.hiddenClass);
		});

		$container.addClass($cache.classes.notifyMeInitialized);

		app.components.search.compare.initNotifyMeFormEvents();
	}

	function initProductTabs(container) {
		$cache.productTabs = container.find($cache.selectors.productTabs);

		$cache.productTabs.each(function() {
			var $this = $(this);
			var dataActiveTab = $this.data('active-tab');
			if (dataActiveTab === false || dataActiveTab === undefined || dataActiveTab === null) {
				$this.tabs({
					collapsible: true,
					active: false,
					create: function(){
						$cache.document.trigger('updateDetailHeight');
					}
				});
			} else {
				$this.tabs({
					active: dataActiveTab,
					create: function(){
						$cache.document.trigger('updateDetailHeight');
					}
				});
			}
		});
	}

	function initProductNavigation() {
		var $container = $(this);

		if ($container.length) {
			$container.find($cache.selectors.navigationProductLink).on('click', function() {
				var params = $(this).data('params');
				try {
					sessionStorage.setItem('searchParams', params);
				} catch (e) {
					// sessionStorage is disabled
				}
			});
		}
	}

	function loadThumbnails() {
		if (!app.configs.pdpThumbnailPreload) {
			$($cache.selectors.imageThumb).each(function() {
				var imageThumbnailItem = $(this);
				if (imageThumbnailItem.data('src')) {
					imageThumbnailItem.attr('src', imageThumbnailItem.data('src'));
					imageThumbnailItem.removeAttr('data-src');
				}
			});
		}
	}

	/**
	 * @private
	 * @function
	 * @description show CTA to chose a variation for NotifyMe subscription
	 */
	function showNotifyMeVariationError() {
		var $productContentBlock = $(this).closest($cache.selectors.productContent);
		var $productErrorBlock = $productContentBlock.find($cache.selectors.errorVariationsSel);

		if ($productErrorBlock.length > 0) {
			$productErrorBlock.show();
		}
	}

	/** ************* Object of method with event handlers ************** */
	eventHandlers = {
		/**
		 * @private
		 * @function
		 * @description Event handler to personalize event
		 */
		productPersonalize : function (e) {
			var $self = $(this);
			var currentString = $self.val();
			var currentStringLength = parseInt($self.attr('maxlength')) - currentString.length;
			var currentStringEncoded = encodeURIComponent(currentString);
			var container = $(e.delegateTarget);
			container.find($cache.selectors.personalizedText).val(currentStringEncoded);
			if (!isNaN(currentStringLength)) {
				container.find($cache.selectors.personalizedLength).text(currentStringLength);
			}
		},

		/**
		 * @private
		 * @function
		 * @description Event handler to handle the add to cart event
		 */
		addToCart: function(e) {
			var $el = $(this),
				action = $el.data('action'),
				$jsErrorWrap = ($cache.addToBagOnSizeSelect && action !== "quickShopEdit") ? $el : $(e.currentTarget).closest('.js-product_content'),
				$jsErrorVariations = $jsErrorWrap.find('.js-error_variations');
			if ($jsErrorVariations.length > 0) {
				if (!$cache.addToCartWrapper.is('[disabled]')) {
					$cache.document.trigger('product.invalid', [$jsErrorVariations]);
					$jsErrorVariations.show();
				}
			} else {
				if ($el.data('action')) {
					return;
				}
				var $form = $el.closest('form'),
					$qty = $form.find('.js-product_quantity'),
					isSubItem = $el.hasClass('js-sub_product_item');
				if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
					$qty.val('1');
				}
				$cache.document.trigger('product.valid', [$jsErrorVariations]);

				var data = [$form.serialize(), 'source=' + encodeURIComponent(app.page.ns)];

				if (app.device.isMobileUserAgent() || app.util.getConfig('minicart.includeShippings')) {
					data = data.concat(['updateShippingMethods=true', 'addToCart=true']);
				}

				data = data.join('&');

				app.cart.update(data, function(response) {
					var $uuid = $form.find('.js-product_uuid');

					if (app.preferences.dynamicPromotionsEnable) {
						$cache.document.trigger('dynamicpromotion.pdp.update');
					}

					if (response.redirectURL) {
						app.page.redirect(response.redirectURL);
					}

					if (($uuid.length > 0 && $uuid.val()) || app.page.ns === 'checkout') {
						$cache.document.trigger('product.added', $form);

						if (app.page.ns === 'checkout') {
							app.page.redirect(app.urls.cartShow);
						} else {
							app.cart.refresh();
						}
					} else {
						var $response = $(response);
						var $addToCartData = $response.filter($cache.selectors.addToCartData);
						var productAddedInfo = {
							success: !$addToCartData.data('error'),
							errorMessage: $addToCartData.data('errorMessage'),
							statusCode: $addToCartData.data('statusCode')
						};

						if (!productAddedInfo.success && productAddedInfo.statusCode === 'checkATS') {
							var $button = $form.find($cache.selectors.addToCart).attr('disabled', 'disabled').removeClass($cache.classes.addToCartActive);
							var $error = $form.find($cache.selectors.availabilityMsg);

							if (!$error.length) {
								$error = $response.find($cache.selectors.availabilityMsg);
								$button.after($error);
							}

							$error.find('p').text(productAddedInfo.errorMessage);
							$error.show(200);

							setTimeout(function() {
								$error.hide(200);
								$button.removeAttr('disabled').addClass($cache.classes.addToCartActive);
							}, 3000);

							return;
						}

						app.fancybox.close();

						if (app.preferences.cartAddProductAjaxTarget === 'popup') {
							var $minicart;

							if (app.device.isMobileView()) {
								$minicart = $(response).filter('#app-components-global-minicart-template');

								if ($minicart.length) {
									$cache.document.trigger('product.added', [$form, productAddedInfo]);
								}

								showMinicartTemplate($minicart);
							} else {
								app.fancybox.open(response, {
									afterShow: function() {
										$minicart = $('#app-components-global-minicart-template');

										showMinicartTemplate($minicart);
										$cache.document.trigger('cart.addproduct.popup.open');
										$cache.document.trigger('product.added', $form);
									},
									afterClose: function() {
										$cache.document.trigger('cart.addproduct.popup.close');
									}
								});
							}
						} else {
							$cache.document.trigger('product.added', [$form, productAddedInfo]);
							$cache.document.trigger('minicart.show', {
								html: response
							});
							$cache.document.trigger('minicart.afterload');
							$cache.document.find('html').addClass('html_fancybox_lock');

							if (app.preferences.dynamicPromotionsEnable) {
								$cache.document.trigger('dynamicpromotion.minicart.update', response);
							}
						}
					}
				});
			}

			return false;

			function showMinicartTemplate($minicart) {
				if ($minicart.length) {
					$cache.document.trigger('minicart.show', {
						html: app.util.renderTemplate($minicart.html(), {})
					});
				}
			}
		},
		/**
		 * @function
		 * @description Scans all ui tabs panels and hides empty or shows ones with data
		 */
		emptyTabs : function (tabsContainerSelector, productTileSelector, wrapperSelector) {
			var productTileSelector = productTileSelector || $cache.selectors.productTile;
			var $tabContainer = $(tabsContainerSelector);
			var $wrapper = $(wrapperSelector);
			if ($tabContainer.length > 0) {
				if (!$tabContainer.tabs('instance')) {
					$tabContainer.tabs();
				}
				$tabContainer.tabs('instance').panels.each(function(index, panel) {
					if ($(panel).find(productTileSelector).length === 0) {
						hideShowTab($tabContainer, index, 'hide');
					} else {
						//For cases when we have additional style for wrapper
						if ($wrapper.length > 0) {
							$wrapper.addClass($cache.classes.recomVisible);
						}
						hideShowTab($tabContainer, index, 'show');
					}
				});
			}
		},
		/**
		 * @private
		 * @function
		 * @description Opens size chart table in fancybox
		 */
		sizeChartLink : function() {
			var url = $(this).data('href');
			var opt = {
				type: 'ajax',
				wrapCSS: 'fancybox-size_chart fancybox-size_chart_tabs',
				helpers: {
					overlay: {
						locked: true
					}
				},
				beforeShow: function() {
					$(document).trigger('sizechart.beforeshow');
				},
				afterShow: function() {
					$($cache.selectors.sizeChartTabs).tabs({
						activate: function( event, ui ) {
							$($cache.selectors.sizeChartTabs).attr('data-activated', ui.newTab.index());
						},
						create: function( event, ui ) {
							$($cache.selectors.sizeChartTabs).attr('data-activated', ui.tab.index());
						}
					});
				},
				onReady: function() {
					// workaround to prevent effect when pop-up flashes with 100% width before normal dimensions are set
					this.wrap && this.wrap.css('visibility', 'hidden');
				}
			};
			if (url) {
				if (app.configs.sizeChartFancybox) {
					opt = $.extend(opt, app.configs.sizeChartFancybox);
				}

				app.fancybox.open(url, opt);
			}
		},
		/**
		 * @private
		 * @function
		 * @description Submit notify me on sale form
		 */
		notifyMeOnSaleSubmit : function(){
			var $this = $(this),
			form = $this.closest('form');
			form.validate();
			if (!form.valid()) {
				return false;
			}
			var url = app.util.appendParamToURL(app.urls.notifyMeOnSaleSubmit, 'pid', $this.data('pid')),
				data = form.serialize();

			$.ajax({
				url: url,
				type: 'POST',
				dataType: 'html',
				data: data
			}).done(function(data) {
				if (data) {
					if (!disableFancyBox) {
						app.fancybox.open($('.js-footer_container'), {
							wrapCSS: $cache.classes.notifymePopupWrapper,
							content: data,
							type: 'html'
						});
					}
					$cache.document.trigger('notifymeonsale.sucesssubmit', {
						response: data
					});
				}
			});
		},
		/**
		 * @private
		 * @function
		 * @description Event handler to handle click on link notify me on sale
		 */
		notifyMeOnSaleLink:  function(e) {
			if (!disableFancyBox) {
				app.fancybox.open($($cache.selectors.notifyMeOnSaleDialog).html(), {
					wrapCSS: $cache.classes.notifymePopupWrapper
				});
				$cache.notifyMeOnSaleContainer.find($cache.selectors.fieldOnSaleEmail).val('');
				if (app.validator) {
					app.validator.init();
				}
			}
		},
		/**
		 * @private
		 * @function
		 * @description Event handler when product master reloaded
		 */
		masterReloaded:  function (e, data) {
			if(data.mode === 'quickview'){
				return;
			}
			var container = $(e.delegateTarget);
			app.owlcarousel.initCarousel(container.find($cache.selectors.mainImgCntr + " " + $cache.selectors.carouselWrapper));
			app.recommendations.init();
			loadLastVisited(container);
			if('custom' in app.components.product){
				app.components.product.custom.init({container: container});
			}
			initThumbnailsSlider($($cache.thumbnailsSlider));
			if (app.preferences.isMobileView) {
				app.components.product.slider.init();
			}
		},
		/**
		 * @private
		 * @function
		 * @description Initialize zoom (open fancybox with high resolution image)
		 *              on click event on main product image
		 */
		processZoom: function (e) {
			var container = $(e.delegateTarget);

			var params = {
				source: container.find($cache.selectors.fancyBoxCntr),
				fancyBoxWrapperClass: $cache.classes.fancyBoxWrapper
			};

			var imgSelector = getImgSelector(container);
			if ($(e.target).closest(imgSelector).length === 0 && imgSelector !== '.js-primary_image') {
				return;
			}
			$($cache.selectors.productTileImageSel).removeClass($cache.classes.target);
			$(e.target).addClass($cache.classes.target);
			var $zoomContainer = container.find($cache.selectors.imagesContainerForZoom),
				$zoomContainerClone = app.product.modifyZoomCntr($zoomContainer.clone()),
				zoomContainerHeight = window.outerHeight,
				primaryImage = $zoomContainerClone.find(imgSelector),
				imgUrl;

			if($(e.target).closest($cache.selectors.imagesContainerForZoomCustom).length) {
				primaryImage = $(e.target);
				switchThumbnail(primaryImage.data('imageindex'));
				primaryImage = primaryImage.clone().addClass($cache.classes.primaryImageClass);

				$.extend(params, {
					mainContainerClass: $cache.classes.zoomMainCntr,

					zoomedImgContainerClass: $cache.classes.zoomImgCntr,
					zoomedImgContainerSel: $cache.selectors.mainImgCntr,
					zoomedImg: primaryImage,

					thumbnailsDirectlyInMainCntr: true,
					thumbnails: $($cache.selectors.thumbnailsSlider).clone(),

					thumbnailSel: $cache.selectors.thumbnailItemSel,
					thumbnailImgSel: "[data-thumbnailindex!=''][data-thumbnailindex]",
					thumbnailSelectedClass: $cache.classes.thumbSelectedCls,

					afterZoomCntrWasConstructed: function(zoomCntr) {
						var zoomCntr = app.product.modifyZoomCntr(zoomCntr);
						$zoomContainer.trigger('images.container.cloned', {
							clone: zoomCntr
						});
						return zoomCntr;
					}
				});
			} else {
				$zoomContainer.trigger('before.images.container.cloned', {
					clone: $zoomContainerClone
				});

				params.providedZoomCntr = $zoomContainerClone;

				$zoomContainer.trigger('images.container.cloned', {
					clone: $zoomContainerClone
				});
			}

			params.afterShow = function(data) {
				loadThumbnails();
				initThumbnailsSlider($($cache.selectors.zoomThumbnailsSlider));
				initThumbnailsClickEvent($($cache.selectors.fancyBoxInner).find($cache.selectors.zoomThumbnailImg));
				$('.js-zoomed ' + $cache.selectors.mainImgCntr).on('click', function() {
					app.fancybox.close();
				});
				initSuperZoom.call(data.content.find($cache.selectors.mainImgCntr));

				$zoomContainer.trigger('images.container.zoomed', {
					clone: $zoomContainerClone
				});
				$cache.document.trigger('product.zoom.aftershow', {
					currentIndex: $(e.target).data("imageindex")
				});
			};

			if (primaryImage.length) {
				$(primaryImage).each(function() {
					if ($(this).closest($cache.selectors.mainImgCntr).data('altimage-url')) {
						imgUrl = $(this).closest($cache.selectors.mainImgCntr).data('altimage-url');
					} else {
						imgUrl = $(this).attr('src');
					}

					imgUrl = app.util.removeParamsFromURL(imgUrl, ['sw', 'sh', 'sm', 'cx', 'cy', 'cw', 'ch']);
					$(this).attr('src', imgUrl);
					$(this).prev('source').attr('srcset', imgUrl + ' 1x,' + imgUrl + ' 2x');
				});
			}

			app.zoom.showZoomedImg(params);
		},
		/**
		 * @private
		 * @function
		 * @description  onclick event show popup 'care and details'
		 */
		openPopupLinkEvents: function (e) {
			var fancyParent,
				activeOverlay = false,
				container = $(e.delegateTarget);
			app.fancybox.open(container.find($cache.selectors.carePopupContent), {
				helpers: {
					overlay: null
				},
				afterShow: function() {
					fancyParent = $('.fancybox-wrap').parents(); // normally html and body
					fancyParent.on('click', function(e) {
						e.stopPropagation();
						if (activeOverlay) app.fancybox.close();
						activeOverlay = true;
					});
					$('.fancybox-wrap').on('click', function(event) {
						event.stopPropagation();
					});
				},
				afterClose: function() {
					fancyParent.off('click');
				}
			});
		},
		/**
		 * @private
		 * @function
		 * @description Submit Notify Me form
		 * TODO: refactor approach for display of Prev and Next buttons on PDP
		 */
		submitNotifyMeEmail : function (e) {
			var form = $(e.currentTarget).closest('form');
			form.validate();
			if (!form.valid()) {
				return false;
			}
			var $variantionElem = $cache.notifyMeLinkInUseElement || $($cache.selectors.notifyMeSelect),
				url = app.util.appendParamToURL(app.urls.notifyMeSubmit, 'pid', $variantionElem.data('variantid')),
				data = form.serialize();

			$.ajax({
				url: url,
				type: 'POST',
				dataType: 'html',
				data: data
			}).done(function(data) {
				if (data) {
					$cache.document.trigger('notifyme.send', $cache.notifymeContainer);
					app.fancybox.open({
						wrapCSS: $cache.classes.notifymePopupWrapper + ' ' + $cache.classes.notifymeSuccessPopupWrapper,
						content: data,
						type: 'html',
						afterShow: function() {
							initNotifyMeFancyboxEvents($($cache.selectors.notifyMeContainer));
						}
					});
				} else {
					setTimeout(function() {
						app.fancybox.close();
					}, $cache.notifyMeAutoclose);
				}

				if ($cache.notifyMeAutoclose > 0) {
					setTimeout(function () {
						app.fancybox.close();
					}, $cache.notifyMeAutoclose);
				}
			});
		},
		/**
		 * @private
		 * @function
		 * @description Temp fix for saving "#start=*" hash during locale change, which shows Previous and Next buttons on PDP
		 * TODO: refactor approach for display of Prev and Next buttons on PDP
		 */
		languageSelector: function () {
			var aTag = $(this);
			if (window.location.hash) {
				aTag[0].hash = window.location.hash;
			}
		},
		/**
		 * @private
		 * @function
		 * @description change main content of product details page when selected new linked Product Set TAB
		 */
		linkedSetTab : function (e) {
			var productSetTabsData = $(e.delegateTarget).data('productSetTabs'),
				thisTabIndex = this.getAttribute('data-tab-index'),
				self = this,
				tabsModel = [];

			if (productSetTabsData) {
				//CHANGE data object for <MAIN> tag
				changeProductSetTabsData(e, productSetTabsData, thisTabIndex);
			} else {
				//CREATE data object for <MAIN> tag
				createProductSetTabsData($(e.delegateTarget));
			}

			changeVariationEventWrapper(e, {
				isMaster: true,
				self: self,
				url: app.util.appendParamsToUrl($(self).attr('href'), {
					format: 'ajax'
				})
			});
		},
		/**
		 * @private
		 * @function
		 * @description open Notify Me popup
		 */
		openNotifyMePopup : function (e, options) {
			var isMultiVariationsProduct = $(this).data('is-multivariations-product');

			if (isMultiVariationsProduct) {
				showNotifyMeVariationError.call(this);

				return;
			}

			var options = options || {};
			var variantID = $(this).data('variantid');
			var url = app.urls.notifyMeForm + (variantID ? '?variantid=' + variantID : '');
			var container = options.container instanceof $ ? options.container : $(e.delegateTarget);
			var isNotifyMeButton = $(e.target).hasClass($cache.classes.notifyMeBtn);
			container.find($cache.selectors.notifyMeLink).removeClass($cache.classes.notifyMe);
			$(this).addClass($cache.classes.notifyMe);
			$cache.notifyMeLinkInUseElement = $(this);
			if (!disableFancyBox || isNotifyMeButton) {
				app.fancybox.open(url, {
					type: 'ajax',
					wrapCSS: options.wrapCSS ? options.wrapCSS : $cache.classes.notifyMeFancyWrap,
					afterShow: function() {
						initNotifyMeFancyboxEvents($($cache.selectors.notifyMeContainer));

						if (document.querySelector(`${$cache.selectors.marketingNotify} input`).checked) {
							document.querySelector($cache.selectors.notifyMeGender)?.classList.remove($cache.classes.hiddenClass);
						}
					}
				});
				if (app.validator) {
					app.validator.init();
				}
			} else if (app.components.global.notifyme) {
				app.components.global.notifyme.open(url, {
					type: 'ajax',
					wrapCSS: $cache.classes.notifyMeFancyWrap
				});
			}
		},
		selectVariation : function(e) {
			var self = this,
				options = {
					self: self
				};
			changeVariationEventWrapper(e, options);
			if (!disableFancyBox) {
				$cache.document.on('click', $cache.selectors.marketingNotify, function() {
					var marketingnotify = $(this).find('input[type=checkbox]');
					marketingnotify.prop('checked', !marketingnotify.is(':checked'));
				});
			}
		},
		/**
		 * @private
		 * @function
		 * @description change main content of product details page when selected new color variants
		 */
		changeMainContent : function (e) {
			var container = $(e.delegateTarget);
			if ($(this).hasClass('js-unavailable-color')) {
				return false;
			}
			var self = this;

			if (app.preferences.iseLinkedProductSets) {
				var productSetTabsData = container.data('productSetTabs');
				if (productSetTabsData) {
					var url = this.getAttribute('href'),
						tabIndex;
					//Get active tab index from tab data
					for (var i = 0, length = productSetTabsData.length; i < length; i++) {
						if (productSetTabsData[i]['active']) {
							thisTabIndex = productSetTabsData[i]['tabIndex'];
							break;
						}
					}
					changeProductSetTabsData(e, productSetTabsData, thisTabIndex, {
						url: url
					});
				}
			}
			changeVariationEvent(e, {
				isMaster: true,
				self: self
			});
		},
		/**
		 * @private
		 * @function
		 * @description Change main and thumbnails images on hover event on variants
		 */
		hoverVariant : function (e){
			var $vimeoContainer = $($cache.selectors.vimeoContainer);
			if ($vimeoContainer.length && !$vimeoContainer.hasClass($cache.classes.loadedContainerClass)) {
				return;
			}

			var target = $(e.target);
			var container = '';

			if (target.data('container-element') !== undefined) {
				container = target.closest(target.data('container-element'));
			} else {
				container = $(e.delegateTarget);
			}

			if (container.hasClass($cache.classes.containerPDP)) {
				var $vimeoContainer = $($cache.selectors.vimeoContainer);
				if ($vimeoContainer.length && !$vimeoContainer.hasClass($cache.classes.loadedContainerClass)) {
					return;
				}
			}
			var primaryImage = container.find(getImgSelector(container)).first();
			if (e.type === 'mouseenter') {
				var thisData = $(this).data();
				var thumbsClass = thisData.thumbs;
				var thumbs = container.find(thumbsClass);

				var currentlyActiveSwatchIsHovered = $(this).closest($cache.selectors.selectedColorItem).length === 1;

				if (!currentlyActiveSwatchIsHovered) {
					primaryImage.closest($cache.selectors.vimeoContainer).addClass($cache.classes.vimeoSwatchHover);
				}

				if (app.util.getConfig('product.downloadImagesLazily')) {
					var attributeValues = thisData.lgimg;
					if (thisData.vimeoThumbnailSrc) {
						attributeValues.hires = thisData.vimeoThumbnailSrc;
						if (!currentlyActiveSwatchIsHovered) {
							primaryImage.closest($cache.selectors.primaryImgContainer).addClass($cache.classes.vimeoPreviewThumbnail);
						}
					}
					changeMainImage({
						primaryImage: primaryImage,
						attributeValues: attributeValues
					});
				}

				$cache.primaryImage.src = primaryImage.attr('src');
				$cache.primaryImage.alt = primaryImage.attr('alt');
				$cache.primaryImage.title = primaryImage.attr('title');

				swapImage(this, container);
				container.find($cache.selectors.thumbnailsSlider).hide();
				container.find($cache.selectors.thumbnailsAdditional).hide();

				initThumbsSrc(thumbs);
				thumbs.show();
				initThumbnailsSlider(thumbs);

				var dataPid = thisData.pid;

				if(app.util.getConfig('product.showSizeOnVariationHover')){
					target.closest($cache.selectors.productContent).find($cache.selectors.sizeListsItem).not($cache.classes.hiddenClass).addClass($cache.classes.hiddenClass);
					target.closest($cache.selectors.productContent).find('#' + dataPid).closest($cache.selectors.sizeListsItem).removeClass($cache.classes.hiddenClass);
				}

			} else if (e.type === 'mouseleave') {
				var originalSrcSet = primaryImage.parent('picture').data($cache.originalSrcSetDataAttr);
				var source = primaryImage.siblings('source');

				primaryImage.closest($cache.selectors.vimeoContainer).removeClass($cache.classes.vimeoSwatchHover);

				primaryImage.closest($cache.selectors.primaryImgContainer).removeClass($cache.classes.vimeoPreviewThumbnail);

				primaryImage.attr('src', $cache.primaryImage.src);
				primaryImage.attr('alt', $cache.primaryImage.alt);
				primaryImage.attr('title', $cache.primaryImage.title);

				if (source.length) {
					source.attr('srcset', originalSrcSet);
				}

				container.find($cache.selectors.thumbnailsAdditional).hide();
				container.find($cache.selectors.thumbnailsSlider).show();

				if(app.util.getConfig('product.showSizeOnVariationHover')){
					target.closest($cache.selectors.productContent).find($cache.selectors.sizeListsItem).not($cache.classes.hiddenClass).addClass($cache.classes.hiddenClass);
					target.closest($cache.selectors.productContent).find($cache.selectors.sizeListsItem + '.m-default').removeClass($cache.classes.hiddenClass);
				}
			}
		},

		clickThumbnails : function(e) {
			e.stopPropagation();
			var $this = $(this),
				dataImg = $this.data('lgimg'),
				container =  $(this).closest('main').find($cache.selectors.mainImgCntr);

			$cache.document.trigger('product.thumbnail.change', {
				currentIndex: $this.data('thumbnailindex'),
				positionIndex: $this.data('positionindex'),
				isZoomed: $this.closest($cache.selectors.zoomed).length
			});
			// if its zoomed product image override src, title, alt
			// attributes and image container

			if ($this.closest($cache.selectors.zoomed).length) {
				dataImg = $this.data('zoomimg');
				container = $this.closest($cache.selectors.zoomed);

				if (dataImg.url == 'null') {
					dataImg.url = $this.data('lgimg').url;
				}

				dataImg.url = app.util.removeDISParameters(dataImg.url);

				var zoomMode = $($cache.selectors.imagesContainerForZoom).data('zoomMode') || '';
				zoomScrollTo(container.find(getImgSelector(container)), zoomMode);
			}

			if (!dataImg) {
				var $fullscreenDiv = $($cache.selectors.fullscreenDiv);
				var imageElem = $fullscreenDiv.find($cache.selectors.carouselImage);
				var sourceElem = $fullscreenDiv.find('source');
				var imgUrl = app.util.removeParamsFromURL(imageElem.attr('src'), ['sw', 'sh', 'sm', 'cx', 'cy', 'cw', 'ch']);

				imageElem.attr('src', imgUrl);
				sourceElem.attr('srcset', imgUrl + ' 1x,' + imgUrl + ' 2x');

				return;
			}

			var zoomedImg = container.find(getImgSelector(container));

			if (!zoomedImg.is('img')) {
				zoomedImg = zoomedImg.find('img');
			}

			if (zoomedImg.length) {
				zoomedImg
					.show()
					.attr({
						src: dataImg.url,
						alt: dataImg.alt,
						title: dataImg.title
					});
				zoomedImg.prev('source').attr('srcset', dataImg.url + ' 1x,' + dataImg.url + ' 2x');
			}

			$this
				.parents($cache.selectors.thumbnails)
				.find($cache.selectors.thumbnailItemSel)
				.removeClass($cache.classes.thumbSelectedCls);
			var $parent = $this.parent();

			if ($parent.hasClass($cache.classes.thumbnailItemClass)) {
				$parent.addClass($cache.classes.thumbSelectedCls);
			} else {
				$parent.parent($cache.selectors.thumbnailItemSel).addClass($cache.classes.thumbSelectedCls);
			}
		}
	};
	/** ************* Object of method for external functionality ************** */
	methods = {
		/**
		 * @function
		 * @description Loads a product into a given container div
		 * @param {Object}
		 *            options An object with the following properties:</br>
		 *            <p>
		 *            containerId - id of the container div, if empty then
		 *            global app.containerId is used
		 *            </p>
		 *            <p>
		 *            source - source string e.g. search, cart etc.
		 *            </p>
		 *            <p>
		 *            label - label for the add to cart button, default is Add
		 *            to Cart
		 *            </p>
		 *            <p>
		 *            url - url to get the product
		 *            </p>
		 *            <p>
		 *            id - id of the product to get, is optional only used when
		 *            url is empty
		 *            </p>
		 */
		get: function(options) {
			var target = options.target || app.quickView.init();
			var source = options.source || '';
			var productListID = options.productlistid || '';

			var productUrl = options.url || app.util.appendParamToURL(app.urls.getProductUrl, 'pid', options.id);
			if (source.length > 0) {
				productUrl = app.util.appendParamToURL(productUrl, 'source', source);
			}
			if (productListID.length > 0) {
				productUrl = app.util.appendParamToURL(productUrl, 'productlistid', productListID);
			}

			// show small loading image
			// app.progress.show(app.ui.primary);
			app.ajax.load({
				target: target,
				url: productUrl,
				data: options.data || '',
				// replace with callback passed in by options
				callback: options.callback || app.product.init
			});
		},

		/**
		 * @function
		 * @description Gets the availability to given product and quantity
		 */
		getAvailability : function (pid, quantity, callback) {
			app.ajax.getJson({
				url: app.util.appendParamsToUrl(app.urls.getAvailability, {
					pid: pid,
					Quantity: quantity
				}),
				callback: callback
			});
		},
		/**
		 * @function
		 * @description Binds the click event to a given target for the
		 *              add-to-cart handling
		 * @param {Element}
		 *            target The target on which an add to cart event-handler
		 *            will be set
		 */
		initAddToCart : function(target) {
			if (!$cache) {
				initializeCache($('.js-pdp_main'));
			}
			if(!($cache.addToBagOnSizeSelect && app.page.ns === "product") || $cache.oneSizeProduct.length){
				target = target ? target : $cache.container;
				target.off('click').on('click', $cache.selectors.addToCart, setAddToCartHandlerWrapper);
			}
		},
		modifyZoomCntr : function(container) {
			return container;
		}, //overlap
		beforeChangeVariationEventFunction : function(func){
			beforeChangeVariationEventFunction = func;
		},
		beforeAddToCartFunction : function(func){
			beforeAddToCartFunction = func;
		},
		isVariationSelected: function(productContainerSelector = $cache.selectors.productContent) {
			return $(productContainerSelector).find($cache.selectors.errorVariationsSel).length === 0;
		}
	};

/** ************* app.product public object ************** */
	app.product = {
		init: function(containerSel, force) {
			containerSel = containerSel || '.js-pdp_main';
			var container = $(containerSel);
			if (!container.length || container.hasClass('js-product-initialized') && (typeof force !== 'undefined' && force !== true)){
				return;
			}
			initSettings();
			initializeCache(container);
			initializeDom();
			initializeEvents();
			container.addClass('js-product-initialized');
			app.wishlist.updateWishlistButtons(container);

			if (containerSel === '.js-quick_view_main') {
				app.wishlist.init();
			}

			if ($('klarna-placement').length) {
				app.components.global.klarnapayments.siteMessageUpdate();
			}
		},
		get : methods.get,
		getAvailability : methods.getAvailability,
		initAddToCart : methods.initAddToCart,
		setAddToCartHandler: setAddToCartHandlerWrapper,
		handleEmptyTabs: eventHandlers.emptyTabs,
		modifyZoomCntr: methods.modifyZoomCntr,
		beforeChangeVariationEventFunction : methods.beforeChangeVariationEventFunction,
		beforeAddToCartFunction : methods.beforeAddToCartFunction,
		isVariationSelected: methods.isVariationSelected,
		openNotifyMePopup : eventHandlers.openNotifyMePopup,
		submitNotifyMeEmail: eventHandlers.submitNotifyMeEmail,
		initSuperZoom: initSuperZoom,
		getImgSelector: getImgSelector,
		loadThumbnails: loadThumbnails
	};
})((window.app = window.app || {}), jQuery);
