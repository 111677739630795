/**
 * @class app.components.global.searchplaceholder
 */
(function (app, $) {
	/**
	 * @private
	 * @function
	 * @description Binds event to the place holder (.blur)
	 */
	function initializeEvents() {
		$('#q').focus(function () {
			var input = $(this);
			if (input.val() === input.attr("placeholder")) {
				input.val("");
			}
		})
		.blur(function () {
			var input = $(this);
			if (input.val() === "" || input.val() === input.attr("placeholder")) {
				input.val(input.attr("placeholder"));
			}
		})
		.blur();
	}

	/*************** app.components.searchplaceholder public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.searchplaceholder = {
	
		init : function (params) {
			if (params.initSearchPlaceholder) {
				initializeEvents();
			}
		}
			
			
	};
}(window.app = window.app || {}, jQuery));