(function (app, $) {

	var $cache = {},
		event = Modernizr.touchevents ? 'touchstart' : 'click',
		videoPlacement = '.js-video_placement';

	function initializeCache() {  
	}
	
	function initializeEvents() {
		 $('.js-youtube_img-play')
			.on(event, function(e){
				
				var $this = $(this),
						videoId = $this.data('video'),
						youtubeIframe = '<iframe src="//www.youtube.com/embed/' + videoId + '?rel=0&autohide=1&autoplay=1&showsearch=0&iv_load_policy=3&theme=light&modestbranding=1&showinfo=0"></iframe> ';

				var videoContainer = $(videoPlacement);
				videoContainer = videoContainer.length ? videoContainer : $this;
				videoContainer.append(youtubeIframe);
				
				if (videoId && videoContainer.length) {
			        $('html,body').animate({
			          scrollTop: videoContainer.offset().top
			        }, 1000);
			        e.stopPropagation();
			        return false;
			    }	
		});  // EO $cache.videoContainer.on();
	
	}

	/*************** app.components.global.youtubeImgPlay public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.youtubeImgPlay = {
			// initializations
			init : function (params) {
				initializeCache();
				initializeEvents();
			}
	};
})(window.app = window.app || {}, jQuery);