import ApplePayDefaultHandler from 'oneapp/src/classes/checkout/payments/applePay/handlers/ApplePayDefaultHandler';

(function(app, $, dwObject) {
	// If the Apple Pay payment processor is not WORLDPAY, skip the initialization of the script.
	if (
		!app.paymentInfo.applePayMerchantProvider
		|| app.paymentInfo.applePayMerchantProvider !== 'WORLDPAY'
	) {
		return;
	}

	const $cache = {
		document: $(document),
		selectors: {
			privacyBlock: '.js-applepay-policy-wrapper',
			checkoutAlter: '.b-checkout_button-alter',
			applePayButtonWrapper: '.js-applepay-button',
			privacyForm: '.js-applepay-policy-form',
			alter: '.js-alter',
			productWrapper: '.js-product_content',
			errorVariations: '.js-error_variations',
			payPalButtonWrapper: '.b-express_paypal-btn',
			amazonButtonWrapper: '.b-amazon_button',
			outerPrivacyBlock: 'outer'
		},
		classes: {
			applepayGeneralButton: 'dw-apple-pay-button',
			applePayCheckoutButton: 'dw-apple-pay-cart',
			applePayMinicartButton: 'dw-apple-pay-mini-cart',
			applePayPDPButton: 'dw-apple-pay-pdp',
			applePayQuickviewButton: 'dw-apple-pay-quickview',
			hHidden: 'h-hidden'
		}
	};

	function initializeEvents() {
		$cache.document.on('cart.updateModels cart.shippingCountryChange', function() {
			beginAPProcess();
		});

		// ApplePay event
		document.addEventListener('click', applePayHandler, false);

		if (app.page.ns !== 'checkout') {
			$cache.document.on('product.added minicart.product.removed minicart.afterload product.variation.reloaded quickview.opened fancy.mobile.added', function() {
				beginAPProcess();
			});
		} else {
			$cache.document.on('cart.updateModels cart.shippingCountryChange', function() {
				beginAPProcess();
			});
		}
	}

	function initializeDOM() {
		ready(function() {
			beginAPProcess();
		});
	}

	function beginAPProcess() {
		if (app.util.isApplePaySupported()) {
			showApplePayButton();
			showAlterSignatures();
		}
	}

	function applePayHandler(evt) {
		const target = evt.target;
		const productID = target.dataset.sku;

		if (target.classList.contains($cache.classes.applepayGeneralButton)) {
			evt.preventDefault();

			const errorWrapper = $(evt.target).closest($cache.selectors.productWrapper);
			const errorVariationsBlock = errorWrapper.find($cache.selectors.errorVariations);

			if (productID && errorVariationsBlock.length) {
				$cache.document.trigger('product.invalid', [errorVariationsBlock]);

				return	errorVariationsBlock.show();
			}

			// checking privacy policy
			const $privacyBlocks = $(determinePrivacyBlock(target));
			const isHidden = $privacyBlocks.hasClass($cache.classes.hHidden);
			let isValid = true;

			revealPrivacyBlocks($privacyBlocks);
			alignPrivacyPolicy();

			if (!isHidden) {
				$.each($privacyBlocks, function() {
					const $privacyBlock = $(this);
					const $privacyForm = $privacyBlock.children($cache.selectors.privacyForm);

					if ($privacyForm.length) {
						$privacyForm.validate();

						var result = $privacyForm.valid();

						if (isValid) {
							isValid = result;
						}
					}
				});

				if (isValid) {
					startSession(productID);
				}
			}
		}
	}

	function alignPrivacyPolicy() {
		const items = [
			$cache.selectors.applePayButtonWrapper,
			$cache.selectors.checkoutAlter,
			$cache.selectors.payPalButtonWrapper,
			$cache.selectors.amazonButtonWrapper
		];
		const $outerCheckoutPolicy = $($cache.selectors.privacyBlock + $cache.selectors.outerPrivacyBlock);

		$.each($outerCheckoutPolicy, function() {
			const checkoutSignature = $(this);

			if (checkoutSignature.is(':visible')) {
				let outerSum = 0;

				for (let i = 0, len = items.length; i < len; i++) {
					const sibling = checkoutSignature.siblings(items[i]);

					outerSum += sibling.length ? sibling.outerWidth(true) : 0;
				}

				checkoutSignature.width(outerSum);
			}
		});
	}

	function revealPrivacyBlocks($privacyBlocks) {
		let firstOpen = false;

		if ($privacyBlocks) {
			$.each($privacyBlocks, function() {
				if (!firstOpen) {
					firstOpen = this.classList.contains($cache.classes.hHidden);
				}

				this.classList.remove($cache.classes.hHidden);
			});
		}

		$cache.document.trigger('appleypay.privacy.revealed', [$privacyBlocks, firstOpen]);
	}

	function determinePrivacyBlock(applePayButton) {
		if (applePayButton.classList.contains($cache.classes.applePayPDPButton)) {
			if (applePayButton.classList.contains($cache.selectors.outerPrivacyBlock)) {
				return $cache.selectors.privacyBlock + '-pdp[data-product-id="' + applePayButton.dataset.sku + '"]';
			}

			return $cache.selectors.privacyBlock + '-pdp-' + applePayButton.dataset.sku;
		} else if (applePayButton.classList.contains($cache.classes.applePayQuickviewButton)) {
			if (applePayButton.classList.contains($cache.selectors.outerPrivacyBlock)) {
				return $cache.selectors.privacyBlock + '-quickview[data-product-id="' + applePayButton.dataset.sku + '"]';
			}

			return $cache.selectors.privacyBlock + '-quickview-' + applePayButton.dataset.sku;
		} else if (applePayButton.classList.contains($cache.classes.applePayMinicartButton)) {
			return $cache.selectors.privacyBlock + '-minicart';
		} else if (applePayButton.classList.contains($cache.classes.applePayCheckoutButton)) {
			return $cache.selectors.privacyBlock + '-checkout';
		}
	}

	function showApplePayButton() {
		const APWrappers = document.querySelectorAll($cache.selectors.applePayButtonWrapper);

		APWrappers.forEach(function(APWrapper) {
			if (APWrapper.dataset.hidden === 'false') {
				APWrapper.classList.remove($cache.classes.hHidden);

				if (app.page.ns === 'checkout') {
					APWrapper.dataset.hidden = 'true';
				}
			}
		});
	}

	/*
	 * Method for starting work when all code is rendered
	 */
	function ready(fn) {
		document.onreadystatechange = function() {
			if (document.readyState === 'complete') {
				fn();
			}
		};
	}

	function showAlterSignatures() {
		const alters = document.querySelectorAll($cache.selectors.alter);

		alters.forEach(function(alter) {
			if (alter.dataset.applepayAlter === 'true') {
				alter.classList.remove($cache.classes.hHidden);
			}
		});
	}

	function startSession(productID) {
		const handler = new ApplePayDefaultHandler(app.preferences.applePayPublicConfigurations);

		handler.handlePayment(productID)
			.then((paymentResult) => {
				switch (paymentResult.status) {
					case 'completed':
						window.location = paymentResult.redirectURL;

						break;
					default:
						break;
				}
			});
	}

	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.applepay = {
		init: function() {
			if (app.paymentInfo.applePayMerchantProvider && dwObject.applepay && !$.isEmptyObject(dwObject.applepay)) {
				initializeDOM();
				initializeEvents();
			}
		},
		showApplePayPrivacyBlocks: function() {
			$($cache.selectors.privacyBlock).removeClass($cache.classes.hHidden);
		}
	};
})(window.app = window.app || {}, jQuery, window.dw = window.dw || {});
