/* global define*/
define('app.components.search.anchorback', function (require, exports) {

	var $cache = {},
		$ = require('$'),
		$document = require('$doc'),
		$window = require('$win'),
		anchorBackInfo = [],
		loadsCompleted = 0,
		localUrl = window.location.href;

	function initializeCache() {
		$cache.isSubView = $('.js-sub-view-conteiner').length;
		$cache.loadElemSel = $cache.isSubView ? '.js-subview-infinite-loads' : '[data-infinite-loads]:last';
		$cache.loadNextPageSel = '.js-load_next_page';
		$cache.curSubViewCntrSel = '.js-sub-view-conteiner.unloaded:first';
		$cache.pageContainer = '.js-list_item_page';
	}
	
	/**
	 * @private
	 * @function
	 * @description Add page information to cookie
	 * @param {Object} pageInfo. Object attributes(
	 * 			{String}	context 	: Selector for move block. (Used only on LNCC), 
	 * 			{String}	url 		: Current page url, 
	 * 			{Integer}	position 	: Scroll top position, 
	 * 			{Integer}	infiniteLoads : Count ajax calls on infinite scroll
	 * 			)
	 */
	function addPageInfo(pageInfo) {
		delPageInfo(pageInfo);
		anchorBackInfo.push(pageInfo);
		if (anchorBackInfo.length > parseInt(app.preferences.anchorBackSavePagesCount)) {
			anchorBackInfo = anchorBackInfo.slice(anchorBackInfo.length - parseInt(app.preferences.anchorBackSavePagesCount));
		}
		setAnchorBackCookie(anchorBackInfo);
	}
	
	/**
	 * @private
	 * @function
	 * @description Load information from cookie to var 'anchorBackInfo'
	 * @param none
	 */
	function loadPageInfo() {
		anchorBackInfo = getAnchorBackCookie();
	}
	
	/**
	 * @private
	 * @function
	 * @description Get page information
	 * @param {String} url - Current page url. 
	 * @returns {Object|False} Page Information object {context, url, position, infiniteLoads} or False
	 */
	function getPageInfo(url) {
		if (!url) {
			return false;
		}
		
		for (var i in anchorBackInfo) {
			if (anchorBackInfo[i].url === url) {
				return anchorBackInfo[i];
			}
		}
		
		return false;
	}
	
	/**
	 * @private
	 * @function
	 * @description Delete page information from cookie
	 * @param {Object} pageInfo - {url : Page url to delete from cookie}
	 * @returns {Boolean} - Delete result
	 */
	function delPageInfo(pageInfo) {
		if (!pageInfo || !pageInfo.url) {
			return false;
		}
		
		var anchorBackInfoNew = [];
		for (var i in anchorBackInfo) {
			if (anchorBackInfo[i].url !== pageInfo.url) {
				anchorBackInfoNew.push(anchorBackInfo[i]);
			}
		}
		anchorBackInfo = anchorBackInfoNew;
		setAnchorBackCookie(anchorBackInfo);
		return true;
	}
	
	/**
	 * @private
	 * @function
	 * @description Get information from cookie 'anchorBackInfo'
	 * @returns {Array} - Array have 'Page Information' objects
	 */
	function getAnchorBackCookie() {
		var cookie = $.cookie('anchorBackInfo') ? JSON.parse($.cookie('anchorBackInfo')) : {};
		if (window.name && cookie[window.name]) {
			return cookie[window.name];
		}
		return [];
	}
	
	/**
	 * @private
	 * @function
	 * @description Set information to cookie 'anchorBackInfo'
	 * @param {Array} - Array have 'Page Information' objects
	 */
	function setAnchorBackCookie(value) {
		var jsonAnchorBackInfo, cookie,
			expiration = new Date(),
			time = 86400; // One day
		if (!window.name) {
			window.name = 'Win' + expiration.getTime();
		}
		
		cookie = $.cookie('anchorBackInfo') ? JSON.parse($.cookie('anchorBackInfo')) : {};
		cookie[window.name] = value;
		jsonAnchorBackInfo = JSON.stringify(cookie),
		
		expiration.setTime(expiration.getTime() + (time * 1000));
		$.cookie('anchorBackInfo', jsonAnchorBackInfo, { expires: expiration });
	}
	
	/**
	 * @private
	 * @function
	 * @description Scroll page (or block on LNCC) to saved position
	 * @param {Object} pageInfo - {position : Scroll top position, context : Selector for move block. (Used only on LNCC)}
	 */
	function scrollTo(pageInfo) {
		$('.js-search_result-content').removeAttr('style');
		var context = $window;
		var infiniteTop = $($cache.pageContainer).length ? $($cache.pageContainer).eq(pageInfo.infiniteLoads).offset().top : 0;
		if (pageInfo.context) {
			context = pageInfo.context;
		}
		$(context).scrollTop(infiniteTop);
		
		$window.one('lazyload', function(){
			$(context).scrollTop(infiniteTop + pageInfo.position);
		});
	}
	
	/**
	 * @private
	 * @function
	 * @description Generate event for ajax load infinite scroll block
	 * @param {Object} pageInfo - {
	 * 				{String}	context 	: Selector for move block. (Used only on LNCC), 
	 * 				{String}	url 		: Current page url, 
	 * 				{Integer}	position 	: Scroll top position, 
	 * 				{Integer}	infiniteLoads : Count ajax calls on infinite scroll
	 * 			}
	 */
	function preloadUpdate(pageInfo) {
		if (loadsCompleted < pageInfo.infiniteLoads) {
			if (app.device.isMobileView()) {
				$document.trigger('grid-preload-update')
			} else {
				if ($cache.isSubView) {
					var $loadEl = $($cache.curSubViewCntrSel).find($cache.loadNextPageSel);
					app.preferences.isSubViewHitsAutoLoad ? app.search.loadNextPage($loadEl[0]) : $document.trigger('grid-preload-update');
				} else {
					app.preferences.isHitsAutoLoad ? app.search.loadNextPage($($cache.loadNextPageSel)[0]) : $document.trigger('grid-preload-update');
				}				
			}
		} else {
			$document.off('grid-preload-updated');
			scrollTo(pageInfo);
			delPageInfo(pageInfo);
		}
	}
	
	/**
	 * @private
	 * @function
	 * @description Init event handler for (infinite scroll block loaded)
	 */
	function initEventPreloadUpdated(pageInfo) {
		$document.on('grid-preload-updated', function () {
			loadsCompleted = $($cache.loadElemSel).data('infinite-loads');
			preloadUpdate(pageInfo);
		});
		
	}
	
	function initializeMobileEvents() {
		initializeEvents(); 
	}

	function initializeEvents() {
		$document.on('scroll search.anchorscroll', function (e, data) {
			var scrollTop = 0,
				loadingPlaceHolder = $($cache.loadElemSel),
				infiniteLoads = 0,
				infiniteScroll = 0,
				pageInfo = {};
			if (data && data.scrollTop) {
				scrollTop = data.scrollTop;
			} else {
				scrollTop = $window.scrollTop();
			}
			 
			$($cache.pageContainer).each(function(index) {
				if (scrollTop >= this.offsetTop) {
					infiniteLoads = index;
					infiniteScroll = scrollTop - this.offsetTop;
				}
			});
			if (data && data.context) {
				pageInfo.context = data.context || null;
			}
			
			pageInfo.url = localUrl;
			pageInfo.position = infiniteScroll;
			pageInfo.infiniteLoads = infiniteLoads || 0;
			addPageInfo(pageInfo);
		});
		
		$document.on('mousewheel', function(e){
			$document.off('grid-preload-updated');
		});
		
		$document.on('grid-update', function(e){
			window.location.replace("#anchorBack");
			localUrl = window.location.href;
		});
	}
	
	function init(data, isPreload) {
		loadPageInfo();
		
		if(data && data.url ){
			localUrl = data.url;
		}
		
		var pageInfo = getPageInfo(localUrl);

		if (pageInfo && isPreload) {
			initEventPreloadUpdated(pageInfo);
			preloadUpdate(pageInfo);
		}
	}

	exports.init = function (data) {
		initializeCache();
		
		if (app.preferences.anchorBackEnable && !app.preferences.enableInfiniteScrollForSEO) {
			if ('scrollRestoration' in history) {
				history.scrollRestoration = 'manual';
			}
			var isPreload = false;
			if (window.location.hash=="#anchorBack"  && app.preferences.isClusterAnchorHash == false) {
				isPreload = true;
			} else {
				window.location.replace("#anchorBack");
			}
			// to get href after anchor id added
			localUrl = window.location.href;
			
			if (require('device').isMobileView()) {
				initializeMobileEvents();
			} else {
				initializeEvents();
			}
			init(data, isPreload);
		}
	};
	

});
