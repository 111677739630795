(function(app, $) {
	var $cache = {};
	
	function initializeCache() {
		$cache = {
			document : $(document),
			decoWidget : $('#deco-widget'),
			selectors: {
				decoMainButton : '#deco-main-button'
			}
		};
	}

	function initializeEvents() {
		if ($cache.decoWidget.length) {
			if (typeof window.drawDecoWidget === "function") {
				drawWidget();
			}
			else {
				window.addEventListener("decowidgetready", drawWidget);
			}
			
			$cache.document.on('click', $cache.selectors.decoMainButton, function() {
				return false;
			});
		}
	}
	
	function drawWidget() {
		window.drawDecoWidget(function() {
			$('<form action="' + app.urls.decoPlaceOrder + '"></form>').appendTo('body').submit();
		}, $cache.decoWidget.data('settings'));
	}

	/**
	 * @namespace app.components.global.deco public object
	 **/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.deco = {
		init: function() {
			initializeCache();
			initializeEvents();
		}
	};
})((window.app = window.app || {}), jQuery);
