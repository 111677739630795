( function(app, $) {
	var $cache = {};
	
	function initializeCache(params) {
		$cache = {
			window: $(window),
			document: $(document),
			body: $('body'),
			header: $('header'),
			headerMainTop: $('js-header_main-top'),
			headerSlotContainer: $('.js-section-1'),
			scrollStateTop: true,
			transparentWhileScrolling: false,
			numberOfOpenedBlocks: 0,
			headerTransparentState: true,
			headerBeforeScroll: 'b-transparent_header',
			headerTransparent: $('.js-transparent_header'),
			headerSlimClass: 'slim-header',
			countrySelectorTitleName: 'js-country_selector-title',
			countrySelectorTitle: $('.js-header_country_selector_item .js-country_selector-title'),
			flyoutWrapperName: 'js-header_min_country_selector',
			flyoutWrapper: $('.js-header_min_country_selector'),
			languageInformerLinkKeep: 'js-language_informer-link-keep',
			toneClassAttrName: 'data-tone-class',
			toneCorrectorBlock: $('.js-tone-corrector'),
			tooltipsOpen: false,
			justLeft: false
		};
		$cache.elementDataToneSel = '.js-tone-corrector [' + $cache.toneClassAttrName + ']';
		$cache.event = {
			headerTransparencyChange : 'headerTransparency.change'
		};

		if (params) {
			$cache = $.extend(true, {}, $cache, params);
		}
	}

	function initializeDom(){
		$cache.toneCorrectorBlock.length && addHeaderToneClass();
	}
	function initializeEvents() {
		if(!$cache.transparentWhileScrolling) {
			$cache.window.on('scroll', function() {
				if ($cache.headerSlotContainer.length
					? $(this).scrollTop() + $cache.headerMainTop.height() < ($($cache.headerSlotContainer).height() / 2)
					: ($cache.headerTransparent.length > 0 && !$cache.header.hasClass($cache.headerSlimClass))) {
					if (!$cache.scrollStateTop) {
						changeHeaderColor(false);
						$cache.scrollStateTop = true;
					}
				} else {
					if($cache.scrollStateTop){
						changeHeaderColor(true);
						$cache.scrollStateTop = false;
					}
				}
			});
		}

		$cache.countrySelectorTitle.on('touchstart', function() {
			changeHeaderColor(!$cache.flyoutWrapper.hasClass('h-hidden'));
		});
		
		$cache.document.on('toggler.toggled', function(e, object){
			if(!object.$this.hasClass($cache.languageInformerLinkKeep)){
				changeHeaderColor(!$cache.tooltipsOpen);
				$cache.tooltipsOpen = !$cache.tooltipsOpen;
			}
		}).on('toggler.untoggled', function(){
			changeHeaderColor(false);
			$cache.tooltipsOpen = !$cache.tooltipsOpen;
		});
		
		$cache.header.on('menu.open', function(){
			changeHeaderColor(true);
			
		}).on('menu.close', function(){
			changeHeaderColor(false);
		});

		if(!app.device.isTabletUserAgent()){
			$cache.header.on('mouseenter menu.popupOpen', function() {
				$cache.justLeft = false;
				changeHeaderColor(true);
			}).on('mouseleave menu.popupClose', function() {
				if(!$cache.justLeft){
					$cache.justLeft = true;
					changeHeaderColor(false);
				}
			});
		}else{
			$cache.countrySelectorTitle.on('mouseout', function(e) {
				var target = $(e.relatedTarget);
				if( !target.hasClass($cache.countrySelectorTitleName) && !target.parents().hasClass($cache.flyoutWrapperName) 
						&& !target.hasClass($cache.flyoutWrapperName) )
				{
					changeHeaderColor(false);
				}				
			});
		}
	}

	function changeHeaderColor(removeTransparent){
		if(removeTransparent){
			$cache.numberOfOpenedBlocks++;
			$cache.header.removeClass($cache.headerBeforeScroll);
			$cache.headerTransparentState = false;
			removeHeaderToneClass();
		}else if($cache.numberOfOpenedBlocks > 0){
			if($cache.numberOfOpenedBlocks == 1){
				$cache.header.addClass($cache.headerBeforeScroll);
				$cache.headerTransparentState = true;
				addHeaderToneClass();
			}
			$cache.numberOfOpenedBlocks--;
		}
		$cache.document.trigger($cache.event.headerTransparencyChange);
	}
	
	function addHeaderToneClass(){
		if($cache.headerTransparentState){
			var el = getActiveElement($cache.elementDataToneSel);
			if(el){
				var attrClass = el.getAttribute($cache.toneClassAttrName);
				if(attrClass !== $cache.header.attr($cache.toneClassAttrName)){
					removeHeaderToneClass();
				}
				$cache.header.addClass(attrClass).attr($cache.toneClassAttrName, attrClass);
			}
		}
	}
	
	function removeHeaderToneClass(){
		$cache.header.removeClass($cache.header.attr($cache.toneClassAttrName));
	}
	
	// Function return most visible element for header transparent effect(nearest left & top of window)
	function getActiveElement(selector){
		var els = document.querySelectorAll(selector),
			rect = {},
			rectTemp = {},
			el = null;
		
		if (els.length > 0){
			el = els[0];
			rect = els[0].getBoundingClientRect();
			for(var i = 1, l = els.length; i < l; i++){
				rectTemp = els[i].getBoundingClientRect();
				if( (rectTemp.bottom > 0 || rectTemp.top < window.innerHeight) && (rectTemp.right > 0 || rectTemp.left > window.innerWidth) ){
					if ( (Math.abs(rect.top) > Math.abs(rectTemp.top)) || ( (Math.abs(rect.top) === Math.abs(rectTemp.top)) && (Math.abs(rect.left) > Math.abs(rectTemp.left)) ) ){
						rect = rectTemp;
						el = els[i];
					}
				}
			}
			
			if(rect.bottom < 0 || rect.top > window.innerHeight || rect.right < 0 || rect.left > window.innerWidth){
				el = null;
			}
		}
		return el;
	}
	
	/**
	 * @namespace app.components.global.transparentheader public object
	 **/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.transparentheader = {
		init : function(params) {
			var isHomePage = app.page && app.page.currentPage && (app.page.currentPage === 'homepage');
			if (!params.onlyHomePage || isHomePage) {
				if (app.preferences.transparentHeader) {
					initializeCache(params);
					var disabledTransparent = false;
					if (app.configs.header.disableTransparent.categories.length > 0 && app.configs.landingpages.showCategoryID) {
						var categories = app.configs.header.disableTransparent.categories;
						for (var id in categories) {
							var currentCategory = $cache.body.find('#' + categories[id]);
							if (currentCategory.length > 0) {
								disabledTransparent = true;
							}
						}
					}
					if (!disabledTransparent) {
						initializeDom();
						initializeEvents();
					}
				}
			}
		},
		addHeaderToneClass: addHeaderToneClass
	};
	
}(window.app = window.app || {}, jQuery));