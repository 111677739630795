(function(app, $) {
	var $cache,
		dataGetters,
		providerSchemeConfig,
		fillProviderProductsConfig,
		fillRecommendationBlockHandler,
		addToCartButtonHandler,
		quickViewButtonHandler;

	dataGetters = {
		pid: function() {
			var updatedPidFromDOM = $(document).find('#pid').val();
			var initialPidfromAppObj = app.page && app.page.currentProduct && app.page.currentProduct.pid ? app.page.currentProduct.pid : '';
			return updatedPidFromDOM || initialPidfromAppObj;
		},
		masterpid: function() {
			return app.page.currentProduct.masterID;
		},
		maxrecommendations: function($block) {
			var carousel = $block.find($cache.selectors.jcarousel),
				carouselSettings = carousel.length && carousel.data('settings') ? carousel.data('settings') : false,
				maxItems;

			if (carouselSettings && this['alignmaxrecommendations']($block)) {
				maxItems = carouselSettings.items;

				var responsive = carouselSettings.responsive,
					viewport = window.innerWidth,
					maxMatch = -1;

				if (responsive) {
					$.each(responsive, function(breakpoint) {
						if (breakpoint <= viewport && breakpoint > maxMatch) {
							maxMatch = breakpoint;
						}
					});

					if (maxMatch !== -1 && 'items' in responsive[maxMatch]) {
						maxItems = responsive[maxMatch].items;
					}
				}
			} else {
				maxItems = $block.data('maxrecommendations');
			}

			return maxItems;
		},
		alignmaxrecommendations: function($block) {
			return $block.data('alignmaxrecommendations');
		},
		search: function() {
			return app.util.getParameterValueFromUrl('q');
		},
		category: function() {
			return app.page.category;
		},
		productsearchresultids: function() {
			if (app.page && 'productSearchResultIDs' in app.page) {
				return app.page.productSearchResultIDs.join(';');
			} else {
				return '';
			}
		},
		productsincartids: function() {
			return app.page.productsInCartIDs ? app.page.productsInCartIDs : {};
		},
		masterproductsincartids: function() {
			return app.page.masterProductsInCartIDs ? app.page.masterProductsInCartIDs : {};
		},
		zone: function($block) {
			return $block.data('zone');
		},
		minicartproductsids: function() {
			return $cache.minicartRecommendationsInfo.data('minicart-products-ids');
		},
		format: function() {
			return app.util.getParameterValueFromUrl('format');
		},
		slotcontentproductsids: function($block) {
			return $block.data('slotcontent-products-ids') ? $block.data('slotcontent-products-ids') : '';
		},
		inclusiveamount: function($block) {
			return $block.data('inclusive-amount') ? $block.data('inclusive-amount') : '';
		},
		showselectedswatchonly: function($block) {
			return $block.data('showselectedswatchonly');
		},
		viewtype: function($block) {
			return $block.data('viewtype') || 'default';
		},
		disablename: function($block) {
			return $block.data('disablename');
		},
		showsubtitle: function($block) {
			return $block.data('showsubtitle');
		},
		disablesubtitle: function($block) {
			return $block.data('disablesubtitle');
		},
		disablepricing: function($block) {
			return $block.data('disablepricing');
		},
		disablepromotion: function($block) {
			return $block.data('disablepromotion');
		},
		showaddtocart: function($block) {
			return $block.data('showaddtocart');
		},
		showratings: function($block) {
			return $block.data('showratings');
		},
		productlistitems: function() {
			return app.page.resx.productListItems;
		},
		pricelist: function() {
			return app.page.resx.priceList;
		},
		productlist: function() {
			return app.page.resx.productList;
		},
		qtylist: function() {
			return app.page.resx.qtyList;
		},
		customerid: function() {
			return app.page.resx.customerid || '';
		},
		transactionid: function() {
			return app.page.resx.transactionid;
		},
		total: function() {
			return app.page.resx.total;
		},
		producttiletype: function($block) {
			return $block.data('producttiletype');
		},
		nonrecomendedtiles: function() {
			var tiles = [];
			app.ui.main.find('.js-capture_product_id').each(function() {
				var id = $(this).data('master-id') || null;
				if (id != null) {
					tiles.push(id);
				}
			});
			return tiles.join(';');
		},
		producttilecustomcssclasses: function($block) {
			return $block.data('producttilecustomcssclasses');
		},
		producttilecustomimagetypes: function($block) {
			return $block.data('producttilecustomimagetypes');
		},
		productnameclasses: function($block) {
			return $block.data('product-name-classes');
		},
		totalprice: function() {
			return app.page.totalPrice;
		},
		ishtmlplacement: function($block) {
			return $block.data('ishtmlplacement') ? $block.data('ishtmlplacement') : 'false';
		}
	};

	providerSchemeConfig = {
		DEMANDWARE: {
			PDP: {
				params: ['pid', 'maxrecommendations']
			},
			CART: {
				params: ['maxrecommendations', 'productsincartids']
			},
			NOHITS: {
				params: ['slotcontentproductsids']
			},
			SEARCH: {
				params: ['maxrecommendations', 'search', 'productsearchresultids']
			},
			PLP: {
				params: ['maxrecommendations', 'category', 'productsearchresultids']
			},
			MINICART: {
				params: ['maxrecommendations', 'minicartproductsids']
			},
			EMPTY_CART: {
				params: ['slotcontentproductsids']
			}
		}
	};

	fillProviderProductsConfig = {
		DEMANDWARE: function(
			$block,
			recommendationsProvider,
			providerData,
			fillRecommendationBlockHandler,
			dataGetters,
			quickViewButtonHandler
		) {
			var typeOptions = {
				DEMANDWARELASTVISITED: {
					url: app.urls.getDWLastVisited,
					isProviderDataRequired: false,
					restrictedZones: []
				},
				DEMANDWARECROSSSELL: { url: app.urls.getDWRecommendations },
				DEMANDWAREUPSELL: { url: app.urls.getDWRecommendationsUpSell },
				DEMANDWAREOTHERS: { url: app.urls.getDWRecommendationsOthers },
				DEMANDWAREALSOVIEW: {
					url: app.urls.getDWRecommendationsAlsoView
				},
				DEMANDWAREALSOBOUGHT: {
					url: app.urls.getDWRecommendationsAlsoBought
				},
				DEMANDWARESHOPTHELOOK: {
					url: app.urls.getDWRecommendationsShopTheLook
				},
				DEMANDWARETOPSELL: { url: app.urls.getDWRecommendationsTopSell }
			};
			getDemandwareRecommendation(
				$block,
				recommendationsProvider,
				providerData,
				fillRecommendationBlockHandler,
				dataGetters,
				quickViewButtonHandler,
				typeOptions[recommendationsProvider.type]
			);
		}
	};

	/**
	 * @TODO Too many arguments, need to re-factor this piece of code
	 */
	getDemandwareRecommendationOld = function(
		$block,
		recommendationsProvider,
		providerData,
		fillRecommendationBlockHandler,
		dataGetters,
		quickViewButtonHandler,
		typeOptions
	) {
		var options = 'undefined' !== typeof typeOptions ? typeOptions : {};
		var providerTypeUrl = 'url' in options ? options['url'] : app.urls.getDWRecommendations;
		var isProviderDataRequired = 'isProviderDataRequired' in options ? !!options['isProviderDataRequired'] : true;
		var restrictedZones = 'restrictedZones' in options ? options['restrictedZones'] : ['EMPTY_CART', 'NOHITS'];

		if (!isProviderDataRequired || !jQuery.isEmptyObject(providerData)) {
			app.recommendations.setLoaderBar($block);
			var zone = dataGetters.zone($block);
			if (restrictedZones instanceof Array && restrictedZones.indexOf(zone) === -1) {
				jQuery.ajax({
					type: 'GET',
					dataType: 'json',
					url: providerTypeUrl,
					data: providerData,
					cache: false,
					success: function(productTiles) {
						if (productTiles) {
							fillRecommendationBlockHandler(
								$block,
								app.recommendations.getRecommendedProducts(productTiles),
								null,
								null,
								dataGetters,
								quickViewButtonHandler
							);
						} else {
							app.recommendations.hideBlock($block);
							$(document).trigger('recommendations.notfound');
						}
					},
					error: function() {
						app.recommendations.hideBlock($block);
					}
				});
			} else {
				fillRecommendationBlockHandler(
					$block,
					app.recommendations.getRecommendedProducts(dataGetters.slotcontentproductsids($block)),
					null,
					null,
					dataGetters,
					quickViewButtonHandler
				);
			}
		} else {
			app.recommendations.hideBlock($block);
		}
	};

	getDemandwareRecommendation = function(
		$block,
		recommendationsProvider,
		providerData,
		fillRecommendationBlockHandler,
		dataGetters,
		quickViewButtonHandler,
		typeOptions
	) {
		var options = 'undefined' !== typeof typeOptions ? typeOptions : {};
		var providerTypeUrl = 'url' in options ? options['url'] : app.urls.getDWRecommendations;
		var isProviderDataRequired = 'isProviderDataRequired' in options ? !!options['isProviderDataRequired'] : true;
		var restrictedZones = 'restrictedZones' in options ? options['restrictedZones'] : ['EMPTY_CART', 'NOHITS'];

		if (!isProviderDataRequired || !jQuery.isEmptyObject(providerData)) {
			app.recommendations.setLoaderBar($block);
			var zone = dataGetters.zone($block);
			if (restrictedZones instanceof Array && restrictedZones.indexOf(zone) > -1) {
				providerData.recids = dataGetters.slotcontentproductsids($block);
			}
			fillRecommendationBlockHandler(
				$block,
				providerData,
				providerTypeUrl,
				null,
				null,
				dataGetters,
				quickViewButtonHandler
			);
		} else {
			app.recommendations.hideBlock($block);
		}
	};

	fillRecommendationBlockHandler = function(
		$block,
		providerData,
		providerTypeUrl,
		pageID,
		blockTitle,
		dataGetters,
		quickViewButtonHandler
	) {
		var globalParamsArray = [
			'zone',
			'viewtype'
			/*				,'showaddtocart'
				,'showratings'
				,'producttiletype'
				,'showselectedswatchonly'
				,'disablename'
				,'showsubtitle'
				,'disablesubtitle'
				,'disablepricing'
				,'disablepromotion'
				,'producttilecustomcssclasses'
				,'producttilecustomimagetypes'
				,'productnameclasses'*/
		];
		var params = (function() {
			var globalParams = {};

			for (
				var i = 0, globalParamsArrayLength = globalParamsArray.length, currentParam, currentVal;
				i < globalParamsArrayLength;
				i++
			) {
				currentParam = globalParamsArray[i];
				currentVal = dataGetters[currentParam]($block);
				if (currentVal != 'false' && currentVal != '') {
					globalParams[currentParam] = currentVal;
				}
			}
			if ($cache.customParamsFillRecommendationBlock && typeof $cache.customParamsFillRecommendationBlock === 'object') {
				globalParams = $.extend(globalParams, $cache.customParamsFillRecommendationBlock);
			}

			return globalParams;
		})();

		var data = providerData;

		if ($block.data('appendAssets')) {
			data.recommendedAppendAssets = JSON.stringify($block.data('appendAssets'));
		}
		if ($block.data('prependAssets')) {
			data.recommendedPrependAssets = JSON.stringify($block.data('prependAssets'));
		}

		var options = {
			url: (function() {
				return app.util.appendParamsToUrl(providerTypeUrl, params);
			})(),
			data: data,
			success: function(itemListHtml) {
				var itemList = $block.find('ul:first').length ? $block.find('ul:first') : $block,
					$blockTitle = $block.find('.recommendation_title');
				app.recommendations.removeLoaderBar($block);

				itemList.html(itemListHtml);

				if (itemList.find($cache.selectors.productTileWrapper).length === 0) {
					app.recommendations.hideBlock($block);
					$(document).trigger('recommendations.notfound', $block);
					return false;
				}

				if (itemList.hasClass('js-product_carousel-list') || itemList.hasClass('js-product_list')) {
					var productCarousel = itemList.closest('.js-carousel'),
						inclusiveAmountEnable = dataGetters['inclusiveamount']($block),
						recommendationsjCarousel = productCarousel.find($cache.selectors.jcarousel),
						productCarouselConfigs = recommendationsjCarousel.data('settings')
							? recommendationsjCarousel.data('settings')
							: false;

					//singleItem looped owlCarousel 1 fix
					if (
						productCarouselConfigs &&
						typeof productCarouselConfigs === 'object' &&
						productCarouselConfigs.singleItem &&
						itemList.find($cache.selectors.productTileWrapper).length === 1
					) {
						recommendationsjCarousel.data(
							'settings',
							$.extend(productCarouselConfigs, {
								nav: false,
								loop: false,
								dots: false
							})
						);
					}

					if (
						inclusiveAmountEnable &&
						(productCarouselConfigs &&
							typeof productCarouselConfigs === 'object' &&
							'items' in productCarouselConfigs) &&
						itemList.find($cache.selectors.productTileWrapper).length > productCarouselConfigs.items
					) {
						recommendationsjCarousel.data(
							'settings',
							$.extend(productCarouselConfigs, {
								nav: true,
								loop: true
							})
						);
					}

					if (productCarousel.length) {
						$(document).trigger('carousel.init', {
							container: productCarousel
						});
					}
				}
				app.ui.main.trigger('imageReplace.globalResponsive');

				app.recommendations.initializeEvents($block, quickViewButtonHandler);
				$(document).trigger('recommendations.loaded', $block.get(0));
			},
			fail: function() {
				app.recommendations.hideBlock($block);
			},
			always: function() {
				app.progress.hide();
			},
			type: 'GET'
		};

		$.ajax(options);
	};

	initCache = function() {
		$cache = {
			recommendationsBlocks: jQuery('.js-recommendations_block'),
			minicartRecommendationsInfo: jQuery('#minicartRecommendationsInfo')
		};
		$cache.selectors = {
			productTileWrapper: '.js-product_tile_wrapper',
			jcarousel: '.js-productrecommendations-jcarousel'
		};
	};

	quickViewButtonHandler = function() {
		$('.cta_quickview').click(function() {
			app.quickView.show({
				url: this.href,
				source: 'quickview',
				recomendationsPage: $(this)
					.parents('.js-recommendations_block')
					.data('zone')
			});
			return false;
		});
	};

	/**  app.recommendations.globalconfig namespace */
	app.recommendations = app.recommendations || {};
	app.recommendations.globalconfig = {
		getDataGetters: function() {
			return dataGetters;
		},
		getProviderSchemeConfig: function() {
			return providerSchemeConfig;
		},
		getFillProviderProductsConfig: function() {
			return fillProviderProductsConfig;
		},
		getCache: function() {
			initCache();
			return $cache;
		},
		getFillRecommendationBlockHandler: function() {
			return fillRecommendationBlockHandler;
		},
		getQuickViewButtonHandler: function() {
			return quickViewButtonHandler;
		}
	};
})((window.app = window.app || {}), jQuery);
