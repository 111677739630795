//app.components.account.addresses
( function(app, $) {
	var $cache = {};
	
	function initializeEvents(params) {
		
		initAddressEvents(params);
		initializeAddressForm();
		
	}
	
	function initializeCache() {
		$cache.addressForm = $(".js-edit_address-form");
		$cache.addresses = $(".js-address_book");
		$cache.addressesMakeDefaultButton = $('.js-make_default_button');
		$cache.addressesMadeDefaultButtonCls = 'b-address_item--checked';
		$cache.addressInput = $cache.addressForm.find("input[name$='_addressid']");
		$cache.countrySelect = $cache.addressForm.find("select[id$='_country']");
		$cache.phoneCode = $cache.addressForm.find("select[id$='_phoneCode']");
		if (typeof $cache.phoneCountryCodeMapp === 'undefined' && app.phone && 'validationData' in app.phone) {
			$cache.phoneCountryCodeMapp = {};
			var keys = Object.keys(app.phone.validationData);
			var item;
			for (var i = 0, l = keys.length; i < l; i++) {
				item = app.phone.validationData[keys[i]];
				$cache.phoneCountryCodeMapp[item['countryCode']] = item['code'];
			}
		}
	}
	
	/**
	 * @private
	 * @function
	 * @description Initializes the events on the address form (apply, cancel, delete)
	 * @param {Element} form The form which will be initialized
	 */
	function initializeAddressForm() {
		initializeCache();
		app.validator.init();
		$cache.addressForm.find("input[name='format']").remove();
		
		app.components.global.tooltips.init();
		
		$cache.addressForm.on("click", ".js-apply_button", function(e) {
			e.preventDefault();
			var addressId = $cache.addressForm.find("input[name$='_addressid']");
			addressId.val(addressId.val().replace(/[^\w+\- ]/g, "-"));
			$cache.addressForm.validate();
			if (!$cache.addressForm.valid()) {
				return false;
			}
			var url = app.util.appendParamsToUrl($cache.addressForm.attr('action'),{format:"ajax"});
			var applyName = $cache.addressForm.find('.js-apply_button').attr('name');
			var options = {
				url: url,
				data: $cache.addressForm.serialize()+"&"+applyName+'=x',
				type: "POST"
			};
			$.ajax( options ).done(function(data){
				if( typeof(data)!=='string' ) {
					if (data.success) {
						app.fancybox.close();
						app.page.refresh(true);
					} else if (data.redirectURL) {
						app.page.redirect(data.redirectURL);
					} else {
						$('.fancybox-inner').html(data.message);
						return false;
					}
				} else {
					$('.fancybox-inner').html(data);
					app.components.account.addresses.init();
					app.components.global.tooltips.init();
				}
			});
		})
			.on("click", ".js-delete_button", function(e){
				e.preventDefault();
				var url = app.util.appendParamsToUrl($cache.addressForm.attr('action'), {format:"ajax"});
				var actionName = $(this).attr('name');
				$.ajax({
					url: url,
					data: $cache.addressForm.serialize()+"&"+actionName+'=x',
					type: "POST"
				}).done(function(data){
					if (data.redirectURL) {
						app.page.redirect(data.redirectURL);
					} else if (data.status.toLowerCase() === 'ok') {
						app.fancybox.close();
						app.page.redirect(app.urls.addressesList);
					} else if (data.message.length > 0) {
						$('.fancybox-inner').html(data.message);
						return false;
					} else {
						app.fancybox.close();
						app.page.refresh(true);
					}
				});
			});

		if (($cache.addressInput.val()==='' || $cache.addressInput.length === 0) && typeof $cache.phoneCountryCodeMapp !== 'undefined') {
			$cache.phoneCode.val($cache.phoneCountryCodeMapp[$cache.countrySelect.val()]);
		}

			$cache.countrySelect.on("change", function(){
				var country = $(this);
				if( !app.countries ) {
				app.countries = app.customObjects.countriesAndStates;
				}
			var states = app.countries[country.val()];
			if ( country.length !== 0 && states && Object.keys(states.regions).length !== 0 ) {
				var options = "",
					select = "",
					selectOptiopnsTemplate = $("#js-template-customer_address-state_option").html(),
					selectTemplate = $("#js-template-customer_address-state_select").html();
					
					for( var code in states.regions ) {
						var optionsObj = {"code":code,"name":states.regions[code]};
						options += app.util.renderTemplate(selectOptiopnsTemplate, optionsObj);
					}
					
					select = app.util.renderTemplate(selectTemplate, {options : options});
					
					$('.js-customer_address-state_field').html(select);
				} else {
					$('.js-customer_address-state_field').html('');
				}
				if ($cache.phoneCode.is(':hidden')) {
					$cache.phoneCode.trigger('change-phone-code', country.val());
				}
				app.fancybox.update();
			});

			$cache.phoneCode.on('change-phone-code', function(e, countryCode){
				var $this = $(this);
				if ($this.is(':hidden') && countryCode in $cache.phoneCountryCodeMapp) {
					$this.val($cache.phoneCountryCodeMapp[countryCode]);
				}
				$this.closest('form').find('input[name$=_phone]').trigger('focusout');
			});
			
			var $options = $('.js-customer_address-state_field select option');
			if ($options.length < 2) {
				$('.js-customer_address-state_field').html('');
			}
		}
		
		
		/**
		 * @private
		 * @function
		 * @description Binds the events on the address form (edit, create, delete)
		 */
		function initAddressEvents(params) {
			
			if ($cache.addresses.length===0) { return; }

			$cache.addresses.on("click", ".js-edit_button, .js-create_button, .js-delete_button", function(e){
				e.preventDefault();
			var options = {type: 'ajax',ajax : {type: "POST", cache : false}, wrapCSS: "fancybox-edit_address", afterShow: initializeAddressForm, padding: app.util.getConfig('account.addresses.createAddress.fancyboxPadding')};
				if ((params != undefined) && !jQuery.isEmptyObject(params)){
					$.extend(options, params);
				}
				app.fancybox.open(this.href, options);
			}).on("change", ".js-make_default_button", function(e){
				e.preventDefault();
				$cache.addressesMakeDefaultButton.removeClass($cache.addressesMadeDefaultButtonCls);
				$(this).addClass($cache.addressesMadeDefaultButtonCls);
				$.ajax({
					url: app.util.appendParamsToUrl($(this).val(), {format:"ajax"}),
					dataType:"json"
			}).done(function(data) {
				if (data.redirectURL) {
					app.page.redirect(data.redirectURL);
				} else if (data.status.toLowerCase() !== 'ok') {
					app.page.refresh(true);
				}
			});
		});
		}
		
		
		/**
		 * @namespace app.components.account.addresses public object
		 **/
		app.components = app.components || {};
		app.components.account = app.components.account || {};
		app.components.account.addresses = {
			init : function (params) {
				
				initializeCache();
				initializeEvents(params);
				
			}
		};

	}(window.app = window.app || {}, jQuery));
