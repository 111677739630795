(function (app, $) {
	var $cache = {};
	
	function initializeCache(){
		$cache = {
			document: $(document),
			hHidden: 'h-hidden'
		};
	}
	
	function initializeEvents(){
		$cache.document.on("click", "[data-show]", function(){
			var element = $($(this).data("show"));
			element && element.length && element.removeClass($cache.hHidden);
		});
		$cache.document.on("click", "[data-hide]", function(){
			var element = $($(this).data("hide"));
			element && element.length && element.addClass($cache.hHidden);
		});
	}
	
	app.popuptoggler = {
		init: function(){
			initializeCache();
			initializeEvents();
		}	
	};
	
}(window.app = window.app || {}, jQuery));