(function (app, $) {
	
	function getDataForSession(){
		var data = {};
		if (app.user.firstVisit === true) {
			data.firstVisit = false;
		}
		return data;
	}
	
	//function can be used in future to process server response
	function processResponse(){}
	
	function updateSessionByAjax() {
		var data = getDataForSession();
		if (!$.isEmptyObject(data)) {
			$.ajax({
				url: app.urls.updateSession,
				type: 'POST',
				dataType: 'json',
				data : data
			}).done(function(resp){
				processResponse(resp);
			});
		}
	}

	/*************** app.components.global.session public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.session = {
		// initializations
		init : function () {
			updateSessionByAjax();
		}
	};
})(window.app = window.app || {}, jQuery);
