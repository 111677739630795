//IMPORTANT: Height for masonry items is necessary (in asset code), because of Ipad Safari bug. 
define('app.components.global.masonry', function(require, exports) {
	var $ = require('$'),
		$cache = {};
	
	function initializeCache() {
		Masonry = require('masonry');
		$cache = {
			document: $(document),
			masonryGridSelector: '.js-masonry-grid',
			masonryGridItemSelector: '.js-masonry-grid-item',
			loadedMasonryClass: 'm-loaded'
		};
	}
	
	function isTabletMasonryViewOn(customMasonryTabletBpValue) {
		return (customMasonryTabletBpValue && $(window).width() < customMasonryTabletBpValue);
	}
	
	function initMasonryGrid(horizontalOrder) {
		// Get the Masonry object properties from data-attributes of masonry block in asset's code 
		//{"horizontalOrder": value} - Fix for Ipad last item position issue  ("horizontalOrder" value can't be taken from data-attributes after window.load)
		var msnry = new Masonry($cache.masonryGridSelector, {"horizontalOrder": horizontalOrder}); 
		$($cache.masonryGridSelector).addClass($cache.loadedMasonryClass);
		return msnry;
	}
	
	function initializeEvents() {
		if($($cache.masonryGridSelector).length) {
			var msnry = {};
			var horizontalOrder = false;
			var masonryDataAttributes = $($cache.masonryGridSelector).data().masonry;
			
			if(!!masonryDataAttributes) {
				horizontalOrder = $($cache.masonryGridSelector).data().masonry.horizontalOrder === true;
			}
			if(!app.device.isIOS()) {
				msnry = initMasonryGrid(horizontalOrder);
			} 
			
			$(window).load(function() {
				if(app.device.isIOS()) {
				//We should init Masonry for Ipad only after loading page
				//Masonry item's height Safari bugfix
					msnry = initMasonryGrid(horizontalOrder);
				}
			});
		}
	}

	/*************** app.components.global.masonry public object ***************/
	exports.init = function () {
		if (!require('device').isMobileView()) {
	        initializeCache();
	        initializeEvents();
		}
    };
});
