// app.recommendations
var resx = resx || {};

(function(app, $) {
	var configs = {
		dataGetters : {},
		providerSchemeConfig : {},
		fillProviderProductsConfig : {},
		cache : {},
		fillRecommendationBlockHandler : {},
		addToCartButtonHandler : {},
		quickViewButtonHandler : {}
	}
	
	/**  app.recommendations namespace */
	app.recommendations = {
		makeCall : true,
		init : function (params) {
			if (params) {
				this.makeCall = params.makeCall ? params.makeCall : this.makeCall; 
			}
			
			if('globalconfig' in app.recommendations) {
				configs.dataGetters = app.recommendations.globalconfig.getDataGetters();
				configs.providerSchemeConfig = app.recommendations.globalconfig.getProviderSchemeConfig();
				configs.fillProviderProductsConfig = app.recommendations.globalconfig.getFillProviderProductsConfig();
				configs.cache = app.recommendations.globalconfig.getCache();
				configs.fillRecommendationBlockHandler = app.recommendations.globalconfig.getFillRecommendationBlockHandler();
				configs.quickViewButtonHandler = app.recommendations.globalconfig.getQuickViewButtonHandler();
			}
			if("customconfig" in app.recommendations) {
				configs.dataGetters = $.extend( true, configs.dataGetters, app.recommendations.customconfig.getDataGetters() || {});
				configs.providerSchemeConfig = $.extend(true, configs.providerSchemeConfig, app.recommendations.customconfig.getProviderSchemeConfig() || {});
				configs.fillProviderProductsConfig = $.extend(true, configs.fillProviderProductsConfig, app.recommendations.customconfig.getFillProviderProductsConfig() || {});
				configs.cache = $.extend(true, configs.cache, app.recommendations.customconfig.getCache() || {});
				configs.fillRecommendationBlockHandler = app.recommendations.customconfig.getFillRecommendationBlockHandler() || configs.fillRecommendationBlockHandler;
				configs.quickViewButtonHandler = app.recommendations.customconfig.getQuickViewButtonHandler() || configs.quickViewButtonHandler;
			}
			app.recommendations.initializeRecommendationsBlocks();
		},
		
		getProviderData : function ($block, recommendationsProvider) {
			var params = {};
			if (recommendationsProvider && recommendationsProvider.config && recommendationsProvider.config.params) {
				for (var i = 0; i < recommendationsProvider.config.params.length; i++) {
					var key = recommendationsProvider.config.params[i];
					if (configs.dataGetters[key]) {
						var dataValue = configs.dataGetters[key]($block);
						if (dataValue) {
							params[key] = dataValue;
						}
					}
				}
			}

			return params;
		},
		
		initializeRecommendationsBlocks : function() {
			if('recommendationsBlocks' in configs.cache) {
				configs.cache.recommendationsBlocks.each(function() {
					var $block = $(this);
					app.recommendations.initializeRecommendationsSingleBlock($block, false);
				});
			}
			
		},
		
		initializeRecommendationsSingleBlock : function($block){
			if ($block.data('ready') === 'true') {
				return;
			}
			var recommendationsProvider = app.recommendations.getProviderForCurrentZone($block);
			if (recommendationsProvider && recommendationsProvider.config) {
				var fillProducts = configs.fillProviderProductsConfig[recommendationsProvider.name];
				if (typeof fillProducts === 'function') {
					var providerData = app.recommendations.getProviderData($block, recommendationsProvider);
					fillProducts($block, recommendationsProvider, providerData, configs.fillRecommendationBlockHandler, 
						configs.dataGetters, configs.quickViewButtonHandler);
						
					if ($block.data('changeTitle') && configs.cache.changeTitleProductName && ($block.find('h2 span span').length > 0)) {
						$block.find('h2 span span').html(configs.cache.changeTitleProductName);
					}
					
				}
				$block.data('ready', 'true');
			}
		},
		
		getProviderForCurrentZone : function($block) {
			var zone = configs.dataGetters.zone($block);
			var recommendationsProviderName = $block.data('recommendations-provider').toUpperCase();
			var recommendationsType = $block.data('recommendations-type') || 'demandwareCrossSell';
			recommendationsType = recommendationsType.toUpperCase();
			var recommendationsProviderConfig = configs.providerSchemeConfig[recommendationsProviderName];

			if (recommendationsProviderConfig) {
				return {
					'config' : recommendationsProviderConfig[ zone.toUpperCase()],
					'name' : recommendationsProviderName,
					'type' : recommendationsType
				};
			} else {
				return;
			}
		},
		
		getRecommendedProducts : function(productTiles){
			if (productTiles){
				var recommmendedProductsIDs = productTiles.toString().split('|');
				var recommendedProducts = new Array();
	
				for (var itemNumber = 0; itemNumber < recommmendedProductsIDs.length; itemNumber++) {
					var recommendedProduct = {};
					recommendedProduct.ID = recommmendedProductsIDs[itemNumber];
					recommendedProducts.push(recommendedProduct);
				}
				return recommendedProducts;
			}
		},
		
		initializeEvents : function($block, quickViewButtonHandler) {
			quickViewButtonHandler($block);
			app.components.global.images.init();
		},
		
		setLoaderBar : function($block) {
			app.progress.show($block);
		},

		removeLoaderBar : function() {
			app.progress.hide();
		},
		
		hideBlock : function($block) {
			$block.hide();
			$block.parent().find('.you_may_also_like_title').hide();
			$block.parent().find('.recommendation_message').hide();
			if ('recommendationsBlocksParentClass' in configs.cache) {
				$block.closest('.'+configs.cache.recommendationsBlocksParentClass).hide();
			}
		},
		
		updateCartRecommendations : function($block) {
			$.get(app.urls.cartGetRecommendationTabs).then(function(response) {
				$block.replaceWith(response);
			});
		}
	};
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.recommendations = app.recommendations;
}(window.app = window.app || {}, jQuery));
