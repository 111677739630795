( function(app, $) {
	var PROVIDER_NONE       = 'none',
		PROVIDER_ADDTHIS    = 'addThis',
		PROVIDER_NATIVE     = 'native';

	var $cache = {},
		defaultConfig = {
			'updateOnClick' : '.js-wishlist_share',
			'networks' : ['facebook', 'twitter', 'pinterest', 'tumblr', 'google', 'sinaweibo']
		},
		provider = app.preferences.socialShareProvider || PROVIDER_NONE,
		config = {},
		addthis = {};

	function initializeCache() {
		$cache = {
			elements : {
				document : $(window.document),
				shareBar : $('.social-share-bar')
			},
			isPDPConfigurable: 'configurator' in app.components.product && app.components.product.configurator.isConfigurable,
			customInputSel : 'input[name = customProducts]'
		}
	}

	function initializeConfig(params) {
		config = params && 'object' === typeof params ? $.extend({}, defaultConfig, params) : defaultConfig;
	}

	function initializeEvents() {
		switch (provider) {
			case PROVIDER_ADDTHIS:
				initAddThisForContainer($cache.elements.document);
				$cache.elements.document.on('socialnetworking.reinit', function(e, data) {
					if('container' in data) {
						initAddThisForContainer(data.container);
					}
				});
				break;
			case PROVIDER_NATIVE:
				$cache.elements.shareBar.find('.js-social_pinterest').on('click', function(event) {
					$cache.elements.document.trigger('social.clicked', event);
					event.preventDefault();
					$("#pinmarklet").remove();
					var scr = document.createElement('script');
					scr.setAttribute('type','text/javascript');
					scr.setAttribute('charset','UTF-8');
					scr.setAttribute('id','pinmarklet');
					scr.setAttribute('src','//assets.pinterest.com/js/pinmarklet.js?r=' + Math.random() * 99999999);
					document.body.appendChild(scr);
				});

				$cache.elements.shareBar.find('.social-share-button:not(.js-social_pinterest)').on('click', function(event) {
					event.preventDefault();
					var el = this;
					$cache.elements.document.trigger('social.clicked', event);
					if($cache.isPDPConfigurable){
						var customInput = $($cache.customInputSel);
						if(typeof customInput.val() !== 'undefined' && customInput.val() !== ''){
							var socialShareNewWindow = window.open("", el.title, $cache.elements.shareBar.data('popup'));
							app.components.product.configurator.saveProductCustomization(function (response) {
								var parameters = app.util.getQueryStringParams(el.href);
								var url = el.href;
								if(parameters.url) {
									url = app.util.appendParamToURL(parameters.url, 'customizationID', response.customizationID.toString());
									url = app.util.appendParamToURL(app.util.removeParamFromURL(el.href, 'url'), 'url', url);
								}
								if(parameters.u) {
									url = app.util.appendParamToURL(parameters.u, 'customizationID', response.customizationID.toString());
									url = app.util.appendParamToURL(app.util.removeParamFromURL(el.href, 'u'), 'u', url);
								}
								socialShareNewWindow.location = url;
							}, customInput.val());
						} else {
							app.components.product.configurator.saveProductCustomizationError();
						}
					} else {
						return !window.open(this.href, this.title, $cache.elements.shareBar.data('popup'));
					}
				});
				break;
		}
	}

	/**
	 * @function
	 * @description Initializes the 'AddThis'-functionality for the social sharing plugin for container
	 *
	 * @param {Object} container Container element
	 */
	function initAddThisForContainer(container) {
		var addThisServices = config['networks'] || {},
			addThisToolboxes = container.find('.js-addthis_toolbox'),
			namespaceConfig = config['namespace'] || {};

		addThisToolboxes.each(function() {
			var addThisToolbox = $(this),
				addThisLinks='',
				len = addThisServices.length;

			for (var i = 0; i < len; i++) {
				var existsSeviceLinks = addThisToolbox.find('.addthis_button_'+addThisServices[i]);
				if (existsSeviceLinks.length === 0) {
					addThisLinks += '<a class="addthis_button_'+addThisServices[i]+'"></a>';
				} else {
					//get DOM element as string for same approach
					addThisLinks += existsSeviceLinks.clone().wrap('<div/>').parent().html();
				}
			}

			if (addThisLinks.length > 0) {
				addThisToolbox.html(addThisLinks);
			}
		});

		try{
			addthis.toolbox('.js-addthis_toolbox');
		} catch(e) {
			window.console && console.log && console.log("social share: can't init AddThis");
			return;
		}
		if (namespaceConfig && $.inArray(app.page.ns, namespaceConfig) !== -1) {
			container.on('mouseover click', config.updateOnClick, function() {
				var $this = $(this);
				var productLink = $this.data('productShare');
				if (!productLink) return;
				var imgSrc = $this.data('shareImage');
				productLink = app.util.getAbsoluteUrl(productLink);
				addthis.update('share', 'url', productLink);
				if (imgSrc) {
					imgSrc = app.util.getAbsoluteUrl(imgSrc);
					addthis.update('share', 'img', imgSrc);
				}
				addthis.ready();
			});
		}
	}

	/**
	 * @namespace app.components.global.socials public object
	 **/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.socials = {
		init : function (params) {
			if (provider === PROVIDER_ADDTHIS) {
				if (!window.addthis) {
					console.warn('Component initialization failed. AddThis missed. [product.socialnetworking]');
					return;
				}
				addthis = window.addthis;
			}
			initializeConfig(params);
			initializeCache();
			initializeEvents();
		}
	};
}(window.app = window.app || {}, jQuery));