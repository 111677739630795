/**
 * @class app.geolocation
 */
(function (app, $) {
	var source = app.preferences.geolocationBackupService || 'https://freegeoip.net/json/';
	app.geolocation = {
			
		/**
		 * @function
		 * @description Retrieves current coordinates, returns deffered with resolved params
		 * @returns {Object} Deffered (if success containing object with lat, lng)
		*/
			
		getCurrentPosition: function(){
			var deffered = $.Deferred();
			
			deffered.notify();
			if(navigator.geolocation && !app.device.isMacOS() && !app.device.isIOS()){
				navigator.geolocation.getCurrentPosition(function(position){	
					params = {
						"lat" : position.coords.latitude,
						"lng" : position.coords.longitude
					};
					deffered.resolve(params);
				}, deffered.reject, { timeout: 5000 });
			}
			else{
				$.ajax({
					url: source,
					dataType: 'jsonp',
					cache: true,
					jsonp: 'callback'
				})
				.done(function(data){
					if(!data){
						deffered.reject();
						return;
					}
					
					var params = {
						"lat": data.latitude,
						"lng": data.longitude
					};
					
					deffered.resolve(params);
				})
				.fail(function(){
					deffered.reject();
				});
			}
			
			return deffered;
		}
	};
}(window.app = window.app || {}, jQuery));