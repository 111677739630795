/**
 * Newsletter+signup flyout
 *
 * The component has two states:
 * a) Newsletter subscribe. (default).
 * b) Create new customer and subscribe.
 *
 * User switches the component state by clicking link '.js-nl_dropdown-flyout-account_registration-link'
 * In case a). After finishing subscription a fancybox is opened
 * In case b). User is redirected to MyAccount
 *
 */
(function (app, $) {
    var $cache = {},
		iframeTarget = 'newsleteriframe';

    function initializeCache() {
        $cache = {
        	wrapper      : $('.js-nl_dropdown'),
            flyout       : $('.js-nl_dropdown-flyout'),
            headerTitle  : $('.js-nl_dropdown-title_signup'),
            nlForm       : $('.js-nl_dropdown-subscribe_form'),
            accountLink  : $('.js-nl_dropdown-flyout-account_registration-link'),
            accountBox   : $('.js-nl_dropdown-flyout-account_registration-box'),
            privacyLink  : $('.js-nl_dropdown-flyout-privacy_box_link')
        };
        $cache.profileEmailField = $cache.nlForm.find('#dwfrm_profile_customer_email');
        $cache.nlEmailField      = $cache.nlForm.find('#dwfrm_newsletter_simple_email');
    }

    function initializeEvents() {
    	$cache.wrapper.on('mouseenter', function(){
    		$cache.flyout.show();
    	});
    	$cache.wrapper.on('mouseleave', function(){
    		$cache.flyout.hide();
    	});

    	$cache.accountLink.on('click', function(){
    		var $this = $(this);

    		if ($cache.accountBox.data('account-opened')) {
    			$cache.accountBox.removeClass("h-show");
    			$cache.accountBox.data('account-opened', false);
    		} else {
    			$cache.accountBox.addClass("h-show");
    			$cache.accountBox.data('account-opened', true);
    		}
    		return false;
    	});

      $cache.nlForm.on('submit', function(e) {
			var formData;

			if ($cache.accountBox.data('account-opened')) {
				$cache.nlForm.validate();
				if ($cache.nlForm.valid()) {
					formData  = $cache.nlForm.serializeArray();
					app.components.account.fakelogin.sendMessage({
						formData : formData,
						oneapp   : {target : iframeTarget}
					});
				}
				return false;
			} else {
				$cache.profileEmailField.validate();
				if ($cache.profileEmailField.valid()) {
					$cache.nlEmailField.val($cache.profileEmailField.val());
					var action = $cache.nlForm.attr('data-nl_action');
					var data   = $cache.nlEmailField.attr('name') + '=' + encodeURIComponent($cache.nlEmailField.val());
					$.post(action, data, function(response) {
						app.fancybox.open(response, {modal: true});
					});
				}
				e.preventDefault();
				return false;
			}
        });

		$(window).on("message", function(e) {
			var originUrl = e.originalEvent.origin;

			if ( originUrl === getIframeUrl()) {
				var message = JSON.parse(e.originalEvent.data);
				if ( message.oneapp && message.oneapp.target == iframeTarget ) {
					$cache.flyout.html(''+message.html);
					app.components.global.newsletterflyout.init();
	    			$cache.accountBox.addClass("h-show");
	    			$cache.accountBox.data('account-opened', true);
	    			app.validator.initForm($cache.nlForm);
				}
			}
		});


        /**
         * Open the Privacy in fancybox by click link
         */
        $cache.privacyLink.on('click', function(){
    		var $this = $(this);

    		var fancyboxOption = {type : "ajax", autoSize: false, width: 600, height: "80%", scrolling: "auto", closeBtn: true};
    		app.fancybox.open(this.href, fancyboxOption);
        	return false;
        });
    }

	/*
	 * get origin url for post message to iframe
	 */
	function getIframeUrl() {
		return "https://" + window.location.host;
	}


	/**
	 * @namespace app.components.global.newsletterflyout public object
	 **/
  app.components = app.components || {};
  app.components.global = app.components.global || {};
  app.components.global.newsletterflyout = {
      init : function (params) {
        initializeCache();
        initializeEvents();
      }
  };

})(window.app = window.app || {}, jQuery);
