;(function(app) {
	function NotificationFactory() {}
	
	NotificationFactory.create = function(notificationType, config) {
		switch(notificationType) {
			case 'configurator':
				return new app.configurator.Notification(config);
			default:
				throw new Error('There is no constructor for notification of type: ' + notificationType);
		}
	};
	
	app.notificationFactory = {
		create: function(notificationType, config) {
			return NotificationFactory.create(notificationType, config);
		}
	};
})((window.app = window.app || {}));
