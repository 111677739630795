//app.components.account.returnproducts
( function(app, $) {
		var $cache = {};
		
		function initializeCache() {
			
			$cache = {
				returnDetails : $('.b-return_details'),
				orderLineItems :  $('.js-return_product_list-wrapper'),
				orderPaymentSummary : $('.js-orderpayment_summary'),
				price : 0,
				returnError : $('.js-returnError'),
				returnForm : $('form.js-returnForm'),
				checkPrivacy : $('.js-checkPrivacy'),
				fancy_open: $(".js-return_fancybox_open"),
				storeCredit : $('.js-storeCredit'),
				storeCreditCheckbox : $('.js-storeCredit input[type="checkbox"]'),
				returnAuthorizationError : $('.js-return_authorization-error'),	
				returnTooltip : $('div.js-return_tooltip_mobile'),
				returnTooltipMsg : $('span.js-tooltip_content_mobile'),
				htmlBody : $('html, body'),
				returnAuthErrorMessages: $(".js-return_auth_error_messages"),
				paymentAmount: $('.js-paymentamount')
			};
			
			$cache['currency'] = $cache.orderPaymentSummary.find('.js-paymentamount').text().substring(0,1);
			
		}
		
		function initializeEvents() {
			
			$cache.orderPaymentSummary.hide();
			$cache.orderLineItems.find('.js-return_product').each(function(){
				var e = $(this),
					c = new CheckSelect(e.find('.f-checkbox'), e.closest('.js-return_product_list').find('.js-return_reason'));
				
				c.init(e);
			});
			
			$cache.checkPrivacy.find('input[type=checkbox]').on('change', function(){
				$cache.checkPrivacy.toggleClass('checked');
				privacyValidation();
			})
			
			privacyValidation();
			
			$cache.fancy_open.on("click", function() {
	            var fancyboxContent = $(this).data('content');
	            if (fancyboxContent) {
	                app.fancybox.open( $(fancyboxContent) );
	            }
	        });
			
			if ($cache.returnAuthorizationError.length) {
				$cache.storeCredit.on('click', function(e) {
					e.preventDefault();
					if($cache.returnAuthorizationError.hasClass('h-hidden')){
						$cache.storeCredit.addClass("checked");
						$cache.storeCreditCheckbox.prop("checked",true);
						$cache.returnAuthorizationError.removeClass('h-hidden');
					} else if (!$cache.storeCreditCheckbox.prop("disabled")) {
						$cache.storeCredit.removeClass("checked");
						$cache.storeCreditCheckbox.prop("checked",false);
						$cache.returnAuthorizationError.addClass('h-hidden');
					}
					return false;
				});
			}
		$cache.returnTooltip.on('click', function() {
			if (!$cache.returnTooltip.hasClass('js-shown')) {
				$cache.returnTooltipMsg.show();
				$cache.returnTooltip.addClass('js-shown m-shown')
			} else {
				$cache.returnTooltipMsg.hide();
				$cache.returnTooltip.removeClass('js-shown m-shown')
			}
		});
			
			scrollToReturnAuthErrors();
		}
		
		function privacyValidation(){
			if ($cache.checkPrivacy.find('input[type=checkbox]').is(':checked')){
				$cache.checkPrivacy.addClass('checked');
			}
			$cache.returnError.hide();
			$cache.returnForm.on('submit', function(){
				
				if(!$cache.checkPrivacy.hasClass("checked")){
					$cache.returnError.show();
				} else {
					$cache.returnError.hide();
				}		
			});
		}
		
		
		var CheckSelect = function(trigger, target){
			this.trigger = trigger;
			this.target = target;
			this.select = null;
			this.reasonTarget = null;
			
			this.status = false;
			
			this.selectedReason = null;
			this.showAdditionalBoxReason = null;
			
			this.init = function(wrapper){
				this.status = this.trigger.prop('checked');
				this.trigger.on('change', {handle: this}, function(e){
					var elem = $(this);
					
					if (elem.hasClass("js-active-item")) {
						$cache.price -= parseFloat(elem.closest('.js-return_product_list').find('.js-return_product_list-data_price span').data("priceValue"));
					} else {
						$cache.price += parseFloat(elem.closest('.js-return_product_list').find('.js-return_product_list-data_price span').data("priceValue"));
					}
					var itemCurrency = elem.closest('.js-return_product_list').find('.js-return_product_list-data_price').data("currency")
					
					if ($cache.price <= 0 ) {
						$cache.orderPaymentSummary.hide();
					}
					else {
						$.get(app.urls.formatPrices, {"standardPrice": $cache.price, "currency": itemCurrency})
							.done(function(response) {
								var prices = JSON.parse(response);
								$cache.paymentAmount.text(prices.standardPrice);
								$cache.orderPaymentSummary.show();
							});
					}
					
					elem.toggleClass("js-active-item");
					e.data.handle.change(elem.attr('checked'));
				});
				
				this.select = this.target.find('select');
				this.selectedReason = this.select.val();
				this.showAdditionalBoxReason = this.select.find('option[data-mandatory-reason][value="' + this.selectedReason + '"]').length;
				this.select.on('change', {handle: this}, function(e){
					var elem = $(this);
					e.data.handle.showAdditionalBoxReason = elem.find('option[data-mandatory-reason][value="' + elem.val() + '"]').length;
					e.data.handle.selectedReason = elem.val();
					// change without general status
					e.data.handle.change(e.data.handle.status);
				});
				
				this.reasonTarget = wrapper.find('.js-reason_additional');
				if (!this.status) this.target.hide();
				if (this.status && !!this.showAdditionalBoxReason){
					this.reasonTarget.show();
				} else{
					this.reasonTarget.hide();
				}
			};
			
			this.change = function(newStatus){
				if (newStatus != this.status) {
					if (newStatus){
						this.appear(this.target);
					} else {
						this.vanish(this.target);
						this.vanish(this.reasonTarget);
					}
					
					this.status = newStatus;
				}
				
				if (!!this.showAdditionalBoxReason && this.status) {
					this.appear(this.reasonTarget);
				} else {
					this.vanish(this.reasonTarget);
				}
				
			};
			
			this.vanish = function(elem){
				elem.fadeOut(200);
			};
			
			this.appear = function(elem){
				elem.fadeIn(200);
			};
			
		};
		
		function scrollToReturnAuthErrors() {
			if ($cache.returnAuthErrorMessages.length) {
				$cache.htmlBody.scrollTop($cache.returnAuthErrorMessages.offset().top);
			}
		};
				
		/**
		 * @namespace app.components.account.returnproducts public object
		 **/
		app.components = app.components || {};
		app.components.account = app.components.account || {};
		app.components.account.returnproducts = {
			init : function (params) {
				
				initializeCache();
				initializeEvents();

			}
		};

	}(window.app = window.app || {}, jQuery));
