(function (app, $, global) {
	
	var $cache = {},
		animSpeed = 500,
		topAnchorMargin = parseInt(app.preferences.scrollToAnchorTopMargin);
	
	function initializeCache() {
		$cache = {
			anchorLinksSel      : "a[href*=#]",
			header              : '.js-header_main',
			backToQuestionClass : 'js-back-to-link'
		};
	}
	
	function initEvents() {
		
		$(document).on("click", $cache.anchorLinksSel, function(e){
	         
	         var $this = $(this),
	         	 headerElement = $($cache.header),
	         	 target,
	         	 topIndent;
	         
	         if ($this.attr('href') !== undefined) {
	        	 var parsedLinkElement = $('<a/>', { href: $this.attr('href') }).get(0),
	        	 	currentAbsPath = window.location.href.replace(window.location.hash, '');
	        	 
	        	 if($this.attr('href').indexOf(parsedLinkElement.hash) === 0 || currentAbsPath === parsedLinkElement.href.replace(parsedLinkElement.hash, '')){
	    	         e.preventDefault();
		        	 target =  $('[name=' + parsedLinkElement.hash.replace('#', '') + '], ' + parsedLinkElement.hash);
		        	 topIndent = headerElement.css('position')  === 'fixed' ? headerElement.height() : 0;
			         topIndent += topAnchorMargin;
			         
			         if ($this.data('custom-href')) {
			        	 target = $('a[href=' + $this.data('custom-href') + ']');
			         } else if (!$this.hasClass($cache.backToQuestionClass)) {
				         $('.' + $cache.backToQuestionClass).data('custom-href', $this.attr('href'));
			         }
			         
			         $('html, body').stop().animate({
				         	scrollTop: target.offset().top - topIndent
			         	}, animSpeed);
	        	 }
	         }
		});
	}
	
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.scrollToAnchor = {
		init : function(){
			
			if(!app.preferences.isMobileView) {
				initializeCache();
				initEvents();
			}
		}
	};
	
}(window.app = window.app || {}, jQuery, window));
