(function ( $ ) {

/* morelines - simple to use, if you need to expand the text by various line height
 *
 * $(function() {
 *	$('.js-morelines_your-class-name').moreLines({
 *		linecount: auto,                      // type number of lines, by default is just one line
 *		classspecific: 'your-class-name',     // it will concat your name in 'b-morelines_your-class-name_wrapper/_ellipsis/_button'
 *		buttontxtmore: "more",                // choose you button name
 *		buttontxtless: "less",                // choose you button name
 *		animationspeed: auto                  // choose animation speed
 *	});
 * });
 * 
 * V.Tsurule
 */
	$.fn.moreLines = function (options) {

	"use strict";

		this.each(function(){

			var baseclass = "b-morelines_",
				basejsclass = "js-morelines_",
				currentclass = "section",
				element = $(this),
				auto = parseFloat(element.css("line-height")),
				fullheight = element.innerHeight(),
				settings = $.extend({
					linecount: auto,
					classspecific: currentclass,
					buttontxtmore: "more lines",
					buttontxtless: "less lines",
					animationspeed: auto
				}, options ),
				
				ellipsisclass = baseclass+settings.classspecific+"_ellipsis",
				buttondefault = baseclass+settings.classspecific+"_button",
				wrapcss = baseclass+settings.classspecific+"_wrapper",
				wrapjs = basejsclass+settings.classspecific+"_wrapper",
				wrapper = $("<div>").addClass(wrapcss+ ' ' +wrapjs).css({'max-width': element.css('width')}),
				linescount = auto * settings.linecount;

			element.wrap(wrapper);

			if (element.parent().not(wrapjs)) {

				if (fullheight > linescount) {

				element.addClass(ellipsisclass).css({'min-height': linescount, 'max-height': linescount, 'overflow': 'hidden'});
				var moreLinesButton = $("<div>", {
					"class": buttondefault,
					click: function() {

						element.toggleClass(ellipsisclass);
						$(this).toggleClass(buttondefault+'_active');

						if (element.css('max-height') !== 'none') {
							element.css({'height': linescount, 'max-height': ''}).animate({height:fullheight}, settings.animationspeed, function () {
								moreLinesButton.html(settings.buttontxtless);
							});

						} else {
							element.animate({height:linescount}, settings.animationspeed, function () {
								moreLinesButton.html(settings.buttontxtmore);
								element.css('max-height', linescount);
							});
						}

					},

					html: settings.buttontxtmore
				});

				element.after(moreLinesButton);
				}
			}
		});

		return this;
	};

}(jQuery));