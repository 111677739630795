(function(app, $) {
	var $cache = {};
	
	function initializeCache(){
		$cache = {
			document: $(document),
			wishlistBlock: $('.js-wishlistblock'),
			wishlistBlockTitleSel: '.js-wishlistblock-title',
			wishlistBlockEmptySel: '.js-wishlist-empty-title',
			wishlistBlockContentSel: '.js-wishlistblock-content',
			wishlistAddToCartButton: '.js-wishlistblock .js-add_to_cart_button',
			wishlistRemoveButton: '.js-wishlistblock .js-wishlistblock-remove',
			wishlistBlockLoginSel: '.js-wishlistblock-login',
			showCheckoutLoginSel: '.js-wishlistblock-login-container',
			notifyMePopupSel: '.js-notifyme_popup',
			hHidden: 'h-hidden',
			activeClass: 'active'
		};
	}
	
	function initializeDOM(){
		displayWishlistBlock();
	}
	
	function initializeEvents(){
		$cache.document.on('wishlist.added', function(){
			displayWishlistBlock();
		});
		
		$cache.document.on('cart.update.models', function(){
			var wishlistBlockTitle = $($cache.wishlistBlockTitleSel);
			if(wishlistBlockTitle.find($cache.wishlistBlockEmptySel).length){
				wishlistBlockTitle.removeClass($cache.activeClass).addClass('empty');
			}
			displayWishlistBlock();
		});
		$cache.document.on(Modernizr.touchevents ? 'touchstart' : 'click', $cache.wishlistAddToCartButton, function(e) {
			e.preventDefault();
			var $this = $(this),
				contentTile = $this.closest('.js-wishlistblock-content_tile'),
				pid = $this.data('pid'),
				pliid = contentTile.data('item'),
				pliidQty = contentTile.data('itemQty'),
				self = this;
			if($cache.wishlistBlock.data('animated')){
				var removeButton = contentTile.children('.js-wishlistblock-remove'),
					productTile = $this.closest('.js-product_tile'),
					addedMessage = contentTile.children('.js-wishlistblock-content-added_message');
				
				removeButton.animate({opacity: 0}, 1000);
				productTile.animate({opacity: 0}, 1000, function(){
					addedMessage.delay(200).fadeIn(500).delay(1500).fadeOut(500, function(){
						removeButton.addClass($cache.hHidden);
						removeProductFromWishlistBlock(pliid, true);
						productTile.addClass($cache.hHidden);
						if(app.page.ns !== 'checkout'){
							addToCartFromWishlistBlock(pid, pliidQty, pliid);
						}
						else{
							$cache.document.trigger('wishlist.cart.add', self);
						}
					});
				});
			}
			else{
				if(app.page.ns !== 'checkout'){
					addToCartFromWishlistBlock(pid, pliidQty, pliid);
				}
				else{
					$cache.document.trigger('wishlist.cart.add', this);
				}
			}
		});
		
		$cache.document.on('click', $cache.wishlistRemoveButton, function(e){
			e.preventDefault();
			var $this = $(this),
				pliid = $this.data('item');
			
			if(app.page.ns !== 'checkout' && pliid){
				removeProductFromWishlistBlock(pliid);
			}
		});
		
		$cache.document.on(Modernizr.touchevents ? 'touchstart' : 'click', $cache.wishlistBlockTitleSel, function() {
			var $this = $(this);
			if(!$this.find($cache.wishlistBlockEmptySel).length){
				var content = $this.next($cache.wishlistBlockContentSel),
					isActive = $this.toggleClass($cache.activeClass).hasClass($cache.activeClass);
				
				content.toggleClass($cache.hHidden);
				if(isActive){
					$.cookie($this.data('type'), 'true', { path: '/' });
				}
				else{
					$.removeCookie($this.data('type'), { path: '/' });
				}
			}
		});
		
		$cache.document.on('click', $cache.wishlistBlockLoginSel, function(e){
			e.preventDefault();
			if(app.page.ns !== 'checkout' && app.device.currentDevice() !== 'mobile'){
				e.stopPropagation();
				app.components.account.fakelogin.show(e);
			}
		});
	}
	
	function displayWishlistBlock(){
		if($cache.wishlistBlock.length){
			var url = app.urls.getFlyoutWishlist;
			url = app.util.appendParamsToUrl(url, {'wishlistblock': true, 'ns': app.page.ns});
			
			app.ajax.load({
				url: url,
				callback: function(data){
					if(data){
						$cache.wishlistBlock.html(data);
						var $wishListProducts = $cache.wishlistBlock.find($cache.notifyMePopupSel);
						if ($wishListProducts.length >= 1) {
							$wishListProducts.on('click', app.product.openNotifyMePopup);
						}
						if (app.page.ns === 'checkout' || app.device.currentDevice() === 'mobile') {
							$($cache.wishlistBlockLoginSel).attr('data-show', $cache.showCheckoutLoginSel);
						}

						document.dispatchEvent(new CustomEvent('price-reinit'));
						document.dispatchEvent(new CustomEvent('lazyload-reinit'));

						callTitleTrigger();
					}
				}
			});
		}
	}
	
	function removeProductFromWishlistBlock(pliid, doNotToggleBlock){
		var data;
		if(pliid){
			data = {
				'pliid': pliid,
				'format': 'ajax',
				'wishlistblock': true
			};
			
			$.ajax({
				url: app.urls.removeProdyctFromWishlist,
				type: 'GET',
				data: data
			})
			.done(function(data){
				if(data){
					$cache.wishlistBlock.html(data);
					$cache.document.trigger('wishlist.updated', ['wishlistblock', doNotToggleBlock]);
					callTitleTrigger();
				}
			});
		}
	}

	function addToCartFromWishlistBlock(pid, quantity, pliid) {
		quantity = quantity || '1';

		if (pid) {
			var postdata = {
				'Quantity': quantity,
				'cartAction': 'add',
				'pid': pid
			};

			app.cart.update(postdata, function(response) {
				if (app.preferences.cartAddProductAjaxTarget === 'popup') {
					app.fancybox.close();
					var $minicart;

					if (app.device.isMobileView()) {
						$minicart = $(response).filter('#app-components-global-minicart-template');

						if ($minicart.length) {
							$cache.document.trigger('product.added');
						}

						app.components.global.minicart.showMinicartTemplate($minicart);
					} else {
						app.fancybox.open(response, {
							afterShow: function() {
								$minicart = $('#app-components-global-minicart-template');

								app.components.global.minicart.showMinicartTemplate($minicart);
								$cache.document.trigger('cart.addproduct.popup.open');
							},
							afterClose: function() {
								$cache.document.trigger('cart.addproduct.popup.close');
							}
						});
					}
				} else {
					$cache.document.trigger('minicart.show', { html: response });
				}
				if (pliid) {
					removeProductFromWishlistBlock(pliid, true);
				}
			});
		}
	}

	function callTitleTrigger(){
		var title = $($cache.wishlistBlockTitleSel),
			cookieName = title.data('type'),
			cookie;
		
		if(cookieName){
			cookie = $.cookie(cookieName);
			if (!cookie) {
				closeWishlistBlockContent();
			}
		}
	}
	
	function closeWishlistBlockContent(){
		var title = $($cache.wishlistBlockTitleSel),
			content = title.next($cache.wishlistBlockContentSel);
		
		title.removeClass($cache.activeClass);
		content.addClass($cache.hHidden);
	}
	
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.wishlistblock = {
		init : function () {
			initializeCache();
			initializeDOM();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);