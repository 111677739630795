;(function (app, $, global) {
	var trackerData = (app && app.trackerData) || {};
	var tagsOnPage = {
		
	},
	eventsOnPage = {
		"default" : {
		}
	}

	
	app.gtm = app.gtm || {};
	app.gtm.config = app.gtm.config || {};
	app.gtm.config.custom = {
		"getTags" : function() {
			return tagsOnPage;
		},
		"getEvents" : function() {
			return eventsOnPage;
		}
	}

}(window.app = window.app || {}, jQuery, window));