(function (app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			body: $('body'),
			welcomePopupWrapperSel:	'.welcome-popup-wrapper',
			welcomePopupSessionKey:	'welcomePopup',
			welcomePopupInitialized: false
		};
	}

	function initializeEvents() {
		if (!$.cookie($cache.welcomePopupSessionKey) && showWelcomePopupOnCurrentPage()) {
			$.cookie($cache.welcomePopupSessionKey, true, { path: '/' });
			$cache.welcomePopupInitialized = true;

			$.get(atob(app.urls.showWelcomePopup))
				.done(function(response) {
					var parsedHTML = $($.parseHTML(response)).filter($cache.welcomePopupWrapperSel).text();

					if ($.trim(parsedHTML).length) {
						return welcomePopupHandler(response);
					}
				});
		}
	}

	function welcomePopupHandler(body) {
		var options = {
			afterClose: function() {
				if (app.callbacks && app.callbacks.getNewsletterInitStatus() && app.components.global.newsletter.getPopupInitStatus()) {
					$cache.welcomePopupInitialized = false;
					return app.callbacks.newsletterPopupHandler()
				}
				if (app.preferences.enableNewsletterPopup && app.components.global.newsletter.getPopupInitStatus()) {
					return app.components.global.newsletter.popupHandler();
				}
			}
		};

		setTimeout(function() {
			app.fancybox.open(body, options);
		}, app.util.getConfig('welcomePopupOpenTimeout'));
	}

	function showWelcomePopupOnCurrentPage() {
		return app.configs.pagesWhereShowWelcomePopup.indexOf('*') !== -1 || app.configs.pagesWhereShowWelcomePopup.indexOf(app.page.currentPage) !== -1;
	}

	function getWelcomePopupInitStatus() {
		return $cache.welcomePopupInitialized;
	}

	/*************** app.components.global.newsletter public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.welcomepopup = {
		init : function () {
			initializeCache();
			initializeEvents();
		},
		getWelcomePopupInitStatus: getWelcomePopupInitStatus
	};
})(window.app = window.app || {}, jQuery);