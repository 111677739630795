/**
 * @class search.priceslider
 */

(function (app, $) {
	
	var $cache = {};

	function initializeCache() {  
		$cache = {
			priceSlider : $(".js-slider_range"),
			priceMinRange : $( ".js-min_range" ),
			priceMaxRange : $( ".js-max_range" ),
			content : $(".js-search_result-content")
		}
		
	}
	
	function initializeEvents(){
		
		if ($cache.priceSlider.length){
			var data = $cache.priceSlider.data('range'),
				defaultRangeValues = data.split('-'),
				rangeValues = {}
			
			var params = app.util.getQueryStringParams(window.location.href);
			
			if (params.hasOwnProperty('pmin')){
				rangeValues.pmin = Number(params.pmin);
			} else {
				rangeValues.pmin = Number(defaultRangeValues[0]);
			}
			
			if (params.hasOwnProperty('pmax')){
				rangeValues.pmax = Number(params.pmax);
			} else {
				rangeValues.pmax = Number(defaultRangeValues[1]);
			}
			
			$cache.priceSlider.slider({
				range: true,
				min: Number(defaultRangeValues[0]),
				max: Number(defaultRangeValues[1]),
				values: [rangeValues.pmin, rangeValues.pmax],
				slide: function(event, ui){
					$cache.priceMinRange.text(app.resources.CURRENCY_SYMBOL + ui.values[0]);
					$cache.priceMaxRange.text(app.resources.CURRENCY_SYMBOL + ui.values[1]);
				},
				stop: function(event, ui) {
					var url = window.location.href,
						queryObj = {
							'pmin' : ui.values[0],
							'pmax' : ui.values[1]
						}
					
					url = app.util.appendParamsToUrl(url,queryObj);
					
					var options = {
							url : url,
							target : $cache.content,
							data : {
								priceRange : true
							},
							callback : function(){
								app.componentsMgr.loadComponent('product.tile');
							}
						}
					
					app.progress.show($cache.content);
					app.search.updateProductListing(url, false);
				}
			});
			$cache.priceMinRange.text(app.resources.CURRENCY_SYMBOL + $cache.priceSlider.slider( "values", 0 ));
			$cache.priceMaxRange.text(app.resources.CURRENCY_SYMBOL + $cache.priceSlider.slider( "values", 1 ));
		}
	} 
	app.components = app.components || {};
	app.components.search = app.components.search || {};
	app.components.search.priceslider = {
		init : function () {
			initializeCache();
			initializeEvents();
		}
	};
	
}(window.app = window.app || {}, jQuery));